import * as React from "react";
import { colors } from "@/utils/style";
import { useUser } from "@/context";
import CryptoJS from "crypto-js";

export const SUBSCRIPTION_CONTENT = {
    text: "This is unfortunately not part of your subscription plan, please ",
    link: "https://kuyua.pipedrive.com/scheduler/R0y0w3HA/initial-meeting",
    linkText: "get in touch",
    text2: " or ",
    email: "sales@kuyua.com",
    email_text: "send us an email here",
};

interface SubscriptionTooltipProps {
    children: React.ReactNode;
    show?: boolean;
    alwaysShow?: boolean;
    backgroundColor?: string;
    feature?: string;
}

const SubscriptionMessage = ({ feature }) => {
    const user = useUser();

    return (
        <>
            <span style={{ color: colors.white }}>{SUBSCRIPTION_CONTENT.text}</span>
            <a
                href={SUBSCRIPTION_CONTENT.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: colors.brightBlue, textDecoration: "underline" }}
                onClick={async (e) => {
                    e.preventDefault();
                    notifyTeamAboutClientInterest(user, feature, window.location.href);
                    window.open(SUBSCRIPTION_CONTENT.link, "_blank", "location=yes,height=570,width=520,scrollbars=yes,status=yes");
                }}
            >
                {SUBSCRIPTION_CONTENT.linkText}
            </a>
            <span style={{ color: colors.white }}>{SUBSCRIPTION_CONTENT.text2}</span>
            <a
                href={`mailto:${SUBSCRIPTION_CONTENT.email}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: colors.brightBlue, textDecoration: "underline" }}
                onClick={async (e) => {
                    e.preventDefault();
                    notifyTeamAboutClientInterest(user, feature, window.location.href);
                    window.open(
                        `mailto:${SUBSCRIPTION_CONTENT.email}`,
                        "_blank",
                        "location=yes,height=570,width=520,scrollbars=yes,status=yes",
                    );
                }}
            >
                {SUBSCRIPTION_CONTENT.email_text}
            </a>
        </>
    );
};

export const SubscriptionTooltip: React.FC<SubscriptionTooltipProps> = ({
    children,
    show = true,
    alwaysShow = false,
    backgroundColor = "rgba(0, 0, 0, 0.8)",
    feature,
}) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    const handleMouseEnter = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setIsVisible(true);
    };

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setIsVisible(false);
        }, 2000);
    };

    React.useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    if (alwaysShow) {
        return <SubscriptionMessage feature={feature} />;
    }

    return (
        <div style={{ position: "relative", display: "inline-block" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {isVisible && show && (
                <div
                    style={{
                        position: "absolute",
                        bottom: "100%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundColor: backgroundColor,
                        color: "white",
                        padding: "15px",
                        borderRadius: "4px",
                        whiteSpace: "nowrap",
                        zIndex: 1000,
                        marginBottom: "8px",
                        opacity: 1,
                    }}
                >
                    <SubscriptionMessage feature={feature} />
                </div>
            )}
        </div>
    );
};

async function notifyTeamAboutClientInterest(client, feature = "Disabled feature", url) {
    try {
        const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();

        console.log(client);
        const emailSubject = `Kuyua notification => ${client.first_name || ""} ${client.last_name || ""} is interested in a feature`;
        const emailBody = `
        <h1>User Information</h1>
        <hr />
        <b>Account ID:</b> ${client.id}<br />
        <b>Account name:</b> ${window.location.pathname.split("/")[1]}<br />
        <b>User name:</b> ${`${client.first_name || ""} ${client.last_name || ""}`}<br />
        <b>Email:</b> ${client.email}
        <hr />
        <b>feature in question:</b> ${feature}<br />
        <b>URL user was on when the noftification was triggered:</b> ${url}<br />
        `;
        const email = process.env.TEAM_EMAIL || "sales@kuyua.com";
        const response = await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/email`, {
            method: "POST",
            body: JSON.stringify({
                email: email,
                subject: emailSubject,
                body: emailBody,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${encryptedToken}`,
            },
        });
        const json = await response.json();
        if (json.error) {
            console.error("Error sending email: ", json.error);
            return;
        }

        console.log("interest email sent successfully!");
        return;
    } catch (error) {
        console.error("Error sending email: ", error);
        return;
    }
}
