import React from "react";
import CryptoJS from "crypto-js";
import { colors, style, StyleSheet } from "@/utils/style";
import Icon from "../../../lib/Icon";
import { LoadingAnimation } from "@/lib";
import { mdiClose } from "@mdi/js";
import { AppContext, useProject, useTeam, ToastAdded } from "@/context";
import { SleapContext } from "@/context/SleapContext";
import { iLocationForm, INewDBSite } from "@/types/sleap";
import { uid } from "uid";
import { z } from "zod";
export default function LocationBulkInsertForm({ siteData, saveHandler, closeHandler, scopeOptions }: iLocationForm): JSX.Element {
    const [site, setSite] = React.useState<INewDBSite | undefined>(siteData);
    const [scopeId, setScopeId] = React.useState<number | null>(null);
    const [seperator, setSeperator] = React.useState("");
    const [sitesText, setSitesText] = React.useState("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [economic_activities, setEconomicActivities] = React.useState([]);
    const editForm = React.useRef(null);
    const team = useTeam();
    const project = useProject();
    const { state, dispatch } = React.useContext(AppContext);
    const { sleapState, sleapDispatch } = React.useContext(SleapContext);
    const [countryOptions, setCountryOptions] = React.useState<{ id: number; label: string; code: string }[]>([]);
    const [selectedCountryIds, setSelectedCountryIds] = React.useState<number[]>([]);
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            Authorization: `Bearer ${encryptedToken}`,
        },
    };
    async function getEconomicActivities() {
        console.log("reportID ==> ", project.latest_report_id);
        let countEndPointURL = `${process.env.KUYUA_APP_BACKEND_URL}/economic-activities`;
        const response = await fetch(countEndPointURL, header);
        const data = await response.json();
        return data.economic_activities;
    }
    try {
        if (economic_activities.length == 0) {
            getEconomicActivities().then((result) => {
                setEconomicActivities(result);
                // //save into localstorage
                // if (window) {
                //     window.localStorage.setItem('economic_activities', JSON.stringify(result));
                // }
            });
        }
    } catch (error) {
        console.log("error: ", error.message);
    }

    function handleFormSeperator(value: string) {
        setSeperator(value);
    }
    function handleFormSitesText(value: string) {
        setSitesText(value);
    }

    async function getEconomomicActivitiesIdsByName(economicActivities: string) {
        let economic_activities_ids = [];
        try {
            if (economicActivities) {
                let economic_activities_names = economicActivities.split(seperator);
                economic_activities_names.map(async (activity) => {
                    const mappedActivity = economic_activities.find((act) => activity.includes(act.economic_activity_name));
                    if (mappedActivity) {
                        economic_activities_ids.push(mappedActivity.economic_activity_id);
                    } else {
                        const replacedActivity = activity.replaceAll(",", " -");
                        const mappedActivityReplace = economic_activities.find((act) =>
                            replacedActivity.includes(act.economic_activity_name),
                        );
                        if (mappedActivityReplace) {
                            economic_activities_ids.push(mappedActivityReplace.economic_activity_id);
                        }
                    }
                });
                return economic_activities_ids.join(",");
            }
        } catch (error) {
            console.log("error:", error.message + "\n" + economicActivities);
        }
    }
    async function getEconomomicActivitiesSepratedByHash(economicActivities: string) {
        let economic_activities_ids = [];
        try {
            if (economicActivities) {
                let economic_activities_names = economicActivities.split(seperator);
                return economic_activities_names.join("#");
            }
        } catch (error) {
            console.log("error:", error.message + "\n" + economicActivities);
        }
    }
    const handleSiteType = (site_type_id: string) => {
        switch (site_type_id) {
            case "own":
                return 1;
            case "upstream":
                return 2;
            case "downstream":
                return 3;
            default:
                break;
        }
    };
    async function getLatAndLngFromAddress(address) {
        const token = "pk.eyJ1IjoiYW5kcmUtaGlja21hbm4iLCJhIjoiY2xoNjR4enBkMDE3cjNqcGc0aG93ZzlueSJ9.JH3ClP3oIf2uvc4ZpFvjJQ";
        //const token = 'sk.eyJ1IjoiYW5kcmUtaGlja21hbm4iLCJhIjoiY2xtajJ5MnM2MDBncTJrbzA0bXJvZmt1aiJ9.30udtxWX0q8OQug5LMQDeA';
        let base_url = "https://api.mapbox.com/geocoding/v5/mapbox.places/";
        let base_url_with_address = base_url + address + ".json";
        const options = {
            headers: {
                access_token: token,
            },
        };
        const response = await fetch(base_url_with_address + `?access_token=${token}`);
        const geocodingObj = await response.json();
        if (geocodingObj.features) {
            //console.log("geocodingObj ====>", geocodingObj.features[0]);
            return geocodingObj.features[0].center;
        }
    }
    async function convertSitesListToCSV(sites) {
        const array = [Object.keys(sites[0])].concat(sites);
        return array
            .map((row) => {
                return Object.values(row)
                    .map((value) => {
                        // Ensure that any values containing commas are properly quoted
                        if (typeof value === "string" && value.includes(",")) {
                            value = `"${value}"`;
                        }
                        return value;
                    })
                    .join(";");
            })
            .join("\n");
    }
    const siteSchema = z.object({
        site_type_id: z.number({
            required_error: "invalid site type.",
            invalid_type_error: "invalid site type.",
        }),
        company_id: z.number(),
        site_name: z.string().min(1, { message: "site name is required." }),
        city: z.string(),
        country: z.string(),
        zip_code: z.string(),
        country_code: z.string(),
        street_address: z.string(),
        economic_activity_ids: z.string(),
    });
    const latlngSchema = z.number({
        required_error: "latitude and longitude are required",
        invalid_type_error: "latitude or longitude must be a number",
    });
    async function saveSites(): Promise<void> {
        if (!valid) return;
        let newDbSites = [];
        let Errors = [];
        let rowsCount = 0;
        setLoading(true);
        try {
            const rows = sitesText.split("\n");
            rows.map(async (row) => {
                const siteKey = uid(32);
                const parsedData = row.split(";");
                let lngValid = {};
                let latValid = {};
                if (parsedData[3] && parsedData[3] !== "site_type") {
                    rowsCount++;
                    let siteAddress = {
                        city: parsedData[6],
                        region: "",
                        country: parsedData[7],
                        province: "",
                        zip_code: parsedData[5],
                        country_code: parsedData[8],
                        street_address: parsedData[4],
                    };
                    let priority_score = Number(parsedData[22]);
                    let longitude = 0;
                    let latitude = 0;
                    if (parsedData[9] === "" || parsedData[10] === "") {
                        let address = siteAddress.country + " " + siteAddress.city + " " + siteAddress.street_address;
                        if (address.length > 256) address = siteAddress.country + " " + siteAddress.street_address;
                        try {
                            let latlng = await getLatAndLngFromAddress(address);
                            //console.log("latlng ==>", latlng);
                            if (latlng) {
                                longitude = latlng[0];
                                latitude = latlng[1];
                                lngValid = latlngSchema.safeParse(Number(longitude));
                                latValid = latlngSchema.safeParse(Number(latitude));
                            } else {
                                Errors.push({
                                    site_Index: rows.indexOf(row),
                                    site_Errors: "error at getting lat and long for this site.",
                                });
                                return null;
                            }
                        } catch (error) {
                            Errors.push({
                                site_Index: rows.indexOf(row),
                                site_Errors: "error at getting lat and long for this site.",
                            });
                            return null;
                        }
                    } else {
                        lngValid = latlngSchema.safeParse(Number(parsedData[9]));
                        latValid = latlngSchema.safeParse(Number(parsedData[10]));
                        //if (!isNaN(Number(parsedData[10])) && !isNaN(Number(parsedData[9]))) {
                        if (lngValid.success && latValid.success) {
                            latitude = Number(parsedData[9]);
                            longitude = Number(parsedData[10]);
                        } else {
                            console.log("Error:", `Site ${parsedData[1]} has wrong latlng.`);
                            //dispatch({ type: ToastAdded, toast: { kind: 'error', text: `Site ${parsedData[1]} has wrong latlng.` } });
                        }
                    }
                    let economic_activities_ids_per_new_row = await getEconomomicActivitiesIdsByName(parsedData[11]);
                    let newDbLatLng = `POINT(${longitude} ${latitude})`;
                    let siteTypeId = handleSiteType(parsedData[3]);
                    const newDbSite = {
                        site_type_id: siteTypeId,
                        company_id: team.id,
                        priority_score: -1,
                        latlng: newDbLatLng,
                        site_name: parsedData[1],
                        city: siteAddress.city,
                        region: "",
                        country: siteAddress.country,
                        province: "",
                        zip_code: siteAddress.zip_code,
                        country_code: siteAddress.country_code,
                        street_address: siteAddress.street_address,
                        site_key: siteKey,
                        priority_location: priority_score > 0 ? true : false,
                        site_asssessment_status_old: "not started",
                        id_old: 0,
                        is_deleted: false,
                        economic_activity_ids: economic_activities_ids_per_new_row ?? "",
                    };
                    const siteValid = siteSchema.safeParse(newDbSite);
                    console.log(`pushed row ${rows.indexOf(row)} ===> ${newDbSite.site_name} and it's ${siteValid.success}`);
                    if (siteValid.success && newDbLatLng) {
                        newDbSites.push(newDbSite);
                    } else {
                        console.log(`Error At row ${rows.indexOf(row)}: ${row}`);
                        let siteError = { site_Index: rows.indexOf(row), site_Errors: [] };
                        if (!siteValid.success) {
                            siteValid.error.issues.map((issue) => {
                                siteError.site_Errors.push(issue.path[0] + ": " + issue.message);
                            });
                        }
                        if (lngValid) {
                            lngValid?.error?.issues.map((issue) => {
                                siteError.site_Errors.push(issue.message);
                            });
                        }
                        if (latValid) {
                            latValid?.error?.issues.map((issue) => {
                                siteError.site_Errors.push(issue.message);
                            });
                        }
                        Errors.push(siteError);
                    }
                }
            });
            console.log("New List site to save into kuyuaappdb =====>", newDbSites);
        } catch (error) {
            console.log("error =====>", error.message);
            setLoading(false);
            dispatch({
                type: ToastAdded,
                toast: { kind: "error", text: "Failed to import sites!" },
            });
        }

        setTimeout(async () => {
            if (Errors.length) {
                console.log("rowsCount ---- > ", rowsCount);
                console.log("Errors ---- > ", Errors.length);
                try {
                    // Convert the array of objects to a string
                    //const fileContent = Errors.join('\n');
                    const fileContent = JSON.stringify(Errors, null, 2);
                    const blob = new Blob([fileContent], { type: "application/json" });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = "FailedImportOutput.json";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                } catch (error) {
                    setLoading(false);
                    console.log("error =====>", error.message);
                    dispatch({
                        type: ToastAdded,
                        toast: { kind: "error", text: "Failed to write output file!" },
                    });
                }
            }
            console.log("newDbSites.length >>>> ", newDbSites.length);

            if (newDbSites.length > 0) {
                try {
                    //for the new DB
                    if (newDbSites.length > 1) {
                        await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/sites`, {
                            method: "POST", // or 'PUT'
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${encryptedToken}`,
                            },
                            body: JSON.stringify(newDbSites),
                        })
                            .then(() => {
                                setLoading(false);
                                if (Errors.length > 0) {
                                    dispatch({
                                        type: ToastAdded,
                                        toast: {
                                            kind: "success",
                                            text: `(${newDbSites.length}) Sites were imported successfully, and (${Errors.length}) Sites failed to be imported, please check the downloaded output file.`,
                                        },
                                    });
                                } else {
                                    dispatch({
                                        type: ToastAdded,
                                        toast: {
                                            kind: "success",
                                            text: `(${newDbSites.length}) Sites were imported successfully.`,
                                        },
                                    });
                                }
                                closeHandler();
                            })
                            .catch((error) => {
                                console.log("Error:", error);
                                setLoading(false);
                                dispatch({
                                    type: ToastAdded,
                                    toast: { kind: "error", text: "Failed to import sites!" },
                                });
                            });
                    } else {
                        if (newDbSites.length > 0) {
                            await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/site`, {
                                method: "POST", // or 'PUT'
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${encryptedToken}`,
                                },
                                body: JSON.stringify(newDbSites[0]),
                            })
                                .then((data) => {
                                    if (Errors.length > 0) {
                                        dispatch({
                                            type: ToastAdded,
                                            toast: {
                                                kind: "success",
                                                text: `(${newDbSites.length}) Sites were imported successfully, and (${Errors.length}) Sites failed to be imported, please check the downloaded output file.`,
                                            },
                                        });
                                    } else {
                                        dispatch({
                                            type: ToastAdded,
                                            toast: {
                                                kind: "success",
                                                text: `(${newDbSites.length}) Sites were imported successfully.`,
                                            },
                                        });
                                    }
                                    closeHandler();
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    console.log("Error:", error);
                                    dispatch({
                                        type: ToastAdded,
                                        toast: { kind: "error", text: "Failed to import sites!" },
                                    });
                                });
                        } else {
                            setLoading(false);
                            dispatch({
                                type: ToastAdded,
                                toast: { kind: "error", text: "Failed to import sites!" },
                            });
                        }
                    }
                } catch (error) {
                    setLoading(false);
                    console.log("Error:", error);
                    dispatch({
                        type: ToastAdded,
                        toast: { kind: "error", text: "Failed to import sites!" },
                    });
                }
            } else {
                setLoading(false);
                if (Errors.length > 0) {
                    dispatch({
                        type: ToastAdded,
                        toast: {
                            kind: "error",
                            text: `(${Errors.length}) Sites failed to be imported, please check the downloaded output file.`,
                        },
                    });
                } else {
                    dispatch({
                        type: ToastAdded,
                        toast: { kind: "error", text: "Failed to import sites!" },
                    });
                }
            }
        }, 2000);
    }
    const valid = seperator !== "" && sitesText !== "";

    return (
        <>
            <div ref={editForm} style={{ ...styles.locationForm, width: "50%" }} className={"sleapSectionRight"}>
                <div style={{ padding: 10 }}>
                    <div style={{ ...style.closeIcon, ...style.centerFlex }} onClick={() => closeHandler()}>
                        <Icon path={mdiClose} color={colors.darkBlue} size={17} />
                    </div>
                    <div style={{ padding: "0 20px" }}>
                        <div
                            style={{
                                ...style.flexRow,
                                width: "66%",
                                gap: 40,
                                justifyContent: "flex-start",
                            }}
                        >
                            <div>
                                <div>Economic activities seperator</div>
                                <input
                                    style={{ ...style.inputField, ...styles.inputField }}
                                    value={seperator}
                                    onChange={(e) => handleFormSeperator(e.target.value)}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                ...style.flexRow,
                                width: "66%",
                                gap: 40,
                                justifyContent: "flex-start",
                            }}
                        >
                            <div>
                                <div>Sites</div>
                                <textarea
                                    style={{ ...style.inputField, ...styles.textarea }}
                                    value={sitesText}
                                    onChange={(e) => handleFormSitesText(e.target.value)}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                ...style.flexRow,
                                marginTop: 10,
                                justifyContent: "flex-end",
                                opacity: !valid ? 0.5 : 1,
                            }}
                        >
                            <button style={style.confirmButton} onClick={() => saveSites()} disabled={!valid}>
                                SAVE
                            </button>
                        </div>
                    </div>
                </div>
                {loading && (
                    <div className="s-loading-overlay">
                        <LoadingAnimation />
                    </div>
                )}
            </div>
        </>
    );
}

const styles = StyleSheet.create({
    locationForm: {
        minHeight: 350,
        fontSize: 16,
        width: "unset",
    },
    searchField: {
        border: "1px solid #FFFFFF",
        borderRadius: 20,
        backgroundColor: "transparent",
        opacity: 1,
        padding: "5px 10px",
        color: colors.white,
        marginTop: 10,
        width: "100%",
    },
    inputField: {
        width: 175,
        marginTop: 5,
    },
    containerStyle: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
    radioStyle: {
        backgroundColor: "transparent",
        margin: 0,
        width: 20,
        height: 20,
        border: "0.15em solid #2FF7FF",
        borderRadius: 50,
        position: "relative",
    },
    checkMarkStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transition: "120ms transform ease-in-out",
        width: 15,
        height: 15,
        borderRadius: 50,
    },
});
