import * as React from "react";
import { mdiAccount } from "@mdi/js";
import { useUser } from "@/context";
import { t } from "@/utils";
import { Icon } from "@/lib";
import { useNavigate } from "react-router-dom";

export default function SidebarPreferencesPanel(): JSX.Element {
    const user = useUser();
    const navigate = useNavigate();
    const onAccountClick = (e: React.MouseEvent<HTMLElement>) => {
        if (e.metaKey) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        window.scrollTo(0, 0);

        navigate("/account");
    };

    return (
        <div className="s-app-sidebar-prefs">
            <div>
                <span
                    style={{
                        background: "#99998A",
                        display: "block",
                        lineHeight: 1,
                        padding: "3px 4px 1px 4px",
                        borderRadius: "4px",
                    }}
                >
                    <Icon color="#000" path={mdiAccount} size={12} />
                </span>
            </div>
            <a href="/account" onClick={onAccountClick}>
                {user.first_name}
            </a>
            <a href="/logout">{t("ui.sign_out")}</a>
        </div>
    );
}
