import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";

const useFetchDependencies = (economicActivitiesIds: string, siteId?: string, fallback?: boolean) => {
    const [totalUniqueDependencies, setTotalUniqueDependencies] = useState(null);
    const [totalUniqueDependenciesWithRating, setTotalUniqueDependenciesWithRating] = useState(null);
    const [restructuredDependenciesGeoServer, setRestructuredDependenciesGeoServer] = useState(null);

    const [totalUniqueFallbackDependencies, setTotalUniqueFallbackDependencies] = useState(null);
    const [totalUniqueFallbackDependenciesWithRating, setTotalUniqueFallbackDependenciesWithRating] = useState(null);
    const [restructuredFallbackDependenciesGeoServer, setRestructuredFallbackDependenciesGeoServer] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            Authorization: `Bearer ${encryptedToken}`,
        },
    };

    useEffect(() => {
        const fetchDependencies = async () => {
            if (economicActivitiesIds.length === 0) return;

            const url = `${process.env.KUYUA_APP_BACKEND_URL}/dependencies?economic_activities_ids=${economicActivitiesIds}${
                siteId ? `&site_id=${siteId}` : ""
            }`;

            try {
                const response = await fetch(url, header);
                const data = await response.json();

                let dependenciesData = null;

                if (!fallback) {
                    if (data.dependencies && data.dependencies.totalUnique) {
                        dependenciesData = data.dependencies;
                    } else if (data.potentialDependencies) {
                        dependenciesData = data.potentialDependencies;
                    }

                    if (dependenciesData) {
                        const { totalUnique, totalUniqueWithRating, restructuredItems } = dependenciesData;

                        // Update state
                        setTotalUniqueDependencies(totalUnique);
                        setTotalUniqueDependenciesWithRating(totalUniqueWithRating);
                        setRestructuredDependenciesGeoServer(restructuredItems);

                        // Save to local storage
                        ["totalUniqueDependencies", "totalUniqueDependenciesWithRating", "restructuredDependenciesGeoServer"].forEach(
                            (key, index) =>
                                localStorage.setItem(key, JSON.stringify([totalUnique, totalUniqueWithRating, restructuredItems][index])),
                        );
                    }
                } else {
                    if (data.dependencies && data.dependencies.totalUnique) {
                        const dependenciesData = data.dependencies;
                        const { totalUnique, totalUniqueWithRating, restructuredItems } = dependenciesData;

                        // Update state
                        setTotalUniqueDependencies(totalUnique);
                        setTotalUniqueDependenciesWithRating(totalUniqueWithRating);
                        setRestructuredDependenciesGeoServer(restructuredItems);
                    }
                    if (data.potentialDependencies && data.potentialDependencies.totalUnique) {
                        const dependenciesData = data.potentialDependencies;
                        const { totalUnique, totalUniqueWithRating, restructuredItems } = dependenciesData;

                        // Update state
                        setTotalUniqueFallbackDependencies(totalUnique);
                        setTotalUniqueFallbackDependenciesWithRating(totalUniqueWithRating);
                        setRestructuredFallbackDependenciesGeoServer(restructuredItems);

                        // Save fallback data to local storage
                        [
                            "totalUniqueFallbackDependencies",
                            "totalUniqueFallbackDependenciesWithRating",
                            "restructuredFallbackDependenciesGeoServer",
                        ].forEach((key, index) =>
                            localStorage.setItem(key, JSON.stringify([totalUnique, totalUniqueWithRating, restructuredItems][index])),
                        );
                    }
                }
            } catch (error) {
                console.error("Error fetching dependencies:", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchDependencies();
    }, [economicActivitiesIds, siteId, fallback]);

    return {
        totalUniqueDependencies,
        totalUniqueDependenciesWithRating,
        restructuredDependenciesGeoServer,
        totalUniqueFallbackDependencies,
        totalUniqueFallbackDependenciesWithRating,
        restructuredFallbackDependenciesGeoServer,
        loading,
        error,
    };
};

export default useFetchDependencies;
