import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";

const useFetchImpacts = (economicActivitiesIds: string, siteId?: string, fallback?: boolean) => {
    const [totalUniqueImpacts, setTotalUniqueImpacts] = useState(null);
    const [totalUniqueImpactsWithRating, setTotalUniqueImpactsWithRating] = useState(null);
    const [restructuredImpactsGeoServer, setRestructuredImpactsGeoServer] = useState(null);

    const [totalUniqueFallbackImpacts, setTotalUniqueFallbackImpacts] = useState(null);
    const [totalUniqueFallbackImpactsWithRating, setTotalUniqueFallbackImpactsWithRating] = useState(null);
    const [restructuredFallbackImpactsGeoServer, setRestructuredFallbackImpactsGeoServer] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            Authorization: `Bearer ${encryptedToken}`,
        },
    };

    useEffect(() => {
        const fetchImpacts = async () => {
            if (economicActivitiesIds.length === 0) return;

            const url = `${process.env.KUYUA_APP_BACKEND_URL}/impacts?economic_activities_ids=${economicActivitiesIds}${
                siteId ? `&site_id=${siteId}` : ""
            }`;

            try {
                const response = await fetch(url, header);
                const data = await response.json();

                let impactsData = null;

                if (!fallback) {
                    if (data.impacts && data.impacts.totalUnique) {
                        impactsData = data.impacts;
                    } else if (data.potentialImpacts) {
                        impactsData = data.potentialImpacts;
                    }

                    if (impactsData) {
                        const { totalUnique, totalUniqueWithRating, restructuredItems } = impactsData;

                        // Update state
                        setTotalUniqueImpacts(totalUnique);
                        setTotalUniqueImpactsWithRating(totalUniqueWithRating);
                        setRestructuredImpactsGeoServer(restructuredItems);

                        // Save to local storage
                        ["totalUniqueImpacts", "totalUniqueImpactsWithRating", "restructuredImpactsGeoServer"].forEach((key, index) =>
                            localStorage.setItem(key, JSON.stringify([totalUnique, totalUniqueWithRating, restructuredItems][index])),
                        );
                    }
                } else {
                    if (data.impacts && data.impacts.totalUnique) {
                        const impactsData = data.impacts;

                        const { totalUnique, totalUniqueWithRating, restructuredItems } = impactsData;

                        // Update state
                        setTotalUniqueImpacts(totalUnique);
                        setTotalUniqueImpactsWithRating(totalUniqueWithRating);
                        setRestructuredImpactsGeoServer(restructuredItems);
                    }
                    if (data.potentialImpacts && data.potentialImpacts.totalUnique) {
                        const impactsData = data.potentialImpacts;
                        const { totalUnique, totalUniqueWithRating, restructuredItems } = impactsData;
                        // Update state
                        setTotalUniqueFallbackImpacts(totalUnique);
                        setTotalUniqueFallbackImpactsWithRating(totalUniqueWithRating);
                        setRestructuredFallbackImpactsGeoServer(restructuredItems);
                    }
                }
            } catch (error) {
                console.error("Error fetching impacts:", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchImpacts();
    }, [economicActivitiesIds]);

    if (fallback) {
        return {
            totalUniqueFallbackImpacts,
            totalUniqueFallbackImpactsWithRating,
            restructuredFallbackImpactsGeoServer,
            loading,
            error,
            totalUniqueImpacts,
            totalUniqueImpactsWithRating,
            restructuredImpactsGeoServer,
        };
    }
    return {
        totalUniqueImpacts,
        totalUniqueImpactsWithRating,
        restructuredImpactsGeoServer,
        loading,
        error,
    };
};

export default useFetchImpacts;
