import * as React from "react";
import classnames from "classnames";

interface IProgressBarProps {
    barStyle?: React.CSSProperties;
    orientation?: string;
    style?: React.CSSProperties;
    value: number;
}

export default function ProgressBar(props: IProgressBarProps): JSX.Element {
    const horizontal = props.orientation !== "vertical";
    const classes = classnames("s-progress-bar", {
        "s-progress-bar-horizontal": horizontal,
        "s-progress-bar-vertical": !horizontal,
    });
    const barStyle = {
        width: horizontal ? Math.min(100, props.value * 100) + "%" : "100%",
        height: !horizontal ? Math.min(100, props.value * 100) + "%" : "100%",
        ...props.barStyle,
    };

    return (
        <div className={classes} style={props.style}>
            <span style={barStyle} />
        </div>
    );
}
