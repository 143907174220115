import * as React from "react";
import { Button, TextInput, Toast, VerticalStack } from "@/lib";
import { httpGet, httpPost, t } from "@/utils";
import { isEmptyString, requiredErrorText } from "@/utils/validation";
import StaticMapWrapper from "@/lib/map/StaticMapWrapper";
import { colors, style, StyleSheet } from "@/utils/style";
import { useNavigate } from "react-router-dom";

interface IPasswordChangeReply {
    ok: boolean;
    reason: string;
}
const newPasswordInvalid = (newPassword: string): string => {
    if (newPassword.length > 0 && newPassword.length < 12) {
        return t("ui.password_too_short");
    } else if (newPassword.length > 64) {
        return t("ui.password_too_long");
    }

    return null;
};
const repeatPasswordInvalid = (newPassword: string, repeatPassword: string): string => {
    if (isEmptyString(repeatPassword)) {
        return requiredErrorText;
    }

    return newPassword !== repeatPassword ? t("ui.password_not_equal") : null;
};

type InvalidToken = "token_invalid" | "token_used" | "token_expired";

export const NewPasswordForm: React.FC<{ recoveryToken: string }> = ({ recoveryToken }) => {
    const [saving, setSaving] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<{ kind: string; text: string }>({
        kind: "",
        text: "",
    });
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [repeatPassword, setRepeatPassword] = React.useState<string>("");

    const newPasswordRef = React.useRef<HTMLInputElement>(null);
    const repeatPasswordRef = React.useRef<HTMLInputElement>(null);
    const [errors, setErrors] = React.useState<Record<string, string>>({});
    const navigate = useNavigate();
    const [invalidToken, setInvalidToken] = React.useState<InvalidToken>();
    const [invalidTokenInformation, setInvalidTokenInformation] = React.useState<string>();

    React.useEffect(() => {
        if (recoveryToken) {
            httpGet<{ ok: boolean; data: Record<string, string> }>(`password-recovery?recovery-token=${recoveryToken}`).then((reply) => {
                if (!reply.ok) {
                    setInvalidToken(reply.data?.error_code);
                    switch (reply.data?.error_code) {
                        case "token_invalid":
                            setInvalidTokenInformation("This password recovery link does not exist");
                            break;
                        case "token_expired":
                            setInvalidTokenInformation("This password recovery link has expired and is not valid anymore");
                            break;
                        case "token_used":
                            setInvalidTokenInformation("This password recovery link has already been used");
                            break;
                    }
                }
            });
        }
    }, [recoveryToken]);

    const onChangeClick = () => {
        if (!newPasswordInvalid(newPassword) && !repeatPasswordInvalid(newPassword, repeatPassword)) {
            setSaving(true);

            httpPost<IPasswordChangeReply>(`password-recovery?recovery-token=${recoveryToken}`, {
                password1: newPassword,
                password2: repeatPassword,
            })
                .then((reply) => {
                    if (reply.ok) {
                        setMessage({ kind: "success", text: "Password changed!" });
                        navigate("/login");
                    } else {
                        setMessage({
                            kind: "error",
                            text: "Password was not changed — check your current password!",
                        });
                    }
                })
                .finally(() => {
                    setSaving(false);
                });
        } else {
            validateFields();
        }
    };

    React.useEffect(() => {
        if (errors.newPassword) {
            setErrors({ ...errors, newPassword: newPasswordInvalid(newPassword) });
        }
        if (errors.repeatPassword) {
            setErrors({
                ...errors,
                repeatPassword: repeatPasswordInvalid(newPassword, repeatPassword),
            });
        }
    }, [newPassword, repeatPassword]);

    const validateFields = () => {
        const newPasswordError = newPasswordInvalid(newPassword);
        const repeatPasswordError = repeatPasswordInvalid(newPassword, repeatPassword);

        setErrors({
            newPassword: newPasswordError,
            repeatPassword: repeatPasswordError,
        });

        if (newPasswordError) {
            newPasswordRef.current.focus();
        } else if (repeatPasswordError) {
            repeatPasswordRef.current.focus();
        }
    };

    if (invalidToken) {
        return (
            <div className="content">
                <StaticMapWrapper right={true} />
                <div style={style.leftContainer}>
                    <p style={styles.title}>password recovery</p>
                    <p style={{ fontSize: 20 }}>{invalidTokenInformation}</p>
                    <p>
                        <a href="/recovery-token">Request a new link</a>
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="content">
            <StaticMapWrapper right={true} />
            <div style={style.leftContainer}>
                <p style={styles.title}>password recovery</p>

                <form>
                    <VerticalStack style={{ margin: "2rem 0", maxWidth: "30rem" }}>
                        <VerticalStack spacing="condensed">
                            <label htmlFor="new_password">{t("ui.new_password")}</label>
                            <TextInput
                                ref={newPasswordRef}
                                id="new_password"
                                type="password"
                                onInput={setNewPassword}
                                onBlur={() =>
                                    setErrors({
                                        ...errors,
                                        newPassword: newPasswordInvalid(newPassword),
                                    })
                                }
                                value={newPassword}
                                error={errors.newPassword}
                            />
                        </VerticalStack>
                        <VerticalStack spacing="condensed">
                            <label htmlFor="repeat_password">{t("ui.repeat_new_password")}</label>
                            <TextInput
                                ref={repeatPasswordRef}
                                id="repeat_password"
                                type="password"
                                onInput={setRepeatPassword}
                                onBlur={() =>
                                    setErrors({
                                        ...errors,
                                        repeatPassword: repeatPasswordInvalid(newPassword, repeatPassword),
                                    })
                                }
                                value={repeatPassword}
                                error={errors.repeatPassword}
                            />
                        </VerticalStack>

                        <Button disabled={saving} onClick={onChangeClick}>
                            {t("ui.change_password")}
                        </Button>
                    </VerticalStack>

                    {message.text && <Toast kind={message.kind} text={message.text} onClose={() => setMessage({ kind: "", text: "" })} />}
                </form>
            </div>
        </div>
    );
};

const styles = StyleSheet.create({
    title: {
        fontWeight: "bold",
        color: colors.brightBlue,
        fontSize: 30,
        textTransform: "uppercase",
    },
});
