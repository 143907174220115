/* eslint-disable @typescript-eslint/no-explicit-any */
import { calculateNaturalHabitatPercentage } from "@/project/site/overview/utils";
import { getRatingTextFromNumber } from "../getRatingText";
import { apiGet } from "../http";
import { isKeyStoneSpecies, isThreatenedSpecies } from "../species";
import CryptoJS from "crypto-js";

if (!process.env.API_KEY || !process.env.SECRET_KEY || !process.env.KUYUA_APP_BACKEND_URL || !process.env.GEO_SERVER_URL) {
    throw new Error("Missing necessary environment variables.");
}

const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();

const header = {
    headers: {
        Authorization: `Bearer ${encryptedToken}`,
    },
};

const formatRating = (rating: string) => {
    const ratingsMap: { [key: string]: string } = {
        H: "High",
        M: "Medium",
        L: "Low",
        VH: "Very High",
    };
    return ratingsMap[rating] || rating;
};

const groupByRating = (data: any[], type: string) => {
    if (!data) return {};

    const result = data.reduce((acc, { rating, values }) => {
        if (Array.isArray(values)) {
            values.forEach((value: string) => {
                // Exclude undefined values and empty strings
                if (value && !value.includes("undefined")) {
                    const r = formatRating(rating);
                    if (r && value.trim()) {
                        const key = `${type} (${r})`;
                        acc[key] = acc[key] || ""; // Initialize if it doesn't exist
                        acc[key] += `• ${value.trim()}\n`;
                    }
                }
            });
        }
        return acc;
    }, {});

    // Exclude empty key if no valid data was added
    if (!result[type] || result[type].trim() === "") {
        delete result[type];
    }

    return Object.keys(result).length === 0 ? { [type]: "" } : result;
};

const backendURL = process.env.KUYUA_APP_BACKEND_URL;

export const downloadSite = async (site: ISite) => {
    let { latlng, country } = site;

    async function fetchSiteKPIS(site) {
        try {
            const KPIsURL = `${backendURL}/site/kpis?site_id=${site.site_id}&kpis=species,habitats,bsa-distance,bsa,msa,msa-delta,change-y-average,deforestation`;
            const response = await fetch(KPIsURL, header);
            const data = response.json();
            return data;
        } catch (error) {
            console.error("Error at fetch kpis.");
        }
    }

    const [kpisResponse] = await Promise.all([fetchSiteKPIS(site)]);

    const {
        habitatMigrationData,
        speciesData,
        BSAClosestDistanceMeters,
        msa,
        msaDelta,
        BSACount,
        impacts,
        dependencies,
        siteName,
        siteAddress,
        siteType,
        changeYAverage,
        deforestationData,
    } = kpisResponse;
    const { species, threatened_species_count, key_stone_species_count } = speciesData;

    const economic_activities = site?.economic_activities || [];

    const distanceToClosestBSA = BSAClosestDistanceMeters?.closestDistanceMeters;

    const sitePriorityScore = Number(site.priority_score).toFixed();

    const fragmentations = habitatMigrationData.habitatDict.reduce((acc, habitat) => {
        acc[`${habitat.name}`] = `${(habitat.percentage * 100).toFixed(1)}%`;
        return acc;
    }, {});
    const naturalHabitatPercentage = `${habitatMigrationData.NaturalHabitatPercentage} %`;

    const nonNaturalHabitatPercentage = `${habitatMigrationData.NonNaturalHabitatPercentage} %`;

    const urbanAreasObj = habitatMigrationData.habitatDict.filter((habitat) => habitat.name === "Urban Areas");
    const nonNaturalSealedPercentage = urbanAreasObj?.length > 0 ? `${((urbanAreasObj[0].percentage * 100) / 2).toFixed(1)} %` : "N/A";

    const threatenedSpecies = threatened_species_count;

    const keystoneSpecies = key_stone_species_count;
    const threatenedAndKeystoneSpecies = threatened_species_count + key_stone_species_count;

    const keystoneSpeciesRisk = keystoneSpecies && threatenedAndKeystoneSpecies && (threatenedAndKeystoneSpecies / keystoneSpecies) * 100;

    const [
        waterRiskImpactLevel,
        waterRiskDependencyLevel,
        { dominantDriver: dominantRisk, treeCoverLossRate: deforestationRisk },
        deforestationImpactLevel,
        deforestationDependencyLevel,
        netPrimary,
    ] = await Promise.all([
        fetchWaterRiskImpact(economic_activities, site.site_id).then(getRatingTextFromNumber),
        fetchWaterRiskDependency(economic_activities, site.site_id).then(getRatingTextFromNumber),
        fetchTreeCoverLossAndDominantDriver(latlng),
        fetchDeforestationRiskImpact(economic_activities, site.site_id).then(getRatingTextFromNumber),
        fetchDeforestationRiskDependency(economic_activities, site.site_id).then(getRatingTextFromNumber),
        fetchNetPrimary(latlng),
    ]);

    const columns = [
        { "Metric(Type)": "Metric(Value)" },
        { "Site Name": siteName },
        { Address: siteAddress },
        { Country: country },
        { Latitude: latlng.lat },
        { Longitude: latlng.lng },
        { "Priority Score (1-100)": sitePriorityScore },
        { "Site Type": siteType },
        {
            "Biodiversity Intactness in (%)": isNaN(parseFloat(String(msa))) ? "N/A" : parseFloat(String(msa)).toFixed(2),
        },
        {
            "Projected Change in Biodiversity Intactness": isNaN(parseFloat(String(msaDelta)))
                ? "N/A"
                : `${parseFloat(String(msaDelta)).toFixed()} %`,
        },
        {
            "Vegetation Productivity (gC/m²/year, 2023)": isNaN(parseFloat(String(netPrimary)))
                ? "N/A"
                : parseFloat(String(netPrimary)).toFixed(0),
        },
        {
            "Trend in Vegetation Productivity": changeYAverage ?? "N/A",
        },
        { "Species Richness": species?.length || "N/A" },
        { "Threatened Species": parseInt(String(threatenedSpecies)).toFixed(0) },
        { "Keystone Species Richness": keystoneSpecies },
        {
            "Keystone Species @ Risk (%)": isNaN(parseFloat(String(keystoneSpeciesRisk)))
                ? "N/A"
                : parseFloat(String(keystoneSpeciesRisk)).toFixed(1),
        },
        { "Biodiversity-Sensitive Areas (BSA)": BSACount || "N/A" },
        {
            "Distance To Closest BSA (m)": parseInt(distanceToClosestBSA).toFixed(0) || "N/A",
        },
        { "Impact Radius (m)": "30000" },
        {
            "Water Risk": {
                "Water Risk": "N/A",
                "Impact Level": waterRiskImpactLevel || "N/A",
                "Dependency Level": waterRiskDependencyLevel || "N/A",
            },
        },
        {
            "Deforestation Risk": {
                "Deforestation Risk": deforestationRisk || "N/A",
                "Dominant Driver": dominantRisk || "N/A",
                "Impact Level": deforestationImpactLevel || "N/A",
                "Dependency Level": deforestationDependencyLevel || "N/A",
            },
        },
        { "Habitat Fragmentation": fragmentations },
        {
            "Habitat Extend": {
                "Natural Habitat": naturalHabitatPercentage,
                "Non-Natural Habitat": nonNaturalHabitatPercentage,
                "Land & Soil Sealing": nonNaturalSealedPercentage,
            },
        },
        groupByRating(
            impacts.impacts.totalUnique > 0 ? impacts.impacts.restructuredItems : impacts.potentialImpacts.restructuredItems,
            "Impacts",
        ),
        groupByRating(
            dependencies.dependencies.totalUnique > 0
                ? dependencies.dependencies.restructuredItems
                : dependencies.potentialDependencies.restructuredItems,
            "Dependencies",
        ),
    ];

    const flattenColumnsWithHierarchy = (columns: any[]) => {
        const flattened: any = {};

        const processValue = (key: string, value: any) => {
            flattened[key] = " ";

            if (typeof value === "object" && !Array.isArray(value)) {
                Object.entries(value).forEach(([nestedKey, nestedValue]) => {
                    flattened[key] += ` • ${nestedKey}: ${nestedValue}\n`;
                });
            } else {
                flattened[key] += value;
            }
        };

        columns.forEach((column) => Object.entries(column).forEach(([key, value]) => processValue(key, value)));

        return flattened;
    };

    const flattenedData = flattenColumnsWithHierarchy(columns);
    const finalData = Object.entries(flattenedData).map(([key, value]) => [key, value]);

    return finalData;
};

function getStringRatingWaterRisk(value: number): string {
    if (value > 0 && value < 20) return "Low";
    if (value >= 20 && value < 40) return "Low-Medium";
    if (value >= 40 && value < 60) return "Medium-High";
    if (value >= 60 && value < 80) return "High";
    return "Very High";
}

function calculateWaterRiskPercentage(assessment: any): number {
    const waterRisk = assessment?.kpis?.overall_water_risk ?? 0;
    const normalizedWaterRisk = waterRisk > 0 ? Math.round((waterRisk / 255) * 100) : 0;
    return 100 - normalizedWaterRisk;
}

async function fetchMsaDelta(latlng: { lat: number; lng: number }) {
    const url = `${process.env.GEO_SERVER_URL}/msa-delta?lat=${latlng.lat}&lng=${latlng.lng}`;
    return fetchData(url, "msa_delta", "Error fetching MSA Delta data");
}

const fetchChangeAverage = async (latlng: { lat: number; lng: number }) => {
    const url = `${process.env.GEO_SERVER_URL}/land-degradation/change-vs-ten-year-average?lat=${latlng.lat}&lng=${latlng.lng}`;
    return fetchData(url, "cya", "Error fetching Change Ten Year Average data");
};

const fetchNetPrimary = async (latlng: { lat: number; lng: number }) => {
    const url = `${process.env.GEO_SERVER_URL}/land-degradation/net-primary-productivity?lat=${latlng.lat}&lng=${latlng.lng}`;
    return fetchData(url, "npp", "Error fetching Net Primary Productivity data");
};

async function fetchMSA(latlng: { lat: number; lng: number }): Promise<string | undefined> {
    const url = `${process.env.GEO_SERVER_URL}/msa?lat=${latlng.lat}&lng=${latlng.lng}`;
    return fetchData(url, "msa", "Error fetching MSA data");
}

async function fetchBSA(latlng: { lat: number; lng: number }, parameters: any): Promise<number | undefined> {
    const radius = parameters.inner_range_meters || 2000;
    const url = `${process.env.GEO_SERVER_URL}/protected-areas/circle?count=true&radius=${radius}&lng=${latlng.lng}&lat=${latlng.lat}`;
    return fetchData(url, "count", "Error fetching BSA count");
}

async function fetchWaterRiskImpact(economic_activities: any[], site_id): Promise<number | undefined> {
    const economicActivitiesIds = economic_activities.map((ea) => ea.id).join(",");
    const url = `${process.env.KUYUA_APP_BACKEND_URL}/impacts/max?site_id=${site_id}&economic_activities_ids=${economicActivitiesIds}&impact_names=water_use,water_pollutants`;
    return fetchData(url, "max_impact", "Error fetching water risk impact");
}

async function fetchWaterRiskDependency(economic_activities: any[], site_id): Promise<number | undefined> {
    const economicActivitiesIds = economic_activities.map((ea) => ea.id).join(",");
    const url = `${process.env.KUYUA_APP_BACKEND_URL}/dependencies/max?site_id=${site_id}&economic_activities_ids=${economicActivitiesIds}&dependency_names=surface_water,ground_water,water_flow_maintenance,water_quality`;
    return fetchData(url, "max_dependency", "Error fetching water risk dependency");
}

async function fetchTreeCoverLossAndDominantDriver(latlng: {
    lat: number;
    lng: number;
}): Promise<{ treeCoverLossRate: string; dominantDriver: string }> {
    const url = `${process.env.GEO_SERVER_URL}/tree-cover-loss?latlng=${latlng.lng},${latlng.lat}`;
    try {
        const response = await fetch(url, header);
        const data = await response.json();

        const treeCoverLossRate = data.tree_cover_loss >= 1 ? "High" : "Low";
        const dominantDriver =
            ["Commodity-driven", "Shifting agriculture", "Forestry", "Wildfire", "Urbanization"][data.tree_cover_loss - 1] || "N/A";

        return { treeCoverLossRate, dominantDriver };
    } catch (error) {
        console.error("Error fetching tree cover loss!", error);
        return { treeCoverLossRate: "Low", dominantDriver: "N/A" };
    }
}

async function fetchDeforestationRiskImpact(economic_activities: any[], site_id): Promise<number | undefined> {
    const economicActivitiesIds = economic_activities.map((ea) => ea.id).join(",");
    const url = `${process.env.KUYUA_APP_BACKEND_URL}/impacts/max?site_id=${site_id}&economic_activities_ids=${economicActivitiesIds}&impact_names=terrestrial_ecosystem_use,disturbances`;
    return fetchData(url, "max_impact", "Error fetching deforestation risk impact");
}

async function fetchDeforestationRiskDependency(economic_activities: any[], site_id): Promise<number | undefined> {
    const economicActivitiesIds = economic_activities.map((ea) => ea.id).join(",");
    const url = `${process.env.KUYUA_APP_BACKEND_URL}/dependencies/max?site_id=${site_id}&economic_activities_ids=${economicActivitiesIds}&dependency_names=climate_regulation,flood_and_storm_protection,filtration,mass_stabilisation_and_erosion_control,mediation_of_sensory_impacts`;
    return fetchData(url, "max_dependency", "Error fetching deforestation risk dependency");
}

async function fetchData<T>(url: string, key: keyof T, errorMsg: string): Promise<T[keyof T] | undefined> {
    try {
        const response = await fetch(url, header);
        const data = await response.json();
        return data[key];
    } catch (error) {
        console.error(`${errorMsg}:`, error);
        return undefined;
    }
}
