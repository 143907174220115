import { externalHttpGet } from "@/utils";
import { RasterColormapChanged, RasterMetadataChanged } from "@/context";

type IGenericDispatch = (action: IGenericAction) => void;

export async function updateRasterColormap(
    state: IAppState,
    dispatch: IGenericDispatch,
    id: string,
    stretchRange: number[],
    valueCount = 10,
): Promise<IRasterColormapEntry[]> {
    const token = state.config.tileserver_api_token;
    const params = {
        colormap: id,
        num_values: "" + valueCount,
        stretch_range: JSON.stringify(stretchRange),
        token,
    };
    const colormap = await externalHttpGet<{ colormap: IRasterColormapEntry[] }>("/api/v1/rasters/colormap", params);

    if (colormap?.colormap) {
        dispatch({
            colormap: colormap.colormap.map((c) => ({
                ...c,
                cssRGBA: `rgba(${c.rgba[0]}, ${c.rgba[1]}, ${c.rgba[2]}, ${c.rgba[3] / 255})`,
            })),
            id,
            stretchRange,
            type: RasterColormapChanged,
            valueCount,
        });
    }

    return colormap?.colormap;
}

export async function updateRasterMetadata(state: IAppState, dispatch: IGenericDispatch, id: string): Promise<IRasterMetadata> {
    const token = state.config.tileserver_api_token;
    // Commented because we will not use it for our rasters.
    // const metadata = await externalHttpGet<IRasterMetadata>(`/api/v1/rasters/metadata/${encodeURIComponent(id)}`, { token });

    // if (metadata?.message) {
    //     //throw new Error(metadata.message);
    // }
    // else if (metadata.keys) {
    //     dispatch({ type: RasterMetadataChanged, metadata });

    //     return metadata;
    // }

    return undefined;
}
