import * as React from "react";
import { httpPost } from "@/utils";
import StaticMapWrapper from "@/lib/map/StaticMapWrapper";
import { colors, style, StyleSheet } from "@/utils/style";
import { MainNavigation } from "@/lib";
import { AppContext, ToastAdded } from "@/context";

export default function PasswordRecoverForm(): JSX.Element {
    const [loginError, setLoginError] = React.useState("");
    const [mail, setMail] = React.useState("");
    const btn = React.useRef(null);
    const { dispatch } = React.useContext(AppContext);

    function handleForm(mail: string) {
        setMail(mail);
        setLoginError("");
    }

    function resetPassword() {
        if (!mail.length) {
            setLoginError("E-Mail required");
            return;
        }
        httpPost("/get-recovery-token", { email: mail }).then((reply) => {
            if (!reply.ok) {
                setLoginError(reply.reason);
                btn.current?.classList.add("shake");

                setTimeout(function () {
                    btn.current?.classList.remove("shake");
                }, 700);
                return;
            } else {
                dispatch({
                    type: ToastAdded,
                    toast: { kind: "success", text: reply.reason },
                });
                setMail("");
            }
        });
    }

    return (
        <>
            <MainNavigation />
            <div className="content">
                <StaticMapWrapper right={true} />
                <div
                    style={{
                        ...style.leftContainer,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        gap: 10,
                    }}
                >
                    <p style={styles.title}>Reset password</p>
                    <div>
                        <div>EMAIL ADDRESS</div>
                        <input style={{ ...style.inputField }} value={mail} onChange={(e) => handleForm(e.target.value)} />
                    </div>
                    <div
                        style={{
                            textTransform: "uppercase",
                            color: loginError ? colors.red : "transparent",
                        }}
                    >
                        {loginError}
                    </div>
                    <button
                        ref={btn}
                        style={{
                            ...style.actionButton,
                            fontSize: 14,
                            fontWeight: "normal",
                            color: colors.black,
                            width: 250,
                        }}
                        onClick={() => resetPassword()}
                        disabled={loginError?.length > 0}
                        className={""}
                    >
                        request new password
                    </button>

                    <div>
                        <a href={"/login"}>Back</a>
                    </div>
                </div>
            </div>
        </>
    );
}

const styles = StyleSheet.create({
    title: {
        fontWeight: "bold",
        color: colors.brightBlue,
        fontSize: 30,
        textTransform: "uppercase",
    },
});
