import * as React from "react";
import classnames from "classnames";
import { MapStyles } from "./constants";
import { hasProperty, t } from "@/utils";
import VerticalStack from "../VerticalStack";
import { AppContext, ProjectExploreRastersChanged } from "@/context";
import { NavLink } from "@/lib";
import { StyleSheet } from "@/utils/style";
import { BaseModalDialog } from "../modals";

interface IMapLayerPickerProps {
  availableRasters: Record<string, IProjectRasterConfig>;
  onClose: () => void;
  onChoose: (
    kind: "basemap" | "raster",
    raster: IProjectRasterConfig | string | undefined
  ) => void;
  selectedBaseMap: string;
  selectedRasters: Record<string, IProjectRasterConfig>;
}

export default function MapLayerPicker(
  props: IMapLayerPickerProps
): JSX.Element {
  const { dispatch } = React.useContext(AppContext);
  // const groupedRasters = React.useMemo(() => {
  //     const otherLabel = t('ui.other');
  //     const grouped = Object
  //         .values(props.availableRasters)
  //         .reduce((g, r) => {
  //             const label = r.group_label || otherLabel;

  //             if (!hasProperty(g, label)) {
  //                 g[label] = [r];
  //             }
  //             else {
  //                 g[label].push(r);
  //             }

  //             return g;
  //         }, {} as Record<string, IProjectRasterConfig[]>);

  //     Object.values(grouped).map(g => g.sort((a, b) => a.label.localeCompare(b.label)));

  //     return Object.entries(grouped).sort(([ak], [bk]) => {
  //         if (ak !== bk) {  // Send "other" category to bottom
  //             if (ak === otherLabel) {
  //                 return 1;
  //             }
  //             else if (bk === otherLabel) {
  //                 return -1;
  //             }
  //         }

  //         return ak.localeCompare(bk);
  //     });
  // }, [props.availableRasters]);
  // console.log('groupedRasters ==>> ', groupedRasters);
  const onChoose = (
    kind: "basemap" | "raster",
    item: IProjectRasterConfig | string | undefined
  ) => {
    // TODO Start animation on clicked item and lengthen the timeout below

    window.setTimeout(() => {
      console.log("here you can pass onChoose ==>> ", kind, item);
      props.onChoose(kind, item);
      props.onClose();
    }, 25);
  };
  const [ownRasters, setOwnRasters] = React.useState({
    label: "",
    rasters: [],
  });
  const [isMounted, setIsMounted] = React.useState(true);

  React.useEffect(() => {
    const fetchRasters = async () => {
      try {
        var raster_msa_delta = {
          id: "msa_delta",
          url: `${process.env.GEO_MIND_URL}/raster/msa_delta?z={z}&x={x}&y={y}`,
          icon: "/-/app/msa_delta.png",
          label: "MSA Delta",
          description:
            "The MSA Delta metric shows the difference in biodiversity over time.",
          tilesize: 256,
          colormap: "ylgn",
          enabled: "True",
          value_format: {
            multiplier: 100,
            num_decimals: 2,
            suffix: " %",
          },
          priority: 3,
        };
        var raster_net_primary_productivity = {
          id: "net_primary_productivity",
          url: `${process.env.GEO_MIND_URL}/raster/net_primary_productivity?z={z}&x={x}&y={y}`,
          icon: "/-/app/net_primary_productivity.png",
          label: "Net Primary Productivity",
          description: "Net Primary Productivity",
          tilesize: 256,
          colormap: "ylgn",
          enabled: "True",
          value_format: {
            multiplier: 100,
            num_decimals: 2,
            suffix: " %",
          },
          priority: 3,
        };
        var raster_change_y_average = {
          id: "change_y_average",
          url: `${process.env.GEO_MIND_URL}/raster/change_y_average?z={z}&x={x}&y={y}`,
          icon: "/-/app/change_y_average.png",
          label: "Change Y Average",
          description: "Change Y Average",
          tilesize: 256,
          colormap: "ylgn",
          enabled: "True",
          value_format: {
            multiplier: 100,
            num_decimals: 2,
            suffix: " %",
          },
          priority: 3,
        };
        var raster_deforestation_2023 = {
          id: "deforestation_2023",
          url: `${process.env.GEO_MIND_URL}/raster/deforestation_2023?z={z}&x={x}&y={y}`,
          icon: "/-/app/deforestation.png",
          label: "deforestation 2023",
          description: "deforestation 2023",
          tilesize: 256,
          colormap: "ylgn",
          enabled: "True",
          value_format: {
            multiplier: 100,
            num_decimals: 2,
            suffix: " %",
          },
          priority: 3,
        };
        var raster_deforestation_2022 = {
          id: "deforestation_2022",
          url: `${process.env.GEO_MIND_URL}/raster/deforestation_2022?z={z}&x={x}&y={y}`,
          icon: "/-/app/deforestation.png",
          label: "deforestation 2022",
          description: "deforestation 2022",
          tilesize: 256,
          colormap: "ylgn",
          enabled: "True",
          value_format: {
            multiplier: 100,
            num_decimals: 2,
            suffix: " %",
          },
          priority: 3,
        };
        var raster_deforestation_2021 = {
          id: "deforestation_2021",
          url: `${process.env.GEO_MIND_URL}/raster/deforestation_2021?z={z}&x={x}&y={y}`,
          icon: "/-/app/deforestation.png",
          label: "deforestation 2021",
          description: "deforestation 2021",
          tilesize: 256,
          colormap: "ylgn",
          enabled: "True",
          value_format: {
            multiplier: 100,
            num_decimals: 2,
            suffix: " %",
          },
          priority: 3,
        };
        var raster_msa = {
          id: "msa",
          url: `${process.env.GEO_MIND_URL}/raster/msa?z={z}&x={x}&y={y}`,
          icon: "/-/app/msa.png",
          label: "MSA",
          description:
            "The MSA metric shows the biodiversity.",
          tilesize: 256,
          colormap: "ylgn",
          enabled: "True",
          value_format: {
            multiplier: 100,
            num_decimals: 2,
            suffix: " %",
          },
          priority: 3,
        };
        var raster_msa_score = {
          id: "msa_score",
          url: `${process.env.GEO_MIND_URL}/raster/msa_score?z={z}&x={x}&y={y}`,
          icon: "/-/app/msa.png",
          label: "MSA Score",
          description:
            "The MSA Score metric shows the biodiversity score.",
          tilesize: 256,
          colormap: "ylgn",
          enabled: "True",
          value_format: {
            multiplier: 100,
            num_decimals: 2,
            suffix: " %",
          },
          priority: 3,
        };
        setOwnRasters({
          label: "Own Rasters",
          rasters: [
            raster_msa,
            raster_msa_score,
            raster_msa_delta,
            raster_net_primary_productivity,
            raster_change_y_average,
            raster_deforestation_2021,
            raster_deforestation_2022,
            raster_deforestation_2023
          ],
        });
        setIsMounted(false);
      } catch (error) {
        console.error("Error fetching rasters:", error);
      }
    };
    if (isMounted) {
      fetchRasters();
    }
  }, [ownRasters]);

  const clearSelectedDataLayer = () => {
    dispatch({ type: ProjectExploreRastersChanged, rasters: {} });
    window.setTimeout(() => {
      props.onClose();
    }, 50);
  };

  const selectedRasterKeys = Array.from(Object.keys(props.selectedRasters));
  const isRasterSelected = selectedRasterKeys.length > 0;
  const activeStyle = { color: "#F7F7F5" };

  return (
    <BaseModalDialog
      onClose={props.onClose}
      scrollableBody={true}
      title={t("map.layer_plural")}
      width="50%"
    >
      <div className="s-map-layer-panel" style={styles.layers}>
        <VerticalStack>
          <div className="s-map-layer-subtitle">
            {t("ui.base_layer_plural")}
          </div>

          {MapStyles.map((s) => (
            <div
              key={s.uri}
              className={classnames("s-map-layer", {
                "s-map-layer-selected":
                  !isRasterSelected && props.selectedBaseMap === s.uri,
              })}
            >
              <div
                className="s-map-layer-thumbnail"
                onClick={() => onChoose("basemap", s.uri)}
                style={{
                  background: `#F7F7F7 center / cover no-repeat url(${s.icon})`,
                }}
              />
              <div>
                <div
                  className="s-map-layer-title"
                  onClick={() => onChoose("basemap", s.uri)}
                >
                  {s.name}
                </div>
              </div>
            </div>
          ))}

          <NavLink
            active={true}
            onClick={() => clearSelectedDataLayer()}
            to="#"
          >
            {t("actions.reset_selection")}
          </NavLink>
        </VerticalStack>
        {/* we comment out for now until we prepare the new layers */}
        {/* <VerticalStack>
                    {groupedRasters.map(([label, rasters]) => <React.Fragment key={label}>
                        <div className="s-map-layer-subtitle">{label}</div>
                        
                        {rasters.filter((item)=> item.id !== "aq3awrdefqal20190715" && item.id !== "aq3awrdefqan20190715").map(r => <div key={r.id} className={classnames('s-map-layer', { 's-map-layer-selected': selectedRasterKeys.includes(r.id) })}>
                            <div
                                className="s-map-layer-thumbnail"
                                onClick={() => onChoose('raster', r)}
                                style={{ background: `#F7F7F7 center / cover no-repeat url(${r.icon})` }} />

                            <div>
                                <div className="s-map-layer-title" onClick={() => onChoose('raster', r)}>{r.label}</div>
                            </div>
                        </div>)}
                    </React.Fragment>)}
                </VerticalStack> */}
        {/* we comment this out for now until we our NEW TILE SERVER */}
        <VerticalStack>
          <React.Fragment key={ownRasters.label}>
            <div className="s-map-layer-subtitle">{ownRasters.label}</div>

            {ownRasters.rasters.filter((item) => item.id !== "aq3awrdefqal20190715" && item.id !== "aq3awrdefqan20190715").map(r => <div key={r.id} className={classnames('s-map-layer', { 's-map-layer-selected': selectedRasterKeys.includes(r.id) })}>
              <div
                className="s-map-layer-thumbnail"
                onClick={() => onChoose('raster', r)}
                style={{ background: `#F7F7F7 center / cover no-repeat url(${r.icon})` }} />

              <div>
                <div className="s-map-layer-title" onClick={() => onChoose('raster', r)}>{r.label}</div>
              </div>
            </div>)}
          </React.Fragment>
        </VerticalStack>
      </div>
    </BaseModalDialog>
  );
}
const styles = StyleSheet.create({
  layers: {
    display: "flex",
    flexDirection: "row",
  },
});
