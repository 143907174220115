import React, { useContext, useEffect } from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import {
    AppContext,
    ImpactsAndDependenciesChanges,
    SetLoadingState,
    SitesChanged,
    ToastAdded,
    useProject,
    useTeam
} from "@/context";
import { mdiAttachment, mdiFileUploadOutline, mdiPlus, mdiReceiptText } from "@mdi/js";
import Icon from "../../../lib/Icon";
import LocationForm from "@/project/sleap/Locate/LocationForm";
import { fetchImpactsAndDependencies, fetchScopes, url } from "@/rest/apiHelper";
import { apiGet, apiPost, postFile, t } from "@/utils";
import { useSleapStep } from "@/utils/customHooks";
import { SleapContext } from "@/context/SleapContext";
import { LocationSearch } from "@/project/sleap/Locate/LocationSearch";
import { LocationTile } from "@/project/sleap/Locate/LocationTile";
import LocationBulkInsertForm from "@/project/sleap/Locate/LocationBulkInsertForm";
import { Button } from "@/project/sleap/Button";
import { CheckHandler, INewDBSite, PolygonHandler } from "@/types/sleap";
import { orderByPriority } from "@/utils/sort";
import { initSites } from "@/lib/AppLoader";
import { IHttpPostResult } from "@/utils/http";
import SiteImportValidationDialog, { ValidationResponse } from "@/project/sleap/Locate/SiteImportValidationDialog";
import Pagination from "@/lib/Pagination/Pagination";
import CryptoJS from "crypto-js";


export const getAddressSearchString = (site): string => {

    return `${site.country} ${site.zip_code} ${site.city} ${site.street_address}`;
};

export const Locate: React.FC<CheckHandler & PolygonHandler & { polygonData: { polygonData: string, polygonCenter: [number, number], locationDetails: { zipcode: string, country: string, city: string, street: string } } | null, selectPolygon: (polygon: string) => void }> = ({ checkHandler, polygonHandler, polygonData, selectPolygon }) => {
    const project = useProject();
    const { dispatch, state } = useContext(AppContext);
    const [scopeOptions, setScopeOptions] = React.useState<iScope[]>([]);
    const [openCreate, setOpenCreate] = React.useState(false);
    const [openBulkInsert, setOpenBulkInsert] = React.useState(false);
    const [showValidationModule, setShowValidationModule] = React.useState(false);
    const team = useTeam();
    const report = project.reports[project.latest_report_id] as IAssessmentReport;
    const [currentOpen, setCurrentOpen] = React.useState<number>();
    const [sites, setSites] = React.useState<INewSite[]>([]);
    const [filterInput, setFilterInput] = React.useState<string>('');
    const { sleapState, sleapDispatch } = React.useContext(SleapContext);
    const [, setSleapStep] = useSleapStep();
    const [response, setResponse] = React.useState<ValidationResponse>();
    const [page, setPage] = React.useState<number>(1);
    const [sitesCount, setSitesCount] = React.useState<number>(-1);
    const [totalSites, setTotalSites] = React.useState(0);
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            'Authorization': `Bearer ${encryptedToken}`
        }
    };
    async function getSitesCount() {
        let countEndPointURL = `${process.env.KUYUA_APP_BACKEND_URL}/sites/count-sites?company_id=${team.id}`;
        const response = await fetch(countEndPointURL, header);
        const sitesCount = await response.json();
        return sitesCount.count;
    }
    const isSuperUser = state.config.user.is_superuser;
    // make it valid for super user only for now, until we set a limit for the sites creation for other users
    // const validForCreate = isSuperUser || totalSites < 16;
    const validForCreate = isSuperUser;
    getSitesCount().then((result) => {
        setTotalSites(result);
        console.log("total sites per user ==>", totalSites);
    });
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const data = new FormData();
        data.append('file', file);
        const path = `/api/v1/teams/${team.slug}/projects/${project.slug}/reports/${project.latest_report_id}/site/import`;
        dispatch({ type: SetLoadingState, loading: true });
        postFile<Record<string, string>>(path, data).then(reply => {
            if (reply.ok) {

                handleImportValidation(reply);
            } else {
                dispatch({ type: ToastAdded, toast: { kind: 'error', text: `Failed to import file! ${reply.response?.error}` } });
            }
        });
    };

    const handleImportValidation = (response: IHttpPostResult<Record<string, string>>) => {

        if (response.data.message.startsWith('Data for all sites in the file is correct')) {

            dispatch({ type: ToastAdded, toast: { kind: 'success', text: 'Import successful.' } });
        } else {

            createValidationPopup(response);
        }
        window.setTimeout(() => initSites(dispatch, team, project, () => { }), 5000);
        const inputField = document.getElementById('csvFileInput');
        if (inputField) {
            inputField.value = '';
        }
    };

    const createValidationPopup = (result: IHttpPostResult<Record<string, string>>) => {

        setResponse(result.data);
        setShowValidationModule(true);
    };

    React.useEffect(() => {

        fetchScopes().then(reply => setScopeOptions(reply));
    }, []);

    React.useEffect(() => {

        (openCreate) && setCurrentOpen(0);
    }, [openCreate]);

    React.useEffect(() => {

        currentOpen > 0 && setOpenCreate(false);
    }, [currentOpen]);

    React.useEffect(() => {

        checkHandler(sites.length > 0);
    }, [sites.length]);


    function createLocation(site: INewDBSite): void {
        (async () => {
            let newDbLatLng = `POINT(${site.latlng.lng} ${site.latlng.lat})`;
            const newDbSite = {
                site_type_id: site.site_type_id,
                company_id: team.id,
                priority_score: -1,
                latlng: newDbLatLng,
                site_name: site.site_name,
                city: site.city != "N/A" ? site.city : "",
                region: site.region ? site.region : "",
                country: site.country != "N/A" ? site.country : "",
                province: site.province ? site.province : "",
                zip_code: site.zip_code ? site.zip_code : "",
                country_code: site.country_code ? site.country_code : "",
                street_address: site.street_address != "N/A" ? site.street_address : "",
                site_key: site.site_key,
                priority_location: false,
                site_asssessment_status_old: "not started",
                id_old: 0,
                is_deleted: false,
                economic_activity_ids: site.economic_activity_ids.join(','),
                drawn_polygon: polygonData?.polygonData
            };
            console.log("New site to save into kuyuaappdb =====>", newDbSite);
            await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/site`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${encryptedToken}`
                },
                body: JSON.stringify(newDbSite),
            }).then(data => {

                dispatch({ type: ToastAdded, toast: { kind: 'success', text: 'Site added successfully.' } });
                //const newSites = [...sites, storedSite].sort(orderByPriority);
                //dispatch({ type: SitesChanged, sites: newSites })
                // fetchImpactsAndDependencies(team.slug, project.slug).then(reply => {
                //     dispatch({ type: ImpactsAndDependenciesChanges, impacts: reply.impacts, dependencies: reply.dependencies });
                // });
                setOpenCreate(false);
                sleapDispatch({ unsetZoom: true, selectedCenter: undefined });
            }).catch((error) => {
                console.error('Error:', error);
            });

        })();
    }

    const closeForm = () => {
        setOpenCreate(false);
        setOpenBulkInsert(false);
        selectPolygon(null);
        //no need to open the location form when clicking on the map
        // sleapDispatch({ unsetZoom: true, selectedCenter: undefined });
    };

    //no need to open the location form when clicking on the map
    React.useEffect(() => {

        if (sleapState.selectedCenter && !currentOpen) {
            setOpenCreate(true);
        }

    }, [sleapState?.selectedCenter]);


    // React.useEffect(() => {
    //     console.log('sleapState?.selectedSiteId ====xxx>>>> ', sleapState?.selectedSiteId);
    //     setCurrentOpen(sleapState?.selectedSiteId ?? 0);
    //     const site = sites.find(site => Number(site.site_id) === Number(sleapState?.selectedSiteId));
    //     if (site) {
    //         onSiteSelected(site);
    //     }
    // }, [sleapState?.selectedSiteId]);

    // useEffect(() => {
    //     if (currentOpen === 0) {
    //         selectPolygon(null);
    //     }
    // }, [selectPolygon]);

    async function getSites() {

        const queries = [`company_id=${team.id}`];

        let currentPage = page;
        if (filterInput.length) {
            currentPage = 1;
            queries.push('search_all=' + filterInput);
        }
        try {
            let NewDbUrl = `${process.env.KUYUA_APP_BACKEND_URL}/sites?page=${page}`;
            NewDbUrl = NewDbUrl + "&" + queries.join("&");
            console.log(NewDbUrl);
            const response = await fetch(NewDbUrl, header);
            const data = await response.json();
            setSitesCount(data.total);
            setSites(data.sites); //mappedSites
        } catch (error) {
            console.error("Error fetching sites from kuyuaappdb:", error);
            dispatch({
                type: ToastAdded,
                toast: { kind: "error", text: t("ui.failed_to_load_sites") },
            });
        }
    };

    React.useEffect(() => {
        getSites();
    }, [page, filterInput]);


    const handlePolygon = (value: boolean): boolean => {
        polygonHandler(value);

        setOpenCreate(true);

        return value;
    };


    useEffect(() => {
        if (polygonData) {
            // setOpenCreate(true);
        }
    }, [polygonData])


    function handleCurrentOpen(arg: number) {
        if (arg === 0) {
            selectPolygon(null);
        }
        setCurrentOpen(arg);
    }

    useEffect(() => {
        if (currentOpen > 0) {
            selectPolygon(sites.find(site => Number(site.site_id) === Number(currentOpen))?.drawn_polygon);
        }
    }, [currentOpen]);

    console.log('currentOpen ====xxx>>>> ', currentOpen);
    return (
        <>
            <div style={{ ...style.flexColumn, padding: 2 }}>
                <div style={{ margin: 10 }}>
                    <LocationSearch inputHandler={setFilterInput} />
                </div>
                <div style={{ ...style.flexColumn, gap: 2, height: 300, overflowY: 'scroll', justifyContent: 'flex-start' }}>
                    {sites.map(site => (
                        <LocationTile
                            site={site}
                            scopeOptions={scopeOptions}
                            key={site.site_id}
                            openHandler={handleCurrentOpen}
                            currentOpen={currentOpen}
                        />),
                    )}
                </div>
                {sitesCount > 10 &&
                    <Pagination
                        changePage={setPage}
                        currentPage={page}
                        lastPage={Math.ceil(sitesCount / 10)}
                    />
                }
                {/* <div style={{ ...style.centerFlex, gap: 10, flexDirection: 'row', justifyContent: 'flex-start', padding: '10px 20px' }}>
                <Button
                  title="Next Step: Evaluate"
                  style={style.sleapSectionRightButton}
                  onClick={() => setSleapStep('E')}
                />
            </div> */}
                <div style={{ ...style.centerFlex, gap: 10, flexDirection: 'row', justifyContent: 'flex-start', padding: '10px 20px' }}>
                    <button
                        style={{ ...styles.iconButton, ...style.centerFlex }}
                        onClick={() => {
                            setOpenCreate(true)
                        }}
                        disabled={!validForCreate}
                        title={validForCreate === false ? "You exceeded the sites creation limits (15)." : "Create new site."}
                    >
                        <Icon path={mdiPlus} color={colors.darkBlue} size={30} />
                    </button>
                    {/* "we disable this for now, until we implement the upload to the new backend" */}
                    {/*<button
                  onClick={() => document.getElementById('csvFileInput').click()}
                  style={{ ...styles.iconButton, ...style.centerFlex }}
                >
                    <Icon path={mdiFileUploadOutline} color={colors.darkBlue} size={30} />
                </button>
                <input
                  type={"file"}
                  id={"csvFileInput"}
                  accept={".csv"}
                  onChange={handleOnChange}
                  hidden
                />*/}
                    <button
                        onClick={() => setOpenBulkInsert(true)}
                        style={{ ...styles.iconButton, ...style.centerFlex }}
                        disabled={!validForCreate}
                        title={validForCreate === false ? "You exceeded the sites creation limits (15)" : "Create new sites."}
                    >
                        <Icon path={mdiReceiptText} color={colors.opaqueBlue} size={30} />
                    </button>
                    {/* "we disable this for now, until we implement the upload to the new backend" */}
                    {/* <div className="button-container">
                    <a
                      href="-/app/site_import_example.csv"
                      download="site_import_template.csv"
                      style={{ ...styles.iconButton, ...style.centerFlex, backgroundColor: 'transparent', border: `1px solid ${colors.white}` }}
                      className="custom-button"
                    >
                        <Icon path={mdiAttachment} color={colors.white} size={30} />
                    </a>
                </div> */}
                </div>
                {openCreate && (
                    <LocationForm
                        saveHandler={createLocation}
                        closeHandler={closeForm}
                        scopeOptions={scopeOptions}
                        polygonHandler={handlePolygon}
                        siteData={{
                            latlng: {
                                lat: polygonData?.polygonCenter?.[1],
                                lng: polygonData?.polygonCenter?.[0]
                            },
                            zip_code: polygonData?.locationDetails?.zipcode,
                            country_code: polygonData?.locationDetails?.country,
                            city: polygonData?.locationDetails?.city,
                            street_address: polygonData?.locationDetails?.street
                        }}
                        isCreate={true}
                    />)}
                {openBulkInsert && (
                    <LocationBulkInsertForm
                        saveHandler={createLocation}
                        closeHandler={closeForm}
                        scopeOptions={scopeOptions}
                    />)}
            </div>
            {showValidationModule && <SiteImportValidationDialog
                validationResponse={response}
                onClose={() => setShowValidationModule(false)}
            />}
        </>
    );
};

const styles = StyleSheet.create({
    iconButton: {
        height: 60,
        width: 60,
        borderRadius: 10,
        border: 'none',
    },
});
