import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useProject, useTeam } from "@/context";
import { httpGet, t } from "@/utils";
import { updateReport } from "@/resources";
import { Button, LoadingAnimation, VerticalStack } from "@/lib";

interface ISiteImportWaitCompletionStepProps {
    data: IAddToSitesData[];
    jobId: string;
    loading: boolean;
}

export default function SiteImportWaitCompletionStep(props: ISiteImportWaitCompletionStepProps): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const [job, setJob] = React.useState<IJob>(undefined);
    const navigate = useNavigate();
    const team = useTeam();
    const project = useProject();
    const onCheckImportCompletion = (intervalId: number) => {
        httpGet<IJob>(`/api/v1/jobs/${team.slug}/${props.jobId}`).then((reply) => {
            if (!reply.ok) {
                return;
            }

            setJob(reply.data);

            if (reply.data.status === "complete") {
                window.clearInterval(intervalId);
            }
        });
    };

    const onShow = () => {
        updateReport(state, dispatch, project, project.latest_report_id, true).then(() =>
            navigate(`/${team.slug}/${project.slug}/explore`),
        );
    };

    React.useEffect(() => {
        const intervalId = window.setInterval(() => {
            onCheckImportCompletion(intervalId);
        }, 2000);

        return () => window.clearInterval(intervalId);
    }, []);

    return (
        <VerticalStack alignItems="center" style={{ marginTop: "4rem" }}>
            {job?.status === "complete" && (
                <>
                    <div>{t("ui.sites_import.importing_sites_complete")}!</div>
                    <Button onClick={onShow} style={{ marginTop: "1.5rem" }} variant="primary">
                        {t("actions.show")}
                    </Button>
                </>
            )}

            {job?.status !== "complete" && (
                <>
                    <LoadingAnimation />
                    <div>{t("ui.sites_import.importing_sites")}…</div>
                </>
            )}
        </VerticalStack>
    );
}
