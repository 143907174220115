import * as React from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import StaticMapWrapper from "@/lib/map/StaticMapWrapper";
import {
  AppContext,
  useLoading,
  useProject,
  useTeam,
  ToastAdded,
  ShowSideStepDetail,
} from "@/context";
import { apiGet, t, getHomeUrl } from "@/utils";
import useFetchDependencies from "@/utils/useFetchDependencies";
import useFetchImpacts from "@/utils/useFetchImpacts";
import DetailContent from "@/project/understand/DetailContent";
import { useSleapStep } from "@/utils/customHooks";
import { Button } from "@/project/sleap/Button";
import Bubble from "@/lib/Bubble";
import { initImpactDependencyClusters } from "@/lib/AppLoader";
import { LoadingAnimation } from "@/lib";
import { getBubbleBarColor } from "@/utils/getBubbleBarColor";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

export default function DashboardPage(): JSX.Element {
  const { state } = React.useContext(AppContext);
  console.log("state from understand ----->>> ", state);
  // const allImpacts = useImpacts();
  // const allDependencies = useDependencies();
  const [showImpact, setShowImpact] = React.useState(false);
  const [showDependencies, setShowDependencies] = React.useState(false);
  const [NoOfImapcts, setNoOfImapcts] = React.useState(0);
  const [NoOfDependencies, setNoOfDependencies] = React.useState(0);

  const dependencies = React.useRef(null);
  const impact = React.useRef(null);
  const project = useProject();
  console.log("project from understand ----->>> ", project);
  const economicActivitiesIds =
    project.economic_activities.map((item) => item.id).join(",") ?? "";
  console.log(
    "_economicActivitiesIds from understand ----->>> ",
    economicActivitiesIds
  );
  const [, setSleapStep] = useSleapStep();
  //const { sites } = useSites();
  const sites = state.sites;
  const [applicableImpacts, setApplicableImpacts] = React.useState(0);
  const [prioritySites, setPrioritySites] = React.useState(0);
  const [applicableDependencies, setApplicableDependencies] = React.useState(0);
  const { dispatch } = React.useContext(AppContext);
  const team = useTeam();
  const scopeAvailable = React.useMemo(
    () => project.is_complete,
    [project.is_complete]
  );
  const [totalSites, setTotalSites] = React.useState(0);
  const [impactRatings, setImpactRatings] = React.useState<iCluster[]>([]);
  const [dependencyRatings, setDependencyRatings] = React.useState<iCluster[]>(
    []
  );
  const isLoading = useLoading();
  const navigate = useNavigate();

  // Call the custom hook and destructure its results
  //const [results, setResults] = React.useState(null);
  const encryptedToken = CryptoJS.AES.encrypt(
    process.env.API_KEY,
    process.env.SECRET_KEY
  ).toString();
  const header = {
    headers: {
      Authorization: `Bearer ${encryptedToken}`,
    },
  };
  async function checkCompanyExistance() {
    let checkCompanyUrl = `${process.env.KUYUA_APP_BACKEND_URL}/company?id=${team.id}`;
    const checkResponse = await fetch(checkCompanyUrl, header);
    const checkCompanyData = await checkResponse.json();
    if (checkCompanyData.error) {
      await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/company`, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${encryptedToken}`,
        },
        body: JSON.stringify({
          company_id: team.id,
          name: team.name,
          slug: team.slug,
          is_deleted: false,
        }),
      })
        .then(async (data) => {
          console.log("Company added successfully.");
        })
        .catch((error) => {
          console.error("Error at adding company:", error);
        });
    }
  }

  const {
    totalUniqueImpacts,
    totalUniqueImpactsWithRating,
    restructuredImpactsGeoServer,
    loading,
    error,
  } = useFetchImpacts(economicActivitiesIds);

  const {
    totalUniqueDependencies,
    totalUniqueDependenciesWithRating,
    restructuredDependenciesGeoServer,
  } = useFetchDependencies(economicActivitiesIds);

  // React.useEffect(() => {
  //     if (economicActivitiesIds) {
  //         setResults({
  //             totalUniqueDependencies,
  //             totalUniqueDependenciesWithRating,
  //             restructuredDependenciesGeoServer,
  //             totalUniqueImpacts,
  //             totalUniqueImpactsWithRating,
  //             restructuredImpactsGeoServer
  //         });

  //     }
  // }, [economicActivitiesIds, site]);

  async function getPriorityCount() {
    let countEndPointURL = `${process.env.KUYUA_APP_BACKEND_URL}/sites/count-priority-locations?company_id=${team.id}`;
    const response = await fetch(countEndPointURL, header);
    const priorityCount = await response.json();
    return priorityCount.count;
  }
  getPriorityCount().then((result) => {
    setPrioritySites(result);
    //save into localstorage
    if (window) {
      window.localStorage.setItem("prioritySites", JSON.stringify(result));
    }
  });
  async function getSitesCount() {
    let countEndPointURL = `${process.env.KUYUA_APP_BACKEND_URL}/sites/count-sites?company_id=${team.id}`;
    const response = await fetch(countEndPointURL, header);
    const sitesCount = await response.json();
    return sitesCount.count;
  }
  getSitesCount().then((result) => {
    setTotalSites(result);
    if (window) {
      window.localStorage.setItem("totalSites", JSON.stringify(result));
    }
  });
  React.useEffect(() => {
    checkCompanyExistance();
  }, []);

  React.useEffect(() => {
    showDependencies && dependencies.current?.classList.add("fade-in");
    showImpact && impact.current?.classList.add("fade-in");
  }, [showDependencies, showImpact]);

  const closeDependencies = React.useCallback(() => {
    dependencies.current?.classList.add("fade-out");
    setTimeout(() => {
      setShowDependencies(false);
    }, 300);
  }, []);

  const closeImpact = React.useCallback(() => {
    impact.current?.classList.add("fade-out");
    setTimeout(() => {
      setShowImpact(false);
    }, 300);
  }, []);

  React.useEffect(() => {
    if (!state?.impactClusters?.length) {
      initImpactDependencyClusters(dispatch, team.slug, project.slug);
    }
    if (scopeAvailable && state?.impactClusters?.length) {
      setImpactRatings(state?.impactClusters.slice(0, 3));
      setDependencyRatings(state?.dependencyClusters.slice(0, 3));
    }
  }, [state?.impactClusters, state?.dependencyClusters]);

  React.useEffect(() => {
    setApplicableImpacts(
      impactRatings.reduce((sum, newValue) => sum + newValue.values.length, 0)
    );
  }, [impactRatings]);
  React.useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("totalUniqueDependenciesWithRating")) {
        const _totalUniqueDependenciesWithRating = JSON.parse(
          localStorage.getItem("totalUniqueDependenciesWithRating")
        );
        console.log(
          "totalUniqueDependenciesWithRating from localstorage ===>>>> ",
          _totalUniqueDependenciesWithRating
        );
        setNoOfDependencies(_totalUniqueDependenciesWithRating ?? 0);
      }
    }, 700);
  }, []);
  React.useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("totalUniqueImpactsWithRating")) {
        const _totalUniqueImpactsWithRating = JSON.parse(
          localStorage.getItem("totalUniqueImpactsWithRating")
        );
        console.log(
          "totalUniqueImpactsWithRating from localstorage ===>>>> ",
          _totalUniqueImpactsWithRating
        );
        setNoOfImapcts(_totalUniqueImpactsWithRating ?? 0);
      }
    }, 700);
  }, []);

  function HandleAssessClick() {
    navigate(getHomeUrl(team) + `/measure`, { state: "A" });
    setSleapStep("A");
    dispatch({ type: ShowSideStepDetail, value: true });
  }
  const showLocateButton =
    state.sideStepActive.length < 1 &&
    scopeAvailable &&
    !showImpact &&
    !showDependencies &&
    !sites?.length;
  const showAssessButton = sites?.length && !showImpact && !showDependencies;

  return (
    <div
      className="content"
      style={{
        height: "100vh",
      }}
    >
      {isLoading && <LoadingAnimation />}
      <StaticMapWrapper right={true} />
      <div style={style.leftContainer}>
        <div>
          <div
            style={{
              ...style.opacityContainer,
              ...styles.logoBox,
              ...style.leftCenter,
            }}
          >
            <img
              src={require("../../assets/office-logo.png")}
              style={{ ...style.img, maxWidth: 150 }}
            />
          </div>
          {!scopeAvailable && (
            <button
              style={{
                ...style.center,
                ...style.actionButton,
                fontSize: 14,
                fontWeight: "normal",
                color: colors.black,
                width: 195,
              }}
              onClick={() => setSleapStep("S")}
            >
              Start
            </button>
          )}
          <div style={{ ...style.topCenter, ...style.centerFlex, width: 300 }}>
            <img
              src={require("../../assets/arrows/arrow-right.png")}
              style={{ ...style.img, transform: "translate(0, 15px)" }}
            />
            {economicActivitiesIds.length > 0 &&
              restructuredImpactsGeoServer &&
              restructuredDependenciesGeoServer && (
                <div style={styles.bubble}>
                  <Bubble
                    count={totalUniqueImpactsWithRating}
                    countTotal={12}
                    style={{
                      color: colors.white,
                      backgroundColor: getBubbleBarColor([
                        ...restructuredImpactsGeoServer,
                        ...restructuredDependenciesGeoServer,
                      ]),
                    }}
                  />
                </div>
              )}

            {NoOfImapcts > 0 && !showImpact && (
              <div
                style={{ ...style.actionButton, ...styles.detailButton }}
                onClick={() => {
                  setShowDependencies(false);
                  setShowImpact(true);
                }}
              >
                Details
              </div>
            )}
            <div style={{ ...style.opacityContainer, ...styles.textBox }}>
              Impacts
            </div>
          </div>
          {/* {sites?.length && (<div style={{ ...style.center, ...style.centerFlex, width: 300 }}>
                    <img src={require('../../assets/arrows/arrow-straight.png')}
                         style={style.img} />
                    <div style={{
                        ...style.bubble, ...style.centerFlex, ...style.upperCaseCenterText,
                        backgroundColor: colors.purple,
                        color: colors.white,
                    }}>
                        {prioritySites}
                        <span style={style.totalSpan}>out of {totalSites}</span>
                    </div>
                    <div style={{ ...style.opacityContainer, ...styles.textBox, transform: 'translate(0px, 55px)' }}>
                        Priority Locations
                    </div>
                </div>)} */}
          <div
            style={{ ...style.bottomCenter, ...style.centerFlex, width: 300 }}
          >
            <img
              src={require("../../assets/arrows/arrow-left.png")}
              style={{ ...style.img, transform: "translate(0, -15px)" }}
            />
            {economicActivitiesIds.length > 0 &&
              restructuredImpactsGeoServer &&
              restructuredDependenciesGeoServer && (
                <div style={styles.bubble}>
                  <Bubble
                    count={totalUniqueDependenciesWithRating}
                    countTotal={21}
                    style={{
                      color: colors.white,
                      backgroundColor: getBubbleBarColor([
                        ...restructuredImpactsGeoServer,
                        ...restructuredDependenciesGeoServer,
                      ]),
                    }}
                  />
                </div>
              )}
            {NoOfDependencies > 0 && !showDependencies && (
              <div
                style={{ ...style.actionButton, ...styles.detailButton }}
                onClick={() => {
                  setShowImpact(false);
                  setShowDependencies(true);
                }}
              >
                Details
              </div>
            )}
            <div style={{ ...style.opacityContainer, ...styles.textBox }}>
              Dependencies
            </div>
          </div>
        </div>
        {showImpact && (
          <div style={{ ...styles.detailContent, top: 0 }} ref={impact}>
            {
              <DetailContent
                closeHandler={closeImpact}
                title={"impacts"}
                barColor={colors.white}
                backgroundColor={colors.red}
                color={"white"}
                clusteredRatings={restructuredImpactsGeoServer}
                totalAvailable={totalUniqueImpacts}
              />
            }
          </div>
        )}
        {showDependencies && (
          <div
            style={{ ...styles.detailContent, bottom: 0 }}
            ref={dependencies}
          >
            {
              <DetailContent
                closeHandler={closeDependencies}
                title={"dependencies"}
                barColor={colors.mediumBlue}
                backgroundColor={colors.yellow}
                color={colors.darkBlue}
                clusteredRatings={restructuredDependenciesGeoServer}
                totalAvailable={totalUniqueDependencies}
              />
            }
          </div>
        )}
      </div>
      {/* {showLocateButton && (
            <Button
                title={'NEXT STEP: LOCATE'}
                onClick={() => setSleapStep('L')}
                style={{ ...style.centerGlobe }}
                size="md"
            />
        )} */}
      {/* Commented to test the view without the button */}
      {/* {showAssessButton && (
          <Button
            title={'ASSESS'}
            onClick={() => HandleAssessClick()}
            style={{ ...style.centerGlobe }}
            size="md"
          />
        )} */}
    </div>
  );
}

const styles = StyleSheet.create({
  logoBox: {
    width: 200,
    height: 140,
    position: "relative",
    top: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 20px",
  },
  textBox: {
    position: "absolute",
    bottom: 0,
    minWidth: 100,
    padding: "5px 10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    transform: "translate(0, 40px)",
    textTransform: "uppercase",
  },
  detailButton: {
    position: "absolute",
    width: 50,
    fontSize: 10,
    height: 20,
    borderRadius: 20,
    color: colors.darkBlue,
    top: -10,
    right: 75,
    lineHeight: 1,
  },
  detailContent: {
    width: 420,
    position: "absolute",
    backgroundColor: colors.darkBlue,
    borderRadius: 10,
    border: "1px solid #2FF7FF",
    // height:100,
    display: "flex",
    flexDirection: "column",
    right: -150,
  },
  bubble: {
    position: "absolute",
  },
});
