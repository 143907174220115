import * as React from "react";
import { mdiArrowRightThin, mdiChevronDown, mdiChevronUp, mdiWindowClose } from "@mdi/js";
import { AppContext, useTeam, useTheme } from "@/context";
import { Icon, ErrorPanel, Dropdown, ActionLink, LoadingAnimation, TableHeaderCell } from "@/lib";
import { BaseModalDialog } from "@/lib/modals";
import { apiGet, t } from "@/utils";
import { getRatingTextFromNumber } from "@/utils/getRatingText";
import "./index.scss";
import { Link, NavLink } from "react-router-dom";
import CryptoJS from "crypto-js";
import { colors, style, StyleSheet } from "@/utils/style";
import Checkbox from "@/utils/Checkbox";
import Tooltip from "@/lib/Tooltip/Tooltip";
import Dropdown2 from "@/utils/Dropdown";
import { DownloadButtonDefault } from "@/components/DownloadButton";
import { DropDownTableHeaderCell } from "@/lib/TableHeaderCell";
// import { ActionLink, HorizontalStack, SectionHeader } from "@/lib";

const containerStyle = {
    height: "100%",
    color: "white",
    fontSize: "12px", // Updated font size
    borderRadius: 10,
    border: `1px solid ${colors.brightBlue}`,
    padding: "10px",
    backgroundColor: colors.darkBlue,
};
const MitigationOptions = [
    { id: "avoid", label: "Avoid" },
    { id: "minimise", label: "Minimize" },
    { id: "restore", label: "Restore" },
    // { id: 'offset', label: 'Offset' },
];

const LevelOptions = [
    { id: "minimal", label: "Minimal" },
    { id: "low", label: "Low" },
    { id: "medium", label: "Medium" },
    { id: "high", label: "High" },
    { id: "significant", label: "Significant" },
];

const StatusOptions = [
    { label: "Planned", value: null },
    { label: "Implemented", value: true },
    { label: "N/A", value: false },
];

const createFilterLabel = (activeFilters: string[], allFilters: { id: string; label: string }[]) => {
    if (activeFilters.length === 0) {
        return t("ui.all");
    }
    return allFilters
        .filter((o) => !!activeFilters.find((f) => f === o.id))
        .map((o) => o.label)
        .join(", ");
};

interface ISiteActionsProps {
    project: IProject;
    report: IAssessmentReport;
    site: INewSiteDetailed;
    siteKpis: {};
}

const toggleFilter = (id: string, filters: string[], setter: React.Dispatch<React.SetStateAction<string[]>>) => {
    if (filters.find((item) => item === id)) {
        setter(filters.filter((filter) => filter !== id));
    } else {
        setter(filters.concat(id));
    }
};
const SelectOptions = React.memo(
    ({ options, onChange, value }: { options: string[]; onChange: (value: string) => void; value: string }) => (
        <select
            onChange={(e) => onChange(e.target.value)}
            style={{
                width: "100%",
                borderRadius: 10,
                height: 30,
                background: "transparent",
                color: "white",
                fontSize: "12px", // Updated font size
            }}
            value={value}
            disabled={false}
        >
            {options.map((option) => (
                <option style={{ color: "black" }} key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    ),
);

export default function SiteActionsTab(props: ISiteActionsProps): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const team = useTeam();
    const theme = useTheme();

    const [ipbesFilters, setIpbesFilters] = React.useState<string[]>([]);
    const [mitigationFilters, setMitigationFilters] = React.useState<string[]>([]);
    const [effortFilters, setEffortFilters] = React.useState<string[]>([]);
    const [impactFilters, setImpactFilters] = React.useState<string[]>([]);

    const [showPopup, setShowPopup] = React.useState<"ipbes" | "mitigation" | null>(undefined);

    const [ipbesOptions, setIpbesOptions] = React.useState<{ id: string; label: string }[]>(undefined);
    const [measureChanged, setMeasureChanged] = React.useState<boolean>(false);

    const [actionPlans, setActionPlans] = React.useState([]);
    const [stats, setStats] = React.useState<ISiteStatistics[]>(undefined);
    const [actionStatus, setActionStatus] = React.useState([]);

    const [expandedRows, setExpandedRows] = React.useState({});
    const [filteredActionPlans, setFilteredActionPlans] = React.useState([]);

    const [subIndustryName, setSubIndustryName] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            Authorization: `Bearer ${encryptedToken}`,
        },
    };
    const tmpEcoActIds = props.site.economic_activities.map((item: any) => item.economic_activity_id);
    const economicActivitiesIds = Array.from(new Set(tmpEcoActIds)).toString();
    const subIndustryIds = props.site.economic_activities.map((item: any) => item.subindustry_id);
    const uniqueStringSubIndustryIds = Array.from(new Set(subIndustryIds)).toString();

    async function getActionPlans() {
        try {
            const url = `${process.env.GEO_MIND_URL}/action-plan/recommendations/`;
            const siteKpisData = props.siteKpis;
            const reqBody = {
                site_conditions: await formatSiteConditions(siteKpisData),
                impacts_dependencies: transformToImpactDependencyString(siteKpisData),
                top_k: 30,
                sub_industries_ids: uniqueStringSubIndustryIds,
            };
            console.log("Action plan post body: ", reqBody);
            const reqResult = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${encryptedToken}`,
                },
                body: JSON.stringify(reqBody),
            });

            const alldata = await reqResult.json();
            const actions = alldata.data.map((action) => {
                action.action.ipbes = JSON.parse(action.action.ipbes)[0];
                return action;
            });
            setActionPlans(actions);
            setStats(alldata.metadata);
        } catch (error) {
            console.error("Error:", error);
            setIsLoading(false);
        }
    }

    async function getActionStatus() {
        try {
            console.log("actions : ", actionPlans);
            if (actionPlans.length < 1) return;

            const actionsIDs = actionPlans.map((action) => `ids[]=${action.action?.recommendation_id}`);
            const url = `${process.env.KUYUA_APP_BACKEND_URL}/recommendations-statuses?site_id=${props.site.site_id}&${actionsIDs.join("&")}`;

            let reqResult = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${encryptedToken}`,
                },
            });

            reqResult = await reqResult.json();
            if (reqResult.data?.length > 0) {
                let ActionStatus = reqResult.data;
                setActionStatus(ActionStatus);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching action status:", error);
            setIsLoading(false);
        }
    }

    const fetchWaterRiskImpact = async () => {
        const url = `${process.env.KUYUA_APP_BACKEND_URL}/impacts/max?site_id=${props.site.site_id}&economic_activities_ids=${economicActivitiesIds}
        &impact_names=water_use,water_pollutants`;
        try {
            const response = await fetch(url, header);
            const data = await response.json();
            if (data.max_impact !== "N/A") {
                return data.max_impact;
            } else {
                return data.potential_max_impact;
            }
        } catch (error) {
            console.error("Error fetching water risk impact:", error);
        }
    };
    const fetchDeforestationRiskImpact = async () => {
        const url = `${process.env.KUYUA_APP_BACKEND_URL}/impacts/max?site_id=${props.site.site_id}&economic_activities_ids=${economicActivitiesIds}&impact_names=terrestrial_ecosystem_use,disturbances`;
        try {
            const response = await fetch(url, header);
            const data = await response.json();
            if (data.max_impact !== "N/A") {
                return data.max_impact;
            } else {
                return data.potential_max_impact;
            }
        } catch (error) {
            console.error("Error fetching deforestation risk impact:", error);
        }
    };
    const fetchWaterRiskDependency = async () => {
        const url = `${process.env.KUYUA_APP_BACKEND_URL}/dependencies/max?site_id=${props.site.site_id}&economic_activities_ids=${economicActivitiesIds}&dependency_names=surface_water,ground_water,water_flow_maintenance,water_quality`;
        try {
            const response = await fetch(url, header);
            const data = await response.json();
            if (data.max_dependency !== "N/A") {
                return data.max_dependency;
            } else {
                return data.potential_max_dependency;
            }
        } catch (error) {
            console.error("Error fetching water risk Dependency:", error);
        }
    };
    const fetchDeforestationRiskDependency = async () => {
        const url = `${process.env.KUYUA_APP_BACKEND_URL}/dependencies/max?site_id=${props.site.site_id}&economic_activities_ids=${economicActivitiesIds}&dependency_names=climate_regulation,flood_and_storm_protection,filtration,mass_stabilisation_and_erosion_control,mediation_of_sensory_impacts`;
        try {
            const response = await fetch(url, header);
            const data = await response.json();
            if (data.max_dependency !== "N/A") {
                return data.max_dependency;
            } else {
                return data.potential_max_dependency;
            }
        } catch (error) {
            console.error("Error fetching Deforestation risk Dependency:", error);
        }
    };
    async function formatSiteConditions(data) {
        const [waterRiskImpact, deforestationRiskImpact, waterRiskDependency, deforestationRiskDependency] = await Promise.all([
            fetchWaterRiskImpact(),
            fetchDeforestationRiskImpact(),
            fetchWaterRiskDependency(),
            fetchDeforestationRiskDependency(),
        ]);
        const habitatDict = data.habitatMigrationData.habitatDict;
        const habitatStr = habitatDict.map((item) => `${item.name} ${item.percentage}%`).join(", ");
        const tmpThreatenedAndKeystoneSpecies = data.speciesData?.key_stone_threatened_species_count;
        const tmpBss =
            data.speciesData?.key_stone_species_count === 0
                ? 0
                : (tmpThreatenedAndKeystoneSpecies / data.speciesData?.key_stone_species_count) * 100;
        const siteConditions =
            `Biodiversity Intactness ${data.msa?.toFixed(0)}%, ` +
            `Projected Change in Biodiversity Intactness ${data.msaDelta}%, ` +
            `Species Richness ${data.speciesData.total_unique_keystone_species}, ` +
            `Biodiversity-Sensitive Areas (BSA) ${data.BSACount}, ` +
            `Vegetation Productivity (gC/m²/year, 2023) ${data.netPrimary || "N/A"}, ` +
            `Trend in Vegetation Productivity ${data.changeYAverage}, ` +
            `Threatened Species ${data.speciesData.threatened_species_count}, ` +
            `Keystone Species Richness ${data.speciesData.key_stone_species_count}, ` +
            `Keystone Species @ Risk ${tmpBss.toFixed(1)}%, ` +
            `Water Risk ${data.waterRiskCategory}, ` +
            `Water Impact Level ${getRatingTextFromNumber(waterRiskImpact)}, ` +
            `Water Dependency Level ${getRatingTextFromNumber(waterRiskDependency)}, ` +
            `Deforestation ${data.deforestationData?.year}, ` +
            `Deforestation dominant Driver ${data.deforestationData?.deforestation_category}, ` +
            `Deforestation Impact Level ${getRatingTextFromNumber(deforestationRiskImpact)}, ` +
            `Deforestion Dependency Level ${getRatingTextFromNumber(deforestationRiskDependency)}, ` +
            `${habitatStr}`;
        return siteConditions;
    }
    async function handleStatusChanged(recommendation_id: number, new_status: number) {
        const site_id = parseInt(props.site.site_id);
        const reqResult = await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/recommendations-statuses`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${encryptedToken}`,
            },
            body: JSON.stringify({
                site_id,
                recommendation_id,
                status: new_status,
            }),
        });

        if (reqResult.status === 200) {
            console.log("Status changed successfully");
        }

        // change the status in the state by reccomendation_id
        let newActionStatus = [...actionStatus]; // copying the array to ensure a re-render
        let index = newActionStatus.findIndex((action) => action.recommendation_id === recommendation_id);
        if (index < 0) {
            newActionStatus.push({ recommendation_id, status: new_status, site_id });
        } else {
            newActionStatus[index].status = new_status;
        }
        setActionStatus(newActionStatus);
    }

    function sortActionsBySimilarity(actions: any) {
        // Sort actions by similarity to the site
        return actions.sort((a, b) => {
            if (a.similarity > b.similarity) {
                return -1;
            } else if (a.similarity < b.similarity) {
                return 1;
            }
            return 0;
        });
    }

    React.useEffect(() => {
        setSubIndustryName(props.site.economic_activities[0].subindustry_name);
        getActionPlans();
    }, []);

    React.useEffect(() => {
        setFilteredActionPlans(actionPlans);

        //-----------------
        // get unique ipbes options, mitigations options are constant
        const uniqueIpbes = actionPlans.reduce((acc, cluster) => {
            const ipbesObject = cluster.action.ipbes;
            if (ipbesObject !== null) {
                acc[ipbesObject.code] = ipbesObject.name;
            }
            return acc;
        }, []);
        const result = Object.entries(uniqueIpbes).map(([id, label]) => ({
            id,
            label,
        }));
        if (result.length > 0) {
            console.log("IPBES kpi: ", result);
            setIpbesOptions(result);
        }
        // ---------------

        getActionStatus();
    }, [actionPlans]);

    React.useEffect(() => {
        if (mitigationFilters.length > 0) {
            setIpbesFilters([]);
            setImpactFilters([]);
            setEffortFilters([]);
        }
    }, [mitigationFilters]);

    React.useEffect(() => {
        if (ipbesFilters.length > 0) {
            setMitigationFilters([]);
            setImpactFilters([]);
            setEffortFilters([]);
        }
    }, [ipbesFilters]);

    React.useEffect(() => {
        if (effortFilters.length > 0) {
            setIpbesFilters([]);
            setMitigationFilters([]);
            setImpactFilters([]);
        }
    }, [effortFilters]);

    React.useEffect(() => {
        if (impactFilters.length > 0) {
            setIpbesFilters([]);
            setMitigationFilters([]);
            setEffortFilters([]);
        }
    }, [impactFilters]);
    const updateFilteredActionPlans = React.useCallback(() => {
        const sortedActions = sortActionsBySimilarity(actionPlans);

        if (mitigationFilters.length > 0) {
            const filtered = sortedActions.filter((plan) => mitigationFilters.includes(plan.action.mitigation_description.toLowerCase()));
            setFilteredActionPlans(filtered);
        } else if (ipbesFilters.length > 0) {
            const filtered = sortedActions.filter((plan) => ipbesFilters.includes(plan.action.ipbes.code));
            setFilteredActionPlans(filtered);
        } else if (impactFilters.length > 0) {
            const filtered = sortedActions.filter((plan) =>
                impactFilters.includes(replaceLevelNumber(plan.action.impact_points).toLowerCase()),
            );
            setFilteredActionPlans(filtered);
        } else if (effortFilters.length > 0) {
            const filtered = sortedActions.filter((plan) =>
                effortFilters.includes(replaceLevelNumber(plan.action.effort_points).toLowerCase()),
            );
            setFilteredActionPlans(filtered);
        } else {
            setFilteredActionPlans(sortedActions);
        }
    }, [mitigationFilters, ipbesFilters, impactFilters, effortFilters, actionPlans]);

    React.useEffect(() => {
        updateFilteredActionPlans();
    }, [updateFilteredActionPlans]);

    const mitigationFiltersLabel = React.useMemo(() => createFilterLabel(mitigationFilters, MitigationOptions), [mitigationFilters]);
    const toggleRow = (id: number) => {
        setExpandedRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    return (
        <div>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", gap: "10px", marginBottom: "1rem" }}>
                <DownloadButtonDefault disabled={true}>Export Data</DownloadButtonDefault>
            </div>
            <div>
                {isLoading ? (
                    <LoadingAnimation />
                ) : actionPlans.length !== 0 ? (
                    <div className="">
                        <>
                            <div style={{ ...containerStyle, marginBottom: "50px" }}>
                                <div className={"table-container"}>
                                    <table
                                        style={{
                                            width: "100%",
                                            borderCollapse: "collapse",
                                            backgroundColor: colors.opaqueBlue,
                                            color: "#fff",
                                            tableLayout: "fixed", // Ensures consistent column width
                                        }}
                                        className="s-table s-table-fixed species-table"
                                    >
                                        <thead>
                                            <tr style={{ backgroundColor: colors.opaqueBlue }}>
                                                <TableHeaderCell
                                                    label="Name"
                                                    labelStyle={{ textAlign: "left", padding: "12px", fontWeight: "bolder" }}
                                                    width="250px"
                                                    sortable={false}
                                                />
                                                <TableHeaderCell
                                                    label="Cluster"
                                                    labelStyle={{ textAlign: "left", padding: "12px", fontWeight: "bolder" }}
                                                    sortable={false}
                                                />
                                                <DropDownTableHeaderCell
                                                    dropdownOptions={ipbesOptions}
                                                    dropdownSelected={ipbesOptions
                                                        .filter((o) => ipbesFilters.includes(o.id))
                                                        .map((o) => o.id)}
                                                    dropDownHandler={(id) => toggleFilter(id, ipbesFilters, setIpbesFilters)}
                                                    label="IRO"
                                                    labelStyle={{ textAlign: "left", padding: "12px", fontWeight: "bolder" }}
                                                    width="15%"
                                                />
                                                <DropDownTableHeaderCell
                                                    dropdownOptions={MitigationOptions}
                                                    dropdownSelected={mitigationFilters}
                                                    dropDownHandler={(id) => toggleFilter(id, mitigationFilters, setMitigationFilters)}
                                                    label="Mitigation Hierarchy"
                                                    labelStyle={{ textAlign: "left", padding: "12px", fontWeight: "bolder" }}
                                                />
                                                <DropDownTableHeaderCell
                                                    dropdownOptions={LevelOptions}
                                                    dropdownSelected={LevelOptions.filter((o) => impactFilters.includes(o.id)).map(
                                                        (o) => o.id,
                                                    )}
                                                    dropDownHandler={(id) => toggleFilter(id, impactFilters, setImpactFilters)}
                                                    label="Level of Impact"
                                                    labelStyle={{ textAlign: "left", padding: "12px", fontWeight: "bolder" }}
                                                />
                                                <DropDownTableHeaderCell
                                                    dropdownOptions={LevelOptions}
                                                    dropdownSelected={LevelOptions.filter((o) => effortFilters.includes(o.id)).map(
                                                        (o) => o.id,
                                                    )}
                                                    dropDownHandler={(id) => toggleFilter(id, effortFilters, setEffortFilters)}
                                                    label="Level of Effort"
                                                    labelStyle={{ textAlign: "left", padding: "12px", fontWeight: "bolder" }}
                                                />
                                                <TableHeaderCell
                                                    label="Status"
                                                    labelStyle={{ textAlign: "left", padding: "12px", fontWeight: "bolder" }}
                                                    width="20%"
                                                    sortable={false}
                                                />
                                                <TableHeaderCell
                                                    label=""
                                                    labelStyle={{ textAlign: "left", padding: "12px", fontWeight: "bolder" }}
                                                    width="50px"
                                                    sortable={false}
                                                />
                                                {/* <th></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!(actionPlans.length > 0 && filteredActionPlans.length < 1) ? ( // don't hide the filters controls when there is no actions because they are filtered
                                                filteredActionPlans.map((action, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr key={index}>
                                                            {" "}
                                                            {/* Normally I would've used the recemmendation-id, but until they are unique I will stick to the index*/}
                                                            <td
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                }}
                                                            >
                                                                {/* <NavLink
                                                                        className=""
                                                                        key={index}
                                                                        to={{ pathname: `./${action.action.cluster_id}` }}
                                                                        state={{ data: [action] }}
                                                                    >
                                                                    </NavLink> */}
                                                                {action.action?.recommendation_description}
                                                            </td>
                                                            <td>{action.action?.cluster_name}</td>
                                                            <td>{action.action?.ipbes?.name}</td>
                                                            <td>{action.action?.mitigation_description}</td>
                                                            <td>{replaceLevelNumber(action.action?.impact_points)}</td>
                                                            <td>{replaceLevelNumber(action.action?.effort_points)}</td>
                                                            <td>
                                                                <SelectOptions
                                                                    options={StatusOptions.map((x) => x.label)}
                                                                    value={statusToOption(
                                                                        actionStatus.find(
                                                                            (status) =>
                                                                                status.recommendation_id ==
                                                                                action.action?.recommendation_id,
                                                                        )?.status,
                                                                    )}
                                                                    onChange={(e) => {
                                                                        handleStatusChanged(
                                                                            action.action?.recommendation_id,
                                                                            optionToStatus(e),
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{ padding: "12px", textAlign: "center" }}>
                                                                <Icon
                                                                    style={{ ...styles.chevron }}
                                                                    color="white"
                                                                    path={expandedRows[index] ? mdiChevronUp : mdiChevronDown}
                                                                    size={32}
                                                                    onClick={() => toggleRow(index)}
                                                                />
                                                            </td>
                                                        </tr>
                                                        {/* Expanded Row */}
                                                        {expandedRows[index] && (
                                                            <tr>
                                                                <td
                                                                    colSpan={8} // Ensure it matches the total column count
                                                                    style={{
                                                                        padding: "12px",
                                                                        backgroundColor: colors.opaqueBlue,
                                                                    }}
                                                                >
                                                                    <div style={{ display: "flex", gap: "16px", textAlign: "left" }}>
                                                                        {/* Description (100%) */}
                                                                        <div style={{ flex: 1 }}>
                                                                            <strong>Description:</strong>
                                                                            <p style={{ padding: "0 30px" }}>
                                                                                {action.action?.recommendation_description}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <tr>
                                                    <th colspan={8} style={{ padding: "6rem 3rem" }}>
                                                        <ErrorPanel
                                                            title={`No actions satisfy the requirements, please change your filters`}
                                                        />
                                                    </th>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    </div>
                ) : (
                    <ErrorPanel title={`No actions for this Division: ${subIndustryName}`} style={{ marginTop: "3rem" }} />
                )}
            </div>

            {!actionPlans && <ErrorPanel title="No items" style={{ marginTop: "3rem" }} />}

            {showPopup && (
                <BaseModalDialog
                    title={showPopup === "mitigation" ? t("mitigation.title") : t("ipbes.title")}
                    onClose={() => setShowPopup(null)}
                >
                    <div>
                        {showPopup === "mitigation" ? (
                            <div>
                                {t("mitigation.info")}
                                <img src="/-/app/mitigation-hierarchy.png" width="100%" />
                            </div>
                        ) : (
                            t("ipbes.info")
                        )}
                    </div>
                </BaseModalDialog>
            )}
        </div>
    );
}

const styles = StyleSheet.create({
    chevron: {
        cursor: "pointer",
    },
    rowIsClosed: {
        backgroundColor: colors.mediumBlue,
    },
    rowIsOpen: {
        backgroundColor: colors.opaqueBlue,
        borderBottom: "0px",
    },
    detailRow: {
        backgroundColor: colors.opaqueBlue,
    },
    list: {
        padding: "unset",
        margin: "unset",
    },
});

function replaceLevelNumber(num: number): string {
    const LevelStrings: string[] = ["minimal", "low", "medium", "high", "significant"];
    return LevelStrings[num] || "minimal";
}

function formatValue(value: string): string {
    // Function to format values into a more human-readable form
    // Replace underscores with spaces
    return value.replace(/_/g, " ");
}

const transformToImpactDependencyString = (data) => {
    // Map rating abbreviations to full forms
    const ratingMap = {
        VH: "Very High",
        H: "High",
        M: "Medium",
        L: "Low",
    };

    const impacts = data?.impacts?.potentialImpacts?.restructuredItems;
    const dependencies = data?.dependencies?.potentialDependencies?.restructuredItems;
    const impactsArray = impacts.flatMap((impact) =>
        impact.values.map((value) => `${ratingMap[impact.rating]} impact ${formatValue(value)}`),
    );

    const dependenciesArray = dependencies.flatMap((dependency) =>
        dependency.values.map((value) => `${ratingMap[dependency.rating]} dependency ${formatValue(value)}`),
    );

    // Combine both impacts and dependencies into one string
    return [...impactsArray, ...dependenciesArray].join(", ");
};

function optionToStatus(event: string): boolean | null {
    // console.log("Option Selected : ", event)
    switch (event) {
        case "Planned":
            return null;
        case "Implemented":
            return true;
        case "N/A":
            return false;
        default:
            return null;
    }
}

function statusToOption(status: boolean | null): string {
    console.log("Option Selected : ", event);

    switch (status) {
        case null:
            return "Planned";
        case true:
            return "Implemented";
        case false:
            return "N/A";
        default:
            return "Planned";
    }
}
