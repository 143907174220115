import React from "react";

type CSSProperties = {
    [key: string]: React.CSSProperties;
};

export const colors = {
    brightBlue: "#2FF7FF",
    mediumBlue: "#3A4A67",
    darkBlue: "#20334D",
    opaqueBlueWithoutDependency: "#253752",
    opaqueBlue: "rgba(32, 51, 77, 0.8)",
    opaqueWhite: "rgba(255,255,255, 0.18)",
    opaqueBrightBlue: "rgba(47, 247, 255, 0.6)",
    red: "#E03131",
    white: "#FFFFFF",
    yellow: "#F5FF00",
    black: "#000000",
    purple: "#AC42FA",
    grey: "#9BA3AE",
    green: "#31E04C",
};

export class StyleSheet {
    static create<Styles extends CSSProperties>(styles: Styles): Styles {
        return styles;
    }
}

export const style = StyleSheet.create({
    inputField: {
        border: "1px solid #FFFFFF",
        borderRadius: 20,
        backgroundColor: "transparent",
        opacity: 1,
        padding: "5px 10px",
        color: colors.white,
        marginTop: 10,
        width: 250,
    },
    centerContainer: {
        height: 400,
        position: "relative",
    },
    leftContainer: {
        height: 400,
        width: "60%",
        position: "relative",
    },
    opacityContainer: {
        backgroundColor: colors.opaqueBlue,
        borderRadius: 10,
    },
    centerGlobe: {
        position: "absolute",
        right: "25%",
    },
    actionButton: {
        backgroundColor: colors.brightBlue,
        width: 140,
        borderRadius: 20,
        height: 40,
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
    },
    confirmButton: {
        backgroundColor: colors.white,
        width: 90,
        borderRadius: 20,
        height: 35,
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
    },
    cancelButton: {
        backgroundColor: colors.mediumBlue,
        width: 90,
        borderRadius: 20,
        height: 35,
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
        color: colors.white,
    },
    bubble: {
        borderRadius: "50%",
        height: 100,
        width: 100,
        position: "absolute",
    },
    upperCaseCenterText: {
        textAlign: "center",
        font: "normal normal bold 40px/45px Arial",
        color: colors.white,
        textTransform: "uppercase",
    },
    img: {
        minWidth: "100%",
        maxHeight: "100%",
    },
    center: {
        left: "50%",
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
    leftCenter: {
        top: "50%",
        left: 0,
        position: "absolute",
        transform: "translate(0, -50%)",
    },
    rightCenter: {
        right: 0,
        top: "50%",
        position: "relative",
        transform: "translate(0, -50%)",
    },
    topCenter: {
        left: "50%",
        top: 0,
        position: "absolute",
        transform: "translate(-50%, 0)",
    },
    bottomCenter: {
        left: "50%",
        bottom: 0,
        position: "absolute",
        transform: "translate(-50%, 0)",
    },
    centerFlex: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    flexColumn: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    flexRow: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "end",
    },

    centerRightPopup: {
        position: "fixed",
        bottom: "50vH",
        right: "30vW",
        transform: "translate(50%, 50%)",
        backgroundColor: "rgba(32, 51, 77, 0.95)",
        zIndex: 2,
        width: 400,
        height: 200,
        borderRadius: 10,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 20,
        justifyContent: "space-between",
        fontSize: 14,
    },
    closeIcon: {
        border: "none",
        width: 20,
        height: 20,
        borderRadius: 5,
        backgroundColor: "white",
        marginLeft: "auto",
    },
    sleapSectionRightButton: {
        position: "fixed",
        right: "25%",
        bottom: 100,
        textTransform: "uppercase",
    },
    totalSpan: {
        fontSize: 10,
        position: "absolute",
        bottom: 15,
    },
    sleapSection: {
        width: "100%",
        borderRadius: 10,
        backgroundColor: colors.opaqueBlue,
        boxShadow: "0px 30px 50px #00000029",
        // height: 50,
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        padding: "10px 15px",
        color: "white",
        textTransform: "uppercase",
        justifyContent: "space-between",
    },
    sleapBackDrop: {
        // minheight: 440,
        backgroundColor: colors.opaqueBlue,
        border: "1px solid #2FF7FF",
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
    },
    disabled: {
        opacity: 0.6,
        pointerEvents: "none",
        backgroundColor: colors.opaqueWhite,
        cursor: "not-allowed",
    },
});
