import en from "@/locales/translations_en.json";
import { getKeyPath } from "./object";

export function t(key: string, opts?: number | Record<string, number | string>, defaultValue: string = undefined): string {
    if (typeof opts === "number") {
        opts = { opts };
    }
    const hasNumericOpts = opts ? Object.values(opts).reduce((has, opt) => has || typeof opt === "number", false) : false;
    const max =
        opts && hasNumericOpts
            ? Object.values(opts).reduce((max, n) => (typeof n === "number" ? Math.max(max as number, n as unknown as number) : max), 0)
            : 1;
    const finalKey = max != 1 ? key + "_plural" : key;
    let text = getKeyPath(finalKey, en);

    if (typeof text !== "string") {
        return defaultValue || finalKey;
    }

    if (opts) {
        for (const [k, v] of Object.entries(opts)) {
            text = (text as string).replace("{" + k + "}", "" + v);
        }
    }

    return (text || key) as string;
}

export function capitalize(s: string): string {
    return s?.length > 0 ? s[0].toUpperCase() + s.substring(1) : s;
}

export function truncate(s: string, len: number): string {
    return s?.length > len ? s.substring(0, len - 2) + "…" : s;
}

export function nameOfSite(s: ISite): string {
    const fmt = (v: number) => Math.round(v * 1000) / 1000;
    const { street_address, city, country } = s.address;
    const named = [street_address, city, country].filter((n) => !!n).join(", ");

    return named || `${fmt(s.latlng.lat)} ${fmt(s.latlng.lng)}`;
}
