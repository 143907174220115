import * as React from "react";

interface IVerticalStackProps {
    alignItems?: "center" | "flex-end" | "flex-start" | "stretch";
    children: React.ReactNode;
    spacing?: "condensed" | "loose" | "none" | "regular" | "tight";
    style?: React.CSSProperties;
}

export default function VerticalStack(props: IVerticalStackProps): JSX.Element {
    const style = {
        ...props.style,
        alignItems: props.alignItems || "flex-start",
    };

    return (
        <div className={"s-vertical-stack s-vertical-stack-" + (props.spacing || "regular")} style={style}>
            {props.children}
        </div>
    );
}
