import * as React from "react";
import { mdiArrowLeft } from "@mdi/js";
import Icon from "./Icon";

interface IBackButtonProps {
    onClick: () => void;
    style?: React.CSSProperties;
}

export default function BackButton(props: IBackButtonProps): JSX.Element {
    return (
        <span className="s-back-button" onClick={props.onClick} style={props.style}>
            <Icon path={mdiArrowLeft} size={24} />
        </span>
    );
}
