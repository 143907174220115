import React, { useContext } from "react";
import {
    AppContext,
    ImpactsAndDependenciesChanges,
    SiteDeleted,
    SiteUpdated,
    ToastAdded,
    useProject,
    useTeam
} from "@/context";
import { apiDelete, apiPatch } from "@/utils/http";
import { fetchImpactsAndDependencies, url } from "@/rest/apiHelper";
import { colors, style, StyleSheet } from "@/utils/style";
import Icon from "../../../lib/Icon";
import { mdiPencilOutline, mdiTrashCanOutline } from "@mdi/js";
import LocationForm from "@/project/sleap/Locate/LocationForm";
import { DeleteLocationForm } from "@/project/sleap/Locate/DeleteLocationForm";
import { SleapContext } from "@/context/SleapContext";
import { INewDBSite, LocationTileProps } from "@/types/sleap";
import CryptoJS from "crypto-js";

export const LocationTile: React.FC<LocationTileProps> = ({ site, scopeOptions, openHandler, currentOpen }) => {
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const team = useTeam();
    const project = useProject();
    const report = project.reports[project.latest_report_id] as IAssessmentReport;
    const { sleapDispatch } = React.useContext(SleapContext);
    const { dispatch, state } = useContext(AppContext);
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();

    const isSuperUser = state.config.user.is_superuser;

    function saveLocation(s: INewDBSite): void {
        //#region Save updated site to the new kuyuaappdb
        let newDbLatLng = s.latlng ? `POINT(${s.latlng.lng} ${s.latlng.lat})` : "";
        // const oldDbSite = {
        //     latlng: newDbLatLng,
        //     data: {
        //         name: s.name,
        //         address: s.address,
        //         parameters: {}
        //     },
        //     report_id: report.id,
        //     is_deleted: false,
        //     site_id: s.site_id,
        //     site_type_id: s.site_type_id,
        //     priority_location: false,
        //     priority_score: 0,
        //     economic_activity_ids: s.economic_activity_ids.join(',')
        // }
        let newDbSite = {
            site_type_id: s.site_type_id,
            company_id: team.id,
            priority_score: -1,
            latlng: newDbLatLng,
            site_name: s.site_name,
            city: s.city != "N/A" ? s.city : "",
            // region: s.region != "N/A" ? s.region : "",
            country: s.country != "N/A" ? s.country : "",
            province: s.province ? s.province : "",
            zip_code: s.zip_code ? s.zip_code : "00000",
            country_code: s.country_code ? s.country_code : "",
            street_address: s.street_address != "N/A" ? s.street_address : "",
            site_key: s.site_key,
            priority_location: false,
            id_old: 0,
            economic_activity_ids: s.economic_activity_ids.join(',')
        };
        (async () => {
            console.log("Updated site to save into kuyuaappdb =====>", newDbSite);
            try {

                await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/site?site_key=${s.site_key}`, {
                    method: 'PUT', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${encryptedToken}`
                    },
                    body: JSON.stringify(newDbSite),
                }).then((data) => {
                    //dispatch({ type: SiteUpdated, site: data });
                    console.log('Site updated successfully.');
                    dispatch({ type: ToastAdded, toast: { kind: 'success', text: 'Site updated successfully.' } });
                    // fetchImpactsAndDependencies(team.slug, project.slug).then(reply => {
                    //     dispatch({ type: ImpactsAndDependenciesChanges, impacts: reply.impacts, dependencies: reply.dependencies });
                    // });
                    setOpenEdit(false);
                    openHandler(0);
                    sleapDispatch({ zoom: 2, selectedCenter: undefined });
                }).catch((error) => {
                    console.error('Error:', error);
                    dispatch({ type: ToastAdded, toast: { kind: 'error', text: `Failed to update site!` } });
                });

            } catch (error) {
                console.error('Error sending site into kuyuaappdb:', error);
                dispatch({ type: ToastAdded, toast: { kind: 'error', text: `Failed to update site!` } });
            }

        })();
        //#endregion
    }

    function deleteLocation(): void {
        //#region Soft Delete site from the new kuyuaappdb
        let deletedDbSite = {
            is_deleted: true
        };
        (async () => {
            try {
                await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/site?site_key=${site.site_key}`, {
                    method: 'PUT', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${encryptedToken}`
                    },
                    body: JSON.stringify(deletedDbSite),
                }).then(data => {
                    //dispatch({ type: SiteDeleted, siteId: site.id })
                    dispatch({ type: ToastAdded, toast: { kind: 'success', text: 'Site deleted successfully.' } });
                    setOpenDelete(false);
                    // fetchImpactsAndDependencies(team.slug, project.slug).then(reply => {
                    //     dispatch({ type: ImpactsAndDependenciesChanges, impacts: reply.impacts, dependencies: reply.dependencies });
                    // });
                }).catch((error) => {
                    console.log('Error:', error);
                    dispatch({ type: ToastAdded, toast: { kind: 'error', text: `Failed to delete site!` } });
                });
            } catch (error) {
                console.error('Error sending site into kuyuaappdb:', error);
                dispatch({ type: ToastAdded, toast: { kind: 'error', text: `Failed to delete site!` } });
            }

        })();
        //#endregion
    }

    React.useEffect(() => {

        if (openEdit) {
            openHandler(parseInt(site.site_id));
        }
    }, [openEdit]);

    React.useEffect(() => {

        setOpenEdit(parseInt(site.site_id) === currentOpen);
    }, [currentOpen]);

    const closeForm = () => {
        setOpenEdit(false);
        openHandler(0);
        sleapDispatch({ unsetZoom: true, selectedCenter: undefined, selectedSiteId: undefined });
    };

    const setTargetLocationSite = (): void => {

        sleapDispatch({ targetSiteId: site.site_id });
    };

    return (
        <div style={styles.locationTile} onClick={setTargetLocationSite}>
            <div style={{ flex: 1 }}>
                {site.site_name}
            </div>
            <div style={{ flex: 1, ...style.centerFlex, justifyContent: 'space-between' }}>
                {/* {site.site_id} */}
                <div style={{ ...style.centerFlex, marginLeft: 'auto' }}>
                    <Icon path={mdiPencilOutline} color={'white'} size={20} style={{ marginRight: 10 }} onClick={() => setOpenEdit(p => !p)} />
                    {isSuperUser && <Icon path={mdiTrashCanOutline} color={'white'} size={20} onClick={() => setOpenDelete(p => !p)} />}
                </div>
            </div>
            {openEdit && (
                <LocationForm
                    siteData={site}
                    saveHandler={saveLocation}
                    closeHandler={closeForm}
                    scopeOptions={scopeOptions}
                />
            )}
            {openDelete && (
                <DeleteLocationForm
                    siteName={site.site_name}
                    siteId={site.site_key}
                    deleteHandler={deleteLocation}
                    closeHandler={() => setOpenDelete(false)}
                />
            )}
        </div>
    );
};

const styles = StyleSheet.create({
    locationTile: {
        backgroundColor: colors.mediumBlue,
        height: 60,
        width: '100%',
        color: 'white',
        padding: '20px 15px',
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
    },
});
