import React, { useEffect, useState, useMemo } from "react";
import CryptoJS from "crypto-js";
import { colors, style, StyleSheet } from "@/utils/style";
import Icon from "../../../lib/Icon";
import { mdiClose } from "@mdi/js";
import { useActivities } from "@/utils/customHooks";
import { MapboxApiEndpoint } from "@/lib/map";
import { externalHttpGet } from "@/utils";
import { generateRandomId } from "@/utils/generateRandomId";
import { AppContext, useTeam } from "@/context";
import { SleapContext } from "@/context/SleapContext";
import { iActivities, iLocationForm, RadioOptionProps } from "@/types/sleap";
import { updateCountries } from "@/resources";
import Dropdown from "@/utils/Dropdown";
import { getAddressSearchString } from "@/project/sleap/Locate/Locate";
import { useMediaQuery } from "react-responsive";
import { uid } from "uid";

// Type for polygon data
interface DrawnPolygonData {
    latlng: {
        lat: number;
        lng: number;
    };
    area: number;
}

export default function LocationForm({
    siteData,
    saveHandler,
    closeHandler,
    scopeOptions,
    polygonHandler,
    isCreate,
    drawnPolygon,
}: iLocationForm & { isCreate: boolean; drawnPolygon?: DrawnPolygonData }): JSX.Element {
    const [site, setSite] = React.useState(siteData);
    const [scopeId, setScopeId] = React.useState<number | null>(siteData && siteData.site_type_id && parseInt(siteData.site_type_id));
    const [activities, handleSelect] = useActivities(null, site);
    const editForm = React.useRef(null);
    const team = useTeam();
    const { state, dispatch } = React.useContext(AppContext);
    const { sleapState, sleapDispatch } = React.useContext(SleapContext);
    const [countryOptions, setCountryOptions] = React.useState<{ id: number; label: string; code: string }[]>([]);
    const [selectedCountryIds, setSelectedCountryIds] = React.useState<number[]>([]);
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const handleChange = (id: number) => {
        setScopeId(id);
    };

    // Memoize polygon data to prevent unnecessary updates
    const memoizedPolygonData = useMemo(() => drawnPolygon, [drawnPolygon?.latlng.lat, drawnPolygon?.latlng.lng, drawnPolygon?.area]);

    // Optimized polygon update effect
    useEffect(() => {
        if (!memoizedPolygonData) return;

        setSite((prevSite) => {
            // Skip update if coordinates haven't changed
            if (prevSite?.latlng?.lat === memoizedPolygonData.latlng.lat && prevSite?.latlng?.lng === memoizedPolygonData.latlng.lng) {
                return prevSite;
            }

            return {
                ...prevSite,
                latlng: {
                    ...memoizedPolygonData.latlng,
                },
            };
        });
    }, [memoizedPolygonData]);

    //TODO: this is a function to find the match between the selected economic activities and the geo-api economic activities
    //we may need it in the future to get the updated ids from the geo server
    // function findMatchingEconomicActivities(economicActivityIds:any) {
    //     const selectedOptions = activities.classes.options.filter((option:any) =>
    //         economicActivityIds.includes(option.id)
    //     );
    //     console.log(selectedOptions);

    //     const matchingActivities = selectedOptions.flatMap((option:any) => {
    //         return economicActivitiesGeoServer.filter((activity:any) =>
    //         activity.economic_activity_name === option.description
    //         );
    //     });

    //     console.log('matchingActivities ==========>>>>>>>> ', matchingActivities);

    //     return matchingActivities;
    // }

    //TODO: we may need this to get the updated ids from the geo server
    // React.useEffect(() => {
    //     (async () => {

    //       const url = `${process.env.KUYUA_APP_BACKEND_URL}/economic-activities`
    //       console.log(url);
    //       try {
    //           const response = await fetch(url, header);
    //           const data = await response.json();
    //           console.log('geo-api economic-activities ===>>> ', data);
    //           if(data.economic_activities && data.economic_activities.length > 0) {
    //             setEconomicActivitiesGeoServer(data.economic_activities);

    //           }

    //       } catch (error) {
    //           console.error('Error fetching economic activities:', error);
    //       }

    //     })();

    //   }, []);

    useEffect(() => {
        editForm.current?.classList.add("fade-in");
        updateCountries(state, dispatch).then((countries) =>
            setCountryOptions(
                Object.values(countries)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((c, i) => ({ id: i, label: c.name, code: c.code })),
            ),
        );
    }, []);

    useEffect(() => {
        setSite((prev) => ({
            ...prev,
            ...sleapState?.selectedCenter?.latlng,
        }));
    }, [sleapState.selectedCenter]);

    function handleForm(value: object) {
        setSite((prev) => ({ ...prev, ...value }));
    }

    const setAddressField = (name: string, value: string) => {
        if (name === "lat" || name === "lng") {
            setSite((prev) => ({
                ...prev,
                latlng: {
                    ...prev?.latlng,
                    [name]: value,
                },
            }));
        } else {
            setSite((prev) => ({
                ...prev,
                // address: { ...prev.address, [name]: value },
                [name]: value,
            }));
        }
    };

    const onChooseCountry = (ids: number[]) => {
        if (!ids.length) {
            setSite((prev) => ({
                ...prev,
                // address: { ...prev.address, country_code: null, country: null },
                country_code: null,
                country: null,
            }));
        } else {
            const country = countryOptions[ids[0]];
            setSite((prev) => ({
                ...prev,
                // address: {
                //   ...prev.address,
                //   country_code: country.code,
                //   country: country.label,
                // },
                country_code: country.code,
                country: country.label,
            }));
            getAddress();
        }
    };

    // fetch address based on either latlng or text address
    const getAddress = () => {
        if (site?.latlng?.lat && site?.latlng?.lng) {
            console.log("latlang from get address", site.latlng.lat, site.latlng.lng);
            const center = {
                lat: Number(site?.latlng.lat),
                lng: Number(site?.latlng.lng),
            };
            sleapDispatch({
                bbox: undefined,
                mapCenter: center,
                pointOfInterest: center,
                zoom: 13,
            });
        } else if (site.street_address !== "" && site.street_address !== "N/A") {
            const text = getAddressSearchString(site);
            const url = `${MapboxApiEndpoint}/${encodeURIComponent(text)}.json`;
            const params = { access_token: state.config.mapbox_public_api_token };
            externalHttpGet<IMapboxQueryResult>(url, params).then((reply) => {
                processMapboxResponse(reply);
            });
        }
    };

    // we split this function to make the code more cleaner and readable
    const processMapboxResponse = (reply: IMapboxQueryResult) => {
        for (const feat of reply.features) {
            if (feat.bbox && feat.center) {
                const center = { lat: feat.center[1], lng: feat.center[0] };

                sleapDispatch({
                    bbox: feat.bbox,
                    mapCenter: center,
                    pointOfInterest: center,
                    zoom: 2,
                });

                return;
            } else if (feat.center) {
                const center = { lat: feat.center[1], lng: feat.center[0] };

                sleapDispatch({
                    bbox: undefined,
                    mapCenter: center,
                    pointOfInterest: center,
                    zoom: 13,
                });

                return;
            }
        }
    };

    useEffect(() => {
        if (site && countryOptions.length) {
            if (site.country_code) {
                setSelectedCountryIds([countryOptions.findIndex((c) => c.code === site.country_code)]);
            } else if (site.country) {
                const countryFound = countryOptions.find((country) => country.label === site.country);
                if (countryFound) {
                    setSelectedCountryIds([countryOptions.findIndex((c) => c.label === site.country)]);
                    !site.country_code &&
                        setSite((prev) => ({
                            ...prev,
                            // address: {
                            //   ...prev.address,
                            //   country_code: countryOptions.find(
                            //     (country) => country.label === site.country
                            //   )?.code,
                            // },
                            country_code: countryOptions.find((country) => country.label === site.country)?.code,
                        }));
                }
            }
        }
    }, [site?.country, countryOptions]);

    async function saveSite(): Promise<void> {
        if (!valid) return;
        //TODO: the old backend has different Ids for economic activities and sub-industries in some few case
        // so we may need to get the data from the geo server
        const siteKey = uid(32);
        //create fake address if lat and lng fields are used
        let initAddress = {
            city: site.city,
            country: site.country,
            country_code: site.country_code,
            street_address: site.street_address,
            zip_code: site.zip_code,
        };
        if (!site || site.city === "" || site.street_address === "" || site.country_code === null || site.country === null) {
            initAddress = {
                city: "N/A",
                country: "N/A",
                country_code: "un",
                street_address: "N/A",
                zip_code: "00000",
            };
        }

        var centerLatLng = null;
        if (site.latlng?.lat && site.latlng?.lng) {
            centerLatLng = {
                lat: Number(site?.latlng.lat),
                lng: Number(site?.latlng.lng),
            };
        }
        console.log(centerLatLng);
        const newSite = {
            economic_activity_ids: activities.classes.selected,
            site_type_id: scopeId,
            city: initAddress.city,
            country: initAddress.country,
            country_code: initAddress.country_code,
            street_address: initAddress.street_address,
            zip_code: initAddress.zip_code,
            site_name: site.site_name || "",
            site_key: site.site_key || siteKey,
            latlng: centerLatLng || sleapState.mapCenter,
        };
        saveHandler(newSite);
    }

    function isEmpty(str: string) {
        return !str || str.length === 0;
    }

    const siteName = (): string => {
        return site?.site_name;
    };

    const siteAddress = (): boolean => {
        return !(isEmpty(site?.street_address) || isEmpty(site?.city) || isEmpty(site?.zip_code) || isEmpty(site?.country_code));
    };

    const siteHasCenter = (): boolean => {
        return (
            site?.latlng?.lat !== undefined &&
            site?.latlng?.lng !== undefined &&
            String(site?.latlng?.lat) !== "" &&
            String(site?.latlng?.lng) !== ""
        );
    };

    const [valid, setValid] = useState(false);

    useEffect(() => {
        if (drawnPolygon && drawnPolygon.latlng) {
            setValid(siteName() && scopeId !== null && activities.classes.selected.length > 0);
        } else {
            setValid(siteName() && (siteHasCenter() || siteAddress()) && scopeId !== null && activities.classes.selected.length > 0);
        }
    }, [site, scopeId, activities.classes.selected, drawnPolygon]);

    return (
        <>
            <div
                ref={editForm}
                style={{
                    ...styles.locationForm,
                    backgroundColor: "rgba(32, 51, 77, 0.95)",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                }}
                // className={"sleapSectionRight"}
            >
                <div style={{ padding: 10 }}>
                    <div style={{ ...style.closeIcon, ...style.centerFlex }} onClick={() => closeHandler()}>
                        <Icon path={mdiClose} color={colors.darkBlue} size={17} />
                    </div>
                    <div style={{ padding: "0 20px" }}>
                        <div
                            style={{
                                ...style.flexRow,
                                width: "66%",
                                gap: 40,
                                justifyContent: "flex-start",
                            }}
                        >
                            <div>
                                <div>SITE NAME</div>
                                <input
                                    style={{ ...style.inputField, ...styles.inputField }}
                                    value={site?.site_name || ""}
                                    onChange={(e) => handleForm({ site_name: e.target.value })}
                                />
                            </div>
                            {/* <div>
                <div>SITE ID</div>
                <input
                  style={{ ...style.inputField, ...styles.inputField }}
                  value={site?.site_id || ""}
                  onChange={(e) => handleForm({ site_id: e.target.value })}
                />
              </div> */}
                            <div
                                style={{
                                    ...style.flexRow,
                                    gap: 30,
                                    justifyContent: "flex-start",
                                }}
                            >
                                {scopeOptions.map((option) => (
                                    <RadioOption scope={option} selected={scopeId} handleChange={handleChange} key={option.id} />
                                ))}
                            </div>
                        </div>

                        <div
                            style={{
                                ...style.flexRow,
                                marginTop: 20,
                                gap: 40,
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                            }}
                        >
                            <div>
                                <div style={{ textTransform: "uppercase" }}>street address</div>
                                <input
                                    style={{ ...style.inputField, ...styles.inputField }}
                                    value={site?.street_address || ""}
                                    onChange={(e) => setAddressField("street_address", e.target.value)}
                                    onBlur={() => getAddress()}
                                />
                            </div>
                            <div>
                                <div style={{ textTransform: "uppercase" }}>city</div>
                                <input
                                    style={{ ...style.inputField, ...styles.inputField }}
                                    value={site?.city || ""}
                                    onChange={(e) => setAddressField("city", e.target.value)}
                                    onBlur={() => getAddress()}
                                />
                            </div>
                            <div>
                                <div style={{ textTransform: "uppercase" }}>zip code</div>
                                <input
                                    style={{ ...style.inputField, ...styles.inputField }}
                                    value={site?.zip_code || ""}
                                    onChange={(e) => setAddressField("zip_code", e.target.value)}
                                    onBlur={() => getAddress()}
                                />
                            </div>
                            <div>
                                <Dropdown
                                    label={"country"}
                                    labelStyle={{ textTransform: "uppercase", fontSize: 16 }}
                                    dropdownStyle={{
                                        padding: "5px 10px",
                                        marginBottom: "unset",
                                        fontSize: 14,
                                        marginTop: 5,
                                        height: "unset",
                                        width: 175,
                                    }}
                                    handler={onChooseCountry}
                                    options={countryOptions}
                                    selected={selectedCountryIds}
                                />
                            </div>
                        </div>

                        <div
                            style={{
                                ...style.flexRow,
                                marginTop: 20,
                                gap: 40,
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                flexWrap: "wrap",
                            }}
                        >
                            <div>
                                <div style={{ textTransform: "uppercase" }}>latitude</div>
                                <input
                                    style={{ ...style.inputField, ...styles.inputField }}
                                    value={site?.latlng?.lat || siteData?.latlng?.lat || ""}
                                    onChange={(e) => setAddressField("lat", e.target.value)}
                                    onBlur={() => getAddress()}
                                />
                            </div>

                            <div>
                                <div style={{ textTransform: "uppercase" }}>longitude</div>
                                <input
                                    style={{ ...style.inputField, ...styles.inputField }}
                                    value={site?.latlng?.lng || siteData?.latlng?.lng || ""}
                                    onChange={(e) => setAddressField("lng", e.target.value)}
                                    onBlur={() => getAddress()}
                                />
                            </div>

                            {isCreate && (
                                <div>
                                    <div>
                                        <div style={{ textTransform: "uppercase" }}>polygon</div>
                                        <button style={{ ...style.inputField, ...styles.inputField }} onClick={() => polygonHandler(true)}>
                                            DRAW
                                        </button>
                                    </div>
                                </div>
                            )}

                            {isCreate &&
                                (drawnPolygon?.area ? (
                                    <div>
                                        <div>
                                            <div style={{ textTransform: "uppercase" }}>Area (m²)</div>
                                            <div style={{ marginTop: 13 }}>
                                                {drawnPolygon?.area && Number(drawnPolygon.area).toLocaleString()} m²
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div style={{ textTransform: "uppercase" }}>Area (m²)</div>
                                        <input
                                            style={{ ...style.inputField, ...styles.inputField }}
                                            onChange={(e) => setAddressField("area", e.target.value)}
                                        />
                                    </div>
                                ))}
                        </div>

                        <div
                            style={{
                                ...style.flexRow,
                                marginTop: 20,
                                gap: 40,
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                maxHeight: "150px", // Set a maximum height
                                overflowY: "auto",
                            }}
                        >
                            {Object.keys(activities).map(
                                (activity: keyof iActivities, i) =>
                                    activities[activity].show && (
                                        <Dropdown
                                            {...activities[activity]}
                                            handler={handleSelect}
                                            key={i}
                                            allSelectable={activities[activity].value === "classes"}
                                        />
                                    ),
                            )}
                        </div>
                        <div
                            style={{
                                ...style.flexRow,
                                marginTop: 10,
                                justifyContent: "flex-end",
                                opacity: !valid ? 0.5 : 1,
                            }}
                        >
                            <button style={style.confirmButton} onClick={() => saveSite()} disabled={!valid}>
                                SAVE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const RadioOption: React.FC<RadioOptionProps> = ({ scope, selected, handleChange }) => {
    const checked = {
        transform: selected === scope.id ? "translate(-50%, -50%) scale(0.65)" : "translate(-50%, -50%) scale(0)",
        backgroundColor: selected === scope.id ? "#2FF7FF" : "transparent",
    };

    return (
        <label className="radio-label" style={styles.containerStyle} onClick={() => handleChange(scope.id)}>
            <div style={styles.radioStyle}>
                <div style={{ ...styles.checkMarkStyle, ...checked }} />
            </div>
            {scope.name}
        </label>
    );
};

const styles = StyleSheet.create({
    locationForm: {
        minHeight: 350,
        fontSize: 16,
        width: "unset",
    },
    searchField: {
        border: "1px solid #FFFFFF",
        borderRadius: 20,
        backgroundColor: "transparent",
        opacity: 1,
        padding: "5px 10px",
        color: colors.white,
        marginTop: 10,
        width: "100%",
    },
    inputField: {
        width: 175,
        marginTop: 5,
    },
    containerStyle: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
    radioStyle: {
        backgroundColor: "transparent",
        margin: 0,
        width: 20,
        height: 20,
        border: "0.15em solid #2FF7FF",
        borderRadius: 50,
        position: "relative",
    },
    checkMarkStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transition: "120ms transform ease-in-out",
        width: 15,
        height: 15,
        borderRadius: 50,
    },
});
