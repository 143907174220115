import React from "react";
import { style } from "@/utils/style";
import { DeleteLocationProps } from "@/types/sleap";

export const DeleteLocationForm: React.FC<DeleteLocationProps> = ({ siteName, siteId, deleteHandler, closeHandler }) => {
    return (
        <div style={style.centerRightPopup}>
            <p>Are you sure you want to delete this site?</p>
            <p>{siteName || siteId}</p>
            <div style={{ ...style.flexRow, marginTop: 10 }}>
                <button style={style.cancelButton} onClick={() => closeHandler()}>
                    cancel
                </button>
                <button style={style.confirmButton} onClick={() => deleteHandler()}>
                    delete
                </button>
            </div>
        </div>
    );
};
