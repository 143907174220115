import { AnySourceData } from "mapbox-gl";

export function expandBBox(bbox: number[], by = 2): number[] {
    if (bbox?.length !== 4) {
        return bbox;
    }

    bbox[0] = Math.max(bbox[0] - by * 2, -180);
    bbox[1] = Math.max(bbox[1] - by, -90);
    bbox[2] = Math.min(bbox[2] + by * 2, 180);
    bbox[3] = Math.min(bbox[3] + by, 90);

    return bbox;
}

interface IMapLegendEntry {
    high: number;
    low: number;
}

export function makeMapLegendRange(range: number[]): IMapLegendEntry[] {
    const entries: IMapLegendEntry[] = [];
    let [min, max] = range;
    const width = max - min;
    let step = 0.0001;

    while (width / step > 10) {
        step *= 10;
    }

    min = Math.floor(min / step) * step;
    max = Math.ceil(max / step) * step;

    const stepWidth = (max - min) / 10;

    for (let i = 0; i < 10; i += 1) {
        entries.push({ high: min + (i + 1) * stepWidth, low: min + i * stepWidth });
    }

    return entries;
}

export function getBBoxFromSourceData(source: AnySourceData, expandBy = 0): number[] | undefined {
    const bbox = [180, 90, -180, -90];
    const geom = (
        source as unknown as {
            data: { geometry: { coordinates: number[][]; type: string } };
        }
    ).data?.geometry;
    const walk = (item: number[]) => {
        if (item.length === 2 && typeof item[0] === "number") {
            bbox[0] = Math.min(bbox[0], item[0]);
            bbox[1] = Math.min(bbox[1], item[1]);
            bbox[2] = Math.max(bbox[2], item[0]);
            bbox[3] = Math.max(bbox[3], item[1]);
        } else {
            for (const subitem of item) {
                walk(subitem as unknown as number[]);
            }
        }
    };

    walk(geom?.coordinates as unknown as number[]);

    if (expandBy !== 0) {
        return expandBBox(bbox, expandBy);
    }

    return bbox;
}

export function toDMS(d: number, isLongitude: boolean) {
    if (d === undefined || d === null) {
        return undefined;
    }

    return {
        dir: d < 0 ? (isLongitude ? "W" : "S") : isLongitude ? "E" : "N",
        deg: 0 | (d < 0 ? (d = -d) : d),
        min: 0 | (((d += 1e-9) % 1) * 60),
        sec: (0 | (((d * 60) % 1) * 6000)) / 100,
    };
}

export function toDMSString(d: number, isLongitude: boolean) {
    const { dir, deg, min, sec } = toDMS(d, isLongitude) || {};

    return dir ? `${deg}°${min}'${sec}"\u00A0${dir}` : undefined;
}
