import { httpGet } from "@/utils";
import { IndustriesChanged } from "@/context";

type IGenericDispatch = (action: IGenericAction) => void;

export async function updateIndustries(state: IAppState, dispatch: IGenericDispatch): Promise<Record<string, IIndustry>> {
    let industries = state.industries;

    if (industries !== undefined) {
        return industries;
    }

    const reply = await httpGet<{ industries: IIndustry[] }>("/api/v1/industries");

    if (!reply.ok) {
        return null;
    }

    industries = reply.data.industries.reduce(
        (agg, ind) => {
            agg[ind.name] = ind;
            return agg;
        },
        {} as Record<string, IIndustry>,
    );

    dispatch({ type: IndustriesChanged, industries });

    return industries;
}
