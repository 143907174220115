import { apiGet } from "@/utils";
import { ProjectChanged, ProjectRemoved, ProjectReportChanged } from "@/context";

type IGenericDispatch = (action: IGenericAction) => void;

export async function updateProject(state: IAppState, dispatch: IGenericDispatch, slug: string): Promise<IProject | null> {
    const projects = state.projects.projects;
    const project = projects[slug] as IProjectLoader;

    if (project && !project.loading) {
        return project as unknown as IProject;
    }

    dispatch({ type: ProjectChanged, project: { slug, loading: true } });

    const reply = await apiGet<IProject>(state.config.team.slug, `projects/${slug}`);

    if (!reply.ok) {
        dispatch({ type: ProjectRemoved, slug: slug });
        return null;
    }

    reply.data.reports = {};

    dispatch({ type: ProjectChanged, project: reply.data });

    return reply.data;
}

export async function updateReport(
    state: IAppState,
    dispatch: IGenericDispatch,
    project: IProject,
    reportId: number,
    forceReload?: boolean,
): Promise<IAssessmentReport | null> {
    const report = project.reports[reportId] as IAssessmentReportLoader;

    if (report && !forceReload) {
        if (report.loading) {
            return null;
        }

        return report as unknown as IAssessmentReport;
    }

    project.reports = {
        ...project.reports,
        [reportId]: { id: reportId, loading: true },
    };

    const path = `projects/${project.slug}/reports/${reportId}`;
    const reply = await apiGet<IAssessmentReport>(state.config.team.slug, path);

    if (!reply.ok) {
        return null;
    }

    dispatch({ type: ProjectReportChanged, project, report: reply.data });

    return reply.data;
}

export async function getSiteSpecies(
    state: IAppState,
    project: IProject,
    report: IAssessmentReport,
    site: ISite,
): Promise<ISiteSpecies | null> {
    if (site.species?.loading) {
        return null;
    }

    const path = `projects/${project.slug}/reports/${report.id}/sites/${site.id}/species`;
    const reply = await apiGet<IAssessedSpecies[]>(state.config.team.slug, path);

    if (!reply.ok) {
        return null;
    }

    return { loading: false, items: reply.data };
}

export async function getSiteKeyAreas(
    state: IAppState,
    project: IProject,
    report: IAssessmentReport,
    site: ISite,
): Promise<ISiteKeyAreas | null> {
    if (site.key_areas?.loading) {
        return null;
    }

    const path = `projects/${project.slug}/reports/${report.id}/sites/${site.id}/key-areas`;
    const reply = await apiGet<IKeyArea[]>(state.config.team.slug, path);

    if (!reply.ok) {
        return null;
    }

    return { loading: false, items: reply.data };
}
