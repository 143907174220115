import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext, useProject, useTeam } from "@/context";
import { apiGet, getHomeUrl, t } from "@/utils";
import { BreadcrumbPath, Button, CopyrightNotice, HorizontalStack, MasterHeader } from "@/lib";
import ReportTable from "./ReportTable";
import "./index.scss";

export default function ProjectReportsPage(): JSX.Element {
    const { state } = React.useContext(AppContext);
    const [reports, setReports] = React.useState<ISFDRReport[]>(undefined);
    const reportRefreshIntervalId = React.useRef<number>(undefined);
    const navigate = useNavigate();
    const params = useParams();
    const team = useTeam();
    const project = useProject();
    const canCreateReport = ["admin", "manager"].includes(state.config.role.slug);
    const isSmallLayout = ["xs", "sm", "md"].includes(state.layoutSize);
    const breadcrumb = [
        { label: t("ui.home"), route: getHomeUrl(team) },
        { label: t("ui.report_plural"), route: getHomeUrl(team) + "/reports" },
    ];
    const hasPendingReports = (reports: ISFDRReport[]) =>
        reports.reduce((pending, r) => pending || r.newest_version.status !== "complete", false);

    React.useEffect(() => {
        document.title = `${t("pages.reports")} — ${project.name} — kuyua`;
    }, []);

    React.useEffect(() => {
        apiGet<{ reports: ISFDRReport[] }>(state.config.team.slug, `projects/${params.project}/sfdr`).then((reply) => {
            if (reply?.ok) {
                setReports(reply.data.reports);

                if (hasPendingReports(reply.data.reports)) {
                    reportRefreshIntervalId.current = window.setInterval(() => {
                        apiGet<{ reports: ISFDRReport[] }>(state.config.team.slug, `projects/${params.project}/sfdr`).then((reply) => {
                            if (reply?.ok) {
                                setReports(reply.data.reports);

                                if (!hasPendingReports(reply.data.reports)) {
                                    window.clearInterval(reportRefreshIntervalId.current);
                                }
                            }
                        });
                    }, 7500);
                }
            }
        });

        return () => window.clearInterval(reportRefreshIntervalId.current);
    }, []);

    return (
        <div style={{ padding: "0 2rem 2rem 2rem" }}>
            <BreadcrumbPath items={breadcrumb} style={{ marginTop: "1rem" }} onItemClick={(item) => navigate(item.route)} />
            <MasterHeader
                style={{
                    marginTop: isSmallLayout ? "60px" : "40px",
                    marginBottom: ".5rem",
                }}
                text={t("ui.report_plural")}
            />

            {canCreateReport && (
                <HorizontalStack horizontalAlign="flex-end">
                    <Button onClick={() => navigate("sfdr/create")} variant="primary">
                        {t("report.sfdr.create_report")}
                    </Button>
                </HorizontalStack>
            )}

            <ReportTable reports={reports} onDeleteReport={(report) => setReports(reports.filter((r) => r.id !== report.id))} />
            <CopyrightNotice />
        </div>
    );
}
