import * as React from "react";
import classnames from "classnames";

interface ISteppedTrackProps {
    steps: {
        active: boolean;
        label: string;
    }[];
    style?: React.CSSProperties;
}

export default function SteppedTrack(props: ISteppedTrackProps): JSX.Element {
    return (
        <div className="s-stepped-track" style={props.style}>
            {props.steps.map((step, ix) => (
                <React.Fragment key={`${ix} ${step.label}`}>
                    <span className="s-stepped-track-spacer" />
                    <div
                        className={classnames("s-stepped-track-step", {
                            "s-stepped-track-step-active": step.active,
                        })}
                    >
                        <span>{ix + 1}</span>
                        <span>{step.label}</span>
                    </div>
                </React.Fragment>
            ))}
            <span className="s-stepped-track-spacer" />
        </div>
    );
}
