import React from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import Checkbox from "@/utils/Checkbox";
import Dropdown from "@/utils/Dropdown";
import { fetchImpactsAndDependencies, fetchScopes } from "@/rest/apiHelper";
import { apiPatch, getHomeUrl } from "@/utils/http";
import { AppContext, ImpactsAndDependenciesChanges, ProjectChanged, ToastAdded, useProject, useTeam } from "@/context";
import { useActivities, useSleapStep } from "@/utils/customHooks";
import { CheckHandler, iActivities, iScopeChecked } from "@/types/sleap";
import { useNavigate } from "react-router-dom";

export default function Scope({ checkHandler }: CheckHandler): JSX.Element {
    const navigate = useNavigate();
    const [scopeOptions, setScopeOptions] = React.useState<iScopeChecked[]>([]);
    const team = useTeam();
    const project = useProject();
    const { dispatch } = React.useContext(AppContext);
    const [, setSleapStep] = useSleapStep();
    const [activities, handleSelect] = useActivities(project);

    const loadScopes = React.useCallback(() => {
        fetchScopes()
            .then((scopesResponse) => {
                const scopes = scopesResponse.map((scope) => ({
                    ...scope,
                    checked: scope.id === 1,
                }));

                const projectScopeIds = project.scopes.map((s) => s.id);
                if (projectScopeIds.length > 0) {
                    scopes.map((s) => (s.checked = projectScopeIds.includes(s.id)));
                }
                setScopeOptions(scopes);
            })
            .catch((e) => {
                console.error("Error fetching scopes:", e);
            });
    }, []);

    React.useEffect(() => {
        loadScopes();
    }, []);

    const handleCheckChange = React.useCallback(
        (index: number) => {
            const updatedOptions = [...scopeOptions];
            updatedOptions[index].checked = !updatedOptions[index].checked;
            setScopeOptions(updatedOptions);
        },
        [scopeOptions],
    );

    React.useEffect(() => {
        setScopeOptions((prev) =>
            prev.map((scope) => ({
                ...scope,
                checked: project.scopes.find((s) => s.id === scope.id) !== undefined,
            })),
        );
    }, [project.scopes]);

    const isComplete = React.useMemo(
        () => activities.section.selected.length && activities.division.selected.length && activities.classes.selected.length,
        [activities],
    );

    React.useEffect(() => {
        !project.is_complete && checkHandler(isComplete > 0);
    }, [isComplete]);

    function saveScope() {
        if (!isComplete) {
            return;
        }
        const payload = {
            scope_ids: scopeOptions.filter((scope) => scope.checked).map((scope) => scope.id),
            economic_activity_ids: activities.classes.selected,
        };
        apiPatch<IProject>(team.slug, `projects/${project.slug}`, payload).then((reply) => {
            if (reply.ok) {
                fetchImpactsAndDependencies(team.slug, project.slug).then((reply) => {
                    dispatch({
                        type: ImpactsAndDependenciesChanges,
                        impacts: reply.impacts,
                        dependencies: reply.dependencies,
                    });
                });
                dispatch({
                    type: ToastAdded,
                    toast: { kind: "success", text: "Scope updated successful." },
                });
                dispatch({
                    type: ProjectChanged,
                    project: { ...project, ...reply.data },
                });
                setSleapStep("");
                navigate(getHomeUrl(team) + "/understand");
            } else {
                dispatch({
                    type: ToastAdded,
                    toast: { kind: "error", text: `Failed to update scope!` },
                });
            }
        });
    }

    return (
        <div style={{ ...style.flexColumn, padding: 30 }}>
            <div>
                <p style={{ fontSize: 14 }}>The analysis should be based on</p>
                <div
                    style={{
                        ...style.centerFlex,
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: 10,
                    }}
                >
                    {scopeOptions.map((scope, i) => (
                        <Checkbox
                            checked={scope.checked}
                            label={scope.name}
                            key={i}
                            checkHandler={() => handleCheckChange(i)}
                            color={colors.brightBlue}
                        />
                    ))}
                </div>
            </div>
            <div>
                <p style={{ fontSize: 14 }}>Choose your main economic activities</p>
                <div
                    style={{
                        ...style.centerFlex,
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: 10,
                    }}
                ></div>
                {Object.keys(activities).map(
                    (activity: keyof iActivities, i) =>
                        activities[activity].show && <Dropdown {...activities[activity]} handler={handleSelect} key={i} />,
                )}
            </div>
            <button
                style={{
                    ...style.actionButton,
                    marginTop: 10,
                    width: 150,
                    ...(!isComplete && styles.disabledButton),
                }}
                onClick={() => saveScope()}
            >
                Apply
            </button>
        </div>
    );
}

const styles = StyleSheet.create({
    disabledButton: {
        backgroundColor: colors.opaqueWhite,
        color: "white",
    },
});
