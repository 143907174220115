import * as React from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import { BaseModalDialog } from "@/lib/modals";
import { SubscriptionTooltip } from "./SubscriptionTooltip";

interface DownloadButtonProps {
    children?: any;
    onClick?: any;
    disabled?: boolean;
    altStyle?: boolean;
    subscriptionDisabled?: boolean;
    feature?: string;
}

export const DownloadButtonDefault: React.FC<DownloadButtonProps> = ({
    children = null,
    onClick = null,
    disabled = false,
    altStyle = false,
    subscriptionDisabled = false,
    feature = "",
    ...props
}) => {
    const [showSubscribeDialog, setShowSubscribeDialog] = React.useState(false);

    return (
        <>
            <SubscriptionTooltip feature={feature} show={showSubscribeDialog} alwaysShow={false} backgroundColor={colors.darkBlue}>
                <button
                    {...props}
                    onClick={
                        !disabled
                            ? onClick
                            : () => {
                                  setShowSubscribeDialog(true);
                              }
                    }
                    style={{
                        ...style.actionButton,
                        ...(altStyle ? styles.export : styles.detailButton),
                        ...styles.updateButton,
                        justifyContent: "center",
                        alignItems: "center",
                        width: "150px",
                        height: "30px",
                        fontSize: "12px",
                        opacity: disabled ? "0.5" : "1",
                        cursor: disabled ? " not-allowed" : "pointer",
                    }}
                >
                    {children}
                </button>
            </SubscriptionTooltip>
        </>
    );
};

const styles = StyleSheet.create({
    export: {
        borderRadius: 20,
        backgroundColor: "transparent",
        // opacity: 1,
        padding: "5px 10px",
        color: colors.white,
        gap: 5,
        border: `1px solid ${colors.white}`,
        fontSize: 13,
        textAlign: "center",
    },
    detailButton: {
        width: 50,
        fontSize: 10,
        height: 20,
        borderRadius: 20,
        color: colors.darkBlue,
        lineHeight: 1,
    },
    updateButton: {
        justifyContent: "flex-end",
        gap: 10,
        height: 30,
        fontSize: 11,
        width: 160,
        position: "left",
        zIndex: 2,
        padding: "5px 10px",
    },
});
