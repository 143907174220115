import { t } from "./i18n";

export const requiredErrorText: string = t("error.required");

export const isEmptyString = (value: string) => {
    return !value || value.trim().length === 0;
};

export const mandatoryStringError = (value: string): string => {
    return isEmptyString(value) ? requiredErrorText : null;
};

export const emailError = (value: string): string => {
    return !/^\S+@([a-z0-9-]+\.)[a-z0-9]{2,}$/.test(value) ? t("error.email") : null;
};
