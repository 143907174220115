import { ActionLink, HorizontalStack, SectionHeader } from "@/lib";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { colors, style, StyleSheet } from "@/utils/style";
import { Icon } from "@/lib";
import * as React from "react";
import { TableHeaderCell } from "@/lib";
import { mdiArrowLeft } from "@mdi/js";
import { t } from "@/utils";
import "../species/index.scss";
import CryptoJS from "crypto-js";

export default function Targets({ site, impacts, dependencies }): JSX.Element {
    const [targets, setTargets] = React.useState([]);
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            Authorization: `Bearer ${encryptedToken}`,
        },
    };
    const [expandedRows, setExpandedRows] = React.useState({});

    const toggleRow = (id) => {
        setExpandedRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    async function getTargetsPlan() {
        const url = `${process.env.KUYUA_APP_BACKEND_URL}/target-plan?site_id=${site.site_id}`;
        try {
            const response = await fetch(url, header);
            const data = await response.json();
            if (data) {
                console.log("Targets Plan: ", data);
                setTargets(data);
            }
        } catch (error) {
            console.error("Error fetching protected areas:", error);
        }
    }
    function formatString(str) {
        const words = str.split("_");
        const formattedWords = [words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase(), ...words.slice(1)];
        return formattedWords.join(" ");
    }

    React.useEffect(() => {
        if (site) {
            getTargetsPlan();
        }
    }, [site]);

    return (
        <div>
            <div style={{ width: "100%", display: "flex", marginBottom: "1rem", justifyContent: "flex-end" }}>
                <button
                    disabled={true}
                    style={{
                        ...style.actionButton,
                        ...styles.detailButton,
                        ...styles.updateButton,
                        justifyContent: "center",
                        alignItems: "center",
                        width: "150px",
                        height: "30px",
                        fontSize: "12px",
                        opacity: "0.5",
                    }}
                >
                    Export Data
                </button>
            </div>
            <HorizontalStack style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                <SectionHeader level={5} text={"Target plan"} bordered={false} />
            </HorizontalStack>
            <div style={styles.wrapper}>
                <div className={"table-container"}>
                    <table className="s-table s-table-fixed species-table">
                        <thead>
                            <tr>
                                <TableHeaderCell label="Target" sortable={false} style={{ ...styles.sTableHeading }} width="70%" />
                                <TableHeaderCell
                                    label="Impacts Addressed"
                                    sortable={false}
                                    style={{ ...styles.sTableHeading }}
                                    width="30%"
                                />
                                <TableHeaderCell
                                    label="Dependencies Addressed"
                                    sortable={false}
                                    style={{ ...styles.sTableHeading }}
                                    width="30%"
                                />
                                <TableHeaderCell label="" sortable={false} style={{ ...styles.sTableHeading }} width="10%" />
                            </tr>
                        </thead>
                        <tbody>
                            {targets.map((row) => (
                                <React.Fragment key={row.target_id}>
                                    {/* Main Row */}
                                    <tr>
                                        <td>{row.target_short_title}</td>
                                        <td style={{ textAlign: "left" }}>
                                            <div style={{ fontSize: "1.3vw", fontWeight: "bold" }}>
                                                {row.impact_names?.length ? row.impact_names?.length : 0}
                                            </div>
                                            <div style={{ fontSize: ".7vw", fontWeight: "bold" }}>out of {impacts}</div>
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            <div style={{ fontSize: "1.3vw", fontWeight: "bold" }}>
                                                {row.dependency_names?.length ? row.dependency_names?.length : 0}
                                            </div>
                                            <div style={{ fontSize: ".7vw", fontWeight: "bold" }}>out of {dependencies}</div>
                                        </td>
                                        <td style={{ padding: "12px", textAlign: "center" }}>
                                            <Icon
                                                style={{ ...styles.chevron }}
                                                color="white"
                                                path={expandedRows[row.target_id] ? mdiChevronUp : mdiChevronDown}
                                                size={32}
                                                onClick={() => toggleRow(row.target_id)}
                                            />
                                        </td>
                                    </tr>
                                    {expandedRows[row.target_id] && (
                                        <tr style={{ backgroundColor: colors.opaqueBlue }}>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    padding: "12px",
                                                    display: "flex",
                                                    width: "202%",
                                                    backgroundColor: colors.opaqueBlue,
                                                }}
                                            >
                                                {/* <div style={{ display: "flex", width: "100%" }}> */}
                                                <div style={{ width: "70%", paddingRight: "16px" }}>
                                                    <strong>Description:</strong>
                                                    <p>{row.target_description}</p>
                                                </div>
                                                <div style={{ width: "30%", paddingRight: "16px", textAlign: "left" }}>
                                                    <strong style={{ display: "block", marginBottom: "16px" }}>Impacts Addressed:</strong>
                                                    <ul style={{ paddingLeft: "1.2em", margin: 0 }}>
                                                        {row.impact_names?.map((impact, idx) => <li key={idx}>{formatString(impact)}</li>)}
                                                    </ul>
                                                </div>
                                                <div style={{ width: "30%", textAlign: "left" }}>
                                                    <strong style={{ display: "block", marginBottom: "16px" }}>
                                                        Dependencies Addressed:
                                                    </strong>
                                                    <ul style={{ paddingLeft: "1.2em", margin: 0 }}>
                                                        {row.dependency_names?.map((dependency, idx) => (
                                                            <li key={idx}>{formatString(dependency)}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div style={{ width: "10%" }}>{/* Empty space to fill the 4th column */}</div>
                                                {/* </div> */}
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

const styles = StyleSheet.create({
    chevron: {
        cursor: "pointer",
    },
    rowIsClosed: {
        backgroundColor: colors.mediumBlue,
    },
    rowIsOpen: {
        backgroundColor: colors.opaqueBlue,
        borderBottom: "0px",
    },
    detailRow: {
        backgroundColor: colors.opaqueBlue,
    },
    list: {
        padding: "unset",
        margin: "unset",
    },
    wrapper: {
        width: "100%",
        borderRadius: 10,
        border: `1px solid ${colors.brightBlue}`,
        padding: 10,
        backgroundColor: colors.darkBlue,
    },
    summaryHeader: {
        height: 90,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: colors.darkBlue,
        borderRadius: 10,
        padding: "5px 20px",
    },
    filterBar: {
        borderRadius: 10,
        backgroundColor: colors.opaqueWhite,
        padding: 10,
        alignItems: "center",
        gap: 10,
    },
    sTableHeading: {
        padding: "10px 5px 10px",
    },
    export: {
        borderRadius: 20,
        backgroundColor: "transparent",
        // opacity: 1,
        padding: "5px 10px",
        color: colors.white,
        gap: 5,
        border: `1px solid ${colors.white}`,
        fontSize: 13,
        textAlign: "center",
    },
    detailButton: {
        width: 50,
        fontSize: 10,
        height: 20,
        borderRadius: 20,
        color: colors.darkBlue,
        lineHeight: 1,
    },
    updateButton: {
        justifyContent: "flex-end",
        gap: 10,
        height: 30,
        fontSize: 11,
        width: 160,
        position: "left",
        zIndex: 2,
        padding: "5px 10px",
    },
});
