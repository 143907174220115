import React, { useCallback, useContext, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { AppContext, ToastAdded, useTeam } from "@/context";
import { colors, style, StyleSheet } from "@/utils/style";
import useFetchImpacts from "@/utils/useFetchImpacts";
import useFetchDependencies from "@/utils/useFetchDependencies";

import { CSVLink } from "react-csv";

import { downloadSite } from "@/utils/downloads/downloadSite";
import { sbm3 } from "@/utils/downloads/sbm3";
import { sbm3Table, sbm3TableForTeam } from "@/utils/downloads/sbm3Table";
import { E45 } from "@/utils/downloads/e45";
import { IRO1 } from "@/utils/downloads/iro1";
import { ESRSE43 } from "@/utils/downloads/esrsE43";
import { ESRSE44 } from "@/utils/downloads/esrsE44";
import { Icon, TableHeaderCell } from "@/lib";
import { mdiChevronDown } from "@mdi/js";
import { DownloadButtonDefault } from "@/components/DownloadButton";
import { t } from "@/utils";

// YN Styles
const containerStyle = {
    height: "100%",
    color: "white",
    fontSize: "12px", // Updated font size
    borderRadius: 10,
    border: `1px solid ${colors.brightBlue}`,
    padding: "10px",
    backgroundColor: colors.darkBlue,
};

const sectionStyle = {
    fontSize: "22px", // Updated font size
    fontWeight: "bold",
};

const flexRowStyle = {
    width: "100%",
    alignItems: "center",
    fontSize: "12px", // Updated font size
};

const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();

const rating = {
    VH: "Very High",
    H: "High",
    M: "Medium",
    L: "Low",
} as const;

interface Answer {
    value: string;
    comment: string;
    questionLabel: string;
}

interface Answers {
    dependencies: { [key: string]: Answer };
    impacts: { [key: string]: Answer };
    yes_no: { [key: string]: Answer };
    comparing: {
        [key: string]: { [year: string]: Answer } & { questionLabel: string };
    };
}

const buttonStyle = {
    cursor: "pointer",
};

const SiteInputTab: React.FC<{ site: INewSiteDetailed; refreshSiteData }> = React.memo(({ site, refreshSiteData }) => {
    const team = useTeam();
    const [answers, setAnswers] = useState<Answers>({
        dependencies: {},
        impacts: {},
        yes_no: {},
        comparing: {},
    });

    const [newQuestionToAdd, setNewQuestionToAdd] = useState<string>("");
    const [newYNQuestionToAdd, setNewYNQuestionToAdd] = useState<string>("");
    const [YNQuestions, setYNQuestions] = useState<Array<{ label: string; id: string }>>([]);
    const [comparingQuestions, setComparingQuestions] = useState<Array<{ id: string; 2023: string; 2024: string; label: string }>>([]);

    const { dispatch, state } = useContext(AppContext);
    const superUser = state.config.user.is_superuser;

    const [editingQuestionId, setEditingQuestionId] = useState<string | null>(null);
    const [editedQuestions, setEditedQuestions] = useState<{ [key: string]: string }>({});

    const [impacts, setImpacts] = useState<any[]>([]);
    const [dependencies, setDependencies] = useState<any[]>([]);

    const economic_activities = site?.economic_activities || [];
    const ecoIds = economic_activities
        .map((eca) => eca.economic_activity_id)
        .filter(Boolean)
        .join(",");
    const { restructuredFallbackImpactsGeoServer } = useFetchImpacts(ecoIds, site.site_id, true);
    console.log("restructuredFallbackImpactsGeoServer", restructuredFallbackImpactsGeoServer);
    const { restructuredFallbackDependenciesGeoServer } = useFetchDependencies(ecoIds, site.site_id, true);
    const getImpactsAndDependencies = useCallback(
        async (impactsAndDependenciesNames) => {
            if (!impactsAndDependenciesNames) {
                console.error("impactsAndDependenciesNames is undefined!");
                return;
            }
            const impactQs =
                restructuredFallbackImpactsGeoServer?.flatMap((im: any) =>
                    im?.values?.map((val: any) => ({
                        value: rating[im.rating as keyof typeof rating],
                        label: val,
                    })),
                ) || [];

            const dependencyQs =
                restructuredFallbackDependenciesGeoServer?.flatMap((dep: any) =>
                    dep?.values?.map((val: any) => ({
                        value: rating[dep.rating as keyof typeof rating],
                        label: val,
                    })),
                ) || [];

            console.log("impactQs: ", impactQs);
            console.log("dependencyQs: ", dependencyQs);

            impactsAndDependenciesNames?.impact_names?.forEach((name) => {
                const exists = impactQs.some((impact) => impact.label === name);
                if (!exists) {
                    impactQs.push({ value: "Low", label: name });
                }
            });

            impactsAndDependenciesNames?.dependencies_names?.forEach((name) => {
                const exists = dependencyQs.some((dep) => dep.label === name);
                if (!exists) {
                    dependencyQs.push({ value: "Low", label: name });
                }
            });

            console.log("impactQs after: ", impactQs);
            console.log("dependencyQs after: ", dependencyQs);

            setImpacts(impactQs);
            setDependencies(dependencyQs);
        },
        [restructuredFallbackImpactsGeoServer, restructuredFallbackDependenciesGeoServer],
    );

    // Fetch impacts and dependencies names
    async function getImpactsAndDependenciesNames() {
        try {
            const response = await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/impacts/names`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${encryptedToken}`,
                },
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error fetching impacts and dependencies names:", error);
        }
    }
    // Fetch and submit questions
    const submitAnswers = useCallback(
        async (answers: Answers) => {
            const finalAnswers = { ...answers };

            function deleteQuestionLabel(obj: { [k: string]: any }) {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        if (key === "questionLabel") {
                            delete obj[key];
                        } else if (typeof obj[key] === "object" && obj[key] !== null) {
                            deleteQuestionLabel(obj[key]);
                        }
                    }
                }
                return obj;
            }

            function convertToNumericRating(entity: { [key: string]: { value: string; comment: string; questionLabel: string } }) {
                for (const key in entity) {
                    entity[key].value = handleRate(entity[key].value as "Low" | "Medium" | "High" | "Very High");
                }
                return entity;
            }

            finalAnswers.impacts = convertToNumericRating(finalAnswers.impacts);
            finalAnswers.dependencies = convertToNumericRating(finalAnswers.dependencies);
            const payload = deleteQuestionLabel(finalAnswers);
            console.log("Payload Qs", payload);
            const siteId = site.site_id;

            fetch(`${process.env.KUYUA_APP_BACKEND_URL}/site-template/answers/${siteId}`, {
                method: "POST",
                body: JSON.stringify({
                    answers: payload,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${encryptedToken}`,
                },
            })
                .then(async (res) => {
                    if (res.ok) {
                        dispatch({
                            type: ToastAdded,
                            toast: {
                                kind: "success",
                                text: "Changes saved successfully",
                            },
                        });
                        const response = await res.json();
                        setAnswers(response.answer.answers);
                        refreshSiteData();
                    } else {
                        const { errors } = await res.json();
                        errors?.forEach((error: string) =>
                            dispatch({
                                type: ToastAdded,
                                toast: {
                                    kind: "error",
                                    text: error,
                                },
                            }),
                        );
                    }
                })
                .catch((error) => console.error("Error submitting questions:", error));
        },
        [answers, site.site_name, site.site_id],
    );

    const submitQuestion = useCallback((question: string, type: string = "comparing") => {
        fetch(`${process.env.KUYUA_APP_BACKEND_URL}/site-template/questions`, {
            method: "POST",
            body: JSON.stringify({
                question,
                type,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${encryptedToken}`,
            },
        })
            .then(async (res) => {
                if (res.ok) {
                    dispatch({
                        type: ToastAdded,
                        toast: {
                            kind: "success",
                            text: "Changes saved successfully",
                        },
                    });
                    getQuestions();
                } else {
                    const { errors } = await res.json();
                    errors?.forEach((error: string) =>
                        dispatch({
                            type: ToastAdded,
                            toast: {
                                kind: "error",
                                text: error,
                            },
                        }),
                    );
                }
            })
            .catch((error) => console.error("Error submitting questions:", error));
    }, []);

    const deleteQuestion = useCallback((questionId: string) => {
        const siteId = site.site_id;
        fetch(`${process.env.KUYUA_APP_BACKEND_URL}/site-template/questions/${questionId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${encryptedToken}`,
            },
            body: JSON.stringify({
                site_id: siteId,
            }),
        })
            .then(async (res) => {
                if (res.ok) {
                    dispatch({
                        type: ToastAdded,
                        toast: {
                            kind: "success",
                            text: "Question deleted successfully",
                        },
                    });
                    getQuestions();
                } else {
                    const { errors } = await res.json();
                    errors?.forEach((error: string) =>
                        dispatch({
                            type: ToastAdded,
                            toast: {
                                kind: "error",
                                text: error,
                            },
                        }),
                    );
                }
            })
            .catch((error) => console.error("Error deleting question:", error));
    }, []);

    // Fetch existing questions template
    const getQuestions = useCallback(async () => {
        try {
            const siteId = site.site_id;
            const response = await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/site-template/answers/${siteId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${encryptedToken}`,
                },
            });
            const { result } = await response.json();

            if (result) {
                const yesNoQuestions = Object.keys(result.answers?.yes_no || {}).map((qid) => ({
                    label: result.answers.yes_no[qid].questionLabel,
                    id: qid,
                }));
                const comparingQuestions = Object.keys(result.answers?.comparing || {}).map((qid) => ({
                    id: qid,
                    2023: result.answers.comparing[qid]["2023"].comment,
                    2024: result.answers.comparing[qid]["2024"].comment,
                    label: result.answers.comparing[qid].questionLabel,
                }));

                setYNQuestions(yesNoQuestions);
                setComparingQuestions(comparingQuestions);

                setAnswers(result.answers);
            }
        } catch (error) {
            console.error("Error fetching questions:", error);
        }
    }, [site.site_name, site.site_id]);

    useEffect(() => {
        if (site) {
            getImpactsAndDependenciesNames()
                .then((data) => {
                    console.log("impacts and dependencies names: ", data);
                    const impactsAndDependenciesNames = data;
                    return Promise.all([getImpactsAndDependencies(impactsAndDependenciesNames), getQuestions()]);
                })
                .catch((error) => {
                    console.error("Error fetching impacts and dependencies names: ", error);
                });
        }
    }, [site, getImpactsAndDependencies, getQuestions]);

    const handleApply = async (question: string, id: any) => {
        try {
            const response = await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/site-template/questions/${id}`, {
                method: "PUT",
                body: JSON.stringify({
                    question,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${encryptedToken}`,
                },
            });

            if (response.ok) {
                dispatch({
                    type: ToastAdded,
                    toast: {
                        kind: "success",
                        text: "Changes saved successfully",
                    },
                });
                getQuestions();
            } else {
                const { errors } = await response.json();
                errors?.forEach((error: string) =>
                    dispatch({
                        type: ToastAdded,
                        toast: {
                            kind: "error",
                            text: error,
                        },
                    }),
                );
            }
        } catch (error) {
            console.error("Error submitting questions:", error);
        }
    };

    // Select options component
    const SelectOptions = React.memo(
        ({ options, onChange, value }: { options: string[]; onChange: (value: string) => void; value: string }) => (
            <select
                onChange={(e) => onChange(e.target.value)}
                style={{
                    width: "100%",
                    borderRadius: 10,
                    height: 30,
                    background: "transparent",
                    color: "white",
                    fontSize: "12px", // Updated font size
                }}
                value={value}
                // disabled={superUser}
            >
                {options.map((option) => (
                    <option style={{ color: "black" }} key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        ),
    );

    const formatName = useCallback((name: string) => {
        return name
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    }, []);

    const handleRate = (value: "Low" | "Medium" | "High" | "Very High") => {
        switch (value) {
            case "Low":
                return "2";
                break;
            case "Medium":
                return "3";
                break;
            case "High":
                return "4";
                break;
            case "Very High":
                return "5";
                break;
            default:
                "1";
                break;
        }
    };

    const DownloadButton = ({
        site,
        downloadFunction,
        filename,
        buttonText,
    }: {
        site: INewSite;
        downloadFunction: (site: INewSite) => Promise<any>;
        filename: string;
        buttonText: string;
    }) => {
        const [csvData, setCsvData] = React.useState(null);
        const [isExporting, setIsExporting] = React.useState(false);
        const [error, setError] = React.useState(false);

        const handleDownload = async () => {
            try {
                setIsExporting(true);
                const data = await downloadFunction(site);
                setIsExporting(false);
                if (!data) {
                    setError(true);
                    return;
                }
                setCsvData(data);
            } catch (error) {
                console.log("🚀 ~ handleDownload ~ error:", error);
                setError(true);
            } finally {
                setIsExporting(false);
            }
        };

        return (
            <DownloadButtonDefault
                onClick={handleDownload}
                style={{
                    ...(error && {
                        color: "white",
                        backgroundColor: "#a62323",
                    }),
                }}
                disabled={isExporting}
            >
                {csvData ? (
                    <CSVLink
                        style={{
                            color: "black",
                            border: "black 1 solid",
                            fontSize: "10px",
                        }}
                        data={csvData}
                        filename={filename}
                    >
                        Download {buttonText}
                    </CSVLink>
                ) : isExporting ? (
                    "Exporting ..."
                ) : error ? (
                    "Failed"
                ) : (
                    buttonText
                )}
            </DownloadButtonDefault>
        );
    };
    const DownloadPDFOrWordButton = ({ site, buttonText }: { site: INewSite; buttonText: string }) => {
        const [isOpen, setIsOpen] = React.useState(false);
        const [isExporting, setIsExporting] = React.useState(false);
        const [error, setError] = React.useState(false);
        const buttonRef = React.useRef(null);
        const dropdownRef = React.useRef(null);

        // Handle click outside to close the dropdown
        React.useEffect(() => {
            const handleClickOutside = (event) => {
                if (
                    dropdownRef.current &&
                    !dropdownRef.current.contains(event.target) &&
                    buttonRef.current &&
                    !buttonRef.current.contains(event.target)
                ) {
                    setIsOpen(false);
                }
            };
            document.addEventListener("mousedown", handleClickOutside);
            return () => document.removeEventListener("mousedown", handleClickOutside);
        }, []);

        const handleDownload = async (format: "pdf" | "docx") => {
            try {
                setIsExporting(true);
                setIsOpen(false);
                setError(false);

                // Call your backend endpoint
                const response = await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/esrsE4/${site.site_id}?format=${format}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${encryptedToken}`,
                    },
                });

                if (!response.ok) throw new Error("Export failed");

                // Create blob from response
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                // Create temporary anchor for download
                const a = document.createElement("a");
                a.href = url;
                a.download = `${site.site_name || " "}_E4.${format}`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } catch (error) {
                console.error("Export error:", error);
                setError(true);
            } finally {
                setIsExporting(false);
                setIsOpen(false);
            }
        };

        return (
            <div style={{ position: "relative" }} ref={buttonRef}>
                <DownloadButtonDefault
                    onClick={() => setIsOpen(!isOpen)}
                    disabled={isExporting}
                    // style={{
                    //     ...(error && {
                    //         color: "white",
                    //         backgroundColor: "#a62323",
                    //     }),
                    // }}
                >
                    {isExporting ? "Exporting..." : error ? "Failed" : buttonText}
                </DownloadButtonDefault>

                {isOpen && (
                    <div
                        ref={dropdownRef}
                        style={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            backgroundColor: colors.mediumBlue,
                            border: `1px solid ${colors.darkBlue}`,
                            borderRadius: "4px",
                            zIndex: 10,
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                            padding: 10,
                        }}
                    >
                        <button
                            onClick={() => handleDownload("pdf")}
                            disabled={isExporting}
                            style={{
                                background: "none",
                                border: "none",
                                color: colors.brightBlue,
                                cursor: "pointer",
                                padding: "5px",
                                backgroundColor: colors.mediumBlue,
                            }}
                        >
                            {isExporting ? "Exporting..." : error ? "Failed" : "PDF"}
                        </button>
                        <button
                            onClick={() => handleDownload("docx")}
                            disabled={isExporting}
                            style={{
                                background: "none",
                                border: `1px solid ${colors.mediumBlue}`,
                                color: colors.brightBlue,
                                cursor: "pointer",
                                padding: "5px",
                            }}
                        >
                            {isExporting ? "Exporting..." : error ? "Failed" : "Word"}
                        </button>
                    </div>
                )}
            </div>
        );
    };

    const DownloadOptions = ({ site, label = "Download" }: { site: ISite; label: string }) => {
        const [isOpen, setIsOpen] = React.useState(false);
        const buttonRef = React.useRef(null);
        const dropdownRef = React.useRef(null);

        React.useEffect(() => {
            const handleClickOutside = (event) => {
                if (buttonRef.current && !buttonRef.current.contains(event.target)) {
                    setIsOpen(false);
                }
            };

            // Add scroll into view logic when dropdown opens
            if (isOpen && dropdownRef.current) {
                const dropdownRect = dropdownRef.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;

                if (dropdownRect.bottom > viewportHeight) {
                    dropdownRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                    });
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [isOpen]);

        return (
            <div style={{ position: "relative" }} ref={buttonRef}>
                <DownloadButtonDefault onClick={() => setIsOpen(!isOpen)}> {label} </DownloadButtonDefault>
                {isOpen && (
                    <div
                        ref={dropdownRef}
                        style={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            backgroundColor: colors.mediumBlue,
                            border: `1px solid ${colors.darkBlue}`,
                            borderRadius: "4px",
                            zIndex: 10,
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                            padding: 10,
                        }}
                    >
                        <DownloadButton
                            site={site}
                            downloadFunction={sbm3Table}
                            filename={`${site.site_name || " "}_sbm3Table.csv`}
                            buttonText="E4 SBM3 Table"
                        />
                        <DownloadButton
                            site={site}
                            downloadFunction={sbm3}
                            filename={`${site.site_name || " "}_sbm3.csv`}
                            buttonText="E4 SBM3 Data"
                        />
                        <DownloadButton
                            site={site}
                            downloadFunction={IRO1}
                            filename={`${site.site_name || " "}_IRO1.csv`}
                            buttonText="E4 IRO-1"
                        />
                        <DownloadButton
                            site={site}
                            downloadFunction={E45}
                            filename={`${site.site_name || " "}_E45.csv`}
                            buttonText="E4-5 (Metrics)"
                        />
                        <DownloadButton
                            site={site}
                            downloadFunction={ESRSE43}
                            filename={`${site.site_name || " "}_E43_ACTIONS.csv`}
                            buttonText="E4-3 (ACTIONS)"
                        />
                        <DownloadButton
                            site={site}
                            downloadFunction={ESRSE44}
                            filename={`${site.site_name || " "}_E44_TARGETS.csv`}
                            buttonText="E4-4 (TARGETS)"
                        />
                    </div>
                )}
            </div>
        );
    };
    const GetReportDownloadOptions = ({ site, label = "Get Report" }: { site: ISite; label: string }) => {
        const [isOpen, setIsOpen] = React.useState(false);
        const buttonRef = React.useRef(null);
        const dropdownRef = React.useRef(null);

        React.useEffect(() => {
            const handleClickOutside = (event) => {
                if (buttonRef.current && !buttonRef.current.contains(event.target)) {
                    setIsOpen(false);
                }
            };

            // Add scroll into view logic when dropdown opens
            if (isOpen && dropdownRef.current) {
                const dropdownRect = dropdownRef.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;

                if (dropdownRect.bottom > viewportHeight) {
                    dropdownRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                    });
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [isOpen]);

        return (
            <div style={{ position: "relative" }} ref={buttonRef}>
                <DownloadButtonDefault onClick={() => setIsOpen(!isOpen)}> {label} </DownloadButtonDefault>
                {isOpen && (
                    <div
                        ref={dropdownRef}
                        style={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            backgroundColor: colors.mediumBlue,
                            border: `1px solid ${colors.darkBlue}`,
                            borderRadius: "4px",
                            zIndex: 10,
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                            padding: 10,
                        }}
                    >
                        <DownloadPDFOrWordButton site={site} buttonText="ESRS-E4" />
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", gap: "10px", marginBottom: "1rem" }}>
                <GetReportDownloadOptions site={site as unknown as ISite} label="Get Report" />
                <DownloadOptions site={site as unknown as ISite} label="Export Data" />
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <button
                    onClick={() => submitAnswers(answers)}
                    style={{
                        ...buttonStyle,
                        width: 200,
                        borderRadius: 15,
                        background: "transparent",
                        color: "white",
                        border: `1px solid ${colors.brightBlue}`,
                        padding: "5px 10px",
                        fontSize: "12px", // Updated font size
                    }}
                >
                    Save
                </button>
            </div>
            <br />
            <div style={{ display: "flex", gap: 15 }}>
                <div style={{ ...containerStyle, width: "70%" }}>
                    <div className={"table-container"}>
                        <table className="s-table s-table-fixed species-table">
                            <thead>
                                <tr>
                                    <th style={{ width: "15%" }} />
                                    <TableHeaderCell label={t("Potential")} width="10%" />
                                    <TableHeaderCell label={t("Actual")} width="15%" />
                                    <TableHeaderCell label={t("Comments")} width="60%" />
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={sectionStyle}>
                                    <td colSpan={4}>Impacts</td>
                                </tr>

                                {impacts.map((impact: { label: string; value: string }) => (
                                    // Inline InputRow component
                                    <tr key={impact.label} style={flexRowStyle}>
                                        <td style={{ width: "25%", fontWeight: "normal", whiteSpace: "wrap" }}>
                                            {formatName(impact.label)}
                                        </td>
                                        <td style={{ width: "5%", whiteSpace: "nowrap", fontSize: "12px" }}>{impact.value}</td>
                                        <td style={{ width: "15%", marginLeft: 30 }}>
                                            <SelectOptions
                                                options={["Low", "Medium", "High", "Very High"]}
                                                onChange={(value) => {
                                                    const actualImpacts: { [key: string]: Answer } = {};
                                                    const label = impact.label;

                                                    impacts.forEach((imp) => {
                                                        const acLabel = imp.label;
                                                        if (acLabel === label) {
                                                            actualImpacts[acLabel] = {
                                                                questionLabel: acLabel,
                                                                value,
                                                                comment: answers.impacts?.[acLabel]?.comment || "",
                                                            };
                                                        } else {
                                                            actualImpacts[acLabel] = {
                                                                questionLabel: acLabel,
                                                                value: answers.impacts?.[acLabel]?.value ?? imp.value,
                                                                comment: answers.impacts?.[acLabel]?.comment || "",
                                                            };
                                                        }
                                                    });

                                                    setAnswers({
                                                        ...answers,
                                                        impacts: actualImpacts,
                                                    });
                                                }}
                                                value={answers?.impacts?.[impact?.label]?.value || impact?.value}
                                            />
                                        </td>
                                        <td style={{ width: "80%" }}>
                                            <textarea
                                                // disabled={superUser}
                                                onChange={(e) => {
                                                    const newComment = e.target.value;
                                                    setAnswers((prev) => ({
                                                        ...prev,
                                                        impacts: {
                                                            ...prev.impacts,
                                                            [impact.label]: {
                                                                ...prev.impacts?.[impact.label],
                                                                comment: newComment,
                                                            },
                                                        },
                                                    }));
                                                }}
                                                value={answers?.impacts?.[impact?.label]?.comment || ""}
                                                style={{
                                                    borderRadius: 10,
                                                    padding: 5,
                                                    height: 30,
                                                    width: "100%",
                                                    background: "transparent",
                                                    color: "white",
                                                    fontSize: "12px",
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}

                                <tr style={sectionStyle}>
                                    <td colSpan={4}>
                                        <hr />
                                    </td>
                                </tr>

                                <tr style={sectionStyle}>
                                    <td colSpan={4}>Dependencies</td>
                                </tr>
                                {dependencies.map((dep: { label: string; value: string }) => (
                                    <tr key={dep.label} style={flexRowStyle}>
                                        <td style={{ width: "25%", fontWeight: "normal", whiteSpace: "wrap" }}>{formatName(dep.label)}</td>
                                        <td style={{ width: "5%", whiteSpace: "nowrap", fontSize: "12px" }}>{dep.value}</td>
                                        <td style={{ width: "15%", marginLeft: 30 }}>
                                            <SelectOptions
                                                options={["Low", "Medium", "High", "Very High"]}
                                                onChange={(value) => {
                                                    const actualDeps: { [key: string]: Answer } = {};
                                                    const label = dep.label;

                                                    dependencies.forEach((dep) => {
                                                        const acLabel = dep.label;
                                                        if (acLabel === label) {
                                                            actualDeps[acLabel] = {
                                                                questionLabel: acLabel,
                                                                value,
                                                                comment: answers.dependencies?.[acLabel]?.comment || "",
                                                            };
                                                        } else {
                                                            actualDeps[acLabel] = {
                                                                questionLabel: acLabel,
                                                                value: answers.dependencies?.[acLabel]?.value ?? dep.value,
                                                                comment: answers.dependencies?.[acLabel]?.comment || "",
                                                            };
                                                        }
                                                    });

                                                    setAnswers({
                                                        ...answers,
                                                        dependencies: actualDeps,
                                                    });
                                                }}
                                                value={answers?.dependencies?.[dep?.label]?.value || dep?.value}
                                            />
                                        </td>
                                        <td style={{ width: "80%" }}>
                                            <textarea
                                                // disabled={superUser}
                                                onChange={(e) => {
                                                    const newComment = e.target.value;
                                                    setAnswers((prev) => ({
                                                        ...prev,
                                                        dependencies: {
                                                            ...prev.dependencies,
                                                            [dep.label]: {
                                                                ...prev.dependencies?.[dep.label],
                                                                comment: newComment,
                                                            },
                                                        },
                                                    }));
                                                }}
                                                value={answers?.dependencies?.[dep?.label]?.comment || ""}
                                                style={{
                                                    borderRadius: 10,
                                                    padding: 5,
                                                    height: 30,
                                                    width: "100%",
                                                    background: "transparent",
                                                    color: "white",
                                                    fontSize: "12px", // Updated font size
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    style={{
                        width: "30%",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        gap: 15,
                    }}
                >
                    <div
                        style={{
                            ...containerStyle,
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                        }}
                    >
                        {superUser && <div style={sectionStyle}>Yes/No Questions</div>}
                        {YNQuestions.map((question) => {
                            const onChange = (data: any) => {
                                setAnswers((prev) => ({
                                    ...prev,
                                    yes_no: {
                                        ...prev.yes_no,
                                        [question.id]: {
                                            ...data,
                                        },
                                    },
                                }));
                            };

                            const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                const newComment = e.target.value;
                                onChange({
                                    questionLabel: question.label,
                                    value: answers.yes_no?.[question.id]?.value,
                                    comment: newComment,
                                });
                            };

                            const isEditing = editingQuestionId === question.id;
                            const editedQuestion = editedQuestions[question.id] || question.label;

                            return (
                                <div
                                    key={question.id}
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexDirection: superUser ? "column" : "row",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: superUser ? "100%" : "50%",
                                            ...(superUser && {
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }),
                                        }}
                                    >
                                        {isEditing ? (
                                            <textarea
                                                value={editedQuestion}
                                                onChange={(e) => setEditedQuestions({ ...editedQuestions, [question.id]: e.target.value })}
                                                style={{
                                                    borderRadius: 10,
                                                    padding: 5,
                                                    height: 30,
                                                    width: 150,
                                                    background: "transparent",
                                                    color: "white",
                                                    fontSize: "12px", // Updated font size
                                                }}
                                            />
                                        ) : (
                                            <text>{question.label}</text>
                                        )}
                                        {superUser && (
                                            <div style={{ display: "flex", gap: 5 }}>
                                                {isEditing ? (
                                                    <button
                                                        onClick={async () => {
                                                            await handleApply(editedQuestion, question.id);
                                                            setEditingQuestionId(null);
                                                        }}
                                                        style={{
                                                            ...buttonStyle,
                                                            background: "transparent",
                                                            color: "white",
                                                            borderRadius: 15,
                                                            border: `1px solid ${colors.brightBlue}`,
                                                            padding: "0px 10px",
                                                            fontSize: "12px", // Updated font size
                                                        }}
                                                    >
                                                        Apply
                                                    </button>
                                                ) : (
                                                    <button
                                                        onClick={() => setEditingQuestionId(question.id)}
                                                        style={{
                                                            ...buttonStyle,
                                                            background: "transparent",
                                                            color: "white",
                                                            borderRadius: 15,
                                                            border: `1px solid ${colors.brightBlue}`,
                                                            padding: "0px 10px",
                                                            fontSize: "12px", // Updated font size
                                                        }}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                                <button
                                                    onClick={() => deleteQuestion(question.id)}
                                                    style={{
                                                        ...buttonStyle,
                                                        background: "transparent",
                                                        color: "white",
                                                        borderRadius: 15,
                                                        border: `1px solid ${colors.brightBlue}`,
                                                        padding: "0px 10px",
                                                        fontSize: "12px", // Updated font size
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {
                                        <div style={{ display: "flex", width: "50%", gap: 5 }}>
                                            <div style={{ width: "50%" }}>
                                                <SelectOptions
                                                    onChange={(value) =>
                                                        onChange({
                                                            questionLabel: question.label,
                                                            value,
                                                            comment: answers["yes_no"]?.[question.id]?.comment,
                                                        })
                                                    }
                                                    options={["Yes", "No"]}
                                                    value={answers["yes_no"]?.[question.id]?.value}
                                                />
                                            </div>
                                            <div style={{ width: "50%" }}>
                                                <textarea
                                                    onChange={handleCommentChange}
                                                    value={answers["yes_no"]?.[question.id]?.comment || ""}
                                                    style={{
                                                        borderRadius: 10,
                                                        padding: 5,
                                                        height: 30,
                                                        width: "100%",
                                                        background: "transparent",
                                                        color: "#ffffffa0",
                                                        fontSize: "12px", // Updated font size
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            );
                        })}
                        {superUser && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    marginTop: "10px",
                                }}
                            >
                                <label>Question</label>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    <textarea
                                        onChange={async (e) => {
                                            setNewYNQuestionToAdd(e.target.value);
                                        }}
                                        placeholder="Add a new question"
                                        name=""
                                        value={newYNQuestionToAdd}
                                        style={{
                                            borderRadius: 10,
                                            padding: 5,
                                            height: 30,
                                            width: 150,
                                            background: "transparent",
                                            color: "#ffffffa0",
                                            fontSize: "12px", // Updated font size
                                        }}
                                    />
                                    <button
                                        style={{
                                            ...buttonStyle,
                                            width: "30%",
                                            background: "transparent",
                                            color: "white",
                                            borderRadius: 15,
                                            border: `1px solid ${colors.brightBlue}`,
                                            fontSize: "12px", // Updated font size
                                        }}
                                        onClick={async () => {
                                            await submitQuestion(newYNQuestionToAdd, "yes_no");
                                            setNewYNQuestionToAdd("");
                                        }}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    <div style={{ ...containerStyle, width: "100%" }}>
                        {
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ width: "50%" }}></div>
                                <div
                                    style={{
                                        width: "50%",
                                        display: "flex",
                                        gap: 5,
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <div style={{ width: "50%" }}>2023</div>
                                    <div style={{ width: "50%" }}>2024</div>
                                </div>
                            </div>
                        }

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                }}
                            >
                                {superUser && <div style={sectionStyle}>Input p.a</div>}

                                {comparingQuestions.length !== 0 &&
                                    comparingQuestions.map((qs) => {
                                        const isEditing = editingQuestionId === qs.id;
                                        const editedQuestion = editedQuestions[qs.id] || qs.label;

                                        return (
                                            <div
                                                key={qs.id}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                    flexDirection: superUser ? "column" : "row",
                                                }}
                                            >
                                                <div style={{ width: superUser ? "100%" : "50%" }}>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                                                            {superUser && "• "}
                                                            {isEditing ? (
                                                                <textarea
                                                                    value={editedQuestion}
                                                                    onChange={(e) =>
                                                                        setEditedQuestions({ ...editedQuestions, [qs.id]: e.target.value })
                                                                    }
                                                                    style={{
                                                                        borderRadius: 10,
                                                                        padding: 5,
                                                                        height: 30,
                                                                        width: 150,
                                                                        background: "transparent",
                                                                        color: "white",
                                                                        fontSize: "12px",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <text>{qs.label}</text>
                                                            )}
                                                        </div>

                                                        {superUser && (
                                                            <div style={{ display: "flex", gap: 5 }}>
                                                                {isEditing ? (
                                                                    <button
                                                                        onClick={async () => {
                                                                            await handleApply(editedQuestion, qs.id);
                                                                            setEditingQuestionId(null);
                                                                        }}
                                                                        style={{
                                                                            ...buttonStyle,
                                                                            background: "transparent",
                                                                            color: "white",
                                                                            borderRadius: 15,
                                                                            border: `1px solid ${colors.brightBlue}`,
                                                                            padding: "0px 10px",
                                                                            fontSize: "12px",
                                                                        }}
                                                                    >
                                                                        Apply
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        onClick={() => setEditingQuestionId(qs.id)}
                                                                        style={{
                                                                            ...buttonStyle,
                                                                            background: "transparent",
                                                                            color: "white",
                                                                            borderRadius: 15,
                                                                            border: `1px solid ${colors.brightBlue}`,
                                                                            padding: "0px 10px",
                                                                            fontSize: "12px",
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                )}
                                                                <button
                                                                    onClick={() => deleteQuestion(qs.id)}
                                                                    style={{
                                                                        ...buttonStyle,
                                                                        background: "transparent",
                                                                        color: "white",
                                                                        borderRadius: 15,
                                                                        border: `1px solid ${colors.brightBlue}`,
                                                                        padding: "0px 10px",
                                                                        fontSize: "12px",
                                                                    }}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {
                                                    <div
                                                        style={{
                                                            width: "50%",
                                                            display: "flex",
                                                            gap: 5,
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        <div>
                                                            <textarea
                                                                onChange={(e) => {
                                                                    setAnswers((prev) => ({
                                                                        ...prev,
                                                                        comparing: {
                                                                            ...prev.comparing,
                                                                            [qs.id]: {
                                                                                ...prev.comparing?.[qs.id],
                                                                                2023: {
                                                                                    ...prev.comparing?.[qs.id]?.["2023"],
                                                                                    comment: e.target.value,
                                                                                },
                                                                            },
                                                                        },
                                                                    }));
                                                                }}
                                                                value={answers?.comparing?.[qs.id]?.["2023"]?.comment || ""}
                                                                style={{
                                                                    borderRadius: 10,
                                                                    padding: 5,
                                                                    height: 30,
                                                                    width: "100%",
                                                                    background: "transparent",
                                                                    color: "white",
                                                                    fontSize: "12px", // Updated font size
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <textarea
                                                                onChange={(e) => {
                                                                    setAnswers((prev) => ({
                                                                        ...prev,
                                                                        comparing: {
                                                                            ...prev?.comparing,
                                                                            [qs.id]: {
                                                                                ...prev?.comparing?.[qs.id],
                                                                                2024: { comment: e.target.value },
                                                                            },
                                                                        },
                                                                    }));
                                                                }}
                                                                value={answers?.comparing?.[qs.id]?.["2024"].comment}
                                                                style={{
                                                                    borderRadius: 10,
                                                                    padding: 5,
                                                                    height: 30,
                                                                    width: "100%",
                                                                    background: "transparent",
                                                                    color: "white",
                                                                    fontSize: "12px", // Updated font size
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })}
                                {superUser && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <label>Question</label>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                            }}
                                        >
                                            <textarea
                                                onChange={async (e) => {
                                                    setNewQuestionToAdd(e.target.value);
                                                }}
                                                placeholder="Add a new question"
                                                name=""
                                                value={newQuestionToAdd}
                                                style={{
                                                    borderRadius: 10,
                                                    padding: 5,
                                                    height: 30,
                                                    width: 150,
                                                    background: "transparent",
                                                    color: "white",
                                                    fontSize: "12px", // Updated font size
                                                }}
                                            />
                                            <button
                                                onClick={async () => {
                                                    await submitQuestion(newQuestionToAdd);
                                                    setNewQuestionToAdd("");
                                                }}
                                                style={{
                                                    ...buttonStyle,
                                                    width: "30%",
                                                    background: "transparent",
                                                    color: "white",
                                                    borderRadius: 15,
                                                    border: `1px solid ${colors.brightBlue}`,
                                                    fontSize: "12px", // Updated font size
                                                }}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SiteInputTab;
