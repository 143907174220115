export const ConfigChanged = "config/configChanged";

export const configReducer = (state: IConfigState, action: IGenericAction): IConfigState => {
    switch (action.type) {
        case ConfigChanged:
            return { ...state, ...action.config };
    }

    return state;
};
