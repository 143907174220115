import * as React from "react";
import HorizontalStack from "./HorizontalStack";
import Icon from "./Icon";
import NavLink from "./NavLink";

export interface ITableRowCell {
    type: "icon" | "icons" | "link";
}

interface ITableLinkCell extends ITableRowCell {
    subtext?: string;
    text: number | string;
    type: "link";
    url: string;
}

interface ITableIconCell extends ITableRowCell {
    icon: {
        color: string;
        path: string;
        size: number;
    };
    text?: string;
    type: "icon";
}

interface ITableIconListCell extends ITableRowCell {
    icons: {
        color: string;
        path: string;
        size: number;
        text?: string;
    }[];
    type: "icons";
}

export type TableRowCellValue = boolean | null | number | string | ITableIconCell | ITableIconListCell | ITableLinkCell;

interface ITableRowCellProps {
    value: TableRowCellValue;
}

export default function TableRowCell(props: ITableRowCellProps): JSX.Element {
    const value = props.value;
    const type = (value as ITableRowCell)?.type;

    if (typeof value === "number") {
        return <td className="s-table s-table-numeric">{value}</td>;
    } else if (value === undefined || value === null) {
        return <td>—</td>;
    } else if (type === "link") {
        const { subtext, text, url } = value as ITableLinkCell;

        return (
            <td>
                <NavLink to={url}>{text}</NavLink>
                {subtext !== undefined && <span className="s-table-subtext">{subtext}</span>}
            </td>
        );
    } else if (type === "icon") {
        const { icon, text } = value as ITableIconCell;

        return (
            <td>
                <HorizontalStack spacing="condensed">
                    <Icon color={icon.color} path={icon.path} size={icon.size} />
                    <span>{text}</span>
                </HorizontalStack>
            </td>
        );
    } else if (type === "icons") {
        return (
            <td>
                <HorizontalStack spacing="condensed">
                    {(value as ITableIconListCell).icons.map((icon, ix) => (
                        <Icon key={ix} color={icon.color} path={icon.path} size={icon.size} tooltip={icon.text} />
                    ))}
                </HorizontalStack>
            </td>
        );
    }

    return <td>{value as string}</td>;
}
