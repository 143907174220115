import * as React from "react";
import * as ReactDOM from "react-dom";
import { AppContext } from "@/context";
import CloseButton from "../CloseButton";
import "./BaseModalDialog.scss";

interface IBaseModalDialogProps {
    backgroundColor?: string;
    bodyPadding?: string;
    children: React.ReactNode;
    closeOnClickOnOverlay?: boolean;
    footer?: React.ReactNode;
    maxBodyHeight?: number | string | undefined;
    onClose: () => void;
    scrollableBody?: boolean;
    title: string;
    verticalAlign?: "center" | "top";
    height: string;
    width: string;
}

interface IBaseModalDialogState {
    mode: string;
}

export default class BaseModalDialog extends React.Component<IBaseModalDialogProps, IBaseModalDialogState> {
    el: HTMLDivElement;

    static contextType = AppContext;

    declare context: React.ContextType<typeof AppContext>;

    constructor(props: IBaseModalDialogProps) {
        super(props);

        this.el = document.createElement("div");
        this.state = { mode: "in" };
    }

    componentDidMount(): void {
        document.querySelector("#modals").appendChild(this.el);
    }

    componentWillUnmount(): void {
        document.querySelector("#modals").removeChild(this.el);
    }

    render(): JSX.Element {
        const { children, closeOnClickOnOverlay, title } = this.props;
        const { layoutSize } = this.context.state;
        const { mode } = this.state;
        const centered = this.props.verticalAlign === "center";
        const hide = () => {
            if (this.state.mode !== "out") {
                this.setState((s) => ({ ...s, mode: "out" }));
                setTimeout(() => this.props.onClose(), 100);
            }
        };
        const onClickOverlay = (e: React.MouseEvent<HTMLDivElement>) => {
            const t = e.target as HTMLDivElement;

            if (closeOnClickOnOverlay !== undefined && !closeOnClickOnOverlay) {
                return;
            }

            if (t.classList.contains("s-modal-overlay")) {
                hide();
            }
        };

        return ReactDOM.createPortal(
            <div className={`s-modal-overlay s-modal-${mode} s-layout-${layoutSize}`} onClick={onClickOverlay}>
                <div
                    className={`s-modal-dialog ${centered && "s-modal-dialog--centered"}`}
                    style={{
                        backgroundColor: this.props.backgroundColor,
                        width: this.props.width,
                    }}
                >
                    <h2 className="s-modal-header">
                        <span>{title}</span>
                        <CloseButton onClick={hide} size="lg" />
                    </h2>
                    <div
                        className="s-modal-body"
                        style={{
                            height: this.props.height || "",
                            width: "100%",
                            maxHeight: this.props.maxBodyHeight,
                            padding: this.props.bodyPadding || undefined,
                            overflowY: this.props.scrollableBody === undefined || this.props.scrollableBody === true ? "auto" : undefined,
                        }}
                    >
                        {children}
                    </div>
                    {this.props.footer && <div className="s-modal-footer">{this.props.footer}</div>}
                </div>
            </div>,
            this.el,
        );
    }
}
