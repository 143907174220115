import React, { useContext } from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import { LocationSearch } from "@/project/sleap/Locate/LocationSearch";
import AssessTile from "@/project/sleap/Assess/AssessTile";
import { Button } from "@/project/sleap/Button";
import { AssessmentInfoBox } from "@/project/sleap/Assess/InfoBox/AssessmentInfoBox";
import { SleapContext } from "@/context/SleapContext";
import AssessDetailRow from "@/project/sleap/Assess/AssessDetailRow";
import { AppContext, ToastAdded, useProject, useTeam } from "@/context";
import { CheckHandler, IAssessDetailTable } from "@/types/sleap";
import { AssessmentDetailHeader } from "@/project/sleap/Assess/AssessmentDetailHeader";
import { ExportButton } from "@/project/sleap/Assess/ExportButton";
import { apiGet, t, unique } from "@/utils";
import Pagination from "@/lib/Pagination/Pagination";
import { accumulatePriorityScore } from "@/utils/calculatePriorityScore";
import { SortOrder } from "@/utils/sort";
import CryptoJS from "crypto-js";
import { fetchScopes } from "@/rest/apiHelper";
import Dropdown from "@/utils/Dropdown";
import Checkbox from "@/utils/Checkbox";
import { useLocation } from "react-router-dom";

const TableSearchBar: React.FC<TableSearchBarProps> = ({ countryHandler, siteTypeHandler, searchHandler, sleapTab }) => {
    const [scopeOptions, setScopeOptions] = React.useState<iScope[]>([]);
    const [selectedScopeIds, setSelectedScopeIds] = React.useState<number[]>([]);
    const [selectedCountryIds, setSelectedCountryIds] = React.useState<number[]>([]);
    const project = useProject();
    const team = useTeam();
    const url = `/api/v1/teams/${team.slug}/projects/${project.slug}/reports/${project.latest_report_id}/sites/assessments/2`;
    const countries = React.useMemo(() => {
        return [{ id: -1, label: t("ui.all") }].concat(
            unique(project.countries)
                .filter((country) => country)
                .sort()
                .map((id, i) => ({ id: i, label: id })),
        );
    }, [project.countries]);

    React.useEffect(() => {
        fetchScopes().then((reply) => {
            setScopeOptions(reply);
            setSelectedScopeIds(reply.map((scope) => scope.id));
        });
    }, []);

    const handleCheck = (id: number, check: boolean) => {
        if (check) {
            setSelectedScopeIds((prev) => [...prev, id]);
        } else {
            setSelectedScopeIds(selectedScopeIds.filter((scopeId) => scopeId !== id));
        }
    };

    const handleCountrySelect = (ids: number[]) => {
        const oldSelect = selectedCountryIds;
        const newSelect = ids.filter((newId) => !oldSelect.includes(newId));

        if (ids.length === 0 || newSelect.includes(-1)) {
            countryHandler(["All"]);
            setSelectedCountryIds([]);
        } else {
            const selectedCountries = countries.filter((country) => ids.includes(country.id)).map((country) => country.label);
            countryHandler(selectedCountries);
            setSelectedCountryIds(ids);
        }
    };

    React.useEffect(() => {
        siteTypeHandler(selectedScopeIds);
    }, [selectedScopeIds]);

    return (
        <div style={{ ...style.flexRow, ...styles.wrapper, padding: "10px 15px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div
                    style={{
                        ...style.centerFlex,
                        flexDirection: "column",
                        fontWeight: "bold",
                    }}
                >
                    <span style={{ fontSize: 12, textTransform: "uppercase" }}>Step 3</span>
                    <span style={{ fontSize: 25, lineHeight: 1 }}>E</span>
                </div>
                <span
                    style={{
                        marginLeft: 30,
                        fontSize: 25,
                        color: colors.brightBlue,
                        textTransform: "uppercase",
                    }}
                >
                    Evaluate
                </span>
            </div>

            <div
                style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <div style={{ marginRight: 20 }}>
                    <LocationSearch inputHandler={searchHandler} />
                </div>
                <div style={{ marginRight: 20 }}>
                    <Dropdown
                        placeholder={"country"}
                        dropdownStyle={{
                            padding: "5px 10px",
                            marginBottom: "unset",
                            fontSize: 13,
                            height: "unset",
                        }}
                        listDropdownStyle={{ top: "unset" }}
                        handler={handleCountrySelect}
                        options={countries}
                        selected={selectedCountryIds}
                        flat
                        multiple
                        selectedRight
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <span style={{ marginRight: 10 }}>Site Type:</span>
                    {scopeOptions.map((scope, i) => (
                        <Checkbox
                            checked={selectedScopeIds.includes(scope.id)}
                            label={scope.name}
                            key={i}
                            checkHandler={(check) => handleCheck(scope.id, check)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

let oldDbSites = [];
const maxSitesOnPage = 10;
const Assess: React.FC<CheckHandler> = ({ checkHandler }) => {
    const { state } = useContext(AppContext);
    const [sites, setSites] = React.useState<INewSite[]>([]);
    const [currentOpen, setCurrentOpen] = React.useState<number>();
    const [filterInput, setFilterInput] = React.useState<string>("");
    const { sleapState, sleapDispatch } = React.useContext(SleapContext);
    const [page, setPage] = React.useState<number>(1);
    const [sitesCount, setSitesCount] = React.useState<number>(-1);
    const [filterQueries, setFilterQueries] = React.useState<string[]>([]);
    const [countries, setCountries] = React.useState<string[]>(["All"]);
    const [selectedSiteTypeIds, setSelectedSiteTypeIds] = React.useState<number[]>([]);
    const [searchText, setSearchText] = React.useState<string>("");

    const project = useProject();
    const team = useTeam();
    const { dispatch } = useContext(AppContext);
    const [sortOrder, setSortOrder] = React.useState<SortOrder>("desc");
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            Authorization: `Bearer ${encryptedToken}`,
        },
    };

    React.useEffect(() => {
        checkHandler(sites.length > 0);
    }, [sites.length]);

    async function getSites() {
        let NewDbUrl = `${process.env.KUYUA_APP_BACKEND_URL}/sites?page=${page}&company_id=${team.id}`;
        let NewDbUrlQueries = [...filterQueries];
        const queries = ["limit=10", ...filterQueries];

        queries.push("order_by=priority_score");
        queries.push("order_direction=" + sortOrder);

        if (!countries.includes("All")) {
            queries.push(`countries[]=${countries.join("&countries[]=")}`);
            NewDbUrlQueries.push("country=" + countries);
        }

        if (searchText) {
            queries.push("filter=" + searchText);
            NewDbUrlQueries.push("search_all=" + searchText);
        }
        if (selectedSiteTypeIds.length) {
            queries.push(`site_type_ids[]=${selectedSiteTypeIds.join("&site_type_ids[]=")}`);
            NewDbUrlQueries.push("site_type_id=" + selectedSiteTypeIds);
        }
        NewDbUrlQueries.push("sortBy=priority_score");
        NewDbUrlQueries.push("sortOrder=" + sortOrder);
        NewDbUrlQueries.push("pageSize=10");

        let currentPage = page;
        if (filterInput.length) {
            currentPage = 1;
            queries.push("filter=" + filterInput);
            NewDbUrlQueries.push("search_all=" + filterInput);
        }
        const url = `projects/${project.slug}/reports/${project.latest_report_id}/site/page/${currentPage}`;
        try {
            NewDbUrl = NewDbUrl + "&" + NewDbUrlQueries.join("&");
            console.log(NewDbUrl);
            const response = await fetch(NewDbUrl, header);
            const data = await response.json();
            const mappedSites = await data.sites;
            setSitesCount(data.total);
            setSites(mappedSites);
            //setSitesCount(mappedSites.length);
            console.log("mapped sites ===>>> ", mappedSites);
        } catch (error) {
            console.error("Error fetching sites from kuyuaappdb:", error);
            dispatch({
                type: ToastAdded,
                toast: { kind: "error", text: t("ui.failed_to_load_sites") },
            });
        }
    }

    React.useEffect(() => {
        getSites();
    }, [page, filterInput, filterQueries, sortOrder, searchText, selectedSiteTypeIds, countries]);

    React.useEffect(() => {
        return () => {
            sleapDispatch({ filteredSites: undefined });
        };
    }, []);

    React.useEffect(() => {
        if (sleapState?.selectedSiteId) {
            const siteSelectedElement = document.querySelector(`#assess-${sleapState.selectedSiteId}`);
            if (siteSelectedElement) {
                siteSelectedElement.scrollIntoView({ behavior: "smooth" });
            }
        }
        setCurrentOpen(sleapState?.selectedSiteId ?? 0);
    }, [sleapState?.selectedSiteId]);

    const filterSites = (queries: string[]) => {
        setFilterQueries(queries);
    };
    function handleSortingType() {
        setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    }
    const url = `/api/v1/teams/${team.slug}/projects/${project.slug}/reports/${project.latest_report_id}/site/export/csv`;
    const location = useLocation();

    const tabId = location?.state === "P" ? "actions" : "overview";
    const handleSetCountry = (selection: string[]): void => {
        setCountries(selection);
    };

    return (
        <>
            <div
                style={{
                    ...style.flexColumn,
                    backgroundColor: colors.darkBlue,
                    zIndex: 2,
                }}
            >
                {state.showSideStepDetail && (
                    <>
                        <AssessDetailTable
                            sites={sites}
                            openHandler={setCurrentOpen}
                            currentOpen={currentOpen}
                            inputHandler={setFilterInput}
                            onFilterSites={filterSites}
                            onChangePage={(page: number) => setPage(page)}
                            page={page}
                            sitesCount={sitesCount}
                            handleSorting={handleSortingType}
                            handleSetCountry={handleSetCountry}
                            setSearchText={setSearchText}
                            setSelectedSiteTypeIds={setSelectedSiteTypeIds}
                            tabId={tabId}
                        />
                    </>
                )}
                {!state.showSideStepDetail && (
                    <>
                        <div
                            style={{
                                ...style.flexColumn,
                                gap: 2,
                                height: 300,
                                overflowY: "scroll",
                                justifyContent: "flex-start",
                            }}
                        >
                            {sites.map((site) => {
                                return (
                                    <AssessTile
                                        key={site.id + "_" + site.site_id}
                                        site={site}
                                        openHandler={setCurrentOpen}
                                        isOpen={currentOpen == site.id}
                                    />
                                );
                            })}
                        </div>
                        {sitesCount > maxSitesOnPage && (
                            <Pagination changePage={setPage} currentPage={page} lastPage={Math.ceil(sitesCount / maxSitesOnPage)} />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

const AssessDetailTable: React.FC<
    IAssessDetailTable & {
        handleSetCountry: any;
        setSelectedSiteTypeIds: any;
        setSearchText: any;
        tabId: any;
    }
> = ({
    sites,
    openHandler,
    currentOpen,
    inputHandler,
    onFilterSites,
    onChangePage,
    page,
    sitesCount,
    handleSorting,
    handleSetCountry,
    setSelectedSiteTypeIds,
    setSearchText,
    tabId,
}) => {
    const [filterOpen, setFilterOpen] = React.useState(false);

    const handleFilterToggle = () => {
        if (filterOpen) {
            onFilterSites([]);
        }
        setFilterOpen((prev) => !prev);
    };

    return (
        <div
            style={{
                backgroundColor: colors.darkBlue,
                ...style.sleapBackDrop,
            }}
        >
            <TableSearchBar
                countryHandler={handleSetCountry}
                siteTypeHandler={setSelectedSiteTypeIds}
                searchHandler={setSearchText}
                sleapTab={tabId}
            />

            <div style={{ padding: "10px 15px" }}>
                <table
                    className="sites-table"
                    style={{
                        overflowY: "scroll",
                    }}
                    id="table"
                >
                    <thead>
                        <tr>
                            <th>PPRIORIITY SCORE</th>
                            <th>SITE NAME</th>
                            <th>ADDRESS</th>
                            <th>COUNTRY</th>
                            <th>SITE TYPE</th>
                            <th>IMPACTS</th>
                            <th>DEPENDENCIES</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ overflowY: "scroll" }}>
                        {sites.map((site) => {
                            return (
                                <AssessDetailRow
                                    key={site.site_id}
                                    site={site}
                                    openHandler={openHandler}
                                    isOpen={currentOpen == site.site_id}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {sitesCount > maxSitesOnPage && (
                <Pagination changePage={onChangePage} currentPage={page} lastPage={Math.ceil(sitesCount / maxSitesOnPage)} />
            )}
        </div>
    );
};

const styles = StyleSheet.create({
    button: {
        position: "fixed",
        right: "25%",
        bottom: 100,
    },
    wrapper: {
        borderRadius: 10,
        backgroundColor: colors.opaqueWhite,
        alignItems: "center",
    },
    headerBar: {
        backgroundColor: colors.mediumBlue,
        height: 60,
        width: "100%",
        color: "white",
        padding: "20px 15px",
        fontSize: 12,
        display: "flex",
        flexDirection: "row",
    },
    tableWrapper: {
        display: "flex",
        flex: 1,
        maxHeight: "70vH",
        overflow: "hidden",
        flexDirection: "column",
        alignItems: "center",
    },
});

export default Assess;
