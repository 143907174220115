import React from "react";
import { CSVLink } from "react-csv";
import { style } from "@/utils/style";
import { HAZARD_TYPE_MAP } from "../helper";
import { getHazardClass, buildCsvHeaders, pickHighestRiskTodayAcrossScenarios } from "../utils";
import { FUTURE_TIMES } from "../constants";
import { ClimateRiskHeaderProps } from "../types";

/**
 * Flatten data for CSV:
 * Columns: Risk, Cluster, Category, Today (max across scenarios),
 * then for each scenario => for each future time => scenario/time column
 */
function flattenForCsv(data: any, dimension: string, scenarios: string[], scenarioLabels: { [key: string]: string }) {
    // Gather all hazards from today and future times
    const hazardSet = new Set<string>();
    scenarios.forEach((scenario) => {
        const scenObj = data[scenario];
        if (!scenObj) return;

        // Gather from today
        const todayObj = scenObj.today;
        if (todayObj) {
            Object.keys(todayObj).forEach((k) => {
                if (["emission_scenario", "model", "downscale", "latitude", "longitude", "start_year", "end_year"].includes(k)) return;
                hazardSet.add(k);
            });
        }

        // Gather from future times
        FUTURE_TIMES.forEach((time) => {
            const timeData = scenObj[time];
            if (!timeData) return;
            Object.keys(timeData).forEach((k) => {
                if (["emission_scenario", "model", "downscale", "latitude", "longitude", "start_year", "end_year"].includes(k)) return;
                hazardSet.add(k);
            });
        });
    });
    const hazardArr = Array.from(hazardSet).sort();

    // Build rows
    const rows = hazardArr.map((hazardKey) => {
        const hazardName = hazardKey.replace(/_/g, " ");
        const meta = HAZARD_TYPE_MAP[hazardName];
        const category = meta ? meta.type : "Unknown";

        // Single "Today" column = highest across scenarios
        const bestToday = pickHighestRiskTodayAcrossScenarios(data, hazardKey, scenarios);

        // Base row object
        const rowObj: any = {
            Risk: hazardName,
            Cluster: dimension,
            Category: category,
            Today: bestToday,
        };

        // Add future columns for each scenario/time combination
        scenarios.forEach((scenario) => {
            const scenLabel = scenarioLabels[scenario] || scenario;
            FUTURE_TIMES.forEach((time) => {
                const colName = `${scenLabel} ${time}`;
                const hazardObj = data[scenario]?.[time]?.[hazardKey];
                const futureRisk = getHazardClass(hazardObj, time) || "no data";
                rowObj[colName] = futureRisk;
            });
        });

        return rowObj;
    });

    return rows;
}

export const ClimateRiskHeader: React.FC<ClimateRiskHeaderProps> = ({
    dimension,
    setDimension,
    data,
    SCENARIOS,
    SCENARIO_LABELS,
    lat,
    lng,
}) => {
    // Prepare CSV data
    const csvRows = data ? flattenForCsv(data, dimension, SCENARIOS, SCENARIO_LABELS) : [];
    const csvHeaders = buildCsvHeaders(SCENARIOS, SCENARIO_LABELS);

    return (
        <div style={{ marginBottom: 16, display: "flex", alignItems: "center", gap: "16px", justifyContent: "space-between" }}>
            <div></div>

            {csvRows.length > 0 && (
                <CSVLink
                    data={csvRows}
                    headers={csvHeaders}
                    filename={`climate-risk-${dimension}-${lat}-${lng}.csv`}
                    style={{ ...style.actionButton, color: "black", fontSize: 12 }}
                >
                    Export Data
                </CSVLink>
            )}
        </div>
    );
};
