import * as React from "react";

interface IGridProps {
    alignItems?: string;
    children: React.ReactNode;
    itemsPerRow: number;
    spacing?: string;
    style?: React.CSSProperties;
}

export default function Grid(props: IGridProps): JSX.Element {
    const style = { ...props.style, alignItems: props.alignItems };

    return (
        <div className={`s-grid s-grid-${props.spacing || "regular"}-${props.itemsPerRow}`} style={style}>
            {props.children}
        </div>
    );
}
