import * as React from "react";
import { AppContext } from "@/context";
import { t } from "@/utils";
import { Button, HorizontalStack, LabeledTextInput, VerticalStack } from "@/lib";

export default function PersonalDetails(): JSX.Element {
    const { state } = React.useContext(AppContext);
    const [isMounted, setIsMounted] = React.useState<boolean>(false);
    const [company, setCompany] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [firstName, setFirstName] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");
    const [phoneNumber, setPhoneNumber] = React.useState<string>("");

    React.useEffect(() => {
        if (isMounted) {
            return;
        }

        const { user } = state.config;

        setIsMounted(true);
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setEmail(user.email);
        setCompany(user.company || "");
        setPhoneNumber(user.phone_number || "");
    });

    return (
        <VerticalStack style={{ maxWidth: "40rem" }}>
            <HorizontalStack>
                <LabeledTextInput id="details_first_name" label={t("ui.first_name")} onInput={(v) => setFirstName(v)} value={firstName} />
                <LabeledTextInput id="details_last_name" label={t("ui.last_name")} onInput={(v) => setLastName(v)} value={lastName} />
            </HorizontalStack>
            <LabeledTextInput id="details_company" label={t("ui.company")} onInput={(v) => setCompany(v)} value={company} />
            <LabeledTextInput id="details_email" disabled={true} label={t("ui.email")} value={email} />
            <LabeledTextInput id="details_phone" label={t("ui.phone_number")} onInput={(v) => setPhoneNumber(v)} value={phoneNumber} />
            <Button style={{ marginTop: "2rem" }}>{t("actions.save")}</Button>
        </VerticalStack>
    );
}
