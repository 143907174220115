import * as React from "react";
import { mdiArrowDown, mdiArrowLeft, mdiArrowRight, mdiArrowUp } from "@mdi/js";
import { useTheme } from "@/context";
import { t, unique } from "@/utils";
import Button from "./Button";
import HorizontalStack from "./HorizontalStack";
import Icon from "./Icon";
import TextInput from "./TextInput";
import VerticalStack from "./VerticalStack";

type TreeId = number | string;

interface ISitePickerProps {
    disabled?: boolean;
    inputColumnLabel: string;
    onSelectedSiteIdsChange: (ids: number[]) => void;
    orientation?: "horizontal" | "vertical";
    outputColumnLabel: string;
    report: IAssessmentReport;
    selectedSiteIds: number[];
    style?: React.CSSProperties;
}

interface ISitePickerColumnProps {
    disabled?: boolean;
    expandedIds: TreeId[];
    filterText: string;
    hiddenIds?: TreeId[];
    id: string;
    items: ISideNavGroup | ISideNavItem[];
    label: string;
    onExpandedIdsChange: (ids: TreeId[]) => void;
    onFilterTextChange: (text: string) => void;
    onSelectedIdsChange: (ids: TreeId[]) => void;
    selectedIds: TreeId[];
    style?: React.CSSProperties;
}

interface ISitePickerTreeProps {
    disabled?: boolean;
    expandedIds: TreeId[];
    hiddenIds?: TreeId[];
    inset?: number;
    onToggleExpanded: (id: TreeId) => void;
    onToggleSelected: (id: TreeId, extend: boolean) => void;
    parentId?: TreeId;
    root: ISideNavGroup | ISideNavItem[];
    selectedIds: TreeId[];
    style?: React.CSSProperties;
    tree: ISideNavGroup | ISideNavItem[];
}

function findNode(tree: ISideNavGroup | ISideNavItem[], id: TreeId): ISideNavGroup | ISideNavItem[] | ISideNavItem {
    if (Array.isArray(tree)) {
        for (const subtree of tree) {
            if (subtree.id === id) {
                return subtree;
            }
        }
    } else {
        for (const [name, subtree] of Object.entries(tree)) {
            if (name === id) {
                return subtree;
            } else {
                const subnode = findNode(subtree, id);

                if (subnode) {
                    return subnode;
                }
            }
        }
    }

    return undefined;
}

function toSiteIds(tree: ISideNavGroup, ids: TreeId[]): number[] {
    const actual = [];
    const collect = (node: ISideNavGroup | ISideNavItem[]) => {
        if (Array.isArray(node)) {
            node.map((leaf) => actual.push(leaf.id));
        } else {
            Object.values(node).map((subtree) => collect(subtree));
        }
    };

    for (const id of ids) {
        if (typeof id === "number") {
            actual.push(id);
        } else {
            collect(findNode(tree, id) as ISideNavGroup);
        }
    }

    return unique(actual);
}

function SitePickerTree(props: ISitePickerTreeProps): JSX.Element {
    const theme = useTheme();
    const isLeaf = Array.isArray(props.tree);
    const inset = props.inset > 0 ? `calc(${props.inset * 22}px + .75rem)` : ".75rem";
    const rootAttrs = !props.parentId
        ? {
              "aria-multiselectable": true,
              role: "listbox",
          }
        : undefined;
    const onItemKeyDown = (e: React.KeyboardEvent, id: TreeId) => {
        if (props.disabled) {
            return;
        }

        switch (e.code) {
            case "ArrowDown":
                e.preventDefault();
                break;

            case "ArrowRight":
                e.preventDefault();

                if (!props.expandedIds.includes(id)) {
                    props.onToggleExpanded(id);
                }
                break;

            case "ArrowLeft":
                e.preventDefault();

                if (props.expandedIds.includes(id)) {
                    props.onToggleExpanded(id);
                }
                break;

            case "ArrowUp":
                e.preventDefault();
                break;

            case "Space":
                e.preventDefault();
                props.onToggleSelected(id, e.metaKey || e.shiftKey);
                break;
        }
    };

    return (
        <ul className="s-site-picker-tree" style={props.style} {...rootAttrs}>
            {isLeaf &&
                (props.tree as ISideNavItem[])
                    .filter((item) => !props.hiddenIds?.includes(item.id))
                    .map((item) => (
                        <li
                            aria-selected={props.selectedIds.includes(item.id)}
                            key={item.id}
                            onClick={(e) => props.onToggleSelected(item.id, e.metaKey || e.shiftKey)}
                            onKeyDown={(e) => onItemKeyDown(e, item.id)}
                            role="option"
                            style={{
                                backgroundColor: props.selectedIds.includes(item.id) ? theme.tintColor : undefined,
                                paddingLeft: inset,
                            }}
                            tabIndex={0}
                        >
                            <div className="s-site-picker-tree-name">{item.name}</div>
                        </li>
                    ))}

            {!isLeaf &&
                Object.entries(props.tree)
                    .sort(([a], [b]) => a.localeCompare(b))
                    .map(([name, subtree], ix) => (
                        <li key={`${ix} ${name}`}>
                            <div
                                className="s-site-picker-tree-node"
                                onKeyDown={(e) => onItemKeyDown(e, name)}
                                aria-selected={props.selectedIds.includes(name)}
                                role="option"
                                tabIndex={0}
                            >
                                <HorizontalStack
                                    className="s-site-picker-tree-name"
                                    spacing="condensed"
                                    style={{
                                        backgroundColor: props.selectedIds.includes(name) ? theme.tintColor : undefined,
                                        paddingLeft: inset,
                                    }}
                                >
                                    <span className="s-site-picker-tree-toggle" onClick={() => props.onToggleExpanded(name)}>
                                        {props.expandedIds.includes(name) ? "▼" : "►"}
                                    </span>
                                    <span onClick={(e) => props.onToggleSelected(name, e.metaKey || e.shiftKey)}>{name}</span>
                                </HorizontalStack>
                            </div>

                            {props.expandedIds.includes(name) && (
                                <SitePickerTree
                                    disabled={props.disabled}
                                    expandedIds={props.expandedIds}
                                    hiddenIds={props.hiddenIds}
                                    inset={(props.inset || 0) + 1}
                                    onToggleExpanded={props.onToggleExpanded}
                                    onToggleSelected={(id, extend) => (props.disabled ? undefined : props.onToggleSelected(id, extend))}
                                    root={props.root}
                                    parentId={name}
                                    selectedIds={props.selectedIds}
                                    tree={subtree}
                                />
                            )}
                        </li>
                    ))}
        </ul>
    );
}

function SitePickerColumn(props: ISitePickerColumnProps): JSX.Element {
    const toggled = (list: (number | string)[], id: number | string) => (list.includes(id) ? list.filter((i) => i !== id) : [...list, id]);
    const multiToggled = (list: (number | string)[], id: number | string, extend: boolean) => {
        if (extend) {
            return toggled(list, id);
        }

        return list.includes(id) ? (list.length === 1 ? [] : [id]) : [id];
    };
    const onSelectAll = () => {
        if (props.disabled) {
            return;
        }

        const walk = (selected: TreeId[], node: ISideNavGroup | ISideNavItem[]): TreeId[] => {
            if (Array.isArray(node)) {
                for (const n of node) {
                    selected.push(n.id);
                }
            } else {
                for (const n of Object.values(node)) {
                    walk(selected, n);
                }
            }

            return selected;
        };

        props.onSelectedIdsChange(walk([], props.items));
    };
    const items = React.useMemo(() => {
        const text = props.filterText.trim().toLocaleLowerCase();
        const walk = (flattened: ISideNavItem[], node: ISideNavGroup | ISideNavItem[]): ISideNavItem[] => {
            if (Array.isArray(node)) {
                for (const n of node) {
                    if (props.hiddenIds?.includes(n.id)) {
                        continue;
                    }

                    if (n.name.toLocaleLowerCase().indexOf(text) >= 0) {
                        flattened.push(n);
                    }
                }
            } else {
                for (const [k, n] of Object.entries(node)) {
                    if (props.hiddenIds?.includes(k)) {
                        continue;
                    }

                    walk(flattened, n);
                }
            }

            return flattened;
        };

        if (text === "") {
            return props.items;
        }

        return walk([], props.items).sort((a, b) => a.name.localeCompare(b.name));
    }, [props.filterText, props.hiddenIds, props.items]);

    return (
        <div className="s-site-picker-column" style={props.style}>
            <label htmlFor={`${props.id}_filter`}>{props.label}</label>

            <TextInput
                id={`${props.id}_filter`}
                onInput={(v) => {
                    props.onSelectedIdsChange([]);
                    props.onFilterTextChange(v);
                }}
                placeholder={t("ui.type_to_filter")}
                value={props.filterText}
            />

            <SitePickerTree
                disabled={props.disabled}
                expandedIds={props.expandedIds}
                hiddenIds={props.hiddenIds}
                onToggleExpanded={(id) => props.onExpandedIdsChange(toggled(props.expandedIds, id))}
                onToggleSelected={(id, extend) => props.onSelectedIdsChange(multiToggled(props.selectedIds, id, extend))}
                root={items}
                selectedIds={props.selectedIds}
                style={{ marginTop: "1rem", maxHeight: "25rem", overflowY: "scroll" }}
                tree={items}
            />

            <div className="s-site-picker-tree-actions">
                <a className="s-link" onClick={onSelectAll}>
                    {t("actions.select_all")}
                </a>
                <a className="s-link" onClick={() => !props.disabled && props.onSelectedIdsChange([])}>
                    {t("actions.select_none")}
                </a>
            </div>
        </div>
    );
}

export default function SitePicker(props: ISitePickerProps): JSX.Element {
    const [inputExpandedIds, setInputExpandedIds] = React.useState<TreeId[]>([]);
    const [outputExpandedIds, setOutputExpandedIds] = React.useState<TreeId[]>([]);
    const [inputSelectedIds, setInputSelectedIds] = React.useState<TreeId[]>([]);
    const [outputSelectedIds, setOutputSelectedIds] = React.useState<TreeId[]>([]);
    const [inputFilterText, setInputFilterText] = React.useState<string>("");
    const [outputFilterText, setOutputFilterText] = React.useState<string>("");
    const selectedTree = React.useMemo(() => {
        return props.selectedSiteIds
            .map((id) => findNode(props.report.sidenav, id))
            .sort((a: ISideNavItem, b: ISideNavItem) => a.name.localeCompare(b.name));
    }, [props.report.sidenav, props.selectedSiteIds]) as ISideNavItem[];
    const isHorizontal = props.orientation !== "vertical";
    const LayoutComponent = isHorizontal ? HorizontalStack : VerticalStack;

    return (
        <LayoutComponent
            className="s-site-picker"
            horizontalAlign="stretch"
            style={{ ...props.style, width: "100%" }}
            verticalAlign="stretch"
        >
            <SitePickerColumn
                disabled={props.disabled}
                expandedIds={inputExpandedIds}
                filterText={inputFilterText}
                hiddenIds={props.selectedSiteIds}
                id="input_site_picker"
                items={props.report.sidenav}
                label={props.inputColumnLabel}
                onFilterTextChange={(t) => setInputFilterText(t)}
                onExpandedIdsChange={(ids) => setInputExpandedIds(ids)}
                onSelectedIdsChange={(ids) => {
                    setOutputSelectedIds([]);
                    setInputSelectedIds(ids);
                }}
                selectedIds={inputSelectedIds}
                style={{ flex: "1 1 auto", width: isHorizontal ? "50%" : "100%" }}
            />

            <div
                style={{
                    alignItems: "center",
                    display: "flex",
                    flex: "0 0 auto",
                    flexFlow: isHorizontal ? "column nowrap" : "row nowrap",
                    justifyContent: "center",
                    width: isHorizontal ? undefined : "100%",
                }}
            >
                <Button
                    disabled={props.disabled || inputSelectedIds.length === 0}
                    onClick={() => {
                        props.onSelectedSiteIdsChange(
                            unique([...props.selectedSiteIds, ...toSiteIds(props.report.sidenav, inputSelectedIds)]),
                        );
                        setInputSelectedIds([]);
                    }}
                >
                    <Icon path={isHorizontal ? mdiArrowRight : mdiArrowDown} />
                </Button>
                <Button
                    disabled={props.disabled || outputSelectedIds.length === 0}
                    onClick={() => {
                        props.onSelectedSiteIdsChange(props.selectedSiteIds.filter((id) => !outputSelectedIds.includes(id)));
                        setOutputSelectedIds([]);
                    }}
                    style={isHorizontal ? { marginTop: ".5rem" } : { marginLeft: ".5rem" }}
                >
                    <Icon path={isHorizontal ? mdiArrowLeft : mdiArrowUp} />
                </Button>
            </div>

            <SitePickerColumn
                disabled={props.disabled}
                expandedIds={outputExpandedIds}
                filterText={outputFilterText}
                id="output_site_picker"
                items={selectedTree}
                label={props.outputColumnLabel}
                onFilterTextChange={(t) => setOutputFilterText(t)}
                onExpandedIdsChange={(ids) => setOutputExpandedIds(ids)}
                onSelectedIdsChange={(ids) => {
                    setInputSelectedIds([]);
                    setOutputSelectedIds(ids);
                }}
                selectedIds={outputSelectedIds}
                style={{ flex: "1 1 auto", width: isHorizontal ? "50%" : "100%" }}
            />
        </LayoutComponent>
    );
}
