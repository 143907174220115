import * as React from "react";

export default function ColorLegend(props: { colors: { color: string; text: string }[] }): JSX.Element {
    return (
        <ul className="s-color-legend">
            {props.colors.map((color) => (
                <li key={color.color} className="s-color-legend-row">
                    <span className="s-color-legend-example" style={{ backgroundColor: color.color }} />
                    <span className="s-color-legend-text">{color.text}</span>
                </li>
            ))}
        </ul>
    );
}
