import * as React from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import BSAMap from "./BSAMap";
import { HeaderKpi } from "../species/SpeciesPage";
import { CSVLink } from "react-csv"; // Import react-csv
import { AppContext, packages, useCompanyPackages } from "@/context";
import { SubscriptionTooltip } from "@/components/SubscriptionTooltip";

const CustomTooltip = ({ children, title, show }) => {
    return <SubscriptionTooltip show={show}>{children}</SubscriptionTooltip>;
};

const BSA = ({ site, setTabId }) => {
    const { state } = React.useContext(AppContext);
    const [bsaCount, setBsaCount] = React.useState(0);
    const [bsa, setBsa] = React.useState([]);
    const [proximity, setProximity] = React.useState<number>(0);
    const [sbtnCoverageAreaPercentage, setSbtnCoverageAreaPercentage] = React.useState<number>(0);
    const [selected, setSelected] = React.useState({});
    const [showKBA, setShowKBA] = React.useState(false); // State for toggling KBA filter
    const companyPackages = useCompanyPackages();

    React.useEffect(() => {
        setTabId("");
    }, [bsa]);

    const isKBAEnabled = companyPackages?.length > 0 ? companyPackages.some((pkg) => pkg.feature_name === packages.KBA) : false;

    function handleBSAChange(data: any) {
        try {
            if (typeof data === "object" && data !== null) {
                setBsaCount(data?.radiusFiltered?.features?.length || 0);
                setBsa(data?.radiusFiltered?.features || []);
                setProximity(data?.closestDistanceMeters || 0);
                setSbtnCoverageAreaPercentage(data?.sbtnCoverageAreaPercentage || 0);
            } else {
                throw new Error("Invalid data format");
            }
        } catch (error) {
            console.error("Error handling BSA data:", error);
            setBsaCount(0);
            setBsa([]);
            setProximity(0);
            setSbtnCoverageAreaPercentage(0);
        }
    }

    const handleSiteType = (site_type_id) => {
        switch (site_type_id) {
            case "1":
                return "own";
            case "2":
                return "upstream";
            case "3":
                return "downstream";
            default:
                return "unknown";
        }
    };

    const toggleKBA = () => setShowKBA((prev) => !prev); // Toggle KBA state
    // Prepare data for CSV export with fallback for missing properties
    const csvData = (Array.isArray(bsa) ? bsa : []).map((item) => ({
        Name: item?.properties?.name || "N/A",
        Distance: item?.properties?.distance_meters ? Number(item.properties.distance_meters).toFixed(0) : "N/A",
        Type: item?.properties?.desig_eng || "N/A",
        "Reported Area (km²)": item?.properties?.rep_area ? Number(item.properties.rep_area).toFixed(3) : "N/A",
        "Biodiversity Sensitive Areas (BSA)": bsaCount || "N/A",
        "Distance to closest (m)": Number(proximity).toFixed(0) || "N/A",
    }));

    return (
        <div>
            {/* CSV Export Button */}
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    position: "relative",
                    gap: "10px",
                }}
            >
                {/* KBA Toggle Button */}

                <CustomTooltip title="Contact us" show={!isKBAEnabled}>
                    <button
                        onClick={isKBAEnabled ? toggleKBA : undefined}
                        style={{
                            ...styles.export,
                            ...(isKBAEnabled ? { cursor: "pointer" } : style.disabled),
                            ...(isKBAEnabled &&
                                showKBA && {
                                    backgroundColor: colors.brightBlue,
                                    color: "black",
                                }),
                        }}
                    >
                        {showKBA ? "Hide KBA" : "Include KBA"}
                    </button>
                </CustomTooltip>
                <CSVLink data={csvData} filename={`${site?.name || "site"}_BSA.csv`}>
                    <button
                        style={{
                            ...styles.export,
                            width: "120px",
                            height: "40px",
                            cursor: "pointer",
                        }}
                    >
                        Export CSV
                    </button>
                </CSVLink>
            </div>
            <br />
            <div style={{ display: "flex", flexDirection: "row", flex: 1, gap: 30 }}>
                <div style={styles.wrapper}>
                    <div className="table-container">
                        <table className="s-table s-table-fixed species-table" style={{ textAlign: "left" }}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Distance (m)</th>
                                    <th>Type</th>
                                    <th>Reported Area (km²)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(bsa) && bsa.length > 0 ? (
                                    bsa.map((item, index) => (
                                        <tr key={index}>
                                            <td onClick={() => setSelected(item)}>{item?.properties?.name || "N/A"}</td>
                                            <td>
                                                {item?.properties?.distance_meters
                                                    ? Number(item.properties.distance_meters).toFixed(0)
                                                    : "N/A"}
                                            </td>
                                            <td>{item?.properties?.desig_eng || "N/A"}</td>
                                            <td>{item?.properties?.rep_area ? Number(item.properties.rep_area).toFixed(3) : "N/A"}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={4}>No Records.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: 20 }}>
                    <div style={styles.summaryHeader}>
                        <HeaderKpi label="Biodiversity Sensitive Areas (BSA)" value={bsaCount || 0} />
                        <HeaderKpi label="Distance to closest (m)" value={Number(proximity).toFixed(0) || 0} />
                        <HeaderKpi label="Core Land Coverage" value={Number(sbtnCoverageAreaPercentage).toFixed(2).toString() + " %"} />
                    </div>
                    <div style={{ position: "relative", minHeight: 500 }}>
                        <BSAMap site={site} onBSAChange={handleBSAChange} selected={selected} showKBA={showKBA} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = StyleSheet.create({
    toggleStyle: {
        width: "120px",
        height: "40px",
        cursor: "pointer",
        backgroundColor: "#4CAF50",
        color: "#FFF",
        border: "none",
        borderRadius: "5px",
        fontSize: "14px",
    },
    wrapper: {
        width: "50%",
        borderRadius: 10,
        border: `1px solid ${colors.brightBlue}`,
        padding: 10,
        backgroundColor: colors.darkBlue,
    },
    summaryHeader: {
        height: 90,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: colors.darkBlue,
        borderRadius: 10,
        padding: "5px 20px",
    },
    export: {
        height: "100%",
        borderRadius: 20,
        backgroundColor: "transparent",
        opacity: 1,
        padding: "5px 10px",
        color: colors.white,
        display: "flex",
        alignItems: "center",
        gap: 5,
        border: `1px solid ${colors.white}`,
        fontSize: 13,
    },
});

export default BSA;
