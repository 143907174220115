import * as React from "react";
import { AppContext, ToastAdded, useProject, useTeam } from "@/context";
import { BreadcrumbPath, ErrorPanel, LoadingAnimation, MasterHeader } from "@/lib";
import { useNavigate, useParams } from "react-router-dom";
import { apiGet, apiPut, externalHttpGet, getHomeUrl, getSiteName, t } from "@/utils";
import { Navigate } from "react-router-dom";
import SitePropertiesForm from "./SitePropertiesForm";
import { MapboxApiEndpoint, parseAddress, StaticMap } from "@/lib/map";
import { ErrorBoundary } from "@sentry/react";
import { deepCopy, isEqual } from "@/utils/object";
import { updateReport } from "@/resources";

const fillEditSiteData = (site: ISite): IAddToSitesData => {
    return {
        location: {
            address: {
                name: site.name || "",
                street_address: site.address.street_address || "",
                zip_code: site.address.zip_code || "",
                city: site.address.city || "",
                country: site.address.country || "",
                country_code: site.address.country_code || "",
                province: site.address.province || "",
            },
            center: deepCopy(site.latlng),
        },
        industry: deepCopy(site.industry),
    } as IAddToSitesData;
};

export default function EditSiteProperties(): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);

    const canEdit = state.config.role.slug === "admin" || state.config.role.slug === "manager";
    if (!canEdit) {
        return <Navigate to="/" />;
    }

    const [loading, setLoading] = React.useState<boolean>(false);
    const [loadingSite, setLoadingSite] = React.useState<boolean>(true);
    const params = useParams();
    const project = useProject();
    const report = project.reports[project.latest_report_id] as IAssessmentReport;
    const navigate = useNavigate();
    const team = useTeam();
    const siteId = parseInt(params.siteId);
    const [site, setSite] = React.useState<ISite>(undefined);
    const url = getHomeUrl(state.config.team);
    const siteUrl = `/${team.slug}/${project.slug}/sites/${siteId}`;
    const breadcrumb = React.useMemo(() => {
        if (!site) {
            return null;
        }

        return [
            { label: t("ui.home"), route: url },
            { label: getSiteName(site), route: siteUrl },
            { label: t("ui.edit_site"), route: `${siteUrl}/edit` },
        ];
    }, [site]);
    const [editSiteData, setEditSiteData] = React.useState<IAddToSitesData>(undefined);
    const [showBanner, setShowBanner] = React.useState<boolean>(false);
    const [pin, setPin] = React.useState<IMapPin>(undefined);
    const [locationChanged, setLocationChanged] = React.useState<boolean>(false);

    React.useEffect(() => {
        const url = `projects/${project.slug}/reports/${report.id}/sites/${siteId}`;
        apiGet<ISite>(team.slug, url)
            .then((reply) => {
                if (reply.ok) {
                    const site = reply.data;
                    setSite(site);
                    setEditSiteData(fillEditSiteData(site));
                    setPin({
                        center: site.latlng,
                        id: `site-${site.latlng.lat}-${site.latlng.lng}`,
                    });
                } else {
                    setSite(undefined);
                    setEditSiteData(undefined);
                }
            })
            .finally(() => setLoadingSite(false));
    }, [siteId]);

    React.useEffect(() => {
        if (editSiteData && site) {
            setWarningVisibility();
        }
    }, [editSiteData?.industry]);

    React.useEffect(() => {
        if (editSiteData) {
            setPin({
                center: editSiteData.location.center,
                id: `site-${editSiteData.location.center.lat}-${editSiteData.location.center.lng}`,
            });

            setWarningVisibility();

            if (locationChanged) {
                fetchAddress();
            }
        }
    }, [editSiteData?.location.center]);

    const fetchAddress = () => {
        const center = editSiteData.location.center;

        if (!center) {
            return;
        }

        const { lat, lng } = center;
        const url = `${MapboxApiEndpoint}/${lng},${lat}.json`;
        const params = { access_token: state.config.mapbox_public_api_token };

        externalHttpGet<IMapboxQueryResult>(url, params).then((reply) => {
            const address = parseAddress(reply);
            address.name = editSiteData.location.address.name;
            setEditSiteData((data) => ({
                ...data,
                location: { ...data.location, address: address },
            }));
        });
    };

    const setWarningVisibility = () => {
        if (!isEqual(editSiteData.industry, site.industry) || !isEqual(editSiteData.location.center, site.latlng)) {
            setShowBanner(true);
        } else {
            setShowBanner(false);
        }
    };

    const setSiteLocation = (point: ILatLng) => {
        setLocationChanged(true);
        setEditSiteData({
            ...editSiteData,
            location: {
                ...editSiteData.location,
                center: { lat: point.lat, lng: point.lng },
            },
        });
    };

    const submit = () => {
        setLoading(true);
        apiPut<ISite>(team.slug, `projects/${project.slug}/reports/${project.latest_report_id}/sites/${site.id}`, editSiteData)
            .then((reply) => {
                if (reply.ok) {
                    dispatch({
                        type: ToastAdded,
                        toast: { kind: "success", text: t("ui.edit_site_success") },
                    });
                    updateReport(state, dispatch, project, project.latest_report_id, true);
                    navigate(siteUrl);
                } else {
                    dispatch({
                        type: ToastAdded,
                        toast: { kind: "error", text: t("ui.edit_site_fail") },
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    if (loadingSite) {
        return <LoadingAnimation />;
    }

    if (!editSiteData && !loadingSite) {
        return (
            <ErrorPanel
                style={{ margin: "30vh auto 0 auto" }}
                text={`The site you've navigated to does not exist or has been deleted`}
                title="Site Not Found"
            />
        );
    }

    return (
        <div className="s-edit-site">
            <BreadcrumbPath
                items={breadcrumb}
                onItemClick={(item) => navigate(item.route)}
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />

            <MasterHeader text={getSiteName(site)} />

            <ErrorBoundary fallback={<p>{t("ui.map_loading_error")}</p>}>
                <div className="s-map-container-small s-edit-site-map">
                    <StaticMap
                        onClick={setSiteLocation}
                        onPinClick={(pin: IMapPin) => setSiteLocation(pin.center)}
                        center={editSiteData?.location.center}
                        interactive={true}
                        layers={[]}
                        pins={[pin]}
                        rasters={{}}
                        zoom={7}
                    />
                </div>
            </ErrorBoundary>
            {showBanner && (
                <div className="s-banner s-banner-error s-edit-site-banner">
                    <span className="s-banner-text">{t("ui.edit_site_warn")}</span>
                </div>
            )}
            <SitePropertiesForm
                data={editSiteData}
                setData={setEditSiteData}
                onCancel={() => navigate(siteUrl)}
                onSubmit={submit}
                submitText={t("actions.save")}
                isSaving={loading}
            ></SitePropertiesForm>
        </div>
    );
}
