import { colors, style, StyleSheet } from "@/utils/style";
import Icon from "../../lib/Icon";
import { mdiClose } from "@mdi/js";
import * as React from "react";
import { iDetailContentProps, iProgressBarProps } from "@/types/sleap";
import { calculatePriorityScore } from "@/utils/calculatePriorityScore";
import { getBubbleBarColor } from "@/utils/getBubbleBarColor";
import { getRatingText } from "@/utils/getRatingText";
import { Rating } from "@/types/utils.d";

export default function DetailContent({
    closeHandler,
    title,
    clusteredRatings,
    color,
    backgroundColor,
    barColor,
    totalAvailable,
    hideCloseButton,
}: iDetailContentProps): JSX.Element {
    console.log("clusteredRatings ===>>> ", clusteredRatings);
    return (
        <div>
            {!hideCloseButton && (
                <div style={{ padding: 10 }}>
                    <div style={{ ...style.closeIcon, ...style.centerFlex }} onClick={() => closeHandler()}>
                        <Icon path={mdiClose} color={colors.darkBlue} size={17} />
                    </div>
                </div>
            )}
            <div style={{ padding: "10px 40px", overflow: "auto", height: 400 }}>
                <span style={{ textTransform: "uppercase", fontSize: 17 }}>{title}</span>
                <div>
                    {clusteredRatings.map((cluster) => (
                        <ClusterDetail
                            {...cluster}
                            backgroundColor={getBubbleBarColor(clusteredRatings)}
                            barColor={barColor}
                            color={color}
                            key={cluster.rating}
                            totalAvailable={totalAvailable}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export const ClusterDetail: React.FC<iClusterWColor> = ({
    rating,
    backgroundColor,
    values,
    color,
    barColor,
    totalAvailable,
}: iClusterWColor) => {
    const availableValues = calculatePriorityScore(rating as Rating, values.length);
    const completed = (availableValues / totalAvailable) * 100;

    ////    no need for opacity for now but we may need it later
    // let opacity = 0.4;
    // switch (rating) {
    //     case Rating.VERY_HIGH:
    //         opacity = 1;
    //         break;
    //     case Rating.HIGH:
    //         opacity = 0.7;
    //         break;
    // }
    return (
        <div style={{ display: "flex", flexDirection: "row", paddingTop: 20 }}>
            {/* <ProgressBar
                backgroundColor={backgroundColor}
                color={color}
                barColor={barColor}
                completed={completed}
                label={availableValues}
                // opacity={opacity}
            /> */}
            <div style={{ ...styles.containerStyles }}>{getRatingText(rating as Rating)}</div>
            <ul style={styles.list}>
                {values.map((value) => (
                    <li key={value}>{value.replaceAll("_", " ")}</li>
                ))}
            </ul>
        </div>
    );
};

export const ProgressBar: React.FC<iProgressBarProps> = ({
    backgroundColor,
    completed,
    label,
    opacity,
    color,
    barColor,
}: iProgressBarProps) => {
    return (
        <div style={{ ...styles.containerStyles, backgroundColor: barColor }}>
            <div
                style={{
                    ...styles.fillerStyles,
                    width: `${completed}%`,
                    backgroundColor: backgroundColor,
                    opacity: opacity,
                }}
            >
                {/* <span style={{ ...styles.labelStyles, color: color }}>{label === 0 ? '' : label}</span> */}
            </div>
        </div>
    );
};

const styles = StyleSheet.create({
    containerStyles: {
        flexShrink: 0,
        height: 15,
        width: 100,
        borderRadius: 5,
    },
    fillerStyles: {
        height: "100%",
        borderRadius: "inherit",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    labelStyles: {
        lineHeight: 1,
        fontSize: 12,
    },
    list: {
        padding: "unset",
        margin: "unset",
        marginLeft: 50,
        fontSize: 12,
    },
});
