import { SleapProvider } from "@/context/SleapContext";
import Sleap from "@/project/sleap/Sleap";
import * as React from "react";

export default function SleapPage(): JSX.Element {
    return (
        <SleapProvider>
            <div
                style={{
                    height: "100%",
                    marginTop: "100px",
                }}
            >
                <Sleap />
            </div>
        </SleapProvider>
    );
}
