// Example hazard‐type map from the screenshot
export const HAZARD_TYPE_MAP = {
    // Temperature
    wildfire: { type: "Acute" },
    "heat wave": { type: "Acute" },
    "cold wave": { type: "Acute" },
    frost: { type: "Acute" },
    "changing water temp.": { type: "Chronic" },
    "changing temperature": { type: "Chronic" },
    "temperature variability": { type: "Chronic" },
    "heat stress": { type: "Chronic" },
    "permafrost thawing": { type: "Chronic" },

    // Water
    drought: { type: "Acute" },
    "heavy precipitation": { type: "Acute" },
    flood: { type: "Acute" },
    "glacial lake outburst": { type: "Acute" },
    "glacier lake outburst": { type: "Acute" },
    "changing precipitation": { type: "Chronic" },
    "changing precipitation pattern": { type: "Chronic" },
    "precipitation variability": { type: "Chronic" },
    "hydrological variability": { type: "Chronic" },
    "sea level rise": { type: "Chronic" },
    "ocean acidification": { type: "Chronic" },
    "saline intrusion": { type: "Chronic" },
    "water stress": { type: "Chronic" },

    // Wind
    storm: { type: "Acute" },
    "wind storm": { type: "Acute" },
    tornado: { type: "Acute" },
    "tropical cyclones": { type: "Acute" },
    "blizzard dust sandstorm": { type: "Acute" },
    "changing wind patterns": { type: "Chronic" },
    "changing wind pattern": { type: "Chronic" },

    // Solid mass
    avalanche: { type: "Acute" },
    landslide: { type: "Acute" },
    subsidence: { type: "Acute" },
    "soil degradation": { type: "Chronic" },
    "soil erosion": { type: "Chronic" },
    "coastal erosion": { type: "Chronic" },
    solifluction: { type: "Chronic" },
};
