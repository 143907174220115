import * as React from "react";
import * as ReactDOM from "react-dom";

const ToastDuration = 4000;

interface IToastProps {
    duration?: number;
    kind?: string;
    onClose: () => void;
    text: string;
}

interface IToastState {
    mode: string;
    timeoutId: number;
}

export default class Toast extends React.Component<IToastProps, IToastState> {
    el: HTMLDivElement;

    constructor(props: IToastProps) {
        super(props);

        this.el = document.createElement("div");
        this.state = { mode: "out", timeoutId: -1 };
    }

    componentDidMount(): void {
        document.querySelector("#modals").appendChild(this.el);

        this.setState({
            ...this.state,
            timeoutId: window.setTimeout(() => {
                this.setState({
                    ...this.state,
                    mode: "out",
                    timeoutId: window.setTimeout(() => this.props.onClose(), 1000),
                });
            }, this.props.duration || ToastDuration),
        });

        window.setTimeout(() => this.setState({ ...this.state, mode: "in" }), 10);
    }

    componentWillUnmount(): void {
        window.clearTimeout(this.state.timeoutId);
        document.querySelector("#modals").removeChild(this.el);
    }

    render(): JSX.Element {
        const { kind, text } = this.props;
        const { mode } = this.state;

        return ReactDOM.createPortal(
            <div className={`s-toast s-toast-${kind || "info"} s-toast-${mode}`}>
                <span className="s-toast-text">{text}</span>
            </div>,
            this.el,
        );
    }
}
