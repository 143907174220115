import * as React from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import { LoadingAnimation } from "@/lib";
import CryptoJS from "crypto-js";
import "./socialIndicators.scss";
import { AppContext } from "@/context";
import { StaticMap } from "@/lib/map";

const years = ["2021", "2022", "2023"];
const mapSanctionsColor = (value: any) => {
  if (value === 0) return "green";
  if (parseFloat(value) > 0) return "red";
  return "green";
};
const mapCorruptionColor = (value: any) => {
  switch (true) {
    case value >= 0.66 && value < 1:
      return "green";
    case value >= 0.51 && value < 0.66:
      return "yellow";
    case value >= 0.35 && value < 0.51:
      return "orange";
    case value >= 0 && value < 0.35:
      return "red";
    default:
      return "gray";
  }
};
const mapLawEnforcementColor = (value: any) => {
  switch (true) {
    case value >= 0.67 && value < 1:
      return "green";
    case value >= 0.51 && value < 0.67:
      return "yellow";
    case value >= 0.35 && value < 0.51:
      return "orange";
    case value >= 0 && value < 0.35:
      return "red";
    default:
      return "gray";
  }
};
const mapArmedConflitColor = (value: any) => {
  switch (true) {
    case value >= 0 && value < 3:
      return "green";
    case value >= 3 && value < 17:
      return "yellow";
    case value >= 17 && value < 143:
      return "orange";
    case value >= 143:
      return "red";
    default:
      return "gray";
  }
};
const mapDataFalsificationColor = (value: any) => {
  switch (true) {
    case value >= 0 && value < 0.37:
      return "green";
    case value >= 0.37 && value < 0.51:
      return "yellow";
    case value >= 0.51 && value < 0.66:
      return "orange";
    case value >= 0.66 && value < 1:
      return "red";
    default:
      return "gray";
  }
};
//TODO: increase wrapper css class width to be near 96%  in the SiteDetailPage
const SocialIndicators = ({ latlng, bbox, mapZoom, site }: any) => {
  const [deforestation, setDeforestation] = React.useState([]);

  async function fetchDeforestation(lat: number, lng: number) {
    const url = `${process.env.GEO_SERVER_URL}/deforestation/all-years?lat=${lat}&lng=${lng}`;
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      if (data) {
        setDeforestation(data);
      }
    } catch (error) {
      console.error("Error fetching deforestation:", error);
    }
  };

  const encryptedToken = CryptoJS.AES.encrypt(
    process.env.API_KEY,
    process.env.SECRET_KEY
  ).toString();
  const header = {
    headers: {
      Authorization: `Bearer ${encryptedToken}`,
    },
  };
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const fetchSocialIndicators = async ({
      country,
      countryCode,
    }: {
      country: string;
      countryCode: string;
    }) => {

      const url = `${process.env.KUYUA_APP_BACKEND_URL}/social-indicators?country=${country}&country_code=${countryCode}`;
      try {
        const response = await fetch(url, header);
        const data = await response.json();

        if (data) {
          setIsLoading(false);
          setData(data);
        }
      } catch (error) {
        console.error("Error fetching social indicators:", error);
      } finally {
        setIsLoading(false);
      }
    };
    getCountry(latlng.lat, latlng.lng).then((res) =>
      fetchSocialIndicators({
        country: res.country,
        countryCode: res.countryCode,
      })
    );
    fetchDeforestation(latlng.lat, latlng.lng);
  }, [latlng]);

  const { state } = React.useContext(AppContext);

  async function getCountry(lat: number, lng: number) {
    const accessToken =
      state.config?.mapbox_public_api_token ||
      "pk.eyJ1IjoiYW5kcmUtaGlja21hbm4iLCJhIjoiY2xoNjR4enBkMDE3cjNqcGc0aG93ZzlueSJ9.JH3ClP3oIf2uvc4ZpFvjJQ";
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.features.length > 0) {
        const countryFeature = data.features.find((feature) =>
          feature.place_type.includes("country")
        );

        if (countryFeature) {
          const country = countryFeature.text;
          const countryCode = countryFeature.properties.short_code;
          return { country, countryCode };
        } else {
          throw new Error("Country not found");
        }
      } else {
        throw new Error("No results found");
      }
    } catch (error) {
      console.error("Error fetching country data:", error);
      return null;
    }
  }

  const [markerColor, setMarkerColor] = React.useState("green");

  const getMarkerColor = (value: number) => {
    switch (value) {
      case 0:
        return "green";
      case 1:
        return "#9ACD32"; // YellowGreen
      case 2:
        return "#ADFF2F"; // GreenYellow
      case 3:
        return "#FFFF00"; // Yellow
      case 4:
        return "#FFD700"; // Gold
      case 5:
        return "yellow";
      default:
        return "green"; // Default color if value is out of range
    }
  };

  const handleYearClick = (yearIndex: number) => {
    const deforestationData = deforestation[yearIndex];
    const value = deforestationData?.value || 0;
    const color = getMarkerColor(value);
    setMarkerColor(color);
  };

  return (
    <>
      {isLoading && <LoadingAnimation />}
      <div className="social-metrics-container">
        <div className="left-panel">
          <h3 style={{ margin: 0 }}>SOCIAL</h3>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>2021</th>
                <th>2022</th>
                <th>2023</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Control Of Corruption</td>
                {years.map((year, idx) => (
                  <td key={idx}>
                    <div
                      className="circle"
                      style={{
                        backgroundColor: mapCorruptionColor(
                          data?.total_wgi_data?.[0]?.[`corruption_${year}`]
                        ),
                      }}
                    ></div>
                    <div>
                      {Math.trunc(
                        data?.total_wgi_data?.[0]?.[`corruption_${year}`] * 100
                      ) / 100 || "*"}
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                <td>Level Of Law Enforcement</td>
                {years.map((year, idx) => (
                  <td key={idx}>
                    <div
                      className="circle"
                      style={{
                        backgroundColor: mapLawEnforcementColor(
                          data?.total_wgi_data?.[0]?.[`law_enforcement_${year}`]
                        ),
                      }}
                    ></div>
                    <div>
                      {Math.trunc(
                        data?.total_wgi_data?.[0]?.[`law_enforcement_${year}`] *
                        100
                      ) / 100 || "*"}
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                <td>Data Falsification Risk</td>
                {years.map((year, idx) => {
                  const dataFalsificationRisk = data?.total_wgi_data?.[0]?.[`data_falsification_risk_${year}`];
                  const formattedValue = dataFalsificationRisk != null
                    ? parseFloat(dataFalsificationRisk).toFixed(2)
                    : "*";

                  return (
                    <td key={idx}>
                      <div
                        className="circle"
                        style={{
                          backgroundColor: mapDataFalsificationColor(dataFalsificationRisk),
                        }}
                      ></div>
                      <div>{formattedValue}</div>
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>Human Rights</td>
                {years.map((year, idx) => {
                  const civilLibertiesIndex = data?.human_rights?.[idx]?.civil_liberties_index;
                  const formattedValue = civilLibertiesIndex != null
                    ? parseFloat(civilLibertiesIndex).toFixed(2)
                    : "*";

                  return (
                    <td key={idx}>
                      <div
                        className="circle"
                        style={{
                          backgroundColor: mapCorruptionColor(civilLibertiesIndex),
                        }}
                      ></div>
                      <div>{formattedValue}</div>
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>Armed Conflict Events</td>
                {years.map((year, idx) => {
                  const armedConflictEvents = data?.armed_conflicts?.[idx]?.events;
                  const formattedValue = armedConflictEvents != null
                    ? armedConflictEvents.toString()
                    : "*";

                  return (
                    <td key={idx}>
                      <div
                        className="circle"
                        style={{
                          backgroundColor: mapArmedConflitColor(armedConflictEvents),
                        }}
                      ></div>
                      <div>{formattedValue}</div>
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>Sanctions</td>
                {years.map((year, idx) => (
                  <td key={idx}>
                    <div
                      className="circle"
                      style={{
                        backgroundColor: mapSanctionsColor(
                          data?.sanctions?.[idx]?.number_of_sanctioned_entities
                        ),
                      }}
                    ></div>
                    <div>
                      {data?.sanctions?.[idx]?.number_of_sanctioned_entities ||
                        "*"}
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                <td>Risk Of Circumvention</td>
                {years.map((year, idx) => (
                  <td key={idx}>
                    <div
                      className="circle"
                      style={{ backgroundColor: "gray" }}
                    ></div>
                    <div></div>
                  </td>
                ))}
              </tr>
              <tr>
                <td>EU Designated Risk</td>
                {years.map((year, idx) => (
                  <td key={idx}>
                    <div
                      className="circle"
                      style={{ backgroundColor: "gray" }}
                    ></div>
                    <div></div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <div className="circle-legend ">
            <div className="circle green"></div> Low
            <div className="circle yellow"></div> Medium
            <div className="circle orange"></div> High
            <div className="circle red"></div> Very High
            <div className="circle gray"></div> In Development
          </div>
          <div className="no-data">* Data for 2023 is not yet available</div>
        </div>

        <div className="right-panel" style={{ marginTop: "26px" }}>
          <table>
            <thead>
              <tr>
                <th></th>
                {years.map((year, idx) => (
                  <th style={{ cursor: "pointer" }} key={idx} onClick={() => handleYearClick(idx)}>
                    {year}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Deforestation</td>
                {years.map((year, idx) => {
                  const deforestationData = deforestation[idx];
                  const hasDeforestation = deforestationData?.year === year ? "Yes" : "No";
                  return (
                    <td key={idx}>
                      {hasDeforestation}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>Main Driver</td>
                {years.map((year, idx) => {
                  const deforestationData = deforestation[idx];
                  const mainDriver = deforestationData?.deforestation_category || "Wildfire";
                  return <td key={idx}>{mainDriver}</td>;
                })}
              </tr>
            </tbody>
          </table>

          <div style={{ position: 'relative', minHeight: 500 }}>
            <StaticMap
              key={markerColor}
              bbox={bbox}
              center={latlng}
              circles={[]}
              layers={[]}
              onLayerLoad={() => { }}
              rasters={{}}
              zoom={mapZoom}
              markers={[{ center: latlng, color: markerColor, id: 'site-' + site.site_id }]}
              baseMap="terrain"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialIndicators;
