import * as React from "react";
import classnames from "classnames";

export default function SidebarLink(props: { active?: boolean; onClick: () => void; label: string }): JSX.Element {
    return (
        <div
            className={classnames("s-sidebar-link", {
                "s-sidebar-link-active": props.active,
            })}
            onClick={props.onClick}
        >
            {props.label}
        </div>
    );
}
