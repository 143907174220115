export const variables = {
  impacts: {
    ghg_emissions: "ghg_emissions",
    non_ghg_emissions: "non_ghg_emissions",
    soil_pollutants: "soil_pollutants",
    water_pollutants: "water_pollutants",
    freshwater_ecosystem_use: "freshwater_ecosystem_use",
    marine_ecosystem_use: "marine_ecosystem_use",
    terrestrial_ecosystem_use: "terrestrial_ecosystem_use",
    disturbances: "disturbances",
    biological_alterations_interferences:
      "biological_alterations_interferences",
    solid_waste: "solid_waste",
    other_resources_use: "other_resources_use",
    water_use: "water_use",
  },
};

export const impactCategories = {
  landDegradation: [
    variables.impacts.ghg_emissions,
    variables.impacts.soil_pollutants,
    variables.impacts.water_pollutants,
    variables.impacts.freshwater_ecosystem_use,
    variables.impacts.marine_ecosystem_use,
    variables.impacts.terrestrial_ecosystem_use,
    variables.impacts.disturbances,
    variables.impacts.biological_alterations_interferences,
  ],
  sealingSoil: [
    variables.impacts.solid_waste,
    variables.impacts.other_resources_use,
    variables.impacts.terrestrial_ecosystem_use,
  ],
  desertification: [
    variables.impacts.soil_pollutants,
    variables.impacts.disturbances,
    variables.impacts.biological_alterations_interferences,
    variables.impacts.terrestrial_ecosystem_use,
    variables.impacts.water_use,
  ],
  threatenedSpeciesAffected: [
    variables.impacts.soil_pollutants,
    variables.impacts.solid_waste,
    variables.impacts.terrestrial_ecosystem_use,
    variables.impacts.freshwater_ecosystem_use,
    variables.impacts.marine_ecosystem_use,
    variables.impacts.water_use,
    variables.impacts.other_resources_use,
    variables.impacts.disturbances,
    variables.impacts.biological_alterations_interferences,
  ],
};
