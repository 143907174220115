// Scenario Configuration
export const SCENARIOS = ["SSP1-2.6", "SSP2-4.5", "SSP5-8.5"];
export const SCENARIO_LABELS = {
    "SSP1-2.6": "1.5°C",
    "SSP2-4.5": "2.7°C",
    "SSP5-8.5": "4.3°C",
};

// Time Periods
export const FUTURE_TIMES = ["2035", "2050", "2100"];
export const ALL_TIMES = ["today", ...FUTURE_TIMES];

// Risk Classifications
export const RISK_ORDER = ["no data", "no risk", "low risk", "medium risk", "high risk", "red flag"];

// Risk Colors
export const RISK_COLORS = {
    "no data": "#6C7A89",
    "no risk": "#3DA46D",
    "low risk": "#3DA46D",
    "medium risk": "#F6BB43",
    "high risk": "#FF8C42",
    "red flag": "#F1654C",
    "not defined": "#6C7A89",
};

// Dimension Options
export const DIMENSION_OPTIONS = [
    { id: "temperature_related", label: "Temperature-Related" },
    { id: "wind_related", label: "Wind-Related" },
    { id: "water_related", label: "Water-Related" },
    { id: "solid_matter_related", label: "Solid Matter-Related" },
];
