import * as React from "react";
import { mdiShieldBugOutline } from "@mdi/js";
import tinycolor from "tinycolor2";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext, useProject, useTeam } from "@/context";
import { getHomeUrl, t } from "@/utils";
import { BreadcrumbPath, CopyrightNotice, HorizontalStack, LoadingAnimation, MasterHeader } from "@/lib";
import { updateSFDRReport } from "@/resources";
import ReportCard from "./ReportResultCard";
import SFDRReportTable from "./SFDRReportTable";

const DoughnutColorMap: Record<null | string, string> = {
    null: "#E6E6E6",
    "-1": "#DDD",
    "0": tinycolor("#BE98EE").spin(180).darken(10).toHexString(),
    "1": tinycolor("#BE98EE").spin(180).darken(15).toHexString(),
    "2": tinycolor("#BE98EE").spin(180).darken(20).toHexString(),
    "3": "#BE98EE",
    "4": tinycolor("#BE98EE").darken(10).toHexString(),
};

export default function SFDRReportPage(): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const navigate = useNavigate();
    const project = useProject();
    const team = useTeam();
    const params = useParams();
    const report = state.sfdr.reports[parseInt(params.reportId)];
    const isSmallLayout = ["xs", "sm", "md"].includes(state.layoutSize);
    const breadcrumb = [
        { label: t("ui.home"), route: getHomeUrl(team) },
        { label: t("ui.report_plural"), route: getHomeUrl(team) + "/reports" },
    ];

    const waterRiskTable = React.useMemo(() => {
        const version = report?.newest_version;

        if (version?.status !== "complete") {
            return [];
        }

        const rows = ["4", "3", "2", "1", "0", "-1", null].map((id) => ({
            label: t(id !== null ? "waterRisk." + id : "ui.unknown"),
            value: (version.sfdr_2_8.total_by_risk[id] || 0) / version.sfdr_2_8.total_count,
        }));

        if (rows[rows.length - 1].value === 0) {
            rows.pop(); // Drop "null" if there is no items in it
        }

        return rows;
    }, [params.reportId, report]);

    const degradationTable = React.useMemo(() => {
        const version = report?.newest_version;

        if (version?.status !== "complete") {
            return [];
        }

        return ["degradation", "desertification", "soil_sealing"].map((id) => ({
            label: t("ui." + id),
            value: ((version.sfdr_2_10.total_by_risk as Record<string, number>)[id] || 0) / version.sfdr_2_10.total_count,
        }));
    }, [params.reportId, report]);

    const protectedAreaTable = React.useMemo(() => {
        const version = report?.newest_version;

        if (version?.status !== "complete") {
            return [];
        }

        return ["protected_area", "primary_forest", "grassland"].map((id) => ({
            label: t("ui." + id),
            value: ((version.sfdr_2_14.total_by_risk as Record<string, number>)[id] || 0) / version.sfdr_2_14.total_count,
        }));
    }, [params.reportId, report]);

    React.useEffect(() => {
        document.title = `${report?.name} — ${project.name} — kuyua`;
    }, [params.reportId, report]);

    React.useEffect(() => {
        updateSFDRReport(state, dispatch, parseInt(params.reportId));
    }, [params.reportId]);

    if (report?.newest_version.status !== "complete") {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                    width: "100%",
                }}
            >
                <LoadingAnimation />
            </div>
        );
    }

    breadcrumb.push({
        label: report.name,
        route: getHomeUrl(team) + `/reports/sfdr/${params.reportId}`,
    });

    const version = report?.newest_version;
    const bd = version.sfdr_1_7;
    const wr = version.sfdr_2_8;
    const pa = version.sfdr_2_14;
    const deg = version.sfdr_2_10;
    const bdSensitiveSegments = [
        { color: "#E3E3E3", id: "n", value: bd.total_count - bd.at_risk_count },
        { color: DoughnutColorMap["4"], id: "y", value: bd.at_risk_count },
    ];
    const sizes = (
        {
            md: ["100%", "50%", "50%"],
            sm: ["100%", "100%", "100%"],
            xs: ["100%", "100%", "100%"],
        } as Record<string, string[]>
    )[state.layoutSize] || ["40%", "30%", "30%"];

    return (
        <div style={{ padding: "0 2rem 2rem 2rem" }}>
            <BreadcrumbPath items={breadcrumb} style={{ marginTop: "1rem" }} onItemClick={(item) => navigate(item.route)} />
            <MasterHeader
                style={{
                    marginTop: isSmallLayout ? "60px" : "40px",
                    marginBottom: ".5rem",
                }}
                text={report.name}
            />

            <HorizontalStack spacing="none" style={{ width: "100%" }} verticalAlign="flex-start" wrap={true}>
                <ReportCard
                    iconPath={mdiShieldBugOutline}
                    onClick={() => navigate("bd")}
                    segments={bdSensitiveSegments}
                    style={{
                        paddingRight: sizes[0] === "100%" ? 0 : "2rem",
                        width: sizes[0],
                    }}
                    table={protectedAreaTable}
                    title={`${bd.at_risk_count} of ${bd.total_count} sites with activities negatively affecting biodiversity-sensitive areas`}
                    text={`${pa.at_risk_count} of ${pa.total_count} sites with natural species and protected areas`}
                />

                <ReportCard
                    onClick={() => navigate("water")}
                    style={{
                        marginTop: "15px",
                        paddingRight: sizes[1] === "100%" ? 0 : "1rem",
                        width: sizes[1],
                    }}
                    table={waterRiskTable}
                    title={`${wr.at_risk_count} of ${wr.total_count} sites operating in areas of high water risk`}
                />

                <ReportCard
                    onClick={() => navigate("pa")}
                    style={{
                        marginTop: "15px",
                        paddingLeft: sizes[2] === "100%" ? 0 : "1rem",
                        width: sizes[2],
                    }}
                    table={degradationTable}
                    title={`${deg.at_risk_count} of ${deg.total_count} sites with activities which causing land degradation, desertification or soil sealing`}
                />
            </HorizontalStack>

            <SFDRReportTable report={report} reportId={params.report_id} />

            <CopyrightNotice />
        </div>
    );
}
