import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import * as React from "react";
import mapboxgl, { LngLatBoundsLike } from "mapbox-gl";
import { mdiFullscreen, mdiFullscreenExit } from "@mdi/js";
import { apiGet, isEqual, truncate } from "@/utils";
import { AppContext, ToastAdded } from "@/context";
import { setInteractive } from "./utils";
import { SatelliteStyleUri, TerrainStyleUri } from "./constants";
import MapZoomPicker from "./MapZoomPicker";
import "mapbox-gl/dist/mapbox-gl.css";
import { colors } from "@/utils/style";
import MapLegends from "./MapLegends";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import { calculatePolygonArea } from "@/utils/calculatePolygonArea";

interface IMapCircle {
    center: ILatLng;
    id: string;
    radius: number;
}

interface IStaticMapProps {
    bbox?: number[];
    center?: ILatLng;
    circles?: IMapCircle[];
    interactive?: boolean;
    layers: string[];
    markers?: {
        center: ILatLng;
        color: string;
        id: string;
    }[];
    onClick?: (e: ILatLng) => void;
    onDoubleClick?: (e: ILatLng) => void;
    onFullscreenChange?: (enabled: boolean) => void;
    onLayerLoad?: (id: string, layer: IKeyAreaLayer) => void;
    onPinClick?: (pin: IMapPin) => void;
    rasters: Record<string, IProjectRasterConfig>;
    roundedCorners?: boolean;
    pins?: IMapPin[];
    zoom: number;
    baseMapUri?: string;
    left?: boolean;
    right?: boolean;
    unsetZoom?: boolean;
    hideControls?: boolean;
    unsetZoomHandler?: () => void;
    protectedAreas?: IKeyArea[];
    isPolygon?: boolean;
    fullScreen?: boolean;
    polygonHandler?: (
        polygonData: string,
        polygonCenter: [number, number],
        locationDetails: {
            zipcode: string;
            country: string;
            city: string;
            street: string;
        },
        area: number,
    ) => void;
    drawnPolygon?: turf.Feature<turf.Geometry, { [name: string]: any }>;
    closePolygon?: () => void;
    baseMap?: "satellite" | "terrain";
}

interface IStaticMapState {
    basemap: string;
    bbox?: number[];
    boundMapClick: (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => void;
    boundMapIdle: () => void;
    boundMapLoad: () => void;
    boundMapMouseOver: () => void;
    boundMapZoom: () => void;
    boundStyleDataChange: () => void;
    center: ILatLng;
    circles: Map<
        string,
        {
            circle: IMapCircle;
            datasource: mapboxgl.AnySourceData;
        }
    >;
    clickCount: number;
    fullscreen: boolean;
    interactive: boolean;
    initialized: boolean;
    layerIds: string[];
    map: mapboxgl.Map;
    mapId: string;
    markers: Record<string, mapboxgl.Marker>;
    pins: Map<
        string,
        {
            content: Node;
            label: Node;
            marker: mapboxgl.Marker;
            pin: IMapPin;
        }
    >;
    zoom?: number;
    hideControls?: boolean;
    suggestions: ISuggestion[];
    searchContainer?: HTMLElement;
    searchMarker?: mapboxgl.Marker;
}

class PortalControl {
    _map: mapboxgl.Map;
    _container: HTMLElement;
    _corner: string;
    _id: string;

    constructor(id: string, corner: string) {
        this._id = id;
        this._corner = corner;
    }

    onAdd(map: mapboxgl.Map): HTMLElement {
        const c = document.createElement("div");

        c.id = this._id;
        this._map = map;
        this._container = c;

        return this._container;
    }

    onRemove(): void {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

const pinCenterIsMapCenter = (pinCenter: ILatLng, mapCenter: ILatLng | undefined) => {
    if (!mapCenter?.lat || !mapCenter?.lng || !pinCenter?.lng || !pinCenter?.lat) return;

    const pinLat = pinCenter.lat.toFixed(2);
    const pinLng = pinCenter.lng.toFixed(2);
    const mapLat = mapCenter.lat.toFixed(2);
    const mapLng = mapCenter.lng.toFixed(2);
    return mapLat === pinLat && mapLng === pinLng;
};

function createCircle(center: ILatLng, radiusInKm: number, points = 64): mapboxgl.AnySourceData {
    const output = [];
    const dx = radiusInKm / (111.32 * Math.cos((center.lat * Math.PI) / 180));
    const dy = radiusInKm / 110.574;
    let theta, x, y;

    for (let i = 0; i < points; i += 1) {
        theta = (i / points) * (2 * Math.PI);
        x = dx * Math.cos(theta);
        y = dy * Math.sin(theta);

        output.push([center.lng + x, center.lat + y]);
    }

    output.push(output[0]);

    return {
        type: "geojson",
        data: {
            type: "FeatureCollection",
            features: [
                {
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "Polygon",
                        coordinates: [output],
                    },
                },
            ],
        },
    };
}

export const KeyBiodiversityAreaColor = "red";
export const Natura2000AreaColor = "blue";
export const SpeciesColor = "#32A852";

const PinLabelMinZoomLevel = 6;
let formerCenterPinId: string;

function getLayerPaintStyle(id: string) {
    let color = SpeciesColor;
    let opacity = 0.66;

    if (/^N2k/.test(id)) {
        color = Natura2000AreaColor;
        opacity = 0.33;
    }

    return {
        "fill-color": color,
        "fill-opacity": opacity,
        "fill-outline-color": "transparent",
    };
}

const SpeciesCirclePaint: mapboxgl.CirclePaint = {
    "circle-radius": 4,
    "circle-color": "#6F3782",
    "circle-stroke-color": "white",
    "circle-stroke-width": 1,
    "circle-opacity": 0.75,
};

class ClickableMarker extends mapboxgl.Marker {
    _clickHandler: () => void;

    onClick(handler: () => void) {
        this._clickHandler = handler;
        return this;
    }

    _onMapClick(e: mapboxgl.MapMouseEvent) {
        const targetEl = e.originalEvent.target as HTMLElement;
        const el = (this as unknown as { _element: HTMLElement })._element;

        if (this._clickHandler && (targetEl === el || el.contains(targetEl))) {
            this._clickHandler();
        }
    }
}

// Define common styles
const commonButtonStyles = {
    position: "absolute",
    zIndex: "10001",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "12px",
    cursor: "pointer",
    backgroundColor: colors.darkBlue,
    color: "white",
    fontSize: "15px",
};

const searchInputStyles = {
    width: "300px",
    padding: "10px 15px",
    border: "none",
    borderRadius: "25px",
    outline: "none",
    fontSize: "16px",
    color: "#ccc",
    backgroundColor: "transparent",
};

const searchSuggestionsStyles = {
    position: "absolute",
    top: "100%",
    left: "0",
    right: "0",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
    maxHeight: "200px",
    overflowY: "auto",
    zIndex: 10002,
};

const suggestionItemStyles = {
    padding: "10px 15px",
    cursor: "pointer",
    borderBottom: "1px solid #eee",
    ":hover": {
        backgroundColor: "#f5f5f5",
    },
    color: colors.black,
};

interface ISuggestion {
    place_name: string;
    center: [number, number];
}

export default class StaticMap extends React.Component<IStaticMapProps, IStaticMapState> {
    static contextType = AppContext;
    context: React.ContextType<typeof AppContext>;

    constructor(props: IStaticMapProps) {
        super(props);
        this.state = this.initializeState(props);

        // Bind event handlers
        this.onMapClick = this.onMapClick.bind(this);
        this.onMapIdle = this.onMapIdle.bind(this);
        this.onMapLoad = this.onMapLoad.bind(this);
        this.onMapZoom = this.onMapZoom.bind(this);
        this.onMapMouseOver = this.onMapMouseOver.bind(this);
        this.onStyleDataChange = this.onStyleDataChange.bind(this);
    }

    initializeState(props: IStaticMapProps): IStaticMapState {
        return {
            basemap: props.baseMap === "terrain" ? TerrainStyleUri : SatelliteStyleUri,
            bbox: props.bbox,
            boundMapClick: undefined,
            boundMapIdle: undefined,
            boundMapLoad: undefined,
            boundMapMouseOver: undefined,
            boundMapZoom: undefined,
            boundStyleDataChange: undefined,
            center: props.center,
            circles: new Map(),
            clickCount: 0,
            fullscreen: props.fullScreen || false,
            interactive: props.interactive || false,
            initialized: false,
            layerIds: [],
            map: null,
            mapId: "map-" + new Date().getTime(),
            markers: {},
            pins: new Map(),
            zoom: props.zoom,
            hideControls: props.hideControls,
            unsetZoom: props.unsetZoom,
            unsetZoomHandler: props.unsetZoomHandler,
            suggestions: [],
        };
    }

    async componentDidMount() {
        const { state, dispatch } = this.context;
        mapboxgl.accessToken =
            state.config?.mapbox_public_api_token ||
            "pk.eyJ1IjoiYW5kcmUtaGlja21hbm4iLCJhIjoiY2xoNjR4enBkMDE3cjNqcGc0aG93ZzlueSJ9.JH3ClP3oIf2uvc4ZpFvjJQ";

        const map = this.initializeMap();
        this.addPolygonDrawingControls(map);
        this.addEventListeners(map);

        this.setState({
            ...this.state,
            map,
            boundMapClick: this.onMapClick.bind(this),
            boundMapIdle: this.onMapIdle.bind(this),
            boundMapLoad: this.onMapLoad.bind(this),
            boundMapZoom: this.onMapZoom.bind(this),
            boundMapMouseOver: this.onMapMouseOver.bind(this),
            boundStyleDataChange: this.onStyleDataChange.bind(this),
        });
    }

    initializeMap(): mapboxgl.Map {
        return new mapboxgl.Map({
            attributionControl: false,
            center: this.props.center,
            container: this.state.mapId,
            interactive: false,
            localFontFamily: "Roobert",
            style: this.state.basemap,
            zoom: this.props.zoom,
        });
    }

    addPolygonDrawingControls(map: mapboxgl.Map) {
        if (this.props.isPolygon) {
            const draw = new MapboxDraw({
                displayControlsDefault: false,
                controls: { polygon: true, trash: true },
                touchEnabled: true,
                boxSelect: true,
                defaultMode: "draw_polygon",
                styles: [
                    // Vertex point style
                    {
                        id: "gl-draw-polygon-and-line-vertex-active",
                        type: "circle",
                        filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                        paint: {
                            "circle-radius": 6,
                            "circle-color": "#fff",
                            "circle-stroke-color": "#3388ff",
                            "circle-stroke-width": 2,
                        },
                    },
                    // Midpoint style
                    {
                        id: "gl-draw-polygon-and-line-midpoint",
                        type: "circle",
                        filter: ["all", ["==", "meta", "midpoint"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                        paint: {
                            "circle-radius": 4,
                            "circle-color": "#fbb03b",
                            "circle-stroke-color": "#fff",
                            "circle-stroke-width": 1,
                        },
                    },
                    // Line style
                    {
                        id: "gl-draw-line",
                        type: "line",
                        filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                        layout: {
                            "line-cap": "round",
                            "line-join": "round",
                        },
                        paint: {
                            "line-color": "#3388ff",
                            "line-width": 2,
                        },
                    },
                    // Polygon fill
                    {
                        id: "gl-draw-polygon-fill",
                        type: "fill",
                        filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                        paint: {
                            "fill-color": "#3388ff",
                            "fill-outline-color": "#3388ff",
                            "fill-opacity": 0.1,
                        },
                    },
                    // Polygon outline
                    {
                        id: "gl-draw-polygon-stroke",
                        type: "line",
                        filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                        layout: {
                            "line-cap": "round",
                            "line-join": "round",
                        },
                        paint: {
                            "line-color": "#3388ff",
                            "line-width": 2,
                        },
                    },
                ],
            });
            map.addControl(new mapboxgl.NavigationControl(), "bottom-right");
            const drawControl = map.addControl(draw, "bottom-left");

            const drawControlContainer = document.querySelector(".mapboxgl-ctrl-bottom-left");
            if (drawControlContainer) {
                (drawControlContainer as HTMLElement).style.left = "10px";
            }

            const navigationControlContainer = document.querySelector(".mapboxgl-ctrl-bottom-right");
            if (navigationControlContainer) {
                (navigationControlContainer as HTMLElement).style.right = "10px";
                (navigationControlContainer as HTMLElement).style.bottom = "30px";
            }

            // Event handler for when a point is added during drawing
            map.on("draw.create", (e) => {
                const features = draw.getAll().features;
                if (features.length > 1) {
                    // Keep only the most recently drawn polygon
                    features.slice(0, -1).forEach((feature) => {
                        draw.delete(feature.id);
                    });
                }
                // Show confirm button if we have at least 3 points
                const currentFeature = features[0];
                if (currentFeature && currentFeature.geometry.type === "Polygon" && currentFeature.geometry.coordinates[0].length >= 4) {
                    // 4 points because the first point is repeated
                    this.showConfirmButton();
                }
            });

            // Event handler for when points are updated
            map.on("draw.update", (e) => {
                const features = draw.getAll().features;
                if (features.length === 0) {
                    this.hideConfirmButton();
                    return;
                }

                const currentFeature = features[0];
                if (currentFeature && currentFeature.geometry.type === "Polygon" && currentFeature.geometry.coordinates[0].length >= 4) {
                    this.showConfirmButton();
                    this.updatePolygonCenter(e);
                } else {
                    this.hideConfirmButton();
                }
            });

            // Event handler for mode changes
            map.on("draw.modechange", (e: { mode: string }) => {
                if (e.mode === "draw_polygon") {
                    this.hideConfirmButton();
                }
            });

            // Event handler for when a polygon is deleted
            map.on("draw.delete", () => {
                this.hideConfirmButton();
            });

            // Event handler for when a polygon is selected
            map.on("draw.selectionchange", (e) => {
                if (e.features && e.features.length > 0) {
                    const feature = e.features[0];
                    if (feature.geometry.type === "Polygon" && feature.geometry.coordinates[0].length >= 4) {
                        this.showConfirmButton();
                        this.updatePolygonCenter(e);
                    }
                } else {
                    this.hideConfirmButton();
                }
            });

            // Final polygon handler
            const finalizePolygon = (e: any) => {
                const polygon = draw.getAll().features[0];
                if (!polygon) return;

                const coords = polygon.geometry.coordinates[0];
                if (coords.length < 4) return; // Need at least 3 points for a valid polygon (4 including the closing point)

                // Only process if we have a valid closed polygon
                if (coords[0][0] === coords[coords.length - 1][0] && coords[0][1] === coords[coords.length - 1][1]) {
                    const polygonData = JSON.stringify(polygon);
                    const center = turf.center(polygon).geometry.coordinates;
                    const area = calculatePolygonArea(polygon as turf.Feature<turf.Polygon>);
                    this.props.polygonHandler?.(polygonData, [center[0], center[1]], null, area);
                }
            };

            // Add event listeners for both create and update events
            map.on("draw.create", finalizePolygon);
            map.on("draw.update", finalizePolygon);

            this.addSearchControls(map, draw);
        }
    }

    addSearchControls(map: mapboxgl.Map, draw: MapboxDraw) {
        const searchContainer = this.createSearchContainer();
        const searchInput = this.createSearchInput();
        const confirmButton = this.createConfirmButton(draw);
        const closeButton = this.createCloseButton(draw);

        searchContainer.appendChild(searchInput);
        document.body.appendChild(searchContainer);
        document.body.appendChild(confirmButton);
        document.body.appendChild(closeButton);
    }

    createSearchContainer(): HTMLElement {
        const container = document.createElement("div");
        container.id = "search-container-" + new Date().getTime();
        Object.assign(container.style, {
            position: "absolute",
            zIndex: "10001",
            top: "120px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "transparent",
            padding: "5px",
            borderRadius: "25px",
            boxShadow: "0 1px 3px rgba(0,0,0,0.3)",
            border: "1px solid #ccc",
        });
        return container;
    }

    createSearchInput(): HTMLInputElement {
        const input = document.createElement("input");
        input.type = "text";
        input.placeholder = "Type an address to find a location...";
        Object.assign(input.style, searchInputStyles);

        input.addEventListener(
            "input",
            debounce(async (e: Event) => {
                const target = e.target as HTMLInputElement;
                if (target.value.length >= 3) {
                    const suggestions = await this.getAddressSuggestions(target.value);
                    this.showSuggestions(suggestions, input);
                } else {
                    this.clearSuggestions();
                }
            }, 300),
        );

        input.addEventListener("keydown", (e: KeyboardEvent) => {
            const suggestionsDiv = document.querySelector(".search-suggestions");
            if (!suggestionsDiv) return;

            const suggestions = suggestionsDiv.querySelectorAll(".suggestion-item");
            const activeItem = suggestionsDiv.querySelector(".suggestion-item.active");
            let activeIndex = Array.from(suggestions).indexOf(activeItem);

            switch (e.key) {
                case "ArrowDown":
                    e.preventDefault();
                    activeIndex = Math.min(activeIndex + 1, suggestions.length - 1);
                    this.updateActiveSuggestion(suggestions, activeIndex);
                    break;
                case "ArrowUp":
                    e.preventDefault();
                    activeIndex = Math.max(activeIndex - 1, 0);
                    this.updateActiveSuggestion(suggestions, activeIndex);
                    break;
                case "Enter":
                    e.preventDefault();
                    if (activeItem) {
                        const suggestion = this.state.suggestions[activeIndex];
                        this.selectSuggestion(suggestion, input);
                    }
                    break;
                case "Escape":
                    this.clearSuggestions();
                    break;
            }
        });

        return input;
    }

    createConfirmButton(draw: MapboxDraw): HTMLButtonElement {
        const button = document.createElement("button");
        button.id = "confirmPolygonButton";
        button.textContent = "Confirm Polygon";
        Object.assign(button.style, commonButtonStyles, { display: "none" });

        button.onclick = async () => {
            alert("Polygon confirmed!");
            this.hideConfirmButton();

            // Remove search container and close button
            const searchContainer = document.querySelector('[id^="search-container"]');
            const closeButton = document.getElementById("closePolygonButton");
            if (searchContainer) {
                searchContainer.remove();
            }
            if (closeButton) {
                closeButton.remove();
            }
            this.props.closePolygon?.();
        };

        return button;
    }

    createCloseButton(draw: MapboxDraw): HTMLButtonElement {
        const button = document.createElement("button");
        button.id = "closePolygonButton";
        button.textContent = "Close";
        Object.assign(button.style, commonButtonStyles, {
            top: "120px",
            right: "20px",
            backgroundColor: colors.red,
            zIndex: "10001",
        });

        button.onclick = () => {
            // Remove all drawn features
            draw.deleteAll();

            // Remove search container and other elements
            const searchContainer = document.querySelector('[id^="search-container"]');
            const confirmButton = document.getElementById("confirmPolygonButton");
            if (searchContainer) searchContainer.remove();
            if (confirmButton) confirmButton.remove();
            button.remove();

            // Reset the draw instance
            draw.changeMode("draw_polygon");

            // Call the closePolygon prop if it exists
            this.props.closePolygon?.();
        };

        return button;
    }

    async performSearch(input: HTMLInputElement, map: mapboxgl.Map) {
        const address = input.value;
        const location = await this.searchAddress(address, mapboxgl.accessToken);
        if (location) {
            map.flyTo({ center: location, zoom: 14 });
        }
    }

    updatePolygonCenter(e: any) {
        const polygon = e.features[0];
        if (!polygon) return;

        // Calculate the centroid of the polygon
        const centroid = turf.centroid(polygon);

        // Convert geographic coordinates to pixel coordinates
        const point = this.state.map.project(centroid.geometry.coordinates);

        const container = document.getElementById("confirmPolygonButton");

        if (container) {
            // Position the container next to the polygon
            Object.assign(container.style, {
                position: "absolute",
                left: `${point.x + 20}px`, // 20px offset from the polygon
                top: `${point.y}px`,
                transform: "translateY(-50%)", // Center vertically
                zIndex: "10002",
            });
        }
    }

    showConfirmButton() {
        const confirmButton = document.getElementById("confirmPolygonButton");
        if (confirmButton) {
            // Ensure button is visible and has a high z-index
            Object.assign(confirmButton.style, {
                display: "block",
                zIndex: "10002",
                opacity: "1",
                transition: "opacity 0.2s ease-in-out",
            });
        }
    }

    hideConfirmButton() {
        const confirmButton = document.getElementById("confirmPolygonButton");
        if (confirmButton) {
            // Fade out the button
            confirmButton.style.opacity = "0";
            setTimeout(() => {
                if (confirmButton) {
                    confirmButton.style.display = "none";
                }
            }, 200);
        }
    }

    addEventListeners(map: mapboxgl.Map) {
        map.on("click", this.onMapClick);
        map.on("idle", this.onMapIdle);
        map.on("load", this.onMapLoad);
        map.on("zoomend", this.onMapZoom);
        map.on("mouseover", this.onMapMouseOver);
        map.on("styledata", this.onStyleDataChange);
        window.addEventListener("resize", this.onMapIdle);
    }

    onMapLoad(): void {
        const { bbox, center, interactive, map, zoom, hideControls } = this.state;

        if (!map) {
            return;
        }

        if (!hideControls) {
            map.addControl(new mapboxgl.AttributionControl({ compact: true }), "top-left");
            map.addControl(new PortalControl("map-zoom-control", "bottom-right"), "bottom-right");
        }

        setInteractive(map, interactive);

        this.setState({ ...this.state, initialized: true });

        if (bbox) {
            map.fitBounds(bbox as LngLatBoundsLike);
        } else if (center) {
            map.setCenter(center);
            map.setZoom(zoom);
        }

        this.forceUpdate();
    }

    onMapIdle(): void {
        this.state.map?.resize();
    }

    onMapClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData): void => {
        if (!this.state.interactive) {
            this.setState({ ...this.state, interactive: true });
            setInteractive(this.state.map, true);
        }

        // Hold for double click

        window.setTimeout(() => {
            if (this.state.clickCount === 1) {
                this.props.onClick?.(e.lngLat);
            }

            this.setState({ ...this.state, clickCount: 0 });
        }, 300);

        if (this.state.clickCount === 1) {
            this.props.onDoubleClick?.(e.lngLat);
        }

        this.setState({ ...this.state, clickCount: this.state.clickCount + 1 });
    };

    onStyleDataChange(): void {
        this.componentDidUpdate();
    }

    onMapMouseOver(): void {
        if (!this.state.initialized || this.state.interactive) {
            return;
        }

        const wrapper = document.querySelector("#" + this.state.mapId);
        let help = wrapper?.querySelector("#" + this.state.mapId + "-activation-help") as HTMLDivElement;

        if (!wrapper || help) {
            return;
        }

        help = document.createElement("div");

        help.id = this.state.mapId + "-activation-help";
        help.textContent = "Click on the map to activate it";
        help.style.pointerEvents = "none";
        help.style.fontSize = ".85rem";
        help.style.background = "rgba(255, 255, 255, .75)";
        help.style.borderRadius = ".5rem";
        help.style.padding = ".5rem 2rem";
        help.style.zIndex = "20";
        help.style.position = "absolute";
        help.style.bottom = ".25rem";
        help.style.left = "50%";
        help.style.transform = "translate(-50%, -50%)";

        wrapper.appendChild(help);
        window.setTimeout(() => help.parentElement?.removeChild(help), 1500);
    }

    onMapZoom(): void {
        const { map, pins } = this.state;

        if (!map) {
            return;
        }

        if (PinLabelMinZoomLevel <= map.getZoom()) {
            for (const pin of pins.values()) {
                if (pin.label && !pin.label.parentNode) {
                    pin.content.appendChild(pin.label);
                }
            }
        } else {
            for (const pin of pins.values()) {
                if (pin.label && pin.label.parentNode) {
                    pin.content.removeChild(pin.label);
                }
            }
        }
    }

    componentWillUnmount(): void {
        const { boundMapClick, boundMapIdle, boundMapLoad, boundMapMouseOver, boundMapZoom, map, searchMarker } = this.state;

        if (map) {
            map.off("click", boundMapClick);
            map.off("idle", boundMapIdle);
            map.off("load", boundMapLoad);
            map.off("zoom", boundMapZoom);
            map.off("mouseover", boundMapMouseOver);

            window.removeEventListener("resize", boundMapIdle);
        }

        // Remove search marker when component unmounts
        if (searchMarker) {
            searchMarker.remove();
        }
    }

    getProjectSlug() {
        return document.location.pathname.split("/")[2];
    }

    recenterMap() {
        const { bbox, center, zoom, unsetZoom } = this.props;
        const map = this.state.map;

        if (map && bbox && !isEqual(bbox, this.state.bbox)) {
            this.setState({ ...this.state, bbox, center });
            map.fitBounds(bbox as LngLatBoundsLike);
        } else if (map && center && !isEqual(center, this.state.center)) {
            this.setState({ ...this.state, bbox, center, zoom });
            map.flyTo({ center, zoom });
        } else if (unsetZoom) {
            map.flyTo({ center, zoom });
            this.props.unsetZoomHandler();
        }
    }

    syncRasterLayers() {
        const map = this.state.map;
        const token = this.context.state.config?.tileserver_api_token;
        const addArgs = (cmap: string, url: string) =>
            `${url}${url.indexOf("?") >= 0 ? "&" : "?"}${cmap ? "colormap=" + cmap : ""}&token=${token}`;
        const ids = Array.from(Object.keys(this.props.rasters || {}));
        const layers = map.getStyle().layers;
        let firstSymbolId: string = undefined;

        for (const lyr of layers) {
            if (lyr.type === "symbol") {
                firstSymbolId = lyr.id;
                break;
            }
        }

        layers.forEach((lyr) => {
            if (lyr.type === "raster" && lyr.source !== "mapbox" && lyr.source !== "mapbox://mapbox.satellite" && !ids.includes(lyr.id)) {
                map.removeLayer(lyr.id);
                map.removeSource(lyr.id);
            }
        });

        Object.values(this.props.rasters || {}).forEach((r) => {
            if (!map.getSource(r.id)) {
                if (r.url.indexOf("mapbox") === 0) {
                    map.addSource(r.id, { type: "raster", tileSize: 256, url: r.url });
                    map.addLayer({ id: r.id, source: r.id, type: "raster" }, "water");
                } else {
                    map.addSource(r.id, {
                        type: "raster",
                        tiles: [r.url],
                        tileSize: r.tilesize || 256,
                    });
                    map.addLayer({ id: r.id, type: "raster", source: r.id }, firstSymbolId);
                }
            }
        });
    }

    syncProtectedAreas() {
        const { map } = this.state;

        if (!map) {
            return;
        }

        const polygonData = {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        properties: {},
                        geometry: {
                            type: "Polygon",
                            coordinates: this.props.protectedAreas.map((area) => area.coordinates),
                        },
                    },
                ],
            },
        };

        if (!map.getSource("polygon-protected-area")) {
            map.addSource("polygon-protected-area", polygonData as mapboxgl.AnySourceData);
            map.addLayer({
                id: "polygon-protected-area",
                type: "fill",
                source: "polygon-protected-area",
                paint: {
                    "fill-color": "red",
                    "fill-opacity": 0.7,
                },
            });
        }
    }

    syncLayers() {
        const { layerIds, map } = this.state;
        const team = this.context.state.config?.team;
        let changed = false;

        if (!map) {
            return;
        }

        for (let i = layerIds.length - 1; i >= 0; i -= 1) {
            const id = layerIds[i];

            if (!this.props.layers.includes(id) && map.getSource(id)) {
                changed = true;

                map.removeLayer(id);
                map.removeSource(id);
                layerIds.splice(i, 1);
            }
        }

        for (let i = 0; i < this.props.layers.length; i += 1) {
            const id = this.props.layers[i];

            if (layerIds.includes(id)) {
                continue;
            }

            layerIds.push(id);

            changed = true;

            apiGet<IKeyAreaLayer>(team.slug, `projects/${this.getProjectSlug()}/maps/${id}`).then((reply) => {
                if (!reply?.ok) {
                    return;
                }

                const { geometry, properties } = reply.data;

                if (!map.getSource(id)) {
                    map.addSource(id, geometry);
                }

                if (properties.kind === "points") {
                    map.addLayer({
                        id: id,
                        type: "circle",
                        source: id,
                        paint: SpeciesCirclePaint,
                    });
                } else {
                    map.addLayer({
                        id: id,
                        type: "fill",
                        source: id,
                        paint: getLayerPaintStyle(id),
                    });
                }

                for (const id of Object.keys(this.state.markers)) {
                    if (map.getLayer(id + "-inner-circle")) {
                        map.moveLayer(id + "-inner-circle");
                    }

                    if (map.getLayer(id + "-outer-circle")) {
                        map.moveLayer(id + "-outer-circle");
                    }
                }

                this.props.onLayerLoad?.(id, reply.data);
            });
        }

        if (changed) {
            this.setState({ ...this.state, layerIds });
        }
    }

    syncMarkers() {
        const { map, markers } = this.state;

        if (!map) {
            return;
        }

        const ids = this.props.markers?.map((m) => m.id) || [];
        let changed = false;

        for (const id of Object.keys(markers)) {
            if (!ids.includes(id)) {
                changed = true;
                markers[id].remove();
                delete markers[id];
            }
        }

        for (const m of this.props.markers || []) {
            if (!markers[m.id]) {
                changed = true;
                markers[m.id] = new ClickableMarker({ color: m.color || "black" }).setLngLat([m.center.lng, m.center.lat]).addTo(map);
            }
        }

        if (changed) {
            this.setState({ ...this.state, markers });
        }
    }

    syncPins() {
        const { map, pins } = this.state;

        if (!map) {
            return;
        }

        const zoom = map.getZoom();
        const showPinLabels = zoom >= PinLabelMinZoomLevel;
        const pinIds = this.props.pins?.map((p) => p.id) || [];

        for (const id of pins.keys()) {
            if (!pinIds.includes(id)) {
                const pin = pins.get(id);

                pin.marker.remove();
                pins.delete(id);
            }
        }

        let newCenterPinId: string;
        for (const pin of this.props.pins || []) {
            const pinIsNewCenter = pinCenterIsMapCenter(pin.center, this.props.center);
            const pinIsFormerCenterPin = formerCenterPinId === pin.id;
            if (pinIsNewCenter && !pin.hasOwnProperty("color")) {
                const marker = new mapboxgl.Marker({
                    color: pin.isPriorityLocation ? colors.purple : colors.brightBlue,
                })
                    .setLngLat(pin.center)
                    .on("click", () => this.props.onPinClick?.(pin))
                    .addTo(map);

                const formerCenterPin = document.getElementById("centerPin");

                if (formerCenterPin) {
                    formerCenterPin.remove();
                }
                marker.getElement().id = "centerPin";
                pins.set(pin.id, { pin, content: undefined, label: null, marker });
                newCenterPinId = pin.id;
            } else if (!pins.get(pin.id) || pinIsFormerCenterPin) {
                const wrapper = document.createElement("div");
                const content = document.createElement("div");
                const circle = document.createElement("span");
                const innerCircle = document.createElement("span");
                const label = document.createElement("span");
                const marker = new mapboxgl.Marker(wrapper).setLngLat(pin.center).addTo(map);

                wrapper.classList.add("s-site-pin");

                wrapper.appendChild(content);

                label.textContent = truncate(pin.text, 30);

                content.addEventListener("click", (e: MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.props.onPinClick?.(pin);
                });

                circle.classList.add("s-site-pin-circle");
                innerCircle.classList.add("s-site-pin-circle-inner");
                pin.isPriorityLocation && innerCircle.classList.add("purple-circle");
                content.appendChild(circle);
                content.appendChild(innerCircle);

                pins.set(pin.id, { pin, content, label, marker });
            }
        }
        formerCenterPinId = newCenterPinId;
    }

    syncCircles() {
        const { circles, map } = this.state;

        if (!map) {
            return;
        }

        const circleIds = this.props.circles?.map((p) => p.id) || [];

        for (const id of circles.keys()) {
            if (!circleIds.includes(id)) {
                if (map.getLayer(id)) {
                    map.removeLayer(id);
                    map.removeSource(id);
                }

                circles.delete(id);
            }
        }

        for (const circle of this.props.circles || []) {
            if (!circles.get(circle.id)) {
                const datasource = createCircle(circle.center, circle.radius / 1000);

                if (!map.getSource(circle.id)) {
                    map.addSource(circle.id, datasource as mapboxgl.AnySourceData);
                    map.addLayer({
                        id: circle.id,
                        type: "fill",
                        source: circle.id,
                        paint: {
                            "fill-outline-color": "red",
                            "fill-color": "transparent",
                            "fill-opacity": 1,
                        },
                    });

                    circles.set(circle.id, { circle, datasource });
                }
            }
        }
    }

    componentDidUpdate(prevProps?: IStaticMapProps) {
        const { initialized, map, fullscreen } = this.state;

        if (prevProps && prevProps.baseMapUri !== this.props.baseMapUri) {
            setInteractive(this.state.map, true);

            this.setState({
                ...this.state,
                basemap: this.props.baseMapUri,
                circles: new Map(),
                interactive: true,
                layerIds: [],
                markers: {},
            });
            this.state.map.setStyle(this.props.baseMapUri);
        }

        if (!map || !initialized) {
            return;
        }

        if (fullscreen) {
            const sourceId = "drawn-polygon";
            if (map.getSource(sourceId)) {
                map.removeLayer(sourceId);
                map.removeSource(sourceId);
            }
        }

        this.syncRasterLayers();
        this.syncLayers();
        this.syncMarkers();
        this.syncCircles();
        this.syncPins();
        this.props.protectedAreas && this.syncProtectedAreas();
        this.recenterMap();
        this.props.drawnPolygon && this.drawAndZoomToPolygon(this.props.drawnPolygon);
    }

    drawAndZoomToPolygon(polygon: any) {
        const { map } = this.state;

        if (!map) {
            console.error("Map is not initialized.");
            return;
        }

        if (polygon.type !== "Feature" || !polygon.geometry || polygon.geometry.type !== "Polygon") {
            console.error("Invalid polygon data:", polygon);
            return;
        }

        const sourceId = polygon.id;

        try {
            if (map.getLayer(sourceId)) {
                const source = map.getSource(sourceId) as mapboxgl.GeoJSONSource;
                source.setData(polygon);
            } else {
                map.addLayer({
                    id: sourceId,
                    type: "fill",
                    source: {
                        type: "geojson",
                        data: polygon,
                        buffer: 0,
                        tolerance: 0.375,
                    },
                    paint: {
                        "fill-color": "blue",
                        "fill-opacity": 0.5,
                    },
                });
            }

            const bbox = turf.bbox(polygon);
            map.fitBounds(bbox as LngLatBoundsLike, { padding: 20 });
        } catch (error) {
            console.error("Error drawing polygon:", error);
        }
    }

    async searchAddress(address: string, accessToken: string): Promise<[number, number] | null> {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${accessToken}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.features && data.features.length > 0) {
                const location = data.features[0].center;

                // Remove any existing search marker
                if (this.state.searchMarker) {
                    this.state.searchMarker.remove();
                }

                // Add new marker at the searched location
                const searchMarker = new mapboxgl.Marker({
                    color: colors.red,
                    draggable: true,
                })
                    .setLngLat(location)
                    .addTo(this.state.map);

                // Update state with new marker
                this.setState({ ...this.state, searchMarker });

                return location;
            }
        } catch (error) {
            console.error("Error searching address:", error);
        }
        return null;
    }

    async getAddressSuggestions(query: string): Promise<ISuggestion[]> {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${mapboxgl.accessToken}&types=address,place,locality,neighborhood`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            return data.features.map((feature) => ({
                place_name: feature.place_name,
                center: feature.center,
            }));
        } catch (error) {
            console.error("Error fetching suggestions:", error);
            return [];
        }
    }

    showSuggestions(suggestions: ISuggestion[], input: HTMLInputElement): void {
        this.clearSuggestions();
        this.setState({ ...this.state, suggestions });

        if (suggestions.length === 0) return;

        const suggestionsDiv = document.createElement("div");
        suggestionsDiv.className = "search-suggestions";
        Object.assign(suggestionsDiv.style, searchSuggestionsStyles);

        suggestions.forEach((suggestion, index) => {
            const item = document.createElement("div");
            item.className = "suggestion-item";
            item.textContent = suggestion.place_name;
            Object.assign(item.style, suggestionItemStyles);

            item.addEventListener("click", () => {
                this.selectSuggestion(suggestion, input);
            });

            item.addEventListener("mouseover", () => {
                this.updateActiveSuggestion(suggestionsDiv.querySelectorAll(".suggestion-item"), index);
            });

            suggestionsDiv.appendChild(item);
        });

        input.parentElement.appendChild(suggestionsDiv);
    }

    clearSuggestions(): void {
        const suggestionsDiv = document.querySelector(".search-suggestions");
        if (suggestionsDiv) {
            suggestionsDiv.remove();
        }
    }

    updateActiveSuggestion(suggestions: NodeListOf<Element>, activeIndex: number): void {
        suggestions.forEach((item, index) => {
            if (index === activeIndex) {
                item.classList.add("active");
                item.style.backgroundColor = "#f5f5f5";
            } else {
                item.classList.remove("active");
                item.style.backgroundColor = "";
            }
        });
    }

    selectSuggestion(suggestion: ISuggestion, input: HTMLInputElement): void {
        input.value = suggestion.place_name;

        // Remove existing marker if any
        if (this.state.searchMarker) {
            this.state.searchMarker.remove();
        }

        // Create and add new marker
        const searchMarker = new mapboxgl.Marker({
            color: colors.red,
            draggable: true,
        })
            .setLngLat(suggestion.center)
            .addTo(this.state.map);

        // Update state with new marker
        this.setState({ ...this.state, searchMarker });

        // Fly to location
        this.state.map.flyTo({
            center: suggestion.center,
            zoom: 14,
            essential: true, // This ensures the animation runs
        });

        this.clearSuggestions();
    }

    render() {
        const toggleFullscreen = (e: React.MouseEvent<HTMLOrSVGElement>, enabled: boolean) => {
            e.stopPropagation();
            e.preventDefault();

            const isInteractive = this.state.interactive;

            this.setState({ ...this.state, fullscreen: enabled, interactive: true });
            this.props.onFullscreenChange?.(enabled);

            window.setTimeout(() => {
                this.state.map.resize();
                this.recenterMap();

                if (!isInteractive) {
                    setInteractive(this.state.map, true);
                }
            }, 150);
        };

        const toggleDataLayerModal = () => {
            const { map } = this.state;
            this.context.dispatch({ type: "toggleDataLayerModal" });

            if (map) {
                const isSatelliteViewEnabled = !this.context.state.isSatelliteViewEnabled;

                if (isSatelliteViewEnabled) {
                    map.setStyle("mapbox://styles/mapbox/satellite-streets-v11");
                    map.setProjection("globe");
                } else {
                    map.setStyle(SatelliteStyleUri);
                }

                this.setState({ ...this.state, fullscreen: false, interactive: true });
                this.props.onFullscreenChange?.(false);
            }
        };

        return (
            <>
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        bottom: 30,
                        width: "450px",
                        zIndex: 9,
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            left: "-50%",
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        <MapLegends rasters={this.props.rasters} />
                    </div>
                </div>
                <div
                    id={this.state.mapId}
                    className={this.props.roundedCorners === undefined || this.props.roundedCorners ? "s-map-rounded-corners" : undefined}
                    style={{
                        bottom: 0,
                        left: this.state.fullscreen ? 0 : this.props.right ? "40%" : 0,
                        position: "absolute",
                        right: this.state.fullscreen ? 0 : this.props.left ? "40%" : 0,
                        top: 0,
                        width: this.state.fullscreen ? "100%" : undefined,
                        height: this.state.fullscreen ? "100%" : undefined,
                        zIndex: this.state.fullscreen ? 10000 : undefined,
                    }}
                />

                {this.state.map && (
                    <>
                        {this.props.onFullscreenChange && (
                            <div className="mapboxgl-ctrl">
                                <div className="s-map-layer-controls-container">
                                    <div
                                        className="s-map-button"
                                        onClick={(e) => toggleFullscreen(e, !this.state.fullscreen)}
                                        style={{ height: "40px" }}
                                    >
                                        <svg viewBox="0 0 24 24" width={24} height={24}>
                                            <path
                                                d={this.state.fullscreen ? mdiFullscreenExit : mdiFullscreen}
                                                fill="black"
                                                strokeWidth="0.5"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        )}

                        <MapZoomPicker
                            onZoomClick={(what) => {
                                const map = this.state.map;

                                this.setState({ ...this.state, interactive: true });
                                setInteractive(this.state.map, true);
                                map.flyTo({ zoom: map.getZoom() + (what === "plus" ? 1 : -1) });
                            }}
                            toggleDataLayerModal={toggleDataLayerModal}
                            targetElementId="map-zoom-control"
                        />
                    </>
                )}
            </>
        );
    }
}

function debounce(func: Function, wait: number): (...args: any[]) => void {
    let timeout: NodeJS.Timeout;
    return function executedFunction(...args: any[]) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
