import * as React from "react";
import { useParams } from "react-router-dom";
import { mdiDeleteOutline } from "@mdi/js";
import { AppContext, useProject, useTeam, ToastAdded } from "@/context";
import { Button, HorizontalStack, Icon, LoadingAnimation, NavLink, NoItemsMessage, TableHeaderCell } from "@/lib";
import { BaseModalDialog } from "@/lib/modals";
import { capitalize, formatDate, t } from "@/utils";
import { dateSort, getSortBy, SortOrder, stringSort } from "@/utils/sort";
import { apiDelete } from "@/utils/http";

type SortType = "name" | "type" | "status" | "updated";

interface IReportTableProps {
    reports: ISFDRReport[];
    onDeleteReport: (report: ISFDRReport) => void;
}

export default function ReportTable(props: IReportTableProps): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const canDelete = ["admin", "manager"].includes(state.config.role.slug);
    const { reports } = props;
    const project = useProject();
    const team = useTeam();
    const params = useParams();
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
    const [sortOrder, setSortOrder] = React.useState<SortOrder>(undefined);
    const [sortType, setSortType] = React.useState<SortType>(undefined);
    const [reportBeingDeleted, setReportBeingDeleted] = React.useState<ISFDRReport>(undefined);
    const sortedReports = React.useMemo(
        () =>
            [].concat(reports).sort((a, b) => {
                if (sortType === "name") {
                    return stringSort(a.name, b.name, sortOrder);
                } else if (sortType === "type") {
                    return stringSort(a.type, b.type, sortOrder);
                } else if (sortType === "status") {
                    return stringSort(a.status, b.status, sortOrder);
                } else if (sortType === "updated") {
                    return dateSort(a.latest_version.created_at, b.latest_version.created_at, sortOrder);
                }
            }),
        [reports, sortOrder, sortType],
    );

    const onSort = (order: SortOrder, type: SortType) => {
        setSortType(type);
        setSortOrder(order);
    };

    const onDeleteReport = () => {
        const url = `projects/${project.slug}/sfdr/${reportBeingDeleted.id}`;

        setIsDeleting(true);
        apiDelete<{ ok: boolean }>(url, team.slug)
            .then((reply) => {
                dispatch({
                    type: ToastAdded,
                    toast: reply?.ok
                        ? { kind: "success", text: t("delete.sfdr_report.toast_success") }
                        : { kind: "error", text: t("delete.sfdr_report.toast_error") },
                });

                if (reply.ok) {
                    props.onDeleteReport(reportBeingDeleted);
                }
            })
            .finally(() => {
                setReportBeingDeleted(undefined);
                setIsDeleting(false);
            });
    };

    return (
        <>
            {reports === undefined && (
                <div style={{ marginTop: "6rem", textAlign: "center" }}>
                    <LoadingAnimation />
                </div>
            )}

            {reports?.length === 0 && <NoItemsMessage />}

            {reports?.length > 0 && (
                <table className="s-table">
                    <colgroup>
                        <col style={{ width: "55%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        {canDelete && <col style={{ width: "0%" }} />}
                    </colgroup>
                    <thead>
                        <tr>
                            <TableHeaderCell
                                label={t("ui.name")}
                                onClick={(arg) => onSort(arg, "name")}
                                sortedBy={getSortBy(sortType, "name", sortOrder)}
                            />
                            <TableHeaderCell
                                label={t("ui.type")}
                                onClick={(arg) => onSort(arg, "type")}
                                sortedBy={getSortBy(sortType, "type", sortOrder)}
                            />
                            <TableHeaderCell
                                label={t("ui.status")}
                                onClick={(arg) => onSort(arg, "status")}
                                sortedBy={getSortBy(sortType, "status", sortOrder)}
                            />
                            <TableHeaderCell
                                label={t("ui.updated_at")}
                                onClick={(arg) => onSort(arg, "updated")}
                                sortedBy={getSortBy(sortType, "updated", sortOrder)}
                            />
                            {canDelete && <th />}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedReports.map((report) => (
                            <tr key={report.id}>
                                <td>
                                    {report.newest_version?.status === "complete" && (
                                        <NavLink to={`/${params.team}/${params.project}/reports/sfdr/${report.id}`}>{report.name}</NavLink>
                                    )}
                                    {report.newest_version?.status !== "complete" && <span>{report.name}</span>}
                                </td>
                                <td>SFDR</td>
                                <td>{capitalize(report.newest_version.status)}</td>
                                <td>{formatDate(report.newest_version.created_at)}</td>
                                {canDelete && (
                                    <td>
                                        <button
                                            className="s-delete-icon-button"
                                            onClick={() => setReportBeingDeleted(report)}
                                            aria-label={t("actions.delete")}
                                        >
                                            <Icon color="#6F6F6F" path={mdiDeleteOutline} size={18} tooltip={t("actions.delete")} />
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {reportBeingDeleted && (
                <BaseModalDialog
                    onClose={() => setReportBeingDeleted(undefined)}
                    title={`${t("delete.sfdr_report.confirm_window_title")}  “${reportBeingDeleted.name}”`}
                    verticalAlign="center"
                >
                    <div className="s-delete-dialog-text">{t("delete.sfdr_report.confirm_window_text")}</div>
                    <HorizontalStack horizontalAlign="flex-end" style={{ marginTop: "2rem" }}>
                        <Button onClick={() => setReportBeingDeleted(undefined)}>{t("actions.cancel")}</Button>
                        <Button disabled={isDeleting} onClick={onDeleteReport} variant="primary">
                            {t("actions.delete")}
                        </Button>
                    </HorizontalStack>
                </BaseModalDialog>
            )}
        </>
    );
}
