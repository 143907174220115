import CryptoJS from "crypto-js";

interface LatLng {
    lat: number;
    lng: number;
}

interface FetchParams<T, Q> {
    endpoint: string;
    responseKey?: keyof T;
    errorMsg?: string;
    queryParams: Q;
}

interface DepAndImpResponse {
    restructuredItems: any[];
}

async function fetchGeoData<T, Q>({ endpoint, responseKey, errorMsg, queryParams }: FetchParams<T, Q>): Promise<T | undefined> {
    const queryString = new URLSearchParams(queryParams as Record<string, string>).toString();
    const url = `${process.env.GEO_SERVER_URL}/${endpoint}?${queryString}`;
    return fetchData<T>(url, responseKey, errorMsg);
}

async function fetchGeoMindData<T, Q>({ endpoint, responseKey, errorMsg, queryParams }: FetchParams<T, Q>): Promise<T | undefined> {
    const queryString = new URLSearchParams(queryParams as Record<string, string>).toString();
    const url = `${process.env.GEO_MIND_URL}/${endpoint}?${queryString}`;
    return fetchData<T>(url, responseKey, errorMsg);
}

async function fetchServerData<T, Q>({ endpoint, responseKey, errorMsg, queryParams }: FetchParams<T, Q>): Promise<T | undefined> {
    const queryString = new URLSearchParams(queryParams as Record<string, string>).toString();
    const url = `${process.env.KUYUA_APP_BACKEND_URL}/${endpoint}?${queryString}`;
    return fetchData<T>(url, responseKey, errorMsg);
}

async function fetchMsaDelta(latlng: LatLng) {
    return fetchGeoData<string, LatLng>({
        endpoint: "msa-delta",
        responseKey: "msa_delta",
        errorMsg: "Error fetching MSA Delta data",
        queryParams: latlng,
    });
}

async function fetchMSA(latlng: LatLng): Promise<string | undefined> {
    return fetchGeoData<string, LatLng>({
        endpoint: "msa",
        responseKey: "msa",
        errorMsg: "Error fetching MSA data",
        queryParams: latlng,
    });
}

async function fetchBSA(latlng: LatLng, radius: number): Promise<number | undefined> {
    return fetchGeoData<number, LatLng & { radius: number; count: string }>({
        endpoint: "protected-areas/circle",
        responseKey: "count",
        errorMsg: "Error fetching BSA count",
        queryParams: { ...latlng, radius, count: "true" },
    });
}

async function fetchSpecies(latlng: LatLng, radius: number, countryCode: string): Promise<number | undefined> {
    const radiusByKm = Number(radius) / 1000 || 20;
    return fetchGeoData<
        number,
        {
            decimalLatitude: number;
            decimalLongitude: number;
            rangeByKM: number;
            countryCode: string;
        }
    >({
        endpoint: "species",
        responseKey: "threatened_species_count",
        errorMsg: "Error fetching species count",
        queryParams: {
            decimalLatitude: latlng.lat,
            decimalLongitude: latlng.lng,
            rangeByKM: radiusByKm,
            countryCode,
        },
    });
}

async function fetchData<T>(url: string, key?: keyof T, errorMsg?: string): Promise<T[keyof T] | T | undefined> {
    try {
        const apiKey = process.env.API_KEY;
        const secretKey = process.env.SECRET_KEY;

        if (!apiKey || !secretKey) {
            throw new Error("API_KEY or SECRET_KEY is not defined in environment variables.");
        }

        const encryptedToken = CryptoJS.AES.encrypt(apiKey, secretKey).toString();

        const header = {
            headers: {
                Authorization: `Bearer ${encryptedToken}`,
            },
        };

        const response = await fetch(url, header);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return key ? (data[key] as T[keyof T]) : (data as T);
    } catch (error) {
        if (errorMsg) {
            console.error(`${errorMsg}:`, error);
        } else {
            console.error("Error fetching data:", error);
        }
        return undefined;
    }
}

async function fetchDepAndImp(endpoint: string, ecoIds: string): Promise<any[]> {
    const response = await fetchServerData<DepAndImpResponse, { economic_activities_ids: string }>({
        endpoint,
        responseKey: endpoint,
        errorMsg: `Error fetching ${endpoint}`,
        queryParams: { economic_activities_ids: ecoIds },
    });
    return response?.restructuredItems || [];
}

async function fetchMateriality(siteId: string) {
    return fetchServerData<any, { site_id: string }>({
        endpoint: `site-template/answers/${siteId}`,
        responseKey: "result",
        errorMsg: "Error fetching materiality",
        queryParams: { site_id: siteId },
    });
}

async function fetchSiteDetails(siteId: string) {
    return fetchServerData<any, { id: string }>({
        endpoint: `site`,
        responseKey: null,
        errorMsg: "Error fetching site details",
        queryParams: { id: siteId },
    });
}

const fetchHabitats = async (latlng: LatLng, radius: number) => {
    return fetchGeoMindData<any, LatLng & { radius: number }>({
        endpoint: `habitats/habitat_classes`,
        responseKey: null,
        errorMsg: "Error fetching habitats",
        queryParams: { ...latlng, radius },
    });
};

async function fetchBSAAll(latlng: LatLng, radius: number) {
    return fetchGeoData<any, LatLng & { radius: number }>({
        endpoint: "protected-areas/all",
        responseKey: null,
        errorMsg: "Error fetching BSA",
        queryParams: { ...latlng, radius },
    });
}

export { fetchMsaDelta, fetchMSA, fetchBSA, fetchSpecies, fetchDepAndImp, fetchMateriality, fetchHabitats, fetchBSAAll, fetchSiteDetails };
