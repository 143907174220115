export const RasterColormapChanged = "rasters/colormapChanged";
export const RasterMetadataChanged = "rasters/rasterChanged";

export function makeRastersState(): IRastersState {
    return {
        colormaps: {},
        metadatas: {},
    };
}

interface IColorMapConfig {
    id: string;
    stretchRange: number[];
    valueCount: number;
}

export function makeRasterColormapKey(conf: IColorMapConfig): string {
    return `${conf.id} ${conf.valueCount} ${conf.stretchRange[0]} ${conf.stretchRange[conf.stretchRange.length - 1]}`;
}

export const rastersReducer = (state: IRastersState, action: IGenericAction): IRastersState => {
    const type = action.type;

    switch (type) {
        case RasterColormapChanged:
            return {
                ...state,
                colormaps: {
                    ...state.colormaps,
                    [makeRasterColormapKey(action as unknown as IColorMapConfig)]: action.colormap as IRasterColormapEntry[],
                },
            };

        case RasterMetadataChanged:
            return {
                ...state,
                metadatas: {
                    ...state.metadatas,
                    [action.metadata.keys.name]: action.metadata as IRasterMetadata,
                },
            };
    }

    return state;
};
