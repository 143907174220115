import * as React from "react";
import {
    mdiBird,
    mdiCity,
    mdiCommaBoxOutline,
    mdiForest,
    mdiGrass,
    mdiImageFilterHdr,
    mdiShieldBugOutline,
    mdiTrendingDown,
    mdiWater,
} from "@mdi/js";
import { AppContext, useProject, useTeam, useTheme } from "@/context";
import { t } from "@/utils";
import { Button, Dropdown, Icon, SectionHeader, Table, TableHeaderCell, TableRowCellValue } from "@/lib";
import { IDropdownItem } from "@/lib/Dropdown";

const Sfdr_2_14_Options = ["protected_area", "primary_forest", "grassland"] as const;
type Sfdr_2_14_OptionTypes = (typeof Sfdr_2_14_Options)[number];
const Sfdr_2_10_Options = ["degradation", "desertification", "soil_sealing"] as const;
type Sfdr_2_10_OptionTypes = (typeof Sfdr_2_10_Options)[number];

interface SFDRFilters {
    sfdr_1_7: string;
    sfdr_2_14: "all" | Sfdr_2_14_OptionTypes;
    sfdr_2_8: string;
    sfdr_2_10: "all" | Sfdr_2_10_OptionTypes;
}

const getWaterRiskOptions = (report: ISFDRReportVersion) => {
    const options: IDropdownItem[] = [];

    report.sites.forEach((site) => {
        const id = "" + site.sfdr_2_8.overall_water_risk;

        if (!options.find((option) => option.id === id)) {
            options.push({
                id: id,
                label: t("waterRisk." + site.sfdr_2_8.overall_water_risk),
            });
        }
    });

    return options;
};

const filterSites = (report: ISFDRReportVersion, filters: SFDRFilters) => {
    let sites = report?.sites;

    if (sites && filters) {
        if (filters.sfdr_1_7 && filters.sfdr_1_7 !== "all") {
            sites = sites.filter((site) => {
                const yesFilter = site.sfdr_1_7.protected_areas?.length > 0;

                return (filters.sfdr_1_7 === "yes" && yesFilter) || (filters.sfdr_1_7 === "no" && !yesFilter);
            });
        }
        if (filters.sfdr_2_14 && filters.sfdr_2_14 !== "all") {
            sites = sites.filter((site) => site.sfdr_2_14[filters.sfdr_2_14 as Sfdr_2_14_OptionTypes]);
        }

        if (filters.sfdr_2_8 && filters.sfdr_2_8 !== "all") {
            sites = sites.filter((site) => site.sfdr_2_8.overall_water_risk.toString() === filters.sfdr_2_8);
        }
        if (filters.sfdr_2_10 && filters.sfdr_2_10 !== "all") {
            sites = sites.filter((site) => site.sfdr_2_10[filters.sfdr_2_10 as Sfdr_2_10_OptionTypes]);
        }
    }

    return sites;
};

const isFiltered = (filters: SFDRFilters) => {
    return Object.values(filters).find((value) => value !== "all" && value);
};

const getFilterQueries = (filters: SFDRFilters) => {
    const keysWithValues = Object.keys(filters).filter(
        (filter) => filters[filter as keyof SFDRFilters] && filters[filter as keyof SFDRFilters] !== "all",
    );

    return keysWithValues.map((key) => key + "=" + filters[key as keyof SFDRFilters]);
};

interface ISFDRReportTableProps {
    report: ISFDRReport;
    reportId: string;
}

export default function SFDRReportTable(props: ISFDRReportTableProps): JSX.Element {
    const { state } = React.useContext(AppContext);
    const theme = useTheme();
    const project = useProject();
    const team = useTeam();
    const [filters, setFilters] = React.useState<SFDRFilters>({} as SFDRFilters);
    const [exportDisabled, setExportDisabled] = React.useState<boolean>(false);

    const onDownloadReportClick = () => {
        setExportDisabled(true);
        const filterQuery = filters ? "?" + getFilterQueries(filters).join("&") : "";

        document.location = `/api/v1/teams/${team.slug}/projects/${project.slug}/sfdr/${props.report.id}/download${filterQuery}`;

        window.setTimeout(() => {
            setExportDisabled(false);
        }, 2500);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filter = (group: keyof SFDRFilters, selected: any) => {
        setFilters({ ...filters, [group]: selected });
    };

    const table = React.useMemo(() => {
        const version = props.report?.newest_version;
        if (version?.status !== "complete") {
            return { columns: [], rows: [] };
        }

        const columns = [
            [
                <th key="site-col" className="s-sfdr-heading-cell">
                    {t("ui.site")}
                </th>,
                <th key="1_7-col" className="s-sfdr-heading-cell">
                    <div id="filter_1_7-label" className="s-sfdr-heading-cell-label">
                        {version?.sfdr_1_7.description}
                    </div>
                    <Dropdown
                        key="filter_1_7"
                        id="filter_1_7"
                        labelId="filter_1_7-label"
                        items={[
                            { id: "all", label: t("ui.all") },
                            { id: "yes", label: t("ui.yes") },
                            { id: "no", label: t("ui.no") },
                        ]}
                        label={!filters.sfdr_1_7 ? t("ui.all") : t("ui." + filters.sfdr_1_7)}
                        onItemClick={(value) => filter("sfdr_1_7", value)}
                    />
                </th>,
                <th key="2_14-col" className="s-sfdr-heading-cell">
                    <div id="filter_2_14-label" className="s-sfdr-heading-cell-label">
                        {version?.sfdr_2_14.description}
                    </div>
                    <Dropdown
                        key="filter_2_14"
                        id="filter_2_14"
                        labelId="filter_2_14-label"
                        items={[{ id: "all", label: t("ui.all") }].concat(
                            Sfdr_2_14_Options.map((option) => ({
                                id: option,
                                label: t("ui." + option),
                            })),
                        )}
                        label={!filters.sfdr_2_14 ? t("ui.all") : t("ui." + filters.sfdr_2_14)}
                        onItemClick={(value) => filter("sfdr_2_14", value)}
                    />
                </th>,
                <th key="2_8-col" className="s-sfdr-heading-cell">
                    <div id="filter_2_8-label" className="s-sfdr-heading-cell-label">
                        {version?.sfdr_2_8.description}
                    </div>
                    <Dropdown
                        key="filter_2_8"
                        id="filter_2_8"
                        labelId="filter_2_8-label"
                        items={[{ id: "all", label: t("ui.all") }].concat(getWaterRiskOptions(props.report?.newest_version))}
                        label={!filters.sfdr_2_8 || filters.sfdr_2_8 === "all" ? t("ui.all") : t("waterRisk." + filters.sfdr_2_8)}
                        onItemClick={(value) => filter("sfdr_2_8", value)}
                    />
                </th>,
                <th key="2_10-col" className="s-sfdr-heading-cell">
                    <div id="filter_2_10-label" className="s-sfdr-heading-cell-label">
                        {version?.sfdr_2_10.description}
                    </div>
                    <Dropdown
                        key="filter_2_10"
                        id="filter_2_10"
                        labelId="filter_2_10-label"
                        items={[{ id: "all", label: t("ui.all") }].concat(
                            Sfdr_2_10_Options.map((option) => ({
                                id: option,
                                label: t("ui." + option),
                            })),
                        )}
                        label={!filters.sfdr_2_10 ? t("ui.all") : t("ui." + filters.sfdr_2_10)}
                        onItemClick={(value) => filter("sfdr_2_10", value)}
                    />
                </th>,
            ] as React.ReactNode[],
        ];

        if (["lg", "xl"].includes(state.layoutSize)) {
            columns.splice(0, 0, [
                <TableHeaderCell key="col-1" label="" width="20%" />,
                <TableHeaderCell
                    key="col-2"
                    columnBackgroundColor="#FFF"
                    label={t("ui.principal_adverse_indicator")}
                    labelStyle={{ fontWeight: "bold", whiteSpace: "pre" }}
                    sortable={false}
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                    width="20%'"
                />,
                <TableHeaderCell
                    key="col-3"
                    colSpan={3}
                    label={t("ui.additional_climate_and_other_environment_related_indicator_plural")}
                    labelStyle={{ fontWeight: "bold" }}
                    sortable={false}
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                    width="60%"
                />,
            ] as React.ReactNode[]);
        }

        return {
            columns,
            colBgColors: columns.length > 1 ? ["transparent", "#FFF", "transparent"] : null,
        };
    }, [props.reportId, props.report, state.layoutSize, filters]);

    const rows = React.useMemo(() => {
        const version = props.report?.newest_version;
        if (version?.status !== "complete") {
            return [];
        }

        const filteredSites = filterSites(version, filters);

        if (!filteredSites || filteredSites.length < 1) {
            return null;
        }

        const activeColor = theme.tintColor,
            inactiveColor = "#CCC";

        return filteredSites.map((s) => [
            {
                subtext: s.country,
                text: s.name,
                type: "link",
                url: `/${team.slug}/${project.slug}/sites/${s.id}`,
            },
            {
                type: "icon",
                icon: {
                    color: s.sfdr_1_7.protected_areas?.length > 0 ? activeColor : inactiveColor,
                    path: mdiShieldBugOutline,
                    size: 20,
                },
                text: s.sfdr_1_7.protected_areas !== null ? t("ui." + (s.sfdr_1_7.protected_areas?.length > 0 ? "yes" : "no")) : "—",
            },
            {
                type: "icons",
                icons: [
                    {
                        color: s.sfdr_2_14.protected_area ? activeColor : inactiveColor,
                        path: mdiBird,
                        size: 20,
                        text: t("ui.protected_area"),
                    },
                    {
                        color: s.sfdr_2_14.primary_forest ? activeColor : inactiveColor,
                        path: mdiForest,
                        size: 20,
                        text: t("ui.primary_forest"),
                    },
                    {
                        color: s.sfdr_2_14.grassland ? activeColor : inactiveColor,
                        path: mdiGrass,
                        size: 20,
                        text: t("ui.grassland"),
                    },
                ],
            },
            {
                type: "icon",
                icon: {
                    color: s.sfdr_2_8.overall_water_risk >= 3 ? activeColor : inactiveColor,
                    path: mdiWater,
                    size: 20,
                },
                text: s.sfdr_2_8.overall_water_risk !== null ? t("waterRisk." + s.sfdr_2_8.overall_water_risk) : "—",
            },
            {
                type: "icons",
                icons: [
                    {
                        color: s.sfdr_2_10.degradation ? activeColor : inactiveColor,
                        path: mdiTrendingDown,
                        size: 20,
                        text: t("ui.degradation"),
                    },
                    {
                        color: s.sfdr_2_10.desertification ? activeColor : inactiveColor,
                        path: mdiImageFilterHdr,
                        size: 20,
                        text: t("ui.desertification"),
                    },
                    {
                        color: s.sfdr_2_10.soil_sealing ? activeColor : inactiveColor,
                        path: mdiCity,
                        size: 20,
                        text: t("ui.soil_sealing"),
                    },
                ],
            },
        ]) as TableRowCellValue[][];
    }, [props.reportId, props.report, filters]);

    return (
        <React.Fragment>
            <SectionHeader
                level={3}
                rightAccessory={
                    <Button disabled={exportDisabled} onClick={onDownloadReportClick} variant="primary">
                        <Icon color="white" className="s-button-icon" path={mdiCommaBoxOutline} size={16} />
                        {!isFiltered(filters) ? t("actions.export_all") : t("actions.export_selection")}
                    </Button>
                }
                bordered={false}
                style={{ margin: "2rem 0 0 0" }}
                text={t("ui.site_plural")}
            />

            <Table {...table} rows={rows} />
        </React.Fragment>
    );
}
