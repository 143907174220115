/* eslint-disable @typescript-eslint/no-explicit-any */
import { INewDBSite } from "@/types/sleap";
import CryptoJS from "crypto-js";

interface ImpactAnswer {
    rating: "VH" | "H" | "M" | "L";
    values: string[];
}

interface ImpactCategories {
    landDegradation: string[];
    sealingSoil: string[];
    desertification: string[];
}

interface Impact {
    values: string[];
    rating: string;
}

interface KPIResponse {
    habitatMigrationData: any;
    speciesData: any;
    msa: any;
    msaDelta: any;
    BSACount: number;
    BSAClosestDistanceMeters: any;
    netPrimary: any;
    changeYAverage: any;
    deforestationData: {
        year: string;
        deforestation_category: string;
    };
    waterRiskCategory: string;
    impacts: {
        impacts: {
            restructuredItems: ImpactAnswer[];
        };
        potentialImpacts: {
            restructuredItems: Impact[];
        };
    };
    siteAddress: string;
    sitePriorityScore: number;
    siteType: string;
    economic_activities: Array<{
        economic_activity_id?: string;
        economic_activity_name?: string;
    }>;
    materiality: any;
}

const artificialHabitats = [
    { id: "14.0", name: "Artificial – Terrestrial" },
    { id: "14.1", name: "Arable Land" },
    { id: "14.2", name: "Pastureland" },
    { id: "14.3", name: "Plantations" },
    { id: "14.4", name: "Rural Gardens" },
    { id: "14.5", name: "Urban Areas" },
    { id: "14.6", name: "Subtropical/Tropical Heavily Degraded Former Forest" },
    { id: "15.0", name: "Artificial – Aquatic" },
    { id: "15.1", name: "Water Storage Areas (over 8 ha)" },
    { id: "15.2", name: "Ponds (below 8 ha)" },
    { id: "15.3", name: "Aquaculture Ponds" },
    { id: "15.4", name: "Salt Exploitation Sites" },
    { id: "15.5", name: "Excavations (open)" },
    { id: "15.6", name: "Wastewater Treatment Areas" },
    { id: "15.7", name: "Irrigated Land (including irrigation channels)" },
    { id: "15.8", name: "Seasonally Flooded Agricultural Land" },
    { id: "15.9", name: "Canals and Drainage Channels, Ditches" },
    {
        id: "15.10",
        name: "Karst and Other Subterranean Hydrological Systems (human-made)",
    },
    { id: "15.11", name: "Marine Anthropogenic Structures" },
    { id: "15.12", name: "Mariculture Cages" },
    { id: "15.13", name: "Marine/Brackish-culture Ponds" },
    { id: "16.0", name: "Introduced Vegetation" },
    { id: "17.0", name: "Other" },
    { id: "18.0", name: "Unknown" },
];

if (!process.env.API_KEY || !process.env.SECRET_KEY || !process.env.KUYUA_APP_BACKEND_URL || !process.env.GEO_SERVER_URL) {
    throw new Error("Missing necessary environment variables.");
}

const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();

const header = {
    headers: {
        Authorization: `Bearer ${encryptedToken}`,
    },
};

const backendURL = process.env.KUYUA_APP_BACKEND_URL;

export const E45 = async (site: INewDBSite): Promise<string[][]> => {
    if (!site) {
        throw new Error("Missing site information.");
    }

    async function fetchSiteKPIS(site: INewDBSite): Promise<KPIResponse> {
        try {
            const KPIsURL = `${process.env.KUYUA_APP_BACKEND_URL}/site/kpis?site_id=${site.site_id}&kpis=msa,msa-delta,bsa,bsa-distance,habitats,npp,species,change-y-average,deforestation,water-risk,materiality`;
            const response = await fetch(KPIsURL, header);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error at fetch kpis.");
            throw error;
        }
    }

    const [kpisResponse] = await Promise.all([fetchSiteKPIS(site)]);
    const {
        habitatMigrationData,
        speciesData,
        msa,
        msaDelta,
        BSACount,
        BSAClosestDistanceMeters,
        netPrimary,
        changeYAverage,
        deforestationData,
        waterRiskCategory,
        impacts: {
            impacts: { restructuredItems: actualImpacts },
            potentialImpacts: { restructuredItems: potentialImpacts },
        },
        siteAddress,
        sitePriorityScore,
        siteType,
        economic_activities,
        materiality,
    } = kpisResponse;

    const ecoIds = economic_activities
        .map((eca) => eca.economic_activity_id)
        .filter(Boolean)
        .join(",");

    const activities = economic_activities.map((e) => `• ${e.economic_activity_name || "Unknown activity"}`).join("\n");

    const impactCategories: ImpactCategories = {
        landDegradation: [
            "ghg_emissions",
            "soil_pollutants",
            "water_pollutants",
            "freshwater_ecosystem_use",
            "marine_ecosystem_use",
            "terrestrial_ecosystem_use",
            "disturbances",
            "biological_alterations_interferences",
        ],
        sealingSoil: ["solid_waste", "other_resources_use", "terrestrial_ecosystem_use"],
        desertification: [
            "soil_pollutants",
            "disturbances",
            "biological_alterations_interferences",
            "terrestrial_ecosystem_use",
            "water_use",
        ],
    };

    const answersOnEco = actualImpacts.some(
        (impact: ImpactAnswer) =>
            ["VH", "H", "M"].includes(impact.rating) &&
            !impact.values.includes("ghg_emissions") &&
            !impact.values.includes("non_ghg_emissions"),
    );

    const finalData: string[][] = [
        ["Site Name", ` ${site.site_name || "Unnamed Site"}`],
        ["Address", ` ${siteAddress || "Address not available"}`],
        ["Country", ` ${site.country || "Unknown"}`],
        ["Latitude", ` ${site.latlng?.lat ?? "N/A"}`],
        ["Longitude", ` ${site.latlng?.lng ?? "N/A"}`],
        ["Site Type", ` ${siteType || "Unknown"}`],
        ["Activities on site", ` ${activities || "No activities recorded"}`],
        ["Impact Radius (m)", ` ${site.radius || "Not specified"}`],
        ["Materiality Threshold", " Medium, high, very high"],
        ["Priority Score (1-100)", ` ${sitePriorityScore}`],
        [""],
        ["", "Potential", "Actual"],

        [
            "Material impacts on BSAs?",
            potentialImpacts
                .filter((impact: Impact) => impact.values.some((value: string) => value !== "ghg_emissions"))
                .some((impact: Impact) => ["VH", "H", "M"].includes(impact.rating)) === true
                ? "Yes"
                : "No",
            answersOnEco
                ? "Yes"
                : actualImpacts
                        .filter((impact: Impact) => impact.values.some((value: string) => value !== "ghg_emissions"))
                        .some((impact: Impact) => ["VH", "H", "M"].includes(impact.rating)) === true
                  ? "Yes"
                  : "No",
        ],
        [""],
        [
            "Material Impact via Air Emissions?",
            potentialImpacts
                .filter((impact: Impact) =>
                    impact.values.some((value: string) => value == "ghg_emissions" || value == "non_ghg_air_pollutants"),
                )
                .some((impact: Impact) => ["VH", "H", "M"].includes(impact.rating)) === true
                ? "Yes"
                : "No",
            actualImpacts.some(
                (impact: Impact) =>
                    ["VH", "H", "M"].includes(impact.rating) &&
                    impact.values.some((value: string) => value === "ghg_emissions" || value === "non_ghg_air_pollutants"),
            )
                ? "Yes"
                : "No",
        ],
        [""],

        [
            "Material Impact via Pollution",
            potentialImpacts
                .filter((impact: Impact) =>
                    impact.values.some(
                        (value: string) => value === "soil_pollutants" || value === "solid_waste" || value === "water_pollutants",
                    ),
                )
                .some((impact: Impact) => ["VH", "H", "M"].includes(impact.rating)) === true
                ? "Yes"
                : "No",
            actualImpacts.some(
                (impact: Impact) =>
                    ["VH", "H", "M"].includes(impact.rating) &&
                    impact.values.some(
                        (value: string) => value === "soil_pollutants" || value === "solid_waste" || value === "water_pollutants",
                    ),
            )
                ? "Yes"
                : "No",
        ],
        [""],

        [
            "Material Impact via Resource Exploitation",
            potentialImpacts
                .filter((impact: Impact) =>
                    impact.values.some((value: string) => value === "water_user" || value === "other_resources_use"),
                )
                .some((impact: Impact) => ["VH", "H", "M"].includes(impact.rating)) === true
                ? "Yes"
                : "No",
            actualImpacts.some(
                (impact: Impact) =>
                    ["VH", "H", "M"].includes(impact.rating) &&
                    impact.values.some((value: string) => value === "water_user" || value === "other_resources_use"),
            )
                ? "Yes"
                : "No",
        ],
        ["Water Risk", waterRiskCategory, waterRiskCategory],
        ["Deforestation Risk", deforestationData.year, deforestationData.year],
        ["Dominant Driver of Deforestation", deforestationData.deforestation_category, deforestationData.deforestation_category],
        [""],
        [
            "Material Impact via Invasive Alien Species?",
            potentialImpacts
                .filter((impact: Impact) => impact.values.some((value: string) => value === "biological_alterations_interferences"))
                .some((impact: Impact) => ["VH", "H", "M"].includes(impact.rating)) === true
                ? "Yes"
                : "No",
            actualImpacts.some(
                (impact: Impact) =>
                    ["VH", "H", "M"].includes(impact.rating) &&
                    impact.values.some((value: string) => value === "biological_alterations_interferences"),
            )
                ? "Yes"
                : "No",
        ],
        ["Number of IAS", "NA", "NA"],
        [""],
        [
            "Material Impact via LU/FU/MU Change?",
            potentialImpacts
                .filter((impact: Impact) =>
                    impact.values.some(
                        (value: string) =>
                            value === "terrestrial_ecosystem_use" ||
                            value === "marine_ecosystem_use" ||
                            value === "freshwater_ecosystem_use",
                    ),
                )
                .some((impact: Impact) => ["VH", "H", "M"].includes(impact.rating)) === true
                ? "Yes"
                : "No",
            actualImpacts.some(
                (impact: Impact) =>
                    ["VH", "H", "M"].includes(impact.rating) &&
                    impact.values.some(
                        (value: string) =>
                            value === "terrestrial_ecosystem_use" ||
                            value === "marine_ecosystem_use" ||
                            value === "freshwater_ecosystem_use",
                    ),
            )
                ? "Yes"
                : "No",
        ],
        ["Change in Land Footprint", "NA", "NA"],
        [
            "Land & Soil Sealing",
            habitatMigrationData?.NonNaturalSealedPercentage ? `${habitatMigrationData?.NonNaturalSealedPercentage}%` : "",
            habitatMigrationData?.NonNaturalSealedPercentage ? `${habitatMigrationData?.NonNaturalSealedPercentage}%` : "",
        ],

        [""],

        [
            "Impacts related to the State of Species?",
            potentialImpacts
                .filter((impact: Impact) =>
                    impact.values.some((value: string) => value !== "ghg_emissions" && value !== "non_ghg_emissions"),
                )
                .some((impact: Impact) => ["VH", "H", "M"].includes(impact.rating)) === true
                ? "Yes"
                : "No",
            answersOnEco
                ? "Yes"
                : actualImpacts
                        .filter((impact: Impact) =>
                            impact.values.some((value: string) => value !== "ghg_emissions" && value !== "non_ghg_emissions"),
                        )
                        .some((impact: Impact) => ["VH", "H", "M"].includes(impact.rating)) === true
                  ? "Yes"
                  : "No",
        ],

        ["Number of Local Species", speciesData.total_unique_keystone_species, speciesData.total_unique_keystone_species],
        ["Number of Local Keystone Species", speciesData.key_stone_species_count, speciesData.key_stone_species_count],
        ["Number of Local Threatened Species", speciesData.threatened_species_count, speciesData.threatened_species_count],
        [
            "Local Keystone Species at Risk (%)",
            `${
                speciesData.key_stone_species_count === 0
                    ? 0
                    : (
                          ((speciesData.threatened_species_count + speciesData.key_stone_species_count) /
                              speciesData.total_unique_keystone_species) *
                          100
                      ).toFixed(1)
            }%`,
            `${
                speciesData.key_stone_species_count === 0
                    ? 0
                    : (
                          ((speciesData.threatened_species_count + speciesData.key_stone_species_count) /
                              speciesData.total_unique_keystone_species) *
                          100
                      ).toFixed(1)
            }%`,
        ],
        [""],

        [
            "Impacts related to Ecosystems?",
            potentialImpacts
                .filter((impact: Impact) =>
                    impact.values.some((value: string) => value !== "ghg_emissions" && value !== "non_ghg_emissions"),
                )
                .some((impact: Impact) => ["VH", "H", "M"].includes(impact.rating)) === true
                ? "Yes"
                : "No",
            answersOnEco
                ? "Yes"
                : actualImpacts
                        .filter((impact: Impact) =>
                            impact.values.some((value: string) => value !== "ghg_emissions" && value !== "non_ghg_emissions"),
                        )
                        .some((impact: Impact) => ["VH", "H", "M"].includes(impact.rating)) === true
                  ? "Yes"
                  : "No",
        ],
        [
            "Biodiversity Intactness",
            `${msa !== undefined ? Number(msa).toFixed(0) + "%" : "N/A"}`,
            `${msa !== undefined ? Number(msa).toFixed(0) + "%" : "N/A"}`,
        ],
        [
            "Projected Change in Biodiversity Intactness",
            `${msaDelta !== undefined ? Number(msaDelta).toFixed(0) + "%" : "N/A"}`,
            `${msaDelta !== undefined ? Number(msaDelta).toFixed(0) + "%" : "N/A"}`,
        ],
        ["Number of Biodiversity Sensitive Areas", BSACount, BSACount],
        ["Vegetation Productivity", netPrimary, netPrimary],
        ["Trend in Vegetation Productivity", changeYAverage, changeYAverage],
        [
            "Habitat Fragmentation",
            habitatMigrationData?.habitatDict?.map((habitat) => `• ${habitat.name} -- ${habitat.percentage}%`).join("\n"),
            habitatMigrationData?.habitatDict?.map((habitat) => `• ${habitat.name} -- ${habitat.percentage}%`).join("\n"),
        ],
        [
            "Extend of Natural Habitat",
            habitatMigrationData?.NaturalHabitatPercentage ? `${habitatMigrationData?.NaturalHabitatPercentage}%` : "",
            habitatMigrationData?.NaturalHabitatPercentage ? `${habitatMigrationData?.NaturalHabitatPercentage}%` : "",
        ],
        [
            "Extend of Non-Natural Habitat",
            habitatMigrationData?.NonNaturalHabitatPercentage ? `${habitatMigrationData?.NonNaturalHabitatPercentage}%` : "",
            habitatMigrationData?.NonNaturalHabitatPercentage ? `${habitatMigrationData?.NonNaturalHabitatPercentage}%` : "",
        ],
        [
            "Land & Soil Sealing",
            habitatMigrationData?.NonNaturalSealedPercentage ? `${habitatMigrationData?.NonNaturalSealedPercentage}%` : "",
            habitatMigrationData?.NonNaturalSealedPercentage ? `${habitatMigrationData?.NonNaturalSealedPercentage}%` : "",
        ],
    ];

    return finalData;
};
