import * as React from "react";
import { Doughnut, HorizontalStack, Icon, ProgressBar, VerticalStack } from "@/lib";

interface IReportResultCardProps {
    iconPath?: string;
    onClick: () => void;
    segments?: {
        color: string;
        id: string;
        value: number;
    }[];
    style?: React.CSSProperties;
    table?: { label: string; value: number }[];
    text?: string;
    title: string;
}

export default function ReportResultCard(props: IReportResultCardProps): JSX.Element {
    return (
        <VerticalStack style={props.style}>
            <HorizontalStack style={{ marginTop: "1.5rem" }}>
                <div>
                    <div
                        style={{
                            fontFamily: "Roobert Medium",
                            fontSize: "1.25rem",
                            marginBottom: ".5rem",
                        }}
                    >
                        {props.title}
                    </div>
                </div>
                {props.segments && (
                    <div style={{ position: "relative" }}>
                        <Doughnut segments={props.segments} size={90} thickness={20} />
                        {props.iconPath && (
                            <Icon
                                color="#999"
                                path={props.iconPath}
                                size={32}
                                style={{
                                    left: "50%",
                                    position: "absolute",
                                    top: "48%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            />
                        )}
                    </div>
                )}
            </HorizontalStack>

            {props.text && <div>{props.text}</div>}

            {props.table && (
                <table style={{ width: "100%" }}>
                    <colgroup>
                        <col />
                        <col style={{ width: "6rem" }} />
                    </colgroup>
                    <tbody>
                        {props.table.map(({ label, value }, ix) => (
                            <tr key={ix}>
                                <td>{label}</td>
                                <td>
                                    <ProgressBar barStyle={{ background: "#BE98EE" }} value={value} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </VerticalStack>
    );
}
