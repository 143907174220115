import * as React from "react";
import classnames from "classnames";
import Icon from "./Icon";

interface IActionLinkProps {
    children?: React.ReactNode;
    disabled?: boolean;
    icon?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
    variant?: string;
    iconColor?: string;
}

export default function ActionLink(props: IActionLinkProps): JSX.Element {
    const callback = props.disabled ? (): void => null : props.onClick;
    const classes = classnames(`s-action-link s-action-link-${props.variant || "default"}`, {
        "s-action-link-disabled": props.disabled,
    });

    return (
        <button className={classes} onClick={callback} style={props.style} type="button">
            {props.icon && <Icon path={props.icon} color={props.iconColor ? props.iconColor : "black"} size={16} />}
            <span>{props.children}</span>
        </button>
    );
}
