/* eslint-disable @typescript-eslint/no-explicit-any */
import { INewDBSite } from "@/types/sleap";
import CryptoJS from "crypto-js";

const artificialHabitats = [
    { id: "14.0", name: "Artificial – Terrestrial" },
    { id: "14.1", name: "Arable Land" },
    { id: "14.2", name: "Pastureland" },
    { id: "14.3", name: "Plantations" },
    { id: "14.4", name: "Rural Gardens" },
    { id: "14.5", name: "Urban Areas" },
    { id: "14.6", name: "Subtropical/Tropical Heavily Degraded Former Forest" },
    { id: "15.0", name: "Artificial – Aquatic" },
    { id: "15.1", name: "Water Storage Areas (over 8 ha)" },
    { id: "15.2", name: "Ponds (below 8 ha)" },
    { id: "15.3", name: "Aquaculture Ponds" },
    { id: "15.4", name: "Salt Exploitation Sites" },
    { id: "15.5", name: "Excavations (open)" },
    { id: "15.6", name: "Wastewater Treatment Areas" },
    { id: "15.7", name: "Irrigated Land (including irrigation channels)" },
    { id: "15.8", name: "Seasonally Flooded Agricultural Land" },
    { id: "15.9", name: "Canals and Drainage Channels, Ditches" },
    { id: "15.10", name: "Karst and Other Subterranean Hydrological Systems (human-made)" },
    { id: "15.11", name: "Marine Anthropogenic Structures" },
    { id: "15.12", name: "Mariculture Cages" },
    { id: "15.13", name: "Marine/Brackish-culture Ponds" },
    { id: "16.0", name: "Introduced Vegetation" },
    { id: "17.0", name: "Other" },
    { id: "18.0", name: "Unknown" }
];
if (
  !process.env.API_KEY ||
  !process.env.SECRET_KEY ||
  !process.env.KUYUA_APP_BACKEND_URL ||
  !process.env.GEO_SERVER_URL
) {
  throw new Error("Missing necessary environment variables.");
}

const encryptedToken = CryptoJS.AES.encrypt(
  process.env.API_KEY,
  process.env.SECRET_KEY
).toString();

const header = {
  headers: {
    Authorization: `Bearer ${encryptedToken}`,
  },
};

const backendURL = process.env.KUYUA_APP_BACKEND_URL;

export const E45 = async (site) => {
  if (!site) {
    throw new Error("Missing site information.");
  }
  async function fetchSiteKPIS(site){
    try {
      const KPIsURL = 
      `${process.env.KUYUA_APP_BACKEND_URL}/site/kpis?site_id=${site.site_id}&kpis=msa,msa-delta,bsa,bsa-distance,habitats,npp,species,change-y-average,deforestation,water-risk,materiality`;
      const response = await fetch(KPIsURL,header);
      const data = response.json();
      return data;
    } catch (error) {
      console.error("Error at fetch kpis.");
    }
  }
  const [kpisResponse] = await Promise.all([
    fetchSiteKPIS(site)
  ]);
  const{habitatMigrationData, speciesData, msa, msaDelta, BSACount,
    BSAClosestDistanceMeters, netPrimary, changeYAverage,
    deforestationData, waterRiskCategory, impacts,
     siteAddress, sitePriorityScore, siteType, economic_activities, materiality}= kpisResponse;
  const ecoIds = economic_activities
    .map((eca: { economic_activity_id: any }) => eca.economic_activity_id)
    .filter(Boolean)
    .join(",");
  const activities = economic_activities
    .map(
      (e: { economic_activity_name: any }) =>
        `• ${e.economic_activity_name || "Unknown activity"}`
    )
    .join("\n");
  
  const materialityValues = materiality?.result?.answers;
  materialityValues.impacts = materialityValues.impacts || {};
  materialityValues.dependencies = materialityValues.dependencies || {};
  // Function to add missing values based on the rating
  async function addMissingValues(categoryList, answerCategory) {
    categoryList.forEach(({ rating, values }) => {
      values.forEach(value => {
        const impValue = value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        // Check if the value already exists in answers; if not, add it
        if (!Object.keys(materialityValues[answerCategory]).includes(impValue)) {
          materialityValues[answerCategory][impValue] = { value: convertRating(rating), comment: "" };
        }
      });
    });
  }
  function convertRating(rating){
    switch (rating) {
      case "VH":
        return "Very High";
      case "H":
        return "High";
      case "M":
        return "Medium"
      case "L":
        return "Low"
      default:
        break;
    }
  }
  // Adding missing impact and dependency values
  await addMissingValues(impacts, "impacts");
  const impactAnswers = materialityValues.impacts || {};
  const impactCategories = {
    landDegradation: [
      "ghg_emissions",
      "soil_pollutants",
      "water_pollutants",
      "freshwater_ecosystem_use",
      "marine_ecosystem_use",
      "terrestrial_ecosystem_use",
      "disturbances",
      "biological_alterations_interferences",
    ],
    sealingSoil: [
      "solid_waste",
      "other_resources_use",
      "terrestrial_ecosystem_use",
    ],
    desertification: [
      "soil_pollutants",
      "disturbances",
      "biological_alterations_interferences",
      "terrestrial_ecosystem_use",
      "water_use",
    ],
  };
  console.log("impactAnswers: ", impactAnswers);
  const answersOnEco = (Object.entries(impactAnswers).some(([key, impact]) =>
    (key !== "GHG Emissions" && key !== "Non Ghg Air Pollutants")
    && ["Very High", "High", "Medium"].includes(impact.value)) && Object.keys(impactAnswers).length > 2)

  const finalData = [
    ["Site Name", ` ${site.site_name || "Unnamed Site"}`],
    ["Address", ` ${siteAddress || "Address not available"}`],
    ["Country", ` ${site.country || "Unknown"}`],
    ["Latitude", ` ${site.latlng?.lat ?? "N/A"}`],
    ["Longitude", ` ${site.latlng?.lng ?? "N/A"}`],
    ["Site Type", ` ${siteType|| "Unknown"}`],
    ["Activities on site", ` ${activities || "No activities recorded"}`],
    ["Impact Radius (m)", ` ${site.radius || "Not specified"}`],
    ["Materiality Threshold", " Medium, high, very high"],
    ["Priority Score (1-100)", ` ${sitePriorityScore}`],
    [""],
    ["", "Potential", "Actual"],

    ["Material impacts on BSAs?", impacts.filter((impact) =>
      impact.values.some((value) => value !== "ghg_emissions"))
      .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No",
      answersOnEco ? "Yes" : (impacts.filter((impact) => impact.values.some((value) => value !== "ghg_emissions"))
        .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No")],
    ["Number of BSAs", BSACount, BSACount],
    ["Distance To Closest BSA (m)", BSAClosestDistanceMeters?.closestDistanceMeters?.distance_meters?.toFixed(0), BSAClosestDistanceMeters?.closestDistanceMeters?.distance_meters?.toFixed(0)],
    [""],

    ["Material impact via Climate Change?", impacts.filter((impact) =>
      impact.values.some((value) => (value == "ghg_emissions" || value == "non_ghg_emissions")))
      .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No",
      Object.keys(impactAnswers).some( key => ["GHG Emissions" , "Non Ghg Air Pollutants"].includes(key)) ? (Object.entries(impactAnswers).some(([key, impact]) =>
        (key == "GHG Emissions" || key == "Non Ghg Air Pollutants") && ["Very High", "High", "Medium"].includes(impact.value)) === true ? "Yes" : "No") :
        impacts.filter((impact) =>
          impact.values.some((value) => (value == "ghg_emissions" || value == "non_ghg_emissions")))
          .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No"],
    [""],

    ["Material Impact via Pollution", impacts.filter((impact) =>
      (impact.values.some((value) => (value == "soil_pollutants" || value == "solid_waste" || value == "water_pollutants"))))
      .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No",
      Object.keys(impactAnswers).some( key => ["Soil Pollutants", "Solid Waste", "Water Pollutants"].includes(key)) ? (Object.entries(impactAnswers).some(([key, impact]) =>
        (key == "Soil Pollutants" || key == "Solid Waste" || key == "Water Pollutants") && ["Very High", "High", "Medium"].includes(impact.value)) === true ? "Yes" : "No") :
        impacts.filter((impact) =>
          (impact.values.some((value) => (value == "soil_pollutants" || value == "solid_waste" || value == "water_pollutants"))))
          .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No"],
    [""],

    ["Material Impact via Resource Exploitation", impacts.filter((impact) =>
    (impact.values.some((value) => (value == "terrestrial_ecosystem_use" || value == "marine_ecosystem_use" || value == "freshwater_ecosystem_use"
      || value == "other_resources_use")))).some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No",
      Object.keys(impactAnswers).some( key => ["Terrestrial Ecosystem Use", "Marine Ecosystem Use", "Freshwater Ecosystem Use", "Other Ressources Use"].includes(key)) ? (Object.entries(impactAnswers).some(([key, impact]) =>
        (key == "Terrestrial Ecosystem Use" || key == "Marine Ecosystem Use" || key == "Freshwater Ecosystem Use" || key == "Other Ressources Use")
        && ["Very High", "High", "Medium"].includes(impact.value)) === true ? "Yes" : "No") :
        impacts.filter((impact) =>
        (impact.values.some((value) => (value == "terrestrial_ecosystem_use" || value == "marine_ecosystem_use" || value == "freshwater_ecosystem_use"
          || value == "other_resources_use")))).some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No"
    ],
    ["Water Risk", waterRiskCategory, waterRiskCategory],
    ["Deforestation Risk", deforestationData.year, deforestationData.year],
    ["Dominant Driver of Deforestation", deforestationData.deforestation_category, deforestationData.deforestation_category],
    [""],
    ["Material Impact via Invasive Alien Species?", impacts.filter((impact) =>
      (impact.values.some((value) => value == "biological_alterations_interferences")))
      .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No",
      Object.keys(impactAnswers).some( key => key == "Biological Alterations Interferences") ? (Object.entries(impactAnswers).some(([key, impact]) =>
        key == "Biological Alterations Interferences" && ["Very High", "High", "Medium"].includes(impact.value)) === true ? "Yes" : "No") :
        impacts.filter((impact) =>
          (impact.values.some((value) => value == "biological_alterations_interferences")))
          .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No"],
    ["Number of IAS", "NA", "NA"],

    [""],

    ["Material Impact via LU/FU/MU Change?", impacts.filter((impact) =>
      (impact.values.some((value) => (value == "terrestrial_ecosystem_use" || value == "marine_ecosystem_use" || value == "freshwater_ecosystem_use"))))
      .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No",
      Object.keys(impactAnswers).some( key => ["Terrestrial Ecosystem Use", "Marine Ecosystem Use", "Freshwater Ecosystem Use"].includes(key)) ? (Object.entries(impactAnswers).some(([key, impact]) =>
        (key == "Terrestrial Ecosystem Use" || key == "Marine Ecosystem Use" || key == "Freshwater Ecosystem Use")
        && ["Very High", "High", "Medium"].includes(impact.value)) === true ? "Yes" : "No") : impacts.filter((impact) =>
          (impact.values.some((value) => (value == "terrestrial_ecosystem_use" || value == "marine_ecosystem_use" || value == "freshwater_ecosystem_use"))))
          .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No"],
    ["Change in Land Footprint", "NA", "NA"],
    ["Land & Soil Sealing", habitatMigrationData?.NonNaturalSealedPercentage ? `${habitatMigrationData?.NonNaturalSealedPercentage}%` : "", habitatMigrationData?.NonNaturalSealedPercentage ? `${habitatMigrationData?.NonNaturalSealedPercentage}%` : ""],

    [""],

    ["Impacts related to the State of Species?", impacts.filter((impact) =>
      (impact.values.some((value) => (value !== "ghg_emissions" && value !== "non_ghg_emissions"))))
      .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No",
      answersOnEco ? "Yes" : (impacts.filter((impact) =>
        (impact.values.some((value) => (value !== "ghg_emissions" && value !== "non_ghg_emissions"))))
        .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No") ],

    ["Number of Local Species", speciesData.total_unique_keystone_species, speciesData.total_unique_keystone_species],
    ["Number of Local Keystone Species", speciesData.key_stone_species_count, speciesData.key_stone_species_count],
    ["Number of Local Threatened Species", speciesData.threatened_species_count, speciesData.threatened_species_count],
    ["Local Keystone Species at Risk (%)", `${speciesData.key_stone_species_count === 0 ? 0 :
      (((speciesData.threatened_species_count + speciesData.key_stone_species_count) / speciesData.total_unique_keystone_species) * 100).toFixed(1)}%`, 
      `${speciesData.key_stone_species_count === 0 ? 0 :
      (((speciesData.threatened_species_count + speciesData.key_stone_species_count) / speciesData.total_unique_keystone_species) * 100).toFixed(1)}%`],
    [""],

    ["Impacts related to Ecosystems?", impacts.filter((impact) =>
      (impact.values.some((value) => (value !== "ghg_emissions" && value !== "non_ghg_emissions"))))
      .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No",
      answersOnEco ? "Yes" : (impacts.filter((impact) =>
        (impact.values.some((value) => (value !== "ghg_emissions" && value !== "non_ghg_emissions"))))
        .some((impact) => ["VH", "H", "M"].includes(impact.rating)) === true ? "Yes" : "No")],
    ["Biodiversity Intactness", `${msa !== undefined ? Number(msa).toFixed(0) + "%" : "N/A"}`, `${msa !== undefined ? Number(msa).toFixed(0) + "%" : "N/A"}`],
    ["Projected Change in Biodiversity Intactness", `${msaDelta !== undefined ? Number(msaDelta).toFixed(0) + "%" : "N/A"}`, `${msaDelta !== undefined ? Number(msaDelta).toFixed(0) + "%" : "N/A"}`],
    ["Number of Biodiversity Sensitive Areas", BSACount, BSACount],
    ["Vegetation Productivity", netPrimary, netPrimary],
    ["Trend in Vegetation Productivity", changeYAverage, changeYAverage],
    ["Habitat Fragmentation", habitatMigrationData?.habitatDict?.map((habitat) => `• ${habitat.name} -- ${habitat.percentage}%`).join("\n"), habitatMigrationData?.habitatDict?.map((habitat) => `• ${habitat.name} -- ${habitat.percentage}%`).join("\n")],
    ["Extend of Natural Habitat", habitatMigrationData?.NaturalHabitatPercentage ? `${habitatMigrationData?.NaturalHabitatPercentage}%` : "", habitatMigrationData?.NaturalHabitatPercentage ? `${habitatMigrationData?.NaturalHabitatPercentage}%` : ""],
    ["Extend of Non-Natural Habitat", habitatMigrationData?.NonNaturalHabitatPercentage ? `${habitatMigrationData?.NonNaturalHabitatPercentage}%` : "", habitatMigrationData?.NonNaturalHabitatPercentage ? `${habitatMigrationData?.NonNaturalHabitatPercentage}%` : ""],
    ["Land & Soil Sealing", habitatMigrationData?.NonNaturalSealedPercentage ? `${habitatMigrationData?.NonNaturalSealedPercentage}%` : "", habitatMigrationData?.NonNaturalSealedPercentage ? `${habitatMigrationData?.NonNaturalSealedPercentage}%` : ""]
  ];

  return finalData;
};
