import * as React from "react";
import classnames from "classnames";

interface IListBarChartItem {
    count: number;
    id?: number | string | symbol;
    label: string;
}

interface IListBarChartProps {
    items: IListBarChartItem[];
    onItemClick?: (item: IListBarChartItem) => void;
    style?: React.CSSProperties;
    barStyle?: React.CSSProperties;
    totalCount?: number;
    hideCount?: boolean;
    centerLabel?: boolean;
}

interface IListBarChartItemsProps {
    item: IListBarChartItem;
    onClick: () => void;
    totalCount: number;
    hideCount: boolean;
    barStyle?: React.CSSProperties;
}

function ListBarChartItem(props: IListBarChartItemsProps): JSX.Element {
    const { item, totalCount, hideCount, barStyle } = props;
    const style = barStyle ? barStyle : {};
    style.backgroundSize = totalCount > 0 ? `${(item.count / totalCount) * 100}% 100%` : undefined;

    return (
        <div style={style}>
            <span onClick={props.onClick}>{item.label}</span>
            {!hideCount && <span>{item.count}</span>}
        </div>
    );
}

export default function ListBarChart(props: IListBarChartProps): JSX.Element {
    return (
        <div
            className={classnames("s-list-bar-chart", {
                "s-list-bar-chart-clickable": !!props.onItemClick,
            })}
            style={props.style}
        >
            {props.items.map((item, ix) => (
                <ListBarChartItem
                    key={`${ix} ${item.label}`}
                    onClick={() => props.onItemClick?.(item)}
                    item={item}
                    totalCount={props.totalCount}
                    barStyle={props.barStyle}
                    hideCount={!!props.hideCount}
                />
            ))}
        </div>
    );
}
