import Icon from "../lib/Icon";
import { mdiCheck } from "@mdi/js";
import * as React from "react";

interface iCheckboxProps {
    checked?: boolean;
    label?: string;
    color?: string;
    readonly?: boolean;
    checkHandler?: (checked: boolean) => void;
}
export default function Checkbox({ checked, label, color, readonly, checkHandler }: iCheckboxProps): JSX.Element {
    return (
        <>
            <label className="checkbox-label">
                <input
                    type="checkbox"
                    checked={checked}
                    className="checkbox-input"
                    readOnly={readonly}
                    onChange={() => checkHandler(!checked)}
                />
                <span className="checkbox-custom" style={{ borderColor: color || "white" }}>
                    {checked && <Icon path={mdiCheck} color={color || "white"} size={17} />}
                </span>
                {label && <span>{label}</span>}
            </label>
        </>
    );
}
