/* eslint-disable @typescript-eslint/no-explicit-any */
import CryptoJS from "crypto-js";
import {
  fetchMateriality
} from "./helpers";
import { impactCategories } from "./variables";
if (
  !process.env.API_KEY ||
  !process.env.SECRET_KEY ||
  !process.env.KUYUA_APP_BACKEND_URL ||
  !process.env.GEO_SERVER_URL
) {
  throw new Error("Missing necessary environment variables.");
}
const encryptedToken = CryptoJS.AES.encrypt(
  process.env.API_KEY,
  process.env.SECRET_KEY
).toString();
const header = {
  headers: {
    Authorization: `Bearer ${encryptedToken}`,
  },
};
const formatRating = (rating: string) =>
  ({
    H: "High",
    M: "Medium",
    L: "Low",
    VH: "Very High",
  }[rating] ||
  rating ||
  "Unknown");

const formatImpactsAndDependencies = (data: any[], type: string) => {
  const result: { [key: string]: string } = {};
  ["VH", "H", "M", "L"].forEach((rating) => {
    result[`${type} (${formatRating(rating)})`] = "";
  });

  if (!Array.isArray(data)) {
    console.error(`Invalid ${type} data: expected array, got ${typeof data}`);
    return result;
  }

  data.forEach(({ rating, values }) => {
    if (Array.isArray(values) && values.length > 0) {
      const key = `${type} (${formatRating(rating)})`;
      result[key] = values
        .filter(
          (value: string) =>
            value && typeof value === "string" && !value.includes("undefined")
        )
        .map((value: string) => `• ${value.trim()}`)
        .join("\n");
    }
  });

  return result;
};
async function fetchDepAndImp(endpoint, ecoIds) {
  try {
      const response = await fetch(
          `${process.env.KUYUA_APP_BACKEND_URL}/${endpoint}?economic_activities_ids=${ecoIds}`,
          header
      );
      if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      return data[endpoint]?.restructuredItems || [];
  } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
      return [];
  }
};
export const sbm3Table = async (site) => {
  if (!site) {
    throw new Error("Missing site information.");
  }
  async function fetchSiteKPIS(site){
    try {
      const KPIsURL = `${process.env.KUYUA_APP_BACKEND_URL}/site/kpis?site_id=${site.site_id}&kpis=msa,bsa,bsa-distance,habitats,materiality`;
      const response = await fetch(KPIsURL,header);
      const data = response.json();
      return data;
    } catch (error) {
      console.error("Error at fetch kpis.");
    }
  }
  const [kpisResponse] = await Promise.all([
    fetchSiteKPIS(site)
  ]);
  const{habitatMigrationData, msa, msaDelta,
    BSAClosestDistanceMeters, impacts, dependencies, siteName,
     siteAddress, materiality, economic_activities} = kpisResponse;
  //-------------//
  // list of economic_activity_names with this conditions:
  // that have a [M, H, VH] impacts rating EXCEPT to have only “ghg emissions” or only “non ghg emissions”.
  let eA = await Promise.all(
    economic_activities.map(async (eco) => {
      const response = await fetchDepAndImp("impacts", eco.economic_activity_id);
      return {
        economic_activity_id: eco.economic_activity_id,
        economic_activity_name: eco.economic_activity_name,
        impacts: response
      };
    })
  );
  const filteredActivities = eA.filter(activity => 
    activity.impacts.some(impact => 
      ["M", "H", "VH"].includes(impact.rating) && 
      !(impact.values.length === 1 && 
        (impact.values.includes("ghg_emissions") || impact.values.includes("non_ghg_emissions")))
    )
  ).map(activity => `• ${activity.economic_activity_name || "Unknown activity"}`).join("\n");
  //-------------//
  const fullSiteName = siteName;
  console.log("filteredActivities: ", filteredActivities);
  console.log("impacts: ", impacts);
  console.log("dependencies: ", dependencies);
  const impactsArray = impacts.flatMap((impact: any) => impact.values);

  const formatImpactCategory = (category: string[]) =>
    impactsArray
      .filter((impact: string) => impact && category.includes(impact))
      .map((impact: any) => ` • ${impact}`)
      .join("\n") || "No impacts identified";

  const ecologicalStatus =
    msa !== undefined ? Number(msa).toFixed(2) : "N/A";
  const projectedChangeInEcologicalStatus =
    msaDelta !== undefined
      ? Number(msaDelta).toFixed(0) + "%"
      : "N/A";

  const activitiesImpactingBSAsAndSpecies = filteredActivities;
  // impacts.filter((impact: { rating: string }) => ["VH", "H", "M"].includes(impact.rating)).flatMap((impact: { values: any[] }) => 
  //   impact.values.filter((value: string) => value !== "ghg_emissions").map((value: any) => ` • ${value}`)).join("\n");

  const impactsCausingLandDegradation = formatImpactCategory(
    impactCategories.landDegradation
  );
  const impactsCausingSoilSealing = formatImpactCategory(
    impactCategories.sealingSoil
  );
  const impactsCausingDesertification = formatImpactCategory(
    impactCategories.desertification
  );

  const impactsCausingThreatenedSpecies = formatImpactCategory(
    impactCategories.threatenedSpeciesAffected
  );

  const materialityValues = materiality?.result?.answers;
  const potentialImpacts = impacts.filter((impact: { rating: string }) => ["VH", "H", "M"].includes(impact.rating))
    .flatMap((impact: { values: any[] }) => impact.values);

  const actualImpactAnswers = Object.entries(materialityValues?.impacts || {}).reduce((acc, [key, values]) => {
    key = key.toLowerCase().trim().replaceAll(" ", "_");
    acc[key] = values;
    return acc;
  }, {});

  const actualImpacts = potentialImpacts
  .filter((impact) => !actualImpactAnswers[impact] || actualImpactAnswers[impact].value !== "Low")
  .map((impact) => ` • ${impact}`)
  .join("\n");

  const potentialDependencies = dependencies.filter((dependency: { rating: string }) => ["VH", "H", "M"].includes(dependency.rating))
    .flatMap((dependency: { values: any[] }) => dependency.values)

  const actualDependenciesAnswers = Object.entries(materialityValues?.dependencies || {}).reduce((acc, [key, values]) => {
    key = key.toLowerCase().trim().replaceAll(" ", "_");
    acc[key] = values;
    return acc;
  }, {});

  const actualDependencies = potentialDependencies
  .filter((dep) => !actualDependenciesAnswers[dep] || actualDependenciesAnswers[dep].value !== "Low")
  .map((dep) => ` • ${dep}`)
  .join("\n");

  const habitats = habitatMigrationData?.habitatDict;
  const naturalHabitatPercentage = (() => {
    const numberPercentage = parseFloat(habitatMigrationData?.NaturalHabitatPercentage);
    if (numberPercentage) {
      if (numberPercentage < 50) return "35%";
      return "65%";
    }
    return "35%" //"35%";
  })();

  const bsaResponse = BSAClosestDistanceMeters;
  let bsa = bsaResponse?.radiusFiltered?.features || [];
  bsa = bsa.map((item: any) => ` • ${item?.properties?.name || "N/A"}`).join("\n");

  const finalData = [
    [
      "Site Name",
      "Address",
      "Activities negatively affecting BSAs",
      "Potential impacts",
      "Actual impacts",
      "Potential dependencies",
      "Actual dependencies",
      "Ecological Status (0-100)",
      "Reference baseline",
      "BSAs impacted",
      "Impacts causing Land Degradation",
      "Impacts causing Desertification",
      "Impacts causing Soil Sealing",
      "Threatened species affected",
    ],
    [
      ` ${fullSiteName || "Unnamed Site"}`,
      ` ${siteAddress || "Address not available"}`,
      activitiesImpactingBSAsAndSpecies,
      potentialImpacts.map((value: any) => ` • ${value}`).join("\n"),
      actualImpacts,
      potentialDependencies.map((value: any) => ` • ${value}`).join("\n"),
      actualDependencies,
      ecologicalStatus,
      naturalHabitatPercentage,
      bsa,
      impactsCausingLandDegradation,
      impactsCausingDesertification,
      impactsCausingSoilSealing,
      impactsCausingThreatenedSpecies,
    ],
  ];

  return finalData;
};

export const sbm3TableForTeam = async (team) => {
  if (!team) {
    throw new Error("Missing team information.");
  }
  async function fetchSiteKPIS(team){
    try {
      const KPIsURL = `${process.env.KUYUA_APP_BACKEND_URL}/sites/kpis?company_id=${team}&kpis=msa,bsa,bsa-distance,habitats,materiality`;
      const response = await fetch(KPIsURL,header);
      const data = response.json();
      return data;
    } catch (error) {
      console.error("Error at fetch kpis.");
    }
  }
  const [kpisResponse] = await Promise.all([
    fetchSiteKPIS(team)
  ]);
  let finalData = [[
    "Site Name",
    "Address",
    "Activities negatively affecting BSAs",
    "Potential impacts",
    "Actual impacts",
    "Potential dependencies",
    "Actual dependencies",
    "Ecological Status (0-100)",
    "Reference baseline",
    "BSAs impacted",
    "Impacts causing Land Degradation",
    "Impacts causing Desertification",
    "Impacts causing Soil Sealing",
    "Threatened species affected",
  ],];
  for (const siteKpis of kpisResponse) {
    const { habitatMigrationData, msa, BSAClosestDistanceMeters, impacts, dependencies, siteName,
      siteAddress, materiality, economic_activities } = siteKpis;

    //-------------//
    // list of economic_activity_names with this conditions:
    // that have a [M, H, VH] impacts rating EXCEPT to have only “ghg emissions” or only “non ghg emissions”.
    let eA = await Promise.all(
      economic_activities.map(async (eco) => {
        const response = await fetchDepAndImp("impacts", eco.economic_activity_id);
        return {
          economic_activity_id: eco.economic_activity_id,
          economic_activity_name: eco.economic_activity_name,
          impacts: response
        };
      })
    );
    const filteredActivities = eA.filter(activity =>
      activity.impacts.some(impact =>
        ["M", "H", "VH"].includes(impact.rating) &&
        !(impact.values.length === 1 &&
          (impact.values.includes("ghg_emissions") || impact.values.includes("non_ghg_emissions")))
      )
    ).map(activity => `• ${activity.economic_activity_name || "Unknown activity"}`).join("\n");
    //-------------//

    const fullSiteName = siteName;

    const impactsArray = impacts.flatMap((impact: any) => impact.values);
    const formatImpactCategory = (category: string[]) =>
      impactsArray.filter((impact: string) => impact && category.includes(impact)).map((impact: any) => ` • ${impact}`).join("\n") || "No impacts identified";

    const ecologicalStatus = msa !== undefined ? Number(msa).toFixed(2) : "N/A";
    
    const activitiesImpactingBSAsAndSpecies = filteredActivities;
    // impacts.filter((impact: { rating: string }) => ["VH", "H", "M"].includes(impact.rating))
    //   .flatMap((impact: { values: any[] }) => impact.values.filter((value: string) => value !== "ghg_emissions").map((value: any) => ` • ${value}`)).join("\n");

    const impactsCausingLandDegradation = formatImpactCategory(impactCategories.landDegradation);
    const impactsCausingSoilSealing = formatImpactCategory(impactCategories.sealingSoil);
    const impactsCausingDesertification = formatImpactCategory(impactCategories.desertification);

    const impactsCausingThreatenedSpecies = formatImpactCategory(impactCategories.threatenedSpeciesAffected);

    const materialityValues = materiality?.result?.answers;

    const potentialImpacts = impacts.filter((impact: { rating: string }) => ["VH", "H", "M"].includes(impact.rating))
      .flatMap((impact: { values: any[] }) => impact.values);

    const actualImpactAnswers = Object.entries(materialityValues?.impacts || {}).reduce((acc, [key, values]) => {
      key = key.toLowerCase().trim().replaceAll(" ", "_");
      acc[key] = values;
      return acc;
    }, {});

    const actualImpacts = potentialImpacts
      .filter((impact) => !actualImpactAnswers[impact] || actualImpactAnswers[impact].value !== "Low")
      .map((impact) => ` • ${impact}`)
      .join("\n");

    const potentialDependencies = dependencies.filter((dependency: { rating: string }) => ["VH", "H", "M"].includes(dependency.rating))
      .flatMap((dependency: { values: any[] }) => dependency.values)

    const actualDependenciesAnswers = Object.entries(materialityValues?.dependencies || {}).reduce((acc, [key, values]) => {
      key = key.toLowerCase().trim().replaceAll(" ", "_");
      acc[key] = values;
      return acc;
    }, {});

    const actualDependencies = potentialDependencies
      .filter((dep) => !actualDependenciesAnswers[dep] || actualDependenciesAnswers[dep].value !== "Low")
      .map((dep) => ` • ${dep}`)
      .join("\n");

    const habitats = habitatMigrationData?.habitatDict;
    const naturalHabitatPercentage = (() => {
      const numberPercentage = parseFloat(habitatMigrationData?.NaturalHabitatPercentage);
      if (numberPercentage) {
        if (numberPercentage < 50) return "35%";
        return "65%";
      }
      return "35%";
    })();

    const bsaResponse = BSAClosestDistanceMeters;
    let bsa = bsaResponse?.radiusFiltered?.features || [];
    bsa = bsa.map((item: any) => ` • ${item?.properties?.name || "N/A"}`).join("\n");

    finalData.push(
      [
        ` ${fullSiteName || "Unnamed Site"}`,
        ` ${siteAddress || "Address not available"}`,
        activitiesImpactingBSAsAndSpecies,
        potentialImpacts.map((value: any) => ` • ${value}`).join("\n"),
        actualImpacts,
        potentialDependencies.map((value: any) => ` • ${value}`).join("\n"),
        actualDependencies,
        ecologicalStatus,
        naturalHabitatPercentage,
        bsa,
        impactsCausingLandDegradation,
        impactsCausingDesertification,
        impactsCausingSoilSealing,
        impactsCausingThreatenedSpecies,
      ]);
  }
  return finalData;
};