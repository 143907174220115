import * as React from "react";
import { mdiMagnify } from "@mdi/js";
import { AppContext } from "@/context";
import { externalHttpGet, t } from "@/utils";
import { Icon, TextInput } from "@/lib";
import { MapboxApiEndpoint } from "@/lib/map";
import Fuse from "fuse.js"; // ES6

interface IExploreMapTopPanelProps {
    onSearchComplete: (data: { bbox: number[]; center: ILatLng; pointOfInterest: ILatLng; zoom: number }) => void;
    searchOptions?: Object[];
    gotoLocation?: (location: ILatLng) => void;
}

export default function ExploreMapTopPanel(props: IExploreMapTopPanelProps): JSX.Element {
    const { state } = React.useContext(AppContext);
    const [searchText, setSearchText] = React.useState<string>("");
    const [showSuggestions, setShowSuggestions] = React.useState<boolean>(false);
    const [filteredResults, setFilteredResults] = React.useState<Object[]>(props.searchOptions);
    const onPerformSearch = () => {
        let text = searchText.trim();

        if (text.length === 0) {
            return;
        }

        if (/^\s*-?([1-9]\d*|0)(\.\d+)?,\s*-?([1-9]\d*|0)(\.\d+)?\s*$/.test(text)) {
            // Mapbox api expects in longitude, latitude order when sought using coordinates, but users tend to supply
            // it in reverse order, which is more familiar

            let [lat, lng] = text.split(",");

            lat = lat.trim();
            lng = lng.trim();
            text = `${lng},${lat}`;
        }

        const url = `${MapboxApiEndpoint}/${encodeURIComponent(text)}.json`;
        const params = { access_token: state.config.mapbox_public_api_token };

        externalHttpGet<IMapboxQueryResult>(url, params).then((reply) => {
            for (const feat of reply.features) {
                if (feat.bbox && feat.center) {
                    const center = { lat: feat.center[1], lng: feat.center[0] };

                    props.onSearchComplete({
                        bbox: feat.bbox,
                        center: center,
                        pointOfInterest: center,
                        zoom: undefined,
                    });

                    return;
                } else if (feat.center) {
                    const center = { lat: feat.center[1], lng: feat.center[0] };

                    props.onSearchComplete({
                        bbox: undefined,
                        center: center,
                        pointOfInterest: center,
                        zoom: 13,
                    });

                    return;
                }
            }
        });
    };

    const fuse = new Fuse(props.searchOptions, { keys: ["name"] });
    function onTextChange(string: string) {
        setSearchText(string);

        if (string == "" || !string) return setFilteredResults(props.searchOptions);
        const results = fuse.search(string);
        setFilteredResults(results.map((_) => _.item));
    }
    return (
        <div className={"s-explore-map-search-box s-explore-map-search-box-" + state.layoutSize}>
            <TextInput
                id="map-search-box"
                ariaLabel={t("actions.search")}
                onEnter={onPerformSearch}
                onInput={onTextChange}
                onBlur={() => {
                    setTimeout(() => {
                        setShowSuggestions(false);
                    }, 200);
                }}
                onFocus={() => setShowSuggestions(true)}
                leftAccessory={<Icon color="#999" path={mdiMagnify} size={24} style={{ marginRight: ".5rem" }} />}
                placeholder="provide an address or search site"
                style={{ boxShadow: "0 2px 4px 0px rgba(0 0 0 / 0.1)", width: "24rem", backdropFilter: "blur(10px)" }}
                variant="default"
                value={searchText}
            />
            {showSuggestions ? (
                <div
                    style={{
                        boxShadow: "0 2px 4px 0px rgba(0 0 0 / 0.1)",
                        width: "24rem",
                        height: "24rem",
                        backdropFilter: "blur(10px)",
                        padding: "0.5rem 1rem 0.5rem 0.5rem",
                        border: "1px solid white",
                        borderRadius: "0.5rem",
                        overflowY: "scroll",
                    }}
                >
                    {filteredResults.map((result, index) => {
                        return (
                            <div
                                className="s-explore-map-search-box-suggestion dropdown-item"
                                style={{ cursor: "pointer" }}
                                key={index}
                                onClick={() => {
                                    props.gotoLocation({ lat: result.lat - 0.01, lng: result.lng + 0.01 });
                                }} // just a shift to remove the marker form appearing on the point
                            >
                                #{result.site_id} - {result.name}
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
}
