import * as React from "react";
import classnames from "classnames";

interface IButtonProps {
    active?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    size?: "md" | "sm";
    style?: React.CSSProperties;
    variant?: "default" | "primary";
}

export default function Button(props: IButtonProps): JSX.Element {
    const button = React.useRef<HTMLDivElement>();
    const classes = classnames("s-button", "s-button-" + (props.variant || "default"), "s-button-" + (props.size || "md"), {
        "s-button-active": props.active && !props.disabled,
        "s-button-disabled": props.disabled,
    });

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (!props.disabled && props.onClick && ["Enter", "NumpadEnter", "Space"].includes(e.code)) {
            e.preventDefault();
            e.stopPropagation();
            props.onClick();
        }
    };

    React.useEffect(() => {
        if (props.disabled) {
            button.current.blur();
        }
    }, [props.disabled]);

    return (
        <div
            className={classes}
            onClick={props.disabled ? () => false : props.onClick}
            onKeyDown={onKeyDown}
            ref={button}
            role="button"
            style={props.style}
            tabIndex={props.disabled ? -1 : 0}
            aria-disabled={props.disabled}
        >
            {props.children}
        </div>
    );
}
