import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useProject, useTeam } from "@/context";
import { formatted, formatPercentage, t } from "@/utils";
import { Button } from "@/lib";

interface IHeadlinePanelProps {
    areas: IMapFeature[];
    kpis: Record<string, number>;
    onPathChange: (path: (number | string)[]) => void;
    path: (number | string)[];
    poiSiteId: number;
    raster: IProjectRasterConfig;
}

const StandardRasterIds = ["aq3bwscat20190715", "msa2015"];

export default function HeadlineKpisPanel(props: IHeadlinePanelProps): JSX.Element {
    const navigate = useNavigate();
    const team = useTeam();
    const project = useProject();
    const { areas, kpis } = props;
    const { aq3bwscat20190715, msa2015 } = kpis;
    const rasterCategories = React.useMemo(() => {
        if (props.raster?.categories?.length > 0) {
            const cats = new Map<number, string>();

            for (const { label, value } of props.raster.categories || []) {
                cats.set(value, label);
            }

            return cats;
        }

        return undefined;
    }, [props.raster?.categories]);
    const onActionClick = () => {
        if (props.poiSiteId) {
            navigate(`/${team.slug}/${project.slug}/sites/${props.poiSiteId}`);
        } else {
            props.onPathChange(["add"]);
        }
    };

    let rasterKpi = undefined;

    if (props.raster && Object.keys(kpis).includes(props.raster.id)) {
        rasterKpi = rasterCategories
            ? rasterCategories.get(Math.round(kpis[props.raster.id]))
            : formatted(props.raster.value_format, kpis[props.raster.id]);
    }

    return (
        <div className="s-map-bottom-panel-headline">
            <div className="s-map-bottom-panel-kpis">
                {props.raster && !StandardRasterIds.includes(props.raster.id) && (
                    <div className="s-map-bottom-panel-kpi">
                        <span>{props.raster.kpi_label || props.raster.label}</span>
                        <span>{rasterKpi === undefined || rasterKpi === null ? "—" : rasterKpi}</span>
                    </div>
                )}
                <div className="s-map-bottom-panel-kpi">
                    <span>{t("ui.mean_species_abundance")}</span>
                    <span>{msa2015 > 0 ? formatPercentage(msa2015) : "–"}</span>
                </div>
                <div className="s-map-bottom-panel-kpi">
                    <span>{t("ui.overall_water_risk")}</span>
                    <span>{aq3bwscat20190715 !== undefined ? t("waterRisk." + aq3bwscat20190715) : "—"}</span>
                </div>
                <div className="s-map-bottom-panel-kpi">
                    <span>{t("ui.protected_area_plural")}</span>
                    <span>
                        <span>{areas ? areas.length : "—"}</span>
                        <Button
                            disabled={!areas || areas.length === 0}
                            onClick={() => props.onPathChange(["areas"])}
                            size="sm"
                            style={{ marginLeft: ".75rem" }}
                        >
                            {t("ui.show_all")}
                        </Button>
                    </span>
                </div>
            </div>

            <span className="s-flexy" />

            <div>
                <div style={{ lineHeight: 1 }}>&nbsp;</div>
                <Button onClick={onActionClick} size="sm" style={{ marginRight: "1rem" }} variant="primary">
                    {t(props.poiSiteId ? "ui.view_site" : "ui.add_to_sites")}
                </Button>
            </div>
        </div>
    );
}
