import * as React from "react";
import { Button, Dropdown, FormFieldLabel, HorizontalStack, TextInput, VerticalStack } from "@/lib";
import { updateCountries, updateIndustries } from "@/resources";
import { AppContext } from "@/context";
import { t } from "@/utils";
import { mandatoryStringError, requiredErrorText } from "@/utils/validation";

interface ISiteFormProps {
    data: IAddToSitesData;
    setData: (data: IAddToSitesData) => void;
    submitText: string;
    onCancel: () => void;
    onSubmit: () => void;
    isSaving: boolean;
}

function asIndustry(opt: { id: string; label: string }): {
    code: string;
    name: string;
} {
    return { code: opt.id, name: opt.label };
}

export function checkIsSaveable(data: IAddToSitesData): boolean {
    const address = data.location.address;
    return !nameError(address.name) && !industryError(data.industry?.code) && !countryError(address.country_code) && !!data.location.center;
}

const nameError = (name: string): string => {
    return name && name.trim().length > 0 ? null : errorText;
};

const industryError = (industryCode: string): string => {
    return mandatoryStringError(industryCode);
};

const countryError = (countryCode: string): string => {
    return countryCode && countryCode.length === 2 ? null : errorText;
};

const errorText: string = requiredErrorText;

const SitePropertiesForm = React.forwardRef(
    (props: ISiteFormProps, ref: React.ForwardedRef<{ showErrorsAndFocus: () => void }>): JSX.Element => {
        React.useImperativeHandle(ref, () => ({
            // eslint-disable-next-line no-unused-labels
            showErrorsAndFocus: () => {
                showErrors();
                firstInvalid().focus();
            },
        }));
        const { dispatch, state } = React.useContext(AppContext);
        const [countryOptions, setCountryOptions] = React.useState<{ id: string; label: string }[]>([]);
        const [industryOptions, setIndustryOptions] = React.useState<{ id: string; label: string }[]>([]);
        const [isSaveable, setIsSaveable] = React.useState<boolean>(false);
        const nameRef = React.useRef<HTMLInputElement>(null);
        const industriesRef = React.useRef<HTMLDivElement>(null);
        const countriesRef = React.useRef<HTMLDivElement>(null);
        const locationErrorRef = React.useRef<HTMLDivElement>(null);
        const [errors, setErrors] = React.useState<Record<string, string>>({});

        const onChooseCountry = (id: string) => {
            const countryLabel = countryOptions.find((o) => o.id === id).label;
            props.setData({
                ...props.data,
                location: {
                    ...props.data.location,
                    address: {
                        ...props.data.location.address,
                        country_code: id,
                        country: countryLabel,
                    },
                },
            });
        };

        const setAddressField = (name: string, value: string) =>
            props.setData({
                ...props.data,
                location: {
                    ...props.data.location,
                    address: { ...props.data.location.address, [name]: value },
                },
            });

        React.useEffect(() => {
            updateCountries(state, dispatch).then((countries) =>
                setCountryOptions(
                    Object.values(countries)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((c) => ({ id: c.code, label: c.name })),
                ),
            );

            updateIndustries(state, dispatch).then((industries) =>
                setIndustryOptions(
                    Object.values(industries)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((c) => ({ id: c.code, label: c.name })),
                ),
            );
        }, []);

        React.useEffect(() => {
            setIsSaveable(checkIsSaveable(props.data));
        }, [props.data, errors]);

        React.useEffect(() => {
            if (props.data.location.center) {
                errors.location = null;
            }
        }, [props.data.location.center]);

        const firstInvalid = () => {
            if (nameError(props.data.location.address.name)) {
                return nameRef.current;
            } else if (industryError(props.data.industry?.code)) {
                return industriesRef.current;
            } else if (countryError(props.data.location.address.country_code)) {
                return countriesRef.current;
            } else if (!props.data.location.center) {
                return locationErrorRef.current;
            }

            return null;
        };

        const showErrors = (): void => {
            setErrors({
                name: nameError(props.data.location.address.name),
                industry: industryError(props.data.industry?.code),
                country: countryError(props.data.location.address.country_code),
                location: !props.data.location.center ? t("error.choose_location") : null,
            });
        };

        const checkAndSubmit = (): void => {
            if (isSaveable) {
                props.onSubmit();
            } else {
                showErrors();
                firstInvalid().focus();
            }
        };

        const validate = (field: string): void => {
            if (field === "name") {
                setErrors({
                    ...errors,
                    name: nameError(props.data.location.address.name),
                });
            } else if (field === "industry") {
                setErrors({
                    ...errors,
                    industry: industryError(props.data.industry?.code),
                });
            } else if (field === "country") {
                setErrors({
                    ...errors,
                    country: countryError(props.data.location.address.country_code),
                });
            }
        };

        return (
            <form>
                <VerticalStack alignItems="stretch" spacing="none" style={{ margin: "auto", maxWidth: "50rem" }}>
                    <div ref={locationErrorRef} className="s-error-text" tabIndex={-1}>
                        {errors.location && <span>{errors.location}</span>}
                    </div>
                    <FormFieldLabel label={t("ui.name")} labelStyle={{ width: "5rem" }}>
                        <TextInput
                            ref={nameRef}
                            id="address_name"
                            onInput={(v) => setAddressField("name", v)}
                            placeholder={t("ui.name")}
                            value={props.data.location.address.name}
                            error={errors.name}
                            onBlur={() => validate("name")}
                        />
                    </FormFieldLabel>

                    <FormFieldLabel label={t("ui.industry")} labelStyle={{ marginTop: "1.65rem", width: "5rem" }}>
                        <Dropdown
                            ref={industriesRef}
                            id="edit_industry"
                            items={industryOptions}
                            label={props.data.industry?.name}
                            onItemClick={(id) =>
                                props.setData({
                                    ...props.data,
                                    industry: asIndustry(industryOptions.find((opt) => opt.id === id)),
                                })
                            }
                            placeholder={t("actions.choose") + "…"}
                            style={{ marginTop: "1rem" }}
                            error={errors.industry}
                            onBlur={() => validate("industry")}
                        />
                    </FormFieldLabel>

                    <p style={{ margin: "1rem 0 0 6.5rem" }}>{t("add_site_panel.check_and_fix_address")}</p>

                    <FormFieldLabel label={t("ui.address")} labelStyle={{ marginTop: "1.65rem", width: "5rem" }}>
                        <TextInput
                            id="address_street"
                            onInput={(v) => setAddressField("street_address", v)}
                            placeholder={t("ui.street_address")}
                            style={{ marginTop: "1rem" }}
                            value={props.data.location.address.street_address}
                        />

                        <HorizontalStack spacing="condensed" style={{ marginTop: ".5rem" }}>
                            <TextInput
                                id="address_zip"
                                onInput={(v) => setAddressField("zip_code", v)}
                                placeholder={t("ui.zip_code")}
                                value={props.data.location.address.zip_code}
                            />
                            <TextInput
                                id="address_city"
                                onInput={(v) => setAddressField("city", v)}
                                placeholder={t("ui.city")}
                                value={props.data.location.address.city}
                            />
                        </HorizontalStack>

                        <HorizontalStack spacing="condensed" style={{ marginTop: ".5rem" }}>
                            <TextInput
                                id="address_province"
                                onInput={(v) => setAddressField("province", v)}
                                placeholder={t("ui.province")}
                                style={{ width: "50%" }}
                                value={props.data.location.address.province}
                            />
                            <Dropdown
                                ref={countriesRef}
                                id="edit_country"
                                items={countryOptions}
                                label={props.data.location.address.country}
                                onItemClick={onChooseCountry}
                                style={{ width: "50%" }}
                                error={errors.country}
                                onBlur={() => validate("country")}
                            />
                        </HorizontalStack>
                    </FormFieldLabel>

                    <HorizontalStack horizontalAlign="flex-end" spacing="condensed" style={{ marginTop: "1.5rem" }}>
                        <Button onClick={props.onCancel}>{t("actions.cancel")}</Button>
                        <Button disabled={props.isSaving} onClick={checkAndSubmit} variant="primary">
                            {props.submitText}
                        </Button>
                    </HorizontalStack>
                </VerticalStack>
            </form>
        );
    },
);

SitePropertiesForm.displayName = "SitePropertiesForm";

export default SitePropertiesForm;
