import React from "react";
import { HAZARD_TYPE_MAP } from "../helper";
import { riskToColor, getHazardClass, pickHighestRiskTodayAcrossScenarios } from "../utils";
import { FUTURE_TIMES, DIMENSION_OPTIONS } from "../constants";
import { ClimateRiskTableProps } from "../types";
import { LoadingState } from "./LoadingState";
import { capitalize } from "@/utils";
import { DropDownTableHeaderCell } from "@/lib/TableHeaderCell";

// Component
export const ClimateRiskTable: React.FC<ClimateRiskTableProps> = ({
    data,
    SCENARIOS,
    SCENARIO_LABELS,
    dimension,
    setDimension,
    loading,
    error,
}) => {
    const [riskType, setRiskType] = React.useState<string>("All");
    const [riskName, setRiskName] = React.useState<string>("All");

    // Build hazard list for the table
    const hazardSet = new Set<string>();
    data &&
        SCENARIOS.forEach((scenario) => {
            const scenObj = data[scenario];
            if (!scenObj) return;

            // Gather from today
            const todayObj = scenObj.today;
            if (todayObj) {
                Object.keys(todayObj).forEach((k) => {
                    4;
                    if (["emission_scenario", "model", "downscale", "latitude", "longitude", "start_year", "end_year"].includes(k)) return;
                    hazardSet.add(k);
                });
            }

            // Gather from future times
            FUTURE_TIMES.forEach((time) => {
                const timeData = scenObj[time];
                if (!timeData) return;
                Object.keys(timeData).forEach((k) => {
                    if (["emission_scenario", "model", "downscale", "latitude", "longitude", "start_year", "end_year"].includes(k)) return;
                    hazardSet.add(k);
                });
            });
        });

    const [hazardArr, setHazardArr] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (!data) return;

        const filteredHazards = Array.from(hazardSet)
            .sort()
            .filter((hazardKey) => {
                const hazardName = hazardKey.replace(/_/g, " ");
                const meta = HAZARD_TYPE_MAP[hazardName];

                if (riskType !== "All") {
                    return meta && meta.type === riskType;
                }

                if (riskName !== "All") {
                    return hazardName === riskName.replace(/_/g, " ");
                }

                return meta;
            });

        setHazardArr(filteredHazards);
    }, [data, riskType, riskName, hazardSet]);

    return (
        <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "center", fontSize: "12px" }}>
            <thead>
                <tr style={{ background: "#293345" }}>
                    <th rowSpan={2} style={{ border: "1px solid #444", padding: 8, minWidth: 150 }}>
                        <DropDownTableHeaderCell
                            dropdownOptions={[
                                { id: "All", label: "All" },
                                ...Array.from(hazardSet)
                                    .sort()
                                    .map((hazard) => ({ id: hazard, label: capitalize(hazard.replace(/_/g, " ")) })),
                            ]}
                            dropdownSelected={[riskName]}
                            dropDownHandler={(id) => {
                                console.log("risk name", id);
                                setRiskName(id);
                            }}
                            label="Risk"
                            labelStyle={{ textAlign: "left", padding: "12px", fontWeight: "bolder" }}
                            width="15%"
                        />
                    </th>
                    <th rowSpan={2} style={{ border: "1px solid #444", padding: 8, minWidth: 120 }}>
                        <DropDownTableHeaderCell
                            dropdownOptions={DIMENSION_OPTIONS.map((option) => ({ id: option.id, label: option.label }))}
                            dropdownSelected={[dimension]}
                            dropDownHandler={(id) => setDimension(id)}
                            label="Risk Category"
                            labelStyle={{ textAlign: "left", padding: "12px", fontWeight: "bolder" }}
                            width="15%"
                        />
                    </th>
                    <th rowSpan={2} style={{ border: "1px solid #444", padding: 8, minWidth: 120 }}>
                        <DropDownTableHeaderCell
                            dropdownOptions={[
                                { id: "All", label: "All" },
                                { id: "Acute", label: "Acute" },
                                { id: "Chronic", label: "Chronic" },
                            ]}
                            dropdownSelected={[riskType]}
                            dropDownHandler={(id) => setRiskType(id)}
                            label={`Risk Type: ${riskType}`}
                            labelStyle={{ textAlign: "left", padding: "12px", fontWeight: "bolder" }}
                            width="15%"
                        />
                    </th>
                    <th rowSpan={2} style={{ border: "1px solid #444", padding: 8, minWidth: 100 }}>
                        Today
                    </th>
                    {SCENARIOS.map((scenario) => (
                        <th key={scenario} colSpan={FUTURE_TIMES.length} style={{ border: "1px solid #444", padding: 8 }}>
                            {SCENARIO_LABELS[scenario]}
                        </th>
                    ))}
                </tr>
                <tr style={{ background: "#293345" }}>
                    {SCENARIOS.flatMap((scenario) =>
                        FUTURE_TIMES.map((time) => (
                            <th key={`${scenario}-${time}`} style={{ border: "1px solid #444", padding: 6 }}>
                                {time}
                            </th>
                        )),
                    )}
                </tr>
            </thead>
            {loading ? (
                <LoadingState loading={loading} error={error} />
            ) : error ? (
                <LoadingState loading={false} error={error} />
            ) : (
                data && (
                    <tbody>
                        {hazardArr.map((hazardKey) => {
                            const hazardName = hazardKey.replace(/_/g, " ");
                            const meta = HAZARD_TYPE_MAP[hazardName];
                            const category = meta ? meta.type : "Unknown";

                            // Get highest risk for today across all scenarios
                            const bestToday = pickHighestRiskTodayAcrossScenarios(data, hazardKey, SCENARIOS);

                            return (
                                <tr key={hazardName}>
                                    <td
                                        style={{
                                            border: "1px solid #444",
                                            padding: 8,
                                            backgroundColor: "#1F2F4C",
                                            textAlign: "left",
                                        }}
                                    >
                                        {capitalize(hazardName)}
                                    </td>
                                    <td
                                        style={{
                                            border: "1px solid #444",
                                            padding: 8,
                                            backgroundColor: "#1F2F4C",
                                            textAlign: "left",
                                        }}
                                    >
                                        {dimension}
                                    </td>
                                    <td
                                        style={{
                                            border: "1px solid #444",
                                            padding: 8,
                                            backgroundColor: "#1F2F4C",
                                        }}
                                    >
                                        {category}
                                    </td>
                                    <td
                                        style={{
                                            border: "1px solid #444",
                                            padding: 8,
                                            backgroundColor: riskToColor(bestToday),
                                        }}
                                    >
                                        {capitalize(
                                            {
                                                "red flag": "severe",
                                                "no risk": "low risk",
                                                "not defined": "no data",
                                            }[bestToday] || bestToday,
                                        )}
                                    </td>
                                    {SCENARIOS.flatMap((scenario) =>
                                        FUTURE_TIMES.map((time) => {
                                            const hazardObj = data[scenario]?.[time]?.[hazardKey];
                                            const futureRisk = getHazardClass(hazardObj, time) || "no data";
                                            return (
                                                <td
                                                    key={`${hazardName}-${scenario}-${time}`}
                                                    style={{
                                                        border: "1px solid #444",
                                                        padding: 8,
                                                        backgroundColor: riskToColor(futureRisk),
                                                    }}
                                                >
                                                    {capitalize(
                                                        {
                                                            "red flag": "severe",
                                                            "no risk": "low risk",
                                                            "not defined": "no data",
                                                        }[futureRisk] || futureRisk,
                                                    )}
                                                </td>
                                            );
                                        }),
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                )
            )}
        </table>
    );
};
