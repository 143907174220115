const ScorePrecision = 1000;
const ScoreDecimals = 3;
const NumberFormatters = new Map<number, Intl.NumberFormat>();

for (const d of [0, 1, 2, 3, 4, 5]) {
    NumberFormatters.set(
        d,
        new Intl.NumberFormat(navigator.language, {
            maximumFractionDigits: d,
            minimumFractionDigits: d,
        }),
    );
}

export function round(value: number, digits = 1): string {
    return NumberFormatters.get(digits).format(value);
}

export function formatted(format: IValueFormat, value: number): string {
    if (!format) {
        return "" + value;
    }

    return `${round(value * (format?.multiplier || 1), format?.num_decimals || 0)}${format?.suffix || ""}`;
}

export function formatPercentage(value: number, digits = 1): string {
    if (value == 1) {
        return "100\u200A\u200A%";
    }

    const mul = digits > 0 ? digits * 10 : 1;
    const v = Math.round(value * 100 * mul) / mul;

    return (isNaN(v) ? "—" : round(v, digits)) + "\u200A\u200A%";
}

export function formatScoreValue(value: number, toNearestInteger = false): string {
    const v = Math.round(ScorePrecision * value) / ScorePrecision;

    if (isNaN(v)) {
        return "—";
    }

    return round(v, toNearestInteger ? 0 : ScoreDecimals);
}
