import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { configReducer } from './config';
import { generateId } from '@/utils';
import { makeProjectsState, projectsReducer } from './projects';
import { makeRastersState, rastersReducer } from './rasters';
import { makeSFDRState, sfdrReducer } from './reports';
import { makeSpeciesState, speciesReducer } from './species';
import { makeNotificationsState, notificationsReducer } from './notifications';
import {orderByPriority} from "@/utils/sort";
import { INewDBSite } from '@/types/sleap';

export { ConfigChanged } from './config';
export { SFDRReportChanged } from './reports';
export { SpeciesChanged } from './species';
export { NotificationsChanged, NotificationRemoved } from './notifications';
export { ProjectExploreBaseMapChanged, ProjectExploreRastersChanged, ProjectsChanged, ProjectChanged, ProjectRemoved,
         ProjectReportChanged, ProjectReportSiteMeasureChanged } from './projects';
export { makeRasterColormapKey, RasterColormapChanged, RasterMetadataChanged } from './rasters';

export const CountriesChanged = 'countriesChanged';
export const IndustriesChanged = 'industriesChanged';
export const IpbesDriversChanged = 'ipbesDriversChanged';
export const LayoutSizeChanged = 'layoutSizeChanged';
export const NavigationEnabledChanged = 'navigationEnabledChanged';
export const NavigationPanelVisibleChanged = 'navigationPanelVisibleChanged';
export const ToastAdded = 'toastAdded';
export const ToastRemoved = 'toastRemoved';
export const DownloadTokenAdded = 'downloadTokenAdded';
export const DownloadTokenRemoved = 'donwloadTokenRemoved';
export const NavigationChanged = 'navigationChanged';
export const SideStepVisible = 'sideStepVisible';
export const SitesChanged = 'sitesChanged';
export const SiteDeleted = 'siteDeleted';
export const SiteUpdated = 'siteUpdated';
export const ShowSideStepDetail = 'showSideStepDetail';
export const ImpactsChanged = 'impactsChanged';
export const DependenciesChanged = 'dependenciesChanged';
export const EconomicActivityCountChanged = 'economicActivityCountChanged';
export const ImpactsAndDependenciesChanges = 'impactsAndDependenciesChanged';
export const SetLoadingState = 'setLoadingState';

export interface IAppContext {
    dispatch: React.Dispatch<IGenericAction>
    state: IAppState
}


const Reducer = (state: IAppState, action: IGenericAction): IAppState => {
    const prefix = action.type.split('/', 1)[0];

    switch (prefix) {
        case 'config':
            return {...state, config: configReducer(state.config, action)};

        case NavigationChanged:
            return {...state, sideStepActive: action.sideStepActive};

        case SideStepVisible:
            return {...state, showSideStep: action.showSideStep};

        case ShowSideStepDetail:
            return {...state, showSideStepDetail: action.value};

        case CountriesChanged:
            return {...state, countries: action.countries};

        case IndustriesChanged:
            return {...state, industries: action.industries};

        case IpbesDriversChanged:
            return {...state, ipbesDrivers: action.ipbesDrivers};

        case LayoutSizeChanged:
            return {...state, layoutSize: action.size};

        case NavigationEnabledChanged:
            return {...state, navigationEnabled: action.navigationEnabled};

        case NavigationPanelVisibleChanged:
            return {...state, navigationPanelVisible: action.navigationPanelVisible};

        case ToastAdded:
            return {...state, toasts: [{id: generateId(), ...action.toast}, ...state.toasts]};

        case ToastRemoved:
            return {...state, toasts: state.toasts.filter(t => t.id !== action.toast.id)};

        case DownloadTokenAdded:
            return {...state, downloadTokens: [...state.downloadTokens, action.token]};

        case DownloadTokenRemoved:
            return {...state, downloadTokens: state.downloadTokens.filter(t => t !== action.token)};

        case 'notifications':
            return {...state, notifications: notificationsReducer(state.notifications, action)};

        case 'projects':
            return {...state, projects: projectsReducer(state.projects, action)};

        case 'rasters':
            return {...state, rasters: rastersReducer(state.rasters, action)};

        case 'sfdr':
            return {...state, sfdr: sfdrReducer(state.sfdr, action)};

        case 'species':
            return {...state, species: speciesReducer(state.species, action)};

        case 'toggleDataLayerModal':
            return {...state, showDataLayerModal: !state.showDataLayerModal};

        case SitesChanged:
            return {...state, sites: action.sites};

        case SiteUpdated:
            const updatedSite = action.site;

            // remove update site from state and add it so useEffect will trigger
            const sitesWithoutUpdatedSite = state.sites.filter(site => site.id !== updatedSite.id);
            sitesWithoutUpdatedSite.push(updatedSite);
            sitesWithoutUpdatedSite.sort(orderByPriority)
            return {...state, sites: sitesWithoutUpdatedSite};

        case SiteDeleted:
            const deletedSiteId = action.siteId;
            const sitesWithoutDeletedSite = state.sites.filter(site => site.id !== deletedSiteId);
            return {...state, sites: sitesWithoutDeletedSite};

        case DependenciesChanged:
            return { ...state, dependencies: action.dependencies};

        case ImpactsChanged:
            return { ...state, impacts: action.impacts};

        case EconomicActivityCountChanged:
            return { ...state, economicActivityCount: action.count};

        case ImpactsAndDependenciesChanges:
            return { ...state, impactClusters: action.impacts, dependencyClusters: action.dependencies };
        case SetLoadingState:
            return { ...state, isLoading: action.loading };
    }

    throw new Error(`Unhandled action: ${action.type}`);
};


const LoggingReducer = (state: IAppState, action: IGenericAction): IAppState => {
    const newState = Reducer(state, action);

    if (newState.config?.debug) {
        // @ts-expect-error: When the debug is on, set the current state to global
        //                   variable so it can be inspected using dev tools
        window.AppState = newState;
    }

    return newState;
};


const AppState: IAppState = {
    config: null,
    countries: undefined,
    downloadTokens: [],
    industries: undefined,
    ipbesDrivers: undefined,
    layoutSize: getLayoutSize(),
    navigationEnabled: true,
    navigationPanelVisible: false,
    notifications: makeNotificationsState(),
    projects: makeProjectsState(),
    sites: [],
    rasters: makeRastersState(),
    sfdr: makeSFDRState(),
    species: makeSpeciesState(),
    toasts: [],
    showDataLayerModal: false,
    sideStepActive: '',
    showSideStep: false,
    showSideStepDetail: false,
    dependencies: [],
    impacts: [],
    economicActivityCount: 0,
    impactClusters: [],
    dependencyClusters: [],
    isLoading: false,
};


export const AppContext = React.createContext<IAppContext>({ dispatch: (): null => null, state: AppState });

let internalDispatch: (msg: IGenericAction) => void = null;


export const AppProvider = (props: { children: React.ReactNode }): JSX.Element => {
    const [state, dispatch] = React.useReducer(LoggingReducer, AppState);

    internalDispatch = dispatch;

    return (
        <AppContext.Provider value={{ dispatch, state }}>
            {props.children}
        </AppContext.Provider>
    );
};

export function useSites(): INewDBSite[] {

    return React.useContext(AppContext).state.sites;
}

export function useUser(): IUser {
    return React.useContext(AppContext).state.config.user;
}


export function useTeam(): ITeam {
    return React.useContext(AppContext).state.config?.team;
}

export function useLoading(): boolean {

    return React.useContext(AppContext).state.isLoading;
}

export function useProject(): IProject {
    const params = useParams();
    const state = React.useContext(AppContext).state;

    return state.projects.projects[params.project] as IProject;
}

export const useDependencies = () => {

    const state = React.useContext(AppContext).state;
    return state.dependencies
};

export const useImpacts = () => {

    const state = React.useContext(AppContext).state;
    return state.impacts
};

export const useEconomicActivityCount = () => {

    const state = React.useContext(AppContext).state;
    return state.economicActivityCount
}


function getLayoutSize(): LayoutSize {
    const w = window.innerWidth;
    let size: LayoutSize = 'xs';

    if (w >= 1280) {
        size = 'xl';
    }
    else if (w >= 1024) {
        size = 'lg';
    }
    else if (w >= 768) {
        size = 'md';
    }
    else if (w >= 640) {
        size = 'sm';
    }

    return size;
}


let layoutSize = getLayoutSize();

window.addEventListener('resize', () => {
    const size = getLayoutSize();

    if (layoutSize !== size) {
        // Call internal dispatch if it has been set up (it has in the app, but not in the admin)

        layoutSize = size;
        internalDispatch?.({ type: LayoutSizeChanged, size: layoutSize });
    }
});


export function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
}


// Note! Keep these in sync with lib/mixins.scss

const AppTheme: IAppTheme = {
    boldFontFamily: 'Roobert Medium',
    darkMode: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
    bgColor: '#FCFCFB',
    hairlineColor: '#E0DFDA',
    tintColor: '#BE98EE',
    tintColorText: '#9455E2',
    textColor: '#0A0A0A',
    secondaryTextColor: '#99998A',
    smallFontSize: '0.9rem',
    successColor: '#6AA71C',
};

export function useTheme(): IAppTheme {
    return AppTheme;
}
