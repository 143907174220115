import * as React from "react";
import { mdiDeleteForever } from "@mdi/js";
import { BaseModalDialog } from "@/lib/modals";
import Image from "../../assets/imageplaceholder.png";
import File from "../../assets/file-placeholder.png";
import { Icon } from "@/lib";
import Tooltip from "../../lib/Tooltip/Tooltip";
import CryptoJS from "crypto-js";
import "./ViewDocs.scss";
import { AppContext } from "@/context";
interface Document {
    id: number;
    filename: string;
    path: string;
    type: string;
}
interface DocumentsProps {
    documents: Document[];
}

export const ViewDocs: React.FC<DocumentsProps> = ({ documents, deleteHandler }) => {
    const { state } = React.useContext(AppContext);
    const isSuperUser = state.config.user.is_superuser;
    const [tooltip, setTooltip] = React.useState<{ title: string; id: number }>(undefined);
    const [selectedDocument, setSelectedDocument] = React.useState<Document | null>(null);
    const [previewURL, setPreviewURL] = React.useState<string | null>("");
    const [downloadURL, setDownloadURL] = React.useState<string | null>("");

    const imageTypeMap = {
        png: true,
        jpg: true,
        jpeg: true,
        webp: true,
    };
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            Authorization: `Bearer ${encryptedToken}`,
        },
    };
    const handleThumbnailClick = (doc) => {
        setSelectedDocument(doc);
        generateDownloadLink(doc);
    };

    const handleCloseModal = () => {
        setSelectedDocument(null);
    };
    async function generateDownloadLink(doc) {
        let generateURL = `${process.env.KUYUA_APP_BACKEND_URL}/docs/generate-download-link?file_name=${doc.path}`;
        await fetch(generateURL, header)
            .then((response) => {
                const downloadURLHeader = response.headers.get("X-download-url");
                setDownloadURL(downloadURLHeader);
                return response.blob().then((blob) => ({
                    blob,
                    downloadURLHeader,
                }));
            })
            .then(({ blob, downloadURLHeader }) => {
                // Use the blob and additional data
                const url = URL.createObjectURL(blob);
                setPreviewURL(url);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    const handleDownload = async () => {
        if (selectedDocument) {
            await fetch(downloadURL, header)
                .then((response) => {
                    if (response.ok) {
                        return response.blob();
                    }
                    throw new Error("Failed to download document");
                })
                .then((blob) => {
                    const downloadUrl = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    link.download = selectedDocument.filename;
                    link.click();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    // const renderThumbnail = (document: Document) => {
    //   const isImage = imageTypeMap[document.type] || false;
    //   const thumbnailSrc = isImage ? Image : File;

    //   return (
    //     <div key={document.id} className="thumbnail">
    //       {tooltip?.id === document.id && (
    //         <div
    //           style={{
    //             color: "black",
    //             position: "relative",
    //             bottom: isSuperUser ? "" : "31px",
    //           }}
    //         >
    //           <Tooltip alignment="left">{tooltip.title}</Tooltip>
    //         </div>
    //       )}
    //       {isSuperUser && (
    //         <Icon
    //           path={mdiDeleteForever}
    //           size={25}
    //           onClick={() => deleteHandler(document)}
    //         />
    //       )}
    //       <img
    //         src={thumbnailSrc}
    //         onMouseOver={() =>
    //           setTooltip({ title: document.filename, id: document.id })
    //         }
    //         onMouseOut={() => setTooltip(undefined)}
    //         alt={document.filename}
    //         className="documentImage"
    //         onClick={() => handleThumbnailClick(document)}
    //       />
    //     </div>
    //   );
    // };

    return (
        <div className="ViewDocs">
            <h2>View Documents</h2>
            <div className="documentsContainer" style={{ padding: 0 }}>
                {/* {documents.map(renderThumbnail)} */}

                <ul style={{ padding: 0 }}>
                    {documents.map((doc) => (
                        <li
                            style={{
                                display: "flex",
                                alignItems: "center",
                                listStyleType: "circle",
                                margin: "-20px 0px",
                            }}
                            onClick={() => handleThumbnailClick(doc)}
                        >
                            <p
                                style={{
                                    fontSize: "20px",
                                    textDecorationLine: "underline",
                                    cursor: "pointer",
                                    textWrap: "nowrap",
                                    display: "flex",
                                    alignItems: "center",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                • {doc.filename}
                            </p>
                            <Icon path={mdiDeleteForever} size={25} onClick={() => deleteHandler(doc)} color="red" />
                        </li>
                    ))}
                </ul>
            </div>

            {selectedDocument && (
                <BaseModalDialog
                    scrollableBody="false"
                    width="90%"
                    height="800px"
                    title={selectedDocument.filename}
                    onClose={handleCloseModal}
                    children={undefined}
                >
                    <div className="fullImageDocument">
                        <iframe src={previewURL} title={selectedDocument.filename} className="fullDocument"></iframe>
                        <button onClick={handleDownload} className="downloadButton">
                            Download
                        </button>
                    </div>
                </BaseModalDialog>
            )}
        </div>
    );
};
