import * as React from "react";
import classnames from "classnames";
import { useProject, useTeam } from "@/context";
import { apiGet } from "@/utils";

interface INotificationBarProps {
    actions: {
        label: string;
        onClick: () => void;
    }[];
    hasMore?: boolean;
    label: string;
    style?: React.CSSProperties;
}

export default function NotificationBar(props: INotificationBarProps): JSX.Element {
    const team = useTeam();
    const project = useProject();
    const [notifications, setNotifications] = React.useState<INotification[]>(undefined);

    React.useEffect(() => {
        apiGet<INotification[]>(team.slug, `projects/${project.slug}/notifications`).then((reply) => {
            if (reply.ok) {
                setNotifications(reply.data);
            }
        });
    }, []);

    if (!notifications?.length) {
        return <></>;
    }

    return (
        <div
            className={classnames("s-notification-bar", {
                "s-notification-bar-has-more": props.hasMore,
            })}
            style={props.style}
        >
            <div>{props.label}</div>
            {props.actions.map((a, ix) => (
                <button key={`${ix} ${a.label}`} onClick={a.onClick}>
                    {a.label}
                </button>
            ))}
        </div>
    );
}
