import React, { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { LoadingAnimation } from "@/lib";
import CryptoJS from "crypto-js";
import BSA from "./BSA";

const BSAMap = ({ site, onBSAChange, showKBA }) => {
    const [selectedBSA, setSelectedBSA] = React.useState({});
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isAlertOpen, setAlertOpen] = React.useState(false);

    const showAlert = () => {
        setAlertOpen(true);
    };

    const closeAlert = () => {
        setAlertOpen(false);
    };

    function addMarker(map, lng, lat) {
        const marker = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);
        map.setCenter([lng, lat]);
        marker.setLngLat([lng, lat]);
    }

    function createCircle(map: any, lng: number, lat: number, radiusInM: number, points = 64) {
        const radiusInKm = radiusInM / 1000;
        const output = [];
        const dx = radiusInKm / (111.32 * Math.cos((lat * Math.PI) / 180));
        const dy = radiusInKm / 110.574;
        let theta, x, y;

        for (let i = 0; i < points; i++) {
            theta = (i / points) * (2 * Math.PI);
            x = dx * Math.cos(theta);
            y = dy * Math.sin(theta);

            output.push([lng + x, lat + y]);
        }

        output.push(output[0]);

        const circleSourceData = {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        properties: {},
                        geometry: {
                            type: "Polygon",
                            coordinates: [output],
                        },
                    },
                ],
            },
        };

        map.addSource("circle-radius", circleSourceData);

        map.addLayer({
            id: "circle-radius-fill",
            type: "fill",
            source: "circle-radius",
            paint: {
                "fill-color": "blue",
                "fill-opacity": 0.1,
            },
        });

        map.addLayer({
            id: "circle-radius-outline",
            type: "line",
            source: "circle-radius",
            paint: {
                "line-color": "blue",
                "line-opacity": 0.5,
                "line-width": 2,
            },
        });
    }

    useEffect(() => {
        setIsLoading(true);
        if (!process.env.API_KEY || !process.env.SECRET_KEY) {
            console.error("Missing API credentials");
            setIsLoading(false);
            return;
        }

        if (typeof site.latlng.lng !== "number" || typeof site.latlng.lat !== "number") {
            console.error("Invalid site coordinates");
            setIsLoading(false);
            return;
        }

        mapboxgl.accessToken = "pk.eyJ1IjoiYW5kcmUtaGlja21hbm4iLCJhIjoiY2xoNjR4enBkMDE3cjNqcGc0aG93ZzlueSJ9.JH3ClP3oIf2uvc4ZpFvjJQ";
        const map = new mapboxgl.Map({
            container: "map",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [site.latlng.lng, site.latlng.lat],
            zoom: 12,
        });

        const loadMapData = async () => {
            map.on("load", async () => {
                const siteRadius = site.radius;
                const radius = siteRadius || 2000;
                const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
                const KBAPointsUrl = `${process.env.GEO_SERVER_URL}/kba/points/all?radius=${radius}&lng=${site.latlng.lng}&lat=${site.latlng.lat}`;
                const KBAPolygonsUrl = `${process.env.GEO_SERVER_URL}/kba/polygons/all?radius=${radius}&lng=${site.latlng.lng}&lat=${site.latlng.lat}`;
                const url = `${process.env.GEO_SERVER_URL}/protected-areas/all?radius=${radius}&lng=${site.latlng.lng}&lat=${site.latlng.lat}`;

                try {
                    const response = await fetch(url, {
                        headers: { Authorization: `Bearer ${encryptedToken}` },
                    });
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const { radiusFiltered, closestDistanceMeters, coverageAreaM2, sbtnClosestRaster, sbtnCoverageAreaPercentage } =
                        await response.json();

                    if (showKBA) {
                        const kbaPolyResponse = await fetch(KBAPolygonsUrl, {
                            headers: { Authorization: `Bearer ${encryptedToken}` },
                        });
                        const kbaPolyData = await kbaPolyResponse.json();
                        const kbaPointResponse = await fetch(KBAPointsUrl, {
                            headers: { Authorization: `Bearer ${encryptedToken}` },
                        });
                        const kbaPointData = await kbaPointResponse.json();

                        const validObjects = [
                            kbaPointData.closestDistanceMeters,
                            kbaPolyData.closestDistanceMeters,
                            { distance_meters: closestDistanceMeters },
                        ].filter((obj) => obj && typeof obj.distance_meters === "number");

                        const selectedClosestDistanceMetersObject = validObjects.reduce(
                            (min, obj) => (obj.distance_meters < min.distance_meters ? obj : min),
                            validObjects[0],
                        );

                        const combinedRadiusFiltered = {
                            type: "FeatureCollection",
                            features: [
                                ...(kbaPointData?.radiusFiltered?.features || []),
                                ...(kbaPolyData?.radiusFiltered?.features || []),
                                ...(radiusFiltered?.features || []),
                            ].sort((a, b) => a.properties.distance_meters - b.properties.distance_meters),
                        };

                        onBSAChange({
                            radiusFiltered: combinedRadiusFiltered,
                            closestDistanceMeters: selectedClosestDistanceMetersObject?.distance_meters || 0,
                            coverageAreaM2,
                            sbtnClosestRaster,
                            sbtnCoverageAreaPercentage,
                        });

                        map.addSource("radiusFiltered", {
                            type: "geojson",
                            data: radiusFiltered,
                        });

                        map.addLayer({
                            id: "radiusFiltered-fill",
                            type: "fill",
                            source: "radiusFiltered",
                            paint: {
                                "fill-color": "pink",
                                "fill-opacity": 0.7,
                            },
                        });
                        map.addSource("kbaPolyRadiusFiltered", {
                            type: "geojson",
                            data: kbaPolyData.radiusFiltered,
                        });

                        map.addLayer({
                            id: "kbaPolyRadiusFiltered-fill",
                            type: "fill",
                            source: "kbaPolyRadiusFiltered",
                            paint: {
                                "fill-color": "yellow",
                                "fill-opacity": 0.7,
                            },
                        });

                        if (selectedClosestDistanceMetersObject && selectedClosestDistanceMetersObject.name) {
                            const closestPoint = [
                                selectedClosestDistanceMetersObject.longitude,
                                selectedClosestDistanceMetersObject.latitude,
                            ];

                            map.addSource("lineToClosest", {
                                type: "geojson",
                                data: {
                                    type: "Feature",
                                    properties: {},
                                    geometry: {
                                        type: "LineString",
                                        coordinates: [[site.latlng.lng, site.latlng.lat], closestPoint],
                                    },
                                },
                            });

                            map.addLayer({
                                id: "lineToClosest",
                                type: "line",
                                source: "lineToClosest",
                                layout: { "line-join": "round", "line-cap": "round" },
                                paint: { "line-color": "red", "line-width": 4 },
                            });
                        }

                        if (coverageAreaM2) {
                            console.log(`Coverage Area ==== ${coverageAreaM2} square meters`);
                        }
                    } else {
                        onBSAChange({
                            radiusFiltered,
                            closestDistanceMeters,
                            coverageAreaM2,
                            sbtnClosestRaster,
                            sbtnCoverageAreaPercentage,
                        });

                        map.addSource("radiusFiltered", {
                            type: "geojson",
                            data: radiusFiltered,
                        });

                        map.addLayer({
                            id: "radiusFiltered-fill",
                            type: "fill",
                            source: "radiusFiltered",
                            paint: {
                                "fill-color": "pink",
                                "fill-opacity": 0.7,
                            },
                        });

                        if (closestDistanceMeters && closestDistanceMeters.name) {
                            const closestPoint = [closestDistanceMeters.longitude, closestDistanceMeters.latitude];

                            map.addSource("lineToClosest", {
                                type: "geojson",
                                data: {
                                    type: "Feature",
                                    properties: {},
                                    geometry: {
                                        type: "LineString",
                                        coordinates: [[site.latlng.lng, site.latlng.lat], closestPoint],
                                    },
                                },
                            });

                            map.addLayer({
                                id: "lineToClosest",
                                type: "line",
                                source: "lineToClosest",
                                layout: { "line-join": "round", "line-cap": "round" },
                                paint: { "line-color": "red", "line-width": 4 },
                            });
                        }

                        if (coverageAreaM2) {
                            console.log(`Coverage Area ==== ${coverageAreaM2} square meters`);
                        }
                    }

                    map.on("click", "radiusFiltered-fill", (e) => {
                        if (e.features && e.features.length > 0) {
                            showAlert();
                            setSelectedBSA(e.features[0].properties);
                        }
                    });

                    map.on("mouseenter", "radiusFiltered-fill", () => {
                        map.getCanvas().style.cursor = "pointer";
                    });

                    map.on("mouseleave", "radiusFiltered-fill", () => {
                        map.getCanvas().style.cursor = "";
                    });

                    addMarker(map, site.latlng.lng, site.latlng.lat);
                    createCircle(map, site.latlng.lng, site.latlng.lat, radius, 64);
                } catch (error) {
                    console.error("Error fetching protected areas:", error);
                } finally {
                    setIsLoading(false);
                }
            });
        };

        loadMapData();

        return () => map.remove();
    }, [site.latlng, setIsLoading, showKBA]);

    return (
        <>
            {isLoading && <LoadingAnimation />}
            <div id="map" style={{ position: "absolute", top: 0, bottom: 0, width: "100%" }} />
            <AlertWindow data={selectedBSA} isOpen={isAlertOpen} onClose={closeAlert} />
        </>
    );
};

export default BSAMap;

const AlertWindow = ({ data, isOpen, onClose }) => {
    if (!isOpen || !data) return null;

    return (
        <div className="alert-window">
            <h3>GID: {data?.gid || "N/A"}</h3>
            <h3>Name: {data?.name || "N/A"}</h3>
            <p>Orig. Name: {data?.orig_name || "N/A"}</p>
            <p>Type: {data?.desig_eng || "N/A"}</p>
            <p>Reported Area (km²): {data?.rep_area ? data.rep_area.toFixed(3) : "N/A"}</p>
            <p>Status Year: {data?.status_yr || "N/A"}</p>
            <button onClick={onClose}>Close</button>
        </div>
    );
};
