import React, { FC, useEffect, useState } from 'react';

type MapLegendsProps = 'waterUser' | 'npp' | 'trendInVegetation' | 'msa' | 'projectedChange' | 'deforestation'


const commonLegendStyles = {
    container: {
        padding: '10px',
        maxWidth: '450px',
        backgroundColor: 'white',
        borderRadius: '10px',
        color: 'black',
        fontSize: '12px',
    },
    bar: {
        display: 'flex',
        width: '100%',
        maxWidth: '400px',
        height: '10px',
        borderRadius: '3px',
        overflow: 'hidden',
    },
    labelContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '5px',
    },
    label: {
        textAlign: 'center',
        flex: 1,
    },
};

const legendsData = {
    waterUser: {
        title: 'Overall Water Risk',
        colors: ['#FFFFE0', '#FFFF00', '#FFA500', '#FF0000', '#8B0000'],
        labels: ['Low (0-1)', 'Low-Medium (1-2)', 'Medium-high (2-3)', 'High (3-4)', 'Extremely high (4-5)'],
        noData: { color: '#808080', label: 'No data' },
    },
    deforestation: {
        title: 'Deforestation',
        gradient: 'linear-gradient(to right, #FF0000, #FFFF00, #008000)',
        labels: ['Min', 'Neutral (0)', 'Max'],
    },
    npp: {
        title: 'Vegetation Productivity (NPP)',
        gradient: 'linear-gradient(to right, #FFFFFF, #008000)',
        labels: ['Low (1)', 'High (255)'],
    },
    trendInVegetation: {
        title: 'Trend in Vegetation Productivity',
        colors: ['#FF0000', '#FFFF00', '#008000'],
        labels: ['Negative (-1)', 'Neutral (0)', 'Positive (1)'],
    },
    msa: {
        title: 'MSA or Biodiversity Intactness',
        gradient: 'linear-gradient(to right, #FFFFFF, #008000)',
        labels: ['Low (1)', 'High (100)'],
    },
    projectedChange: {
        title: 'Projected Change in Biodiversity Intactness',
        gradient: 'linear-gradient(to right, #FF0000, #FFFF00, #008000)',
        labels: ['Min', 'Neutral (0)', 'Max'],
    },
};

const Legend = ({ title, colors, gradient, labels, noData }: any) => {
    return (
        <div style={commonLegendStyles.container}>
            <h3>{title}</h3>
            <div
                style={{
                    ...commonLegendStyles.bar,
                    background: gradient || undefined,
                }}
            >
                {!gradient &&
                    colors.map((color: string, index: number) => (
                        <div key={index} style={{ flex: 1, backgroundColor: color }}></div>
                    ))}
            </div>
            <div style={commonLegendStyles.labelContainer}>
                {labels.map((label: string, index: number) => (
                    <div key={index} style={commonLegendStyles.label}>
                        <label>{label.split(' ')[0]}</label>
                        <small>{label.split(' ')[1]}</small>
                    </div>
                ))}
            </div>
            {noData && (
                <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                    <div
                        style={{
                            display: 'inline-block',
                            backgroundColor: noData.color,
                            width: '15px',
                            height: '15px',
                            marginRight: '5px',
                        }}
                    ></div>
                    <label>{noData.label}</label>
                </div>
            )}
        </div>
    );
};

const MapLegends: FC<{ rasters: any }> = ({ rasters }) => {
    const [type, setType] = useState<MapLegendsProps | null>(null);


    useEffect(() => {
        const rasterName = Object.keys(rasters || {})[0] || ""
        switch (true) {
            case rasterName.includes('msa_delta') || rasterName.includes('msa') || rasterName.includes('msa_score'):
                setType('msa')
                break;
            case rasterName.includes('change_y_average'):
                setType('trendInVegetation')
                break;
            case rasterName.includes('net_primary_productivity'):
                setType('npp')
                break;
            case rasterName.includes('water_user'):
                setType('waterUser')
                break;

            default:
                if (rasterName.includes('deforestation')) {
                    setType('deforestation');
                } else {
                    setType(null);
                }
                break;
        }
    }, [rasters])

    if (type) {
        const legend = legendsData[type as keyof typeof legendsData];
        if (!legend) return null;

        return (
            <Legend
                title={legend.title}
                colors={legend.colors}
                gradient={legend.gradient}
                labels={legend.labels}
                noData={legend.noData}
            />
        );
    };
    return <></>
}

export default MapLegends;
