import * as React from "react";

interface IProgressRingProps {
    color?: string;
    progress: number;
    radius: number;
    rotation?: number;
    stroke: number;
    style?: React.CSSProperties;
}

export default function ProgressRing(props: IProgressRingProps): JSX.Element {
    const { color, progress, radius, rotation, stroke, style } = props;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;
    const svgStyle = {
        transform: `rotate(${rotation !== undefined ? rotation : "-90"}deg)`,
    } as React.CSSProperties;

    return (
        <div style={{ display: "inline-block", ...style }}>
            <svg height={radius * 2} width={radius * 2} style={svgStyle}>
                <circle
                    cx={radius}
                    cy={radius}
                    fill="transparent"
                    r={normalizedRadius}
                    stroke={color || "#BE98EE"}
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeWidth={stroke}
                    style={{ strokeDashoffset, transition: "stroke-dashoffset .35s" }}
                />
            </svg>
        </div>
    );
}
