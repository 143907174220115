import * as React from "react";
import classnames from "classnames";
import { SortOrder } from "@/utils/sort";
import { mdiClose } from "@mdi/js";
import Icon from "./Icon";
import { StyleSheet } from "@/utils/style";
import { set } from "zod";

interface ITableHeaderCellProps {
    columnBackgroundColor?: string;
    colSpan?: number;
    label: string;
    labelStyle?: React.CSSProperties;
    onClick?: (sortBy: SortOrder) => void;
    sortable?: boolean;
    sortedBy?: SortOrder | undefined;
    style?: React.CSSProperties;
    textAlign?: "end";
    verticalAlign?: "bottom" | "middle" | "top";
    width?: string;
}

export type TableHeaderCellValue = string | ITableHeaderCellProps;

export default function TableHeaderCell(props: ITableHeaderCellProps): JSX.Element {
    const isSortable = props.sortable === undefined || props.sortable;
    const isAscending = props.sortedBy === "asc";
    const classes = classnames("s-table-heading", {
        "s-table-heading-sortable": isSortable,
        ["s-table-heading-" + props.verticalAlign]: props.verticalAlign,
    });

    const HeaderCell = (props.sortable ? "button" : "span") as keyof JSX.IntrinsicElements;

    return (
        <th className={classes} colSpan={props.colSpan || 1} style={{ ...props.style, width: props.width }}>
            <HeaderCell
                className={`s-table-heading-button ${props.textAlign === "end" ? "s-table-heading-button--align-right" : ""}`}
                onClick={() => props.onClick?.(isAscending ? "desc" : "asc")}
                style={props.labelStyle}
                tabIndex={isSortable ? 0 : -1}
            >
                {props.label}
                {isSortable && (
                    <span className={`s-arrow-small-grey ${!props.sortedBy ? "s-arrow-small-grey-hidden" : ""} `}>
                        {isAscending ? "▲" : "▼"}
                    </span>
                )}
            </HeaderCell>
        </th>
    );
}

type dropDownOption = {
    id: string;
    label: string;
    selected?: boolean;
};
interface IdropDownTableHeaderCellProps {
    columnBackgroundColor?: string;
    colSpan?: number;
    label: string;
    labelStyle?: React.CSSProperties;
    onClick?: () => void;

    style?: React.CSSProperties;
    textAlign?: "end";
    verticalAlign?: "bottom" | "middle" | "top";
    width?: string;

    dropdownOptions?: dropDownOption[];
    dropdownSelected?: string[];
    dropDownHandler?: (clickedOptionID: string) => void;
}

export function DropDownTableHeaderCell(props: IdropDownTableHeaderCellProps): JSX.Element {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <th
            className="s-table-heading s-table-heading-sortable"
            colSpan={props.colSpan || 1}
            style={{ ...props.style, width: props.width }}
        >
            <button className={`s-table-heading-button`} onClick={() => setIsOpen(!isOpen)} style={props.labelStyle} tabIndex={-1}>
                {props.label}
                <span className={`s-arrow-small-grey`}>▼</span>
            </button>

            {isOpen && props.dropdownOptions?.length > 0 && (
                <div style={{ width: "150%", position: "relative", top: 0, left: 0, zIndex: 1000 }}>
                    <ul className={"dropdown-list"} style={{ padding: 0, top: 0 }}>
                        {props.dropdownOptions?.map((option) => (
                            <Selected
                                id={option.id}
                                label={option.label}
                                selected={props.dropdownSelected.includes(option.id)}
                                onClick={() => {
                                    setIsOpen(false);
                                    props.dropDownHandler(option.id);
                                }}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </th>
    );
}

function Selected({
    label,
    id,
    selected = false,
    onClick,
}: {
    label: string;
    id: string;
    selected: boolean;
    onClick: () => void;
}): JSX.Element {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "5px",
                cursor: "pointer",
                padding: "5px",
            }}
            className={`dropdown-item ${selected ? "dropdown-item-selected" : ""}`}
            onClick={onClick}
        >
            <span style={{ fontSize: 15, textAlign: "left" }}>{label}</span>
            {selected && <Icon path={mdiClose} size={18} color={"black"} style={{ marginRight: 5 }} />}
        </div>
    );
}

const styles = StyleSheet.create({
    multipleContainer: {
        marginTop: 10,
        display: "flex",
        flexDirection: "column",
        color: "white",
        maxHeight: 100,
        overflowY: "scroll",
    },
});
