import * as turf from "@turf/turf";

export function calculatePolygonArea(polygon: turf.Feature<turf.Polygon>): number {
    try {
        // Calculate area in square meters
        const area = turf.area(polygon);
        return Math.round(area); // Round to nearest whole number
    } catch (error) {
        console.error("Error calculating area:", error);
        return 0;
    }
}
