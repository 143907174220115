export const variables = {
    impacts: {
        ghg_emissions: "ghg_emissions",
        non_ghg_emissions: "non_ghg_emissions",
        soil_pollutants: "soil_pollutants",
        water_pollutants: "water_pollutants",
        freshwater_ecosystem_use: "freshwater_ecosystem_use",
        marine_ecosystem_use: "marine_ecosystem_use",
        terrestrial_ecosystem_use: "terrestrial_ecosystem_use",
        disturbances: "disturbances",
        biological_alterations_interferences: "biological_alterations_interferences",
        solid_waste: "solid_waste",
        other_resources_use: "other_resources_use",
        water_use: "water_use",
        non_ghg_air_pollutants: "non_ghg_air_pollutants",
    },
};

const landDegradationImpacts = [
    variables.impacts.non_ghg_air_pollutants,
    variables.impacts.soil_pollutants,
    variables.impacts.water_pollutants,
    variables.impacts.freshwater_ecosystem_use,
    variables.impacts.marine_ecosystem_use,
    variables.impacts.terrestrial_ecosystem_use,
    variables.impacts.soil_pollutants,
    variables.impacts.disturbances,
    variables.impacts.biological_alterations_interferences,
];

export const impactCategories = {
    landDegradation: landDegradationImpacts,
    sealingSoil: [variables.impacts.solid_waste, variables.impacts.other_resources_use, variables.impacts.terrestrial_ecosystem_use],
    desertification: [...landDegradationImpacts, variables.impacts.water_use],
    threatenedSpeciesAffected: [
        variables.impacts.soil_pollutants,
        variables.impacts.solid_waste,
        variables.impacts.terrestrial_ecosystem_use,
        variables.impacts.freshwater_ecosystem_use,
        variables.impacts.marine_ecosystem_use,
        variables.impacts.water_use,
        variables.impacts.other_resources_use,
        variables.impacts.disturbances,
        variables.impacts.biological_alterations_interferences,
    ],
};
