import classnames from "classnames";
import * as React from "react";

interface IHorizontalStackProps {
    horizontalAlign?: "center" | "flex-end" | "flex-start" | "space-between" | "stretch";
    className?: string;
    children: React.ReactNode;
    spacing?: "condensed" | "loose" | "none" | "regular" | "tight";
    style?: React.CSSProperties;
    verticalAlign?: "flex-start" | "center" | "flex-end" | "stretch";
    wrap?: boolean;
}

export default function HorizontalStack(props: IHorizontalStackProps): JSX.Element {
    const style = {
        ...props.style,
        alignItems: props.verticalAlign,
        justifyContent: props.horizontalAlign,
    };
    const classes = classnames(props.className, "s-horizontal-stack", "s-horizontal-stack-" + (props.spacing || "regular"), {
        "s-horizontal-stack-wrap": props.wrap,
    });

    return (
        <div className={classes} style={style}>
            {props.children}
        </div>
    );
}
