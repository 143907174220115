export const NotificationsChanged = "notifications/notificationsChanged";
export const NotificationRemoved = "notifications/notificationRemoved";

export function makeNotificationsState(): INotificationsState {
    return {
        notifications: [],
    };
}

export const notificationsReducer = (state: INotificationsState, action: IGenericAction): INotificationsState => {
    switch (action.type) {
        case NotificationsChanged:
            return { ...state, notifications: [...action.notifications] };

        case NotificationRemoved:
            return {
                ...state,
                notifications: state.notifications.filter((n) => n.id !== action.notification.id),
            };
    }
};
