import * as React from "react";
import { t } from "@/utils";
import { Dropdown, Rating } from "@/lib";
import { BaseModalDialog } from "@/lib/modals";

interface IRecommendationMeasureGroupProps {
    label: string;
    actionPlan: any;
    project: IProject;
    report: IAssessmentReport;
    site: INewSiteDetailed;
    onChange?: () => void;
}

interface IRecommendationMeasureProps {
    id: string;
    cluster?: any;
    mitigation?: any;
    recommendation: any;
    actionProgress?: any;
    updateProgress: (id: string, progress: number) => void;
    project: IProject;
    report: IAssessmentReport;
    site: INewSiteDetailed;
}

function RecommendationMeasure(props: IRecommendationMeasureProps): JSX.Element {
    const [isActive, setIsActive] = React.useState<boolean>(false);
    const [progress, setProgress] = React.useState<number>(0);
    const { site } = props;

    React.useEffect(() => {
        //fetch actionsProgress from local storage and if not found set it from the props
        const actionsProgress = JSON.parse(localStorage.getItem("actionsProgress-" + site.id + "-" + props.cluster.id));
        if (actionsProgress) {
            console.log("actionsProgress from localstorage ====>>> ", actionsProgress);
            const action = actionsProgress.find((action: any) => action.id === props.id);

            if (action) {
                setProgress(action.progress);
            }
        } else {
            setProgress(0);
        }
    }, [props.actionProgress]);

    function updateProgress(id: string, progress: number) {
        props.updateProgress(id, progress);
    }

    return (
        <div className="s-measure">
            <div className="s-measure-title">
                <span>{props.recommendation.recommendation_description}</span>
            </div>

            <div className="s-measure-impact">
                <Rating range={5} size="xs" value={props.recommendation.impact_points} />
            </div>

            <div className="s-measure-effort">
                <Rating range={5} size="xs" value={props.recommendation.effort_points} />
            </div>

            {/* the status is not yet implemented mostafa */}
            {/* TODO to be implemented */}
            {/* <div className="s-measure-controls" style={{ marginTop: '-2px' }}>
                <Dropdown
                    id={props.id}
                    disabled={isActive}
                    items={[
                        { id: 'done', label: t('ui.mark_as_implemented') },
                        { id: 'irrelevant', label: t('ui.mark_as_non_applicable') },
                        { id: 'reset', label: t('ui.reset') },
                    ]}
                    label={progress === 1 ? t('ui.implemented') : (progress < 0 ? t('ui.non_applicable') : t('ui.complete') + '…')}
                    onItemClick={what => updateProgress(props.id,{ done: 1, irrelevant: -1, reset: 0 }[what])}
                    size="sm"
                    variant={progress !== 0 ? 'selected' : undefined } />
            </div> */}
        </div>
    );
}

export default function RecommendationMeasureGroup(props: IRecommendationMeasureGroupProps): JSX.Element {
    const [picVisible, setPicVisible] = React.useState<boolean>(false);
    const [actionProgress, setActionProgress] = React.useState<any>([]);
    const [recommendationWithId, setRecommendationWithId] = React.useState<any>([]);

    //TODO options for each action
    // React.useEffect(() => {
    //     //fetch actionsProgress from local storage and if not found set it from the props
    //     const actionsProgressLS = JSON.parse(localStorage.getItem('actionsProgress-'+props.site.id+'-'+props.actionPlan.cluster.id));
    //     if (!actionsProgressLS || actionsProgressLS.length === 0) {
    //         const _actionProgress: { id: string, progress: number }[] = [];
    //         const _recommendationWithId: { id: string, [key: string]: any }[] = [];
    //         props.actionPlan.recommendations.forEach((element:any) => {
    //             const id = props.site.id + '_' + props.actionPlan.cluster.id + '_' + element.id;
    //             _actionProgress.push({ id, progress: 0 });
    //             _recommendationWithId.push({ key: id, ...element });
    //         });

    //         localStorage.setItem('actionsProgress'+props.site.id, JSON.stringify(_actionProgress));
    //         setActionProgress(_actionProgress);
    //         setRecommendationWithId(_recommendationWithId);
    //     }else{
    //         const _recommendationWithId: { key: string, [key: string]: any }[] = [];
    //         props.actionPlan.forEach((element:any) => {
    //             const id = props.site.site_id + '_' + element.action.cluster_name + '_' + element.action.recommendation_id;
    //             _recommendationWithId.push({ key: id, ...element.action });
    //         });
    //         setActionProgress(actionsProgressLS);
    //         setRecommendationWithId(_recommendationWithId)
    //     }
    // }, []);

    function updateProgress(id: string, progress: number) {
        const updatedActionsProgress = actionProgress.map((action: any) => {
            return action.id === id ? { id: action.id, progress } : action;
        });
        localStorage.setItem(
            "actionsProgress-" + props.site.id + "-" + props.actionPlan.cluster.id,
            JSON.stringify(updatedActionsProgress),
        );
        setActionProgress(updatedActionsProgress);
    }

    return (
        <>
            <div className="s-measure-group">
                <div className="s-measure-group-header">
                    <h2 className="s-measure-group-title">{props.actionPlan[0].action.cluster_name}</h2>

                    <span
                        className="s-label s-label-clickable"
                        role="button"
                        style={{ fontSize: ".6rem", marginTop: "3px", marginLeft: "1rem" }}
                        onClick={() => setPicVisible(true)}
                    >
                        {props?.actionPlan[0].action.mitigation_description}
                    </span>
                </div>

                <div className="s-measure-headline">
                    <div className="s-measure-title">{t("ui.task")}</div>
                    <div className="s-measure-impact">{t("ui.impact")}</div>
                    <div className="s-measure-effort">{t("ui.effort")}</div>
                </div>

                <div className="s-measure-list">
                    {props?.actionPlan.map((recommendation: any) => (
                        <RecommendationMeasure
                            key={recommendation.action.recommendation_id}
                            recommendation={recommendation.action}
                            cluster={recommendation.action.cluster_id}
                            project={props.project}
                            report={props.report}
                            site={props.site}
                            id={recommendation.action.recommendation_id}
                            actionProgress={actionProgress}
                            updateProgress={updateProgress}
                        />
                    ))}
                </div>

                <div className="s-measure-group-description">{props.actionPlan[0].action.cluster_description}</div>
            </div>

            {picVisible && (
                <BaseModalDialog title="Mitigation Hierarchy" onClose={() => setPicVisible(false)}>
                    <img src="/-/app/mitigation-hierarchy.png" width="100%" />
                </BaseModalDialog>
            )}
        </>
    );
}
