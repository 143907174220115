/* eslint-disable @typescript-eslint/no-explicit-any */
import CryptoJS from "crypto-js";

import { fetchDepAndImp } from "./helpers";
import { impactCategories, variables } from "./variables";

const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();

const header = {
    headers: {
        Authorization: `Bearer ${encryptedToken}`,
    },
};

if (!process.env.API_KEY || !process.env.SECRET_KEY || !process.env.KUYUA_APP_BACKEND_URL || !process.env.GEO_SERVER_URL) {
    throw new Error("Missing necessary environment variables.");
}

const formatRating = (rating: string) =>
    ({
        H: "High",
        M: "Medium",
        L: "Low",
        VH: "Very High",
    })[rating] ||
    rating ||
    "Unknown";

const formatImpactsAndDependencies = (data: any[], type: string) => {
    const result: { [key: string]: string } = {};
    ["VH", "H", "M", "L"].forEach((rating) => {
        result[`${type} (${formatRating(rating)})`] = "";
    });

    if (!Array.isArray(data)) {
        console.error(`Invalid ${type} data: expected array, got ${typeof data}`);
        return result;
    }

    data.forEach(({ rating, values }) => {
        if (Array.isArray(values) && values.length > 0) {
            const key = `${type} (${formatRating(rating)})`;
            result[key] = values
                .filter((value: string) => value && typeof value === "string" && !value.includes("undefined"))
                .map((value: string) => `• ${value.trim()}`)
                .join("\n");
        }
    });

    return result;
};

// const filterAndFormatImpactsAndDependencies = (
//   impacts: { rating: string; values: string[] }[],
//   categories: string[]
// ) => {
//   return impacts
//     .filter((impact) => ["VH", "H", "M"].includes(impact.rating))
//     .filter((impact) =>
//       impact.values.some((im: string) => categories.includes(im))
//     )
//     .map((impact) => ` • ${impact}`)
//     .join("\n");
// };

export const sbm3 = async (site: INewSite) => {
    if (!site) {
        throw new Error("Missing site information.");
    }
    async function fetchSiteKPIS(site) {
        try {
            const KPIsURL = `${process.env.KUYUA_APP_BACKEND_URL}/site/kpis?site_id=${site.site_id}&kpis=msa,msa-delta,bsa,bsa-distance,habitats,npp,species,change-y-average,deforestation,water-risk`;
            const response = await fetch(KPIsURL, header);
            const data = response.json();
            return data;
        } catch (error) {
            console.error("Error at fetch kpis.");
        }
    }
    const [kpisResponse] = await Promise.all([fetchSiteKPIS(site)]);
    const {
        speciesData,
        msa,
        msaDelta,
        BSACount,
        siteName,
        impacts,
        dependencies,
        siteAddress,
        sitePriorityScore,
        siteType,
        economic_activities,
    } = kpisResponse;

    const fullSiteName = siteName;
    const activities = economic_activities
        .map((e: { economic_activity_name: any }) => `• ${e.economic_activity_name || "Unknown activity"}`)
        .join("\n");

    const formattedImpacts = formatImpactsAndDependencies(impacts.potentialImpacts.restructuredItems, "Impact");
    const actualFormattedImpacts = formatImpactsAndDependencies(impacts.impacts.restructuredItems, "Impact");
    const formattedDependencies = formatImpactsAndDependencies(dependencies.potentialDependencies.restructuredItems, "Dependency");
    const actualFormattedDependencies = formatImpactsAndDependencies(dependencies.dependencies.restructuredItems, "Dependency");

    const impactsArray = impacts.impacts.restructuredItems;
    const potentialImpactsArray = impacts.potentialImpacts.restructuredItems;

    const formatImpactCategory = (impacts: any[], category: string[]) => {
        const significantImpacts = impacts.filter(({ rating, values }) => {
            return ["M", "H", "VH"].includes(rating) && values.some((value: string) => category.includes(value));
        });
        if (significantImpacts.length === 0) {
            return "No impacts identified";
        }
        return significantImpacts
            .flatMap(({ values }) => values.filter((value: string) => category.includes(value)))
            .map((impact: any) => ` • ${impact}`)
            .join("\n");
    };

    const formatDesertificationImpacts = (impacts: any[], categories: string[]) => {
        const significantNonWaterImpacts = impacts.filter(({ rating, values }) => {
            return (
                ["M", "H", "VH"].includes(rating) &&
                values.some((value: string) => value !== variables.impacts.water_use && categories.includes(value))
            );
        });
        const isWaterUseSignificant = impacts.some(({ rating, values }) => {
            return ["M", "H", "VH"].includes(rating) && values.includes(variables.impacts.water_use);
        });

        if (isWaterUseSignificant && significantNonWaterImpacts.length === 0) {
            return "No impacts identified";
        }
        if (!isWaterUseSignificant && significantNonWaterImpacts.length > 0) {
            return "No impacts identified";
        }
        if (isWaterUseSignificant && significantNonWaterImpacts.length > 0) {
            return impacts
                .flatMap(({ values }) => values.filter((value: string) => categories.includes(value)))
                .map((impact) => ` • ${impact}`)
                .join("\n");
        }
        return "No impacts identified";
    };

    const impactsCausingLandDegradation = formatImpactCategory(potentialImpactsArray, impactCategories.landDegradation);

    const actualImpactsCausingLandDegradation = formatImpactCategory(impactsArray, impactCategories.landDegradation);

    const impactsCausingSoilSealing = formatImpactCategory(potentialImpactsArray, impactCategories.sealingSoil);

    const actualImpactsCausingSoilSealing = formatImpactCategory(impactsArray, impactCategories.sealingSoil);

    const impactsCausingDesertification = formatDesertificationImpacts(potentialImpactsArray, impactCategories.desertification);

    const actualImpactsCausingDesertification = formatDesertificationImpacts(impactsArray, impactCategories.desertification);

    const ecologicalStatus = msa !== undefined ? Number(msa).toFixed(2) : "N/A";
    const projectedChangeInEcologicalStatus = msaDelta !== undefined ? Number(msaDelta).toFixed(0) + "%" : "N/A";

    let eA = await Promise.all(
        economic_activities.map(async (eco) => {
            const response = await fetchDepAndImp("impacts", eco.economic_activity_id);
            return {
                economic_activity_id: eco.economic_activity_id,
                economic_activity_name: eco.economic_activity_name,
                impacts: response,
            };
        }),
    );
    const activitiesImpactingBSAsAndSpecies = eA
        .filter((activity) =>
            activity.impacts.some(
                (impact: { rating: string; values: string[] }) =>
                    ["M", "H", "VH"].includes(impact.rating) &&
                    !(
                        impact.values.length === 1 &&
                        (impact.values.includes(variables.impacts.ghg_emissions) ||
                            impact.values.includes(variables.impacts.non_ghg_emissions))
                    ),
            ),
        )
        .map((activity) => `• ${activity.economic_activity_name || "Unknown activity"}`)
        .join("\n");

    const finalData = [
        ["Site Name", ` ${fullSiteName || "Unnamed Site"}`],
        ["Address", ` ${siteAddress || "Address not available"}`],
        ["Country", ` ${site.country || "Unknown"}`],
        ["Latitude", ` ${site.latlng?.lat ?? "N/A"}`],
        ["Longitude", ` ${site.latlng?.lng ?? "N/A"}`],
        ["Site Type", ` ${siteType}`],
        ["Activities on site", ` ${activities || "No activities recorded"}`],
        ["Impact Radius (m)", ` ${site.radius || "Not specified"}`],
        ["Materiality Threshold", " medium, high, very high"],
        ["Priority Score (1-100)", ` ${sitePriorityScore}`],
        [""],
        ["Ecological Status (0-100)", ` ${ecologicalStatus}`],
        ["Projected Change in Ecological Status", ` ${projectedChangeInEcologicalStatus}`],
        [""],
        ["Activities impacting BSAs and Species", activitiesImpactingBSAsAndSpecies],
        ["Biodiversity Sensitive Areas impacted", ` ${BSACount === undefined ? "N/A" : BSACount}`],
        ["Threatened Species", ` ${speciesData?.threatened_species_count || "N/A"}`],
        [""],
        ["", "Potential", "Actual"],
        ["Impacts causing Land Degradation", impactsCausingLandDegradation, actualImpactsCausingLandDegradation],
        ["Impacts causing Soil Sealing", impactsCausingSoilSealing, actualImpactsCausingSoilSealing],
        ["Impacts causing Desertification", impactsCausingDesertification, actualImpactsCausingDesertification],
        [""],
        ["Rated Impacts", "Potential", "Actual"],

        ...Object.entries(formattedImpacts).map(([key, value]) => [key, ` ${value}`, ` ${actualFormattedImpacts[key]}`]),
        [""],
        ["Rated Dependencies", "Potential", "Actual"],
        ...Object.entries(formattedDependencies).map(([key, value]) => [key, ` ${value}`, ` ${actualFormattedDependencies[key]}`]),
    ];

    return finalData;
};
