import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext, useProject, useTeam } from "@/context";
import { getHomeUrl, t, unique } from "@/utils";
import { BreadcrumbPath, FilterBar, ITableProps, LoadingAnimation, MasterHeader, Table } from "@/lib";
import { updateSFDRReport } from "@/resources";

type SFDRReportListType = "bd" | "pa" | "water";

interface ISFDRReportSiteListPageProps {
    listType: SFDRReportListType;
}

function compileBDTable(team: ITeam, project: IProject, rv: ISFDRReportVersion): ITableProps {
    return {
        columns: [t("ui.site"), t("ui.country"), t("ui.protected_area_plural"), t("ui.distance_nearest_km")],
        rows: rv.sites
            .filter((s) => s.sfdr_1_7.protected_areas?.length > 0)
            .map((s) => [
                {
                    text: s.name,
                    type: "link",
                    url: `/${team.slug}/${project.slug}/sites/${s.id}`,
                },
                s.country,
                s.sfdr_1_7.protected_areas.length,
                s.sfdr_1_7.protected_areas[0].distance_km,
            ]),
    };
}

function compilePATable(team: ITeam, project: IProject, rv: ISFDRReportVersion): ITableProps {
    const any = (obj: Record<string, boolean>) => {
        for (const v of Object.values(obj)) {
            if (v) {
                return true;
            }
        }

        return false;
    };

    return {
        columns: [t("ui.site"), t("ui.country"), t("ui.protected_area"), t("ui.primary_forest"), t("ui.grassland")],
        rows: rv.sites
            .filter((s) => any(s.sfdr_2_14))
            .map((s) => [
                {
                    text: s.name,
                    type: "link",
                    url: `/${team.slug}/${project.slug}/sites/${s.id}`,
                },
                s.country,
                t(s.sfdr_2_14.has_primary_forest ? "ui.yes" : "ui.no"),
                t(s.sfdr_2_14.has_protected_area ? "ui.yes" : "ui.no"),
                t(s.sfdr_2_14.has_grassland ? "ui.yes" : "ui.no"),
            ]),
    };
}

function compileWaterTable(team: ITeam, project: IProject, rv: ISFDRReportVersion): ITableProps {
    return {
        columns: [t("ui.site"), t("ui.country"), "Water Risk", "Management Policy"],
        rows: rv.sites
            .filter((s) => s.sfdr_2_8.overall_water_risk >= 3)
            .map((s) => [
                {
                    text: s.name,
                    type: "link",
                    url: `/${team.slug}/${project.slug}/sites/${s.id}`,
                },
                s.country,
                t("waterRisk." + s.sfdr_2_8.overall_water_risk),
                "No",
            ]),
    };
}

function compileTable(listType: SFDRReportListType, team: ITeam, project: IProject, report: ISFDRReport): ITableProps {
    switch (listType) {
        case "bd":
            return compileBDTable(team, project, report.newest_version);

        case "pa":
            return compilePATable(team, project, report.newest_version);

        case "water":
            return compileWaterTable(team, project, report.newest_version);
    }
}

export default function SFDRReportSiteListPage(props: ISFDRReportSiteListPageProps): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const navigate = useNavigate();
    const project = useProject();
    const team = useTeam();
    const params = useParams();
    const report = state.sfdr.reports.get(parseInt(params.reportId));
    const isSmallLayout = ["xs", "sm", "md"].includes(state.layoutSize);
    const [country, setCountry] = React.useState<string>("_all");
    const countryOptions = React.useMemo(() => {
        const cs = unique(report?.newest_version.sites.map((s) => s.country));

        return [{ id: "_all", label: t("ui.all") }].concat(cs.map((id) => ({ id, label: id })));
    }, [country, params.reportId, report]);
    const table = React.useMemo(() => {
        if (!report) {
            return { columns: [], rows: [] };
        }

        const table = compileTable(props.listType, team, project, report);

        if (country === "_all") {
            return table;
        }

        return {
            columns: table.columns,
            rows: table.rows.filter((row) => row[1] === country),
        };
    }, [country, params.listType, params.reportId, report]);
    const breadcrumb = [
        { label: t("ui.home"), route: getHomeUrl(team) },
        { label: t("ui.report_plural"), route: getHomeUrl(team) + "/reports" },
    ];

    React.useEffect(() => {
        document.title = `${report?.name} — ${project.name} — kuyua`;
    }, [params.reportId, report]);

    React.useEffect(() => {
        updateSFDRReport(state, dispatch, parseInt(params.reportId));
    }, [params.reportId]);

    if (!report) {
        return <LoadingAnimation />;
    }

    breadcrumb.push({
        label: report.name,
        route: getHomeUrl(team) + `/reports/sfdr/${params.reportId}`,
    });
    breadcrumb.push({
        label: {
            bd: t("ui.sites_in_biodiversity_sensitive_areas"),
            pa: t("ui.natural_species_and_protected_areas"),
            water: t("ui.high_water_risk_sites"),
        }[props.listType],
        route: null,
    });

    return (
        <div style={{ padding: "0 2rem 2rem 2rem" }}>
            <BreadcrumbPath items={breadcrumb} style={{ marginTop: "1rem" }} onItemClick={(item) => navigate(item.route)} />
            <MasterHeader
                style={{
                    marginTop: isSmallLayout ? "60px" : "40px",
                    marginBottom: "1.5rem",
                }}
                text={report.name}
            />

            <FilterBar
                buttons={[
                    {
                        disabled: true,
                        id: "dl",
                        label: t("actions.export"),
                        variant: "primary",
                    },
                ]}
                dropdowns={[
                    {
                        disabled: false,
                        id: "kind",
                        items: countryOptions,
                        label: country !== "_all" ? country : t("ui.country"),
                    },
                ]}
                onDropdownItemSelect={(_, country) => setCountry(country)}
            />

            <Table {...table} />
        </div>
    );
}
