import * as React from "react";

interface ICloseButtonProps {
    color?: string;
    onClick?: () => void;
    size?: string;
    style?: React.CSSProperties;
    thickness?: number;
    title?: string;
}

export default function CloseButton(props: ICloseButtonProps): JSX.Element {
    const color = props.color || "#333";
    const size = { md: 12, lg: 18 }[props.size] || 12;
    const thickness = props.thickness || 1;

    return (
        <svg className="s-close-button" width={size} height={size} onClick={props.onClick} style={props.style}>
            {props.title && <title>{props.title}</title>}
            <g>
                <line x1="0" y1="0" x2={size - 1} y2={size - 1} stroke={color} strokeWidth={thickness} />
                <line x1="0" y1={size - 1} x2={size - 1} y2="0" stroke={color} strokeWidth={thickness} />
            </g>
        </svg>
    );
}
