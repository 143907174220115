import { hasProperty, t } from "@/utils";
import * as React from "react";
import TableRowCell, { TableRowCellValue } from "./TableRowCell";

export interface ITableProps {
    columns: React.ReactNode[][] | React.ReactNode[];
    colBgColors?: string[];
    rows: TableRowCellValue[][];
}

export default function Table(props: ITableProps): JSX.Element {
    const header =
        props.columns.length > 0 && !hasProperty(props.columns[0] as unknown as Record<string, unknown>, "length")
            ? ([props.columns] as React.ReactNode[][])
            : (props.columns as React.ReactNode[][]);

    return (
        <table className="s-table s-table-fixed">
            <colgroup>{props.colBgColors?.map((col, ix) => <col key={ix} style={{ backgroundColor: col }} />)}</colgroup>
            <thead>
                {header.map((row, rix) => (
                    <tr key={rix}>{row.map((cell) => cell)}</tr>
                ))}
            </thead>
            <tbody>
                {props.rows ? (
                    props.rows.map((row, rix) => (
                        <tr key={rix}>
                            {row.map((cell, cix) => (
                                <TableRowCell key={cix} value={cell} />
                            ))}
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td style={{ borderBottom: "none" }}>{t("ui.no_results")}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
