/* eslint-disable @typescript-eslint/no-explicit-any */
import CryptoJS from "crypto-js";

const rating = {
    VH: "Very High",
    H: "High",
    M: "Medium",
    L: "Low",
};
if (!process.env.API_KEY || !process.env.SECRET_KEY || !process.env.KUYUA_APP_BACKEND_URL || !process.env.GEO_SERVER_URL) {
    throw new Error("Missing necessary environment variables.");
}

const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();

const header = {
    headers: {
        Authorization: `Bearer ${encryptedToken}`,
    },
};

export const IRO1 = async (site) => {
    if (!site) {
        throw new Error("Missing site information.");
    }
    async function fetchSiteKPIS(site) {
        try {
            const KPIsURL = `${process.env.KUYUA_APP_BACKEND_URL}/site/kpis?site_id=${site.site_id}&kpis=bsa,materiality`;
            const response = await fetch(KPIsURL, header);
            const data = response.json();
            return data;
        } catch (error) {
            console.error("Error at fetch kpis.");
        }
    }
    const [kpisResponse] = await Promise.all([fetchSiteKPIS(site)]);
    const {
        BSACount,
        impacts: impactsData,
        dependencies: dependenciesData,
        siteAddress,
        sitePriorityScore,
        siteType,
        materiality,
        economic_activities,
    } = kpisResponse;
    const ecoIds = economic_activities
        .map((eca: { economic_activity_id: any }) => eca.economic_activity_id)
        .filter(Boolean)
        .join(",");
    const activities = economic_activities
        .map((e: { economic_activity_name: any }) => `• ${e.economic_activity_name || "Unknown activity"}`)
        .join("\n");

    const impacts = impactsData.potentialImpacts.restructuredItems;
    const dependencies = dependenciesData.potentialDependencies.restructuredItems;
    const answers = materiality?.result?.answers ?? null;

    const impactCategories = {
        landDegradation: [
            "ghg_emissions",
            "soil_pollutants",
            "water_pollutants",
            "non_ghg_air_pollutants",
            "freshwater_ecosystem_use",
            "marine_ecosystem_use",
            "terrestrial_ecosystem_use",
            "disturbances",
            "biological_alterations_interferences",
        ],
        sealingSoil: ["solid_waste", "other_resources_use", "terrestrial_ecosystem_use"],
        desertification: [
            "soil_pollutants",
            "disturbances",
            "biological_alterations_interferences",
            "terrestrial_ecosystem_use",
            "water_use",
        ],
    };

    const finalData = [
        ["Site Name", ` ${site.site_name || "Unnamed Site"}`],
        ["Address", ` ${siteAddress || "Address not available"}`],
        ["Country", ` ${site.country || "Unknown"}`],
        ["Latitude", ` ${site.latlng?.lat ?? "N/A"}`],
        ["Longitude", ` ${site.latlng?.lng ?? "N/A"}`],
        ["Site Type", ` ${siteType || "Unknown"}`],
        ["Activities on site", ` ${activities || "No activities recorded"}`],
        ["Impact Radius (m)", ` ${site.radius || "Not specified"}`],
        ["Materiality Threshold", " Medium, high, very high"],
        ["Priority Score (1-100)", ` ${sitePriorityScore}`],
        [""],

        ["Consideration of affected communities?", "Yes", "No"],
        ["Scenario analysis conducted?", "Yes", "No"],
        ["Biodiversity Sensitive Areas impacted", BSACount],
        ["Have legally required environmental impact assessments or similar evaluations been conducted?", "No"],
        ["Have externally audited environmental management systems (e.g., ISO or EMAS) or certification schemes been implemented?", "No"],
        ["Have legally required actions to reduce impacts been implemented?", "No"],
        [""],

        ["", "Potential", "Actual", "Reason for rating changes", "ESRS Standard"],
        ["Transition Risk assessed?", "Yes", "Yes"],
        ["Level of Transition Risk", impacts?.length ? rating[impacts[0].rating as keyof typeof rating] : ""],
        ["Physical Risk assessed?", "Yes", "Yes"],
        ["Level of Physical Risk", dependencies?.length ? rating[dependencies[0].rating as keyof typeof rating] : ""],
        ["Systemic Risk considered?", "Yes"],
        ["Impacts assessed?", "Yes", "Yes"],
        [
            "GHG Emissions",
            getRating("ghg_emissions", impacts),
            getAnswersValue("impacts", "Ghg Emissions", answers, "ghg_emissions", impacts),
            getAnswersComments("impacts", "Ghg Emissions", answers, "ghg_emissions", impacts),
            "E1",
            "Impact Driver: Climate Change",
        ],
        [
            "Non-GHG Air Pollutants",
            getRating("non_ghg_air_pollutants", impacts),
            getAnswersValue("impacts", "Non Ghg Air Pollutants", answers, "non_ghg_air_pollutants", impacts),
            getAnswersComments("impacts", "Non Ghg Air Pollutants", answers, "non_ghg_air_pollutants", impacts),
            "E2",
            "Impact Driver: Pollution",
        ],
        [
            "Solid Waste",
            getRating("solid_waste", impacts),
            getAnswersValue("impacts", "Solid Waste", answers, "solid_waste", impacts),
            getAnswersComments("impacts", "Solid Waste", answers, "solid_waste", impacts),
            "E5",
            "Impact Driver: Pollution \nImpact on Ecosystems",
        ],
        [
            "Soil Pollution",
            getRating("soil_pollutants", impacts),
            getAnswersValue("impacts", "Soil Pollutants", answers, "soil_pollutants", impacts),
            getAnswersComments("impacts", "Soil Pollutants", answers, "soil_pollutants", impacts),
            "E2",
            "Impact Driver: Pollution \nImpact on Species \nImpact on Ecosystems",
        ],
        [
            "Water Pollution",
            getRating("water_pollutants", impacts),
            getAnswersValue("impacts", "Water Pollutants", answers, "water_pollutants", impacts),
            getAnswersComments("impacts", "Water Pollutants", answers, "water_pollutants", impacts),
            "E2",
            "Impact Driver: Pollution \nImpact on Species \nImpact on Ecosystems",
        ],
        [
            "Terrestrial Ecosystem Use",
            getRating("terrestrial_ecosystem_use", impacts),
            getAnswersValue("impacts", "Terrestrial Ecosystem Use", answers, "terrestrial_ecosystem_use", impacts),
            getAnswersComments("impacts", "Terrestrial Ecosystem Use", answers, "terrestrial_ecosystem_use", impacts),
            "E4",
            "Impact Driver: Land Use Change\nImpact on Species\nImpact on Ecosystems",
        ],
        [
            "Freshwater Ecosystem Use",
            getRating("freshwater_ecosystem_use", impacts),
            getAnswersValue("impacts", "Freshwater Ecosystem Use", answers, "freshwater_ecosystem_use", impacts),
            getAnswersComments("impacts", "Freshwater Ecosystem Use", answers, "freshwater_ecosystem_use", impacts),
            "E3, E4",
            "Impact Driver: Freshwater Use Change\nImpact on Species\nImpact on Ecosystems",
        ],
        [
            "Marine Ecosystem Use",
            getRating("marine_ecosystem_use", impacts),
            getAnswersValue("impacts", "Marine Ecosystem Use", answers, "marine_ecosystem_use", impacts),
            getAnswersComments("impacts", "Marine Ecosystem Use", answers, "marine_ecosystem_use", impacts),
            "E3",
            "Impact Driver: Marine Use Change\nImpact on Species\nImpact on Ecosystems",
        ],
        [
            "Water Use",
            getRating("water_use", impacts),
            getAnswersValue("impacts", "Water Use", answers, "water_use", impacts),
            getAnswersComments("impacts", "Water Use", answers, "water_use", impacts),
            "E3",
            "Impact Driver: Direct Exploitation\nImpact on Ecosystems",
        ],
        [
            "Other Ressources Use",
            getRating("other_resources_use", impacts),
            getAnswersValue("impacts", "Other Ressources Use", answers, "other_resources_use", impacts),
            getAnswersComments("impacts", "Other Ressources Use", answers, "other_resources_use", impacts),
            "E4, E5",
            "Impact Driver: Direct Exploitation\nImpact on Ecosystems",
        ],
        [
            "Disturbances",
            getRating("disturbances", impacts),
            getAnswersValue("impacts", "Disturbances", answers, "disturbances", impacts),
            getAnswersComments("impacts", "Disturbances", answers, "disturbances", impacts),
            "E4",
            "Impact Driver: Direct Exploitation\nImpact on Species\nImpact on Ecosystems",
        ],
        [
            "Biological Alterations Interferences",
            getRating("biological_alterations_interferences", impacts),
            getAnswersValue("impacts", "Biological Alterations Interferences", answers, "biological_alterations_interferences", impacts),
            getAnswersComments("impacts", "Biological Alterations Interferences", answers, "biological_alterations_interferences", impacts),
            "E4",
            "Impact Driver: Invasive Alien Species\nImpact on Species\nImpact on Ecosystems",
        ],
        [""],

        ["Dependencies assessed?", "Yes", "Yes"],
        [
            "Animal based energy",
            getRating("animal_based_energy", dependencies),
            getAnswersValue("dependencies", "Animal Based Energy", answers, "animal_based_energy", dependencies),
            getAnswersComments("dependencies", "Animal Based Energy", answers, "animal_based_energy", dependencies),
            "E5",
            "Dependency on Ecosystem Services",
        ],
        [
            "Pollination",
            getRating("pollination", dependencies),
            getAnswersValue("dependencies", "Pollination", answers, "pollination", dependencies),
            getAnswersComments("dependencies", "Pollination", answers, "pollination", dependencies),
            "E2, E4",
            "Dependency on Ecosystem Services",
        ],
        [
            "Pest control",
            getRating("pest_control", dependencies),
            getAnswersValue("dependencies", "Pest Control", answers, "pest_control", dependencies),
            getAnswersComments("dependencies", "Pest Control", answers, "pest_control", dependencies),
            "E4",
            "Dependency on Ecosystem Services",
        ],
        [
            "Disease control",
            getRating("disease_control", dependencies),
            getAnswersValue("dependencies", "Disease Control", answers, "disease_control", dependencies),
            getAnswersComments("dependencies", "Disease Control", answers, "disease_control", dependencies),
            "E4",
            "Dependency on Ecosystem Services",
        ],
        [
            "Genetic materials",
            getRating("genetic_materials", dependencies),
            getAnswersValue("dependencies", "Genetic Materials", answers, "genetic_materials", dependencies),
            getAnswersComments("dependencies", "Genetic Materials", answers, "genetic_materials", dependencies),
            "E4",
            "Dependency on Ecosystem Services",
        ],
        [
            "Maintain nursery habitats",
            getRating("maintain_nursery_habitats", dependencies),
            getAnswersValue("dependencies", "Maintain Nursery Habitats", answers, "maintain_nursery_habitats", dependencies),
            getAnswersComments("dependencies", "Maintain Nursery Habitats", answers, "maintain_nursery_habitats", dependencies),
            "E4",
            "Dependency on Ecosystem Services",
        ],
        [
            "Ground water",
            getRating("ground_water", dependencies),
            getAnswersValue("dependencies", "Ground Water", answers, "ground_water", dependencies),
            getAnswersComments("dependencies", "Ground Water", answers, "ground_water", dependencies),
            "E3",
            "Dependency on Ecosystem Services",
        ],
        [
            "Surface water",
            getRating("surface_water", dependencies),
            getAnswersValue("dependencies", "Surface Water", answers, "surface_water", dependencies),
            getAnswersComments("dependencies", "Surface Water", answers, "surface_water", dependencies),
            "E3",
            "Dependency on Ecosystem Services",
        ],
        [
            "Water quality",
            getRating("water_quality", dependencies),
            getAnswersValue("dependencies", "Water Quality", answers, "water_quality", dependencies),
            getAnswersComments("dependencies", "Water Quality", answers, "water_quality", dependencies),
            "E2, E3",
            "Dependency on Ecosystem Services",
        ],
        [
            "Mass stabilization and erosion control",
            getRating("mass_stabilisation_and_erosion_control", dependencies),
            getAnswersValue(
                "dependencies",
                "Mass Stabilisation And Erosion Control",
                answers,
                "mass_stabilisation_and_erosion_control",
                dependencies,
            ),
            getAnswersComments(
                "dependencies",
                "Mass Stabilisation And Erosion Control",
                answers,
                "mass_stabilisation_and_erosion_control",
                dependencies,
            ),
            "E4",
            "Dependency on Ecosystem Services",
        ],
        [
            "Flood and storm protection",
            getRating("flood_and_storm_protection", dependencies),
            getAnswersValue("dependencies", "Flood And Storm Protection", answers, "flood_and_storm_protection", dependencies),
            getAnswersComments("dependencies", "Flood And Storm Protection", answers, "flood_and_storm_protection", dependencies),
            "E1, E4",
            "Dependency on Ecosystem Services",
        ],
        [
            "Water flow maintenance",
            getRating("water_flow_maintenance", dependencies),
            getAnswersValue("dependencies", "Water Flow Maintenance", answers, "water_flow_maintenance", dependencies),
            getAnswersComments("dependencies", "Water Flow Maintenance", answers, "water_flow_maintenance", dependencies),
            "E3",
            "Dependency on Ecosystem Services",
        ],
        [
            "Buffering and attenuation mass flows",
            getRating("buffering_and_attenuation_of_mass_flows", dependencies),
            getAnswersValue(
                "dependencies",
                "Buffering And Attenuation Mass Flows",
                answers,
                "buffering_and_attenuation_of_mass_flows",
                dependencies,
            ),
            getAnswersComments(
                "dependencies",
                "Buffering And Attenuation Mass Flows",
                answers,
                "buffering_and_attenuation_of_mass_flows",
                dependencies,
            ),
            "E3, E4",
            "Dependency on Ecosystem Services",
        ],
        [
            "Bioremediation",
            getRating("bio_remediation", dependencies),
            getAnswersValue("dependencies", "Bio Remediation", answers, "bio_remediation", dependencies),
            getAnswersComments("dependencies", "Bio Remediation", answers, "bio_remediation", dependencies),
            "E2",
            "Dependency on Ecosystem Services",
        ],
        [
            "Filtration",
            getRating("filtration", dependencies),
            getAnswersValue("dependencies", "Filtration", answers, "filtration", dependencies),
            getAnswersComments("dependencies", "Filtration", answers, "filtration", dependencies),
            "E2",
            "Dependency on Ecosystem Services",
        ],
        [
            "Soil quality",
            getRating("soil_quality", dependencies),
            getAnswersValue("dependencies", "Soil Quality", answers, "soil_quality", dependencies),
            getAnswersComments("dependencies", "Soil Quality", answers, "soil_quality", dependencies),
            "E2, E4",
            "Dependency on Ecosystem Services",
        ],
        [
            "Dilution by atmosphere and ecosystems",
            getRating("dilution_by_atmosphere_and_ecosystems", dependencies),
            getAnswersValue(
                "dependencies",
                "Dilution By Atmosphere And Ecosystems",
                answers,
                "dilution_by_atmosphere_and_ecosystems",
                dependencies,
            ),
            getAnswersComments(
                "dependencies",
                "Dilution By Atmosphere And Ecosystems",
                answers,
                "dilution_by_atmosphere_and_ecosystems",
                dependencies,
            ),
            "E3",
            "Dependency on Ecosystem Services",
        ],
        [
            "Fibres and other materials",
            getRating("fibres_and_other_materials", dependencies),
            getAnswersValue("dependencies", "Fibres And Other Materials", answers, "fibres_and_other_materials", dependencies),
            getAnswersComments("dependencies", "Fibres And Other Materials", answers, "fibres_and_other_materials", dependencies),
            "E5",
            "Dependency on Ecosystem Services",
        ],
        [
            "Climate regulation",
            getRating("climate_regulation", dependencies),
            getAnswersValue("dependencies", "Climate Regulation", answers, "climate_regulation", dependencies),
            getAnswersComments("dependencies", "Climate Regulation", answers, "climate_regulation", dependencies),
            "E1",
            "Dependency on Ecosystem Services",
        ],
        [
            "Ventilation",
            getRating("ventilation", dependencies),
            getAnswersValue("dependencies", "Ventilation", answers, "ventilation", dependencies),
            getAnswersComments("dependencies", "Ventilation", answers, "ventilation", dependencies),
            "E2",
            "Dependency on Ecosystem Services",
        ],
        [
            "Mediation of sensory impacts",
            getRating("mediation_of_sensory_impacts", dependencies),
            getAnswersValue("dependencies", "Mediation Of Sensory Impacts", answers, "mediation_of_sensory_impacts", dependencies),
            getAnswersComments("dependencies", "Mediation Of Sensory Impacts", answers, "mediation_of_sensory_impacts", dependencies),
            "E4",
            "Dependency on Ecosystem Services",
        ],
    ];

    return finalData;
};
function getRating(value, data) {
    const result = data.find((item) => item.values.includes(value));
    return result ? rating[result.rating as keyof typeof rating] : "Low"; // Return rating if found, otherwise Low
}
function getAnswersValue(category, name, answersData, dbName, relatedData) {
    const _name = name.toLowerCase().replaceAll(" ", "_");
    return answersData[category] && answersData[category][_name] ? answersData[category][_name].value : getRating(dbName, relatedData);
}
function getAnswersComments(category, name, answersData, dbName, relatedData) {
    const _name = name.toLowerCase().replaceAll(" ", "_");
    return answersData[category] && answersData[category][_name] ? answersData[category][_name]?.comment || "" : "";
}
