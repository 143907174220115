import * as React from "react";
import classnames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

interface ILinkProps {
    active?: boolean;
    activeStyle?: React.CSSProperties;
    className?: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
    to: string;
    onClick?: () => void;
}

export default function NavLink(props: ILinkProps): JSX.Element {
    const location = useLocation();
    const url = location.pathname + location.search;
    const navigate = useNavigate();
    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (e.metaKey) {
            return; // Let the browser handle the href
        }

        e.stopPropagation();
        e.preventDefault();

        if (props.onClick) {
            props.onClick();
        }

        window.scrollTo(0, 0);

        navigate((e.currentTarget as HTMLAnchorElement).getAttribute("href"));
    };

    return (
        <a
            className={classnames("s-nav-link", props.className === undefined ? "s-link" : props.className, {
                "s-nav-link-active": props.active || url === props.to,
            })}
            href={props.to}
            onClick={onClick}
            style={props.active || url == props.to ? props.activeStyle : props.style}
        >
            {props.children}
        </a>
    );
}
