import * as React from "react";
import { mdiMagnify, mdiFileDelimitedOutline, mdiCloudUploadOutline } from "@mdi/js";
import { truncate } from "@/utils";
import Button from "./Button";
import Dropdown from "./Dropdown";
import HorizontalStack from "./HorizontalStack";
import Icon from "./Icon";
import TextInput from "./TextInput";

const icons = {
    csv: mdiFileDelimitedOutline,
    upload: mdiCloudUploadOutline,
};

export interface IFilterBarButton {
    disabled?: boolean;
    id: string;
    label: string;
    variant?: "primary";
    icon?: "csv" | "upload";
}

export interface IFilterBarDropdown {
    disabled?: boolean;
    id: string;
    items: { id: string; label: string }[];
    label: string;
}

interface IFilterBarProps {
    buttons?: IFilterBarButton[];
    dropdowns?: IFilterBarDropdown[];
    onButtonClick?: (id: string) => void;
    onDropdownItemSelect?: (dropdownId: string, itemId: string) => void;
    onSearchTextChange?: (text: string) => void;
    searchText?: string;
    searchTextPlaceholder?: string;
    style?: React.CSSProperties;
}

export default function FilterBar(props: IFilterBarProps): JSX.Element {
    return (
        <HorizontalStack className="s-filter-bar" style={props.style} wrap={true}>
            {props.dropdowns?.map((d, index) => (
                <Dropdown
                    id={"filterbar_dropdown" + index}
                    align="left"
                    key={d.id}
                    disabled={d.disabled}
                    items={d.items}
                    label={truncate(d.label, 30)}
                    onItemClick={(item) => props.onDropdownItemSelect?.(d.id, item)}
                />
            ))}
            {props.searchText !== undefined && (
                <TextInput
                    id="filter-input"
                    ariaLabel={props.searchTextPlaceholder}
                    leftAccessory={<Icon path={mdiMagnify} size={16} />}
                    onInput={(text) => props.onSearchTextChange?.(text)}
                    placeholder={props.searchTextPlaceholder}
                    style={{ maxWidth: "20rem" }}
                    value={props.searchText}
                />
            )}

            <span className="s-flexy" />
            <div className="s-filter-bar-buttons">
                {props.buttons?.map((b) => (
                    <Button disabled={b.disabled} key={b.id} onClick={() => props.onButtonClick?.(b.id)} variant={b.variant}>
                        {b.icon && <Icon color="white" className="s-button-icon" path={icons[b.icon]} size={16} tooltip={b.icon} />}
                        {b.label}
                    </Button>
                ))}
            </div>
        </HorizontalStack>
    );
}
