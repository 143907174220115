import * as React from "react";
import TextArea from "./TextArea";

interface ILabeledTextAreaProps {
    disabled?: boolean;
    error?: string;
    inputStyle?: React.CSSProperties;
    label: string;
    leftAccessory?: React.ReactElement;
    onInput?: (value: string) => void;
    placeholder?: string;
    rightAccessory?: React.ReactElement;
    size?: string;
    style?: React.CSSProperties;
    value: string;
    id: string;
}

const LabeledTextInput = React.forwardRef((props: ILabeledTextAreaProps, ref: React.ForwardedRef<HTMLLabelElement>): JSX.Element => {
    return (
        <label className="s-labeled-text-input" ref={ref} style={props.style}>
            <span>{props.label}</span>
            <TextArea
                id={props.id}
                disabled={props.disabled}
                error={props.error}
                leftAccessory={props.leftAccessory}
                onInput={props.onInput}
                placeholder={props.placeholder}
                rightAccessory={props.rightAccessory}
                size={props.size}
                style={props.inputStyle}
                value={props.value}
            />
        </label>
    );
});

LabeledTextInput.displayName = "LabeledTextInput";

export default LabeledTextInput;
