import * as React from "react";
import { BackButton, Button, HorizontalStack, LoadingAnimation, SectionHeader, TableHeaderCell } from "@/lib";
import { t } from "@/utils";
import { getPrimaryProperties } from "./utils";
import { getSortBy, SortOrder, stringSort } from "@/utils/sort";

interface IProtectedAreaProps {
    area: IMapFeature;
    onPathChange: (path: (number | string)[]) => void;
}

interface IProtectedAreaListItemProps {
    area: IMapFeature;
    onClick: () => void;
}

interface IProtectedAreasPanelProps {
    areas: IMapFeature[];
    onClose: () => void;
    onPathChange: (path: (number | string)[]) => void;
    path: (number | string)[];
    style?: React.CSSProperties;
}

type ProtectedAreasSortType = "name" | "value";

function ProtectedArea(props: IProtectedAreaProps): JSX.Element {
    const [allVisible, setAllVisible] = React.useState<boolean>(false);
    const [sortOrder, setSortOrder] = React.useState<SortOrder>("asc");
    const [sortType, setSortType] = React.useState<ProtectedAreasSortType>("name");
    const all = React.useMemo(
        () =>
            Object.entries(props.area.properties).sort((a, b) => {
                if (sortType === "name") {
                    return stringSort(a[0], b[0], sortOrder);
                } else {
                    return stringSort(a[1], b[1], sortOrder);
                }
            }),
        [props.area, sortOrder, sortType],
    );
    const primary = all.filter(([k]) => getPrimaryProperties(Object.keys(props.area.properties)).includes(k), [props.area]);
    const hasButton = all.length !== primary.length;

    const sort = (order: SortOrder, type: ProtectedAreasSortType) => {
        setSortType(type);
        setSortOrder(order);
    };

    return (
        <div>
            <HorizontalStack>
                <BackButton onClick={() => props.onPathChange(["areas"])} style={{ marginBottom: "0.5rem" }} />
                <SectionHeader bordered={false} level={3} text={props.area.name} />
            </HorizontalStack>

            <table className="s-table">
                <thead>
                    <tr>
                        <TableHeaderCell
                            onClick={(arg) => sort(arg, "name")}
                            sortedBy={getSortBy(sortType, "name", sortOrder)}
                            label={t("ui.name")}
                        />
                        <TableHeaderCell
                            onClick={(arg) => sort(arg, "value")}
                            sortedBy={getSortBy(sortType, "value", sortOrder)}
                            label={t("ui.value")}
                        />
                    </tr>
                </thead>
                {hasButton && (
                    <tfoot>
                        <tr>
                            <td colSpan={2}>
                                <Button onClick={() => setAllVisible(!allVisible)} style={{ marginTop: "1.5rem" }}>
                                    {t(allVisible ? "ui.show_less" : "ui.show_all")}
                                </Button>
                            </td>
                        </tr>
                    </tfoot>
                )}
                <tbody>
                    {(allVisible ? all : primary).map(([k, v]) => (
                        <tr key={k}>
                            <td>{k}</td>
                            <td>{v}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

function getProtectionClass(props: Record<string, string>): string {
    for (const k of ["alueTyyppi"]) {
        switch (props[k]) {
            case "SAC":
                return "Special Area of Conservation";

            case "SAC/SPA":
                return "Special Protection Site";
        }
    }

    for (const k of ["INT_CRIT"]) {
        if (props[k] && props[k] !== "Not Applicable") {
            return `UNEP-WCMC ${props[k]}`;
        }
    }

    for (const k of ["IUCN_CAT", "IUCNKatego"]) {
        if (props[k] && !["Not Assigned", "Not Reported"].includes(props[k])) {
            return `IUCN ${props[k]}`;
        }
    }

    for (const k of ["SITETYPE"]) {
        switch (props[k]) {
            case "A":
            case "C":
                return "Special Protection Site";

            case "B":
                return "Habitats Directive";
        }
    }

    for (const k of ["LakiPerust", "N2"]) {
        if (props[k]) {
            return props[k];
        }
    }

    return "—";
}

function ProtectedAreaListItem(props: IProtectedAreaListItemProps): JSX.Element {
    const { area } = props;

    return (
        <tr>
            <td className={!area.name ? "s-no-value" : undefined}>
                <span className="s-link" onClick={props.onClick}>
                    {area.name || t("ui.unnamed_protected_area")}
                </span>
            </td>
            <td>{area.type}</td>
            <td>{getProtectionClass(area.properties)}</td>
        </tr>
    );
}

type ProtectedAreasPanelSortType = "name" | "type" | "protection";

export default function ProtectedAreasPanel(props: IProtectedAreasPanelProps): JSX.Element {
    if (!props.areas) {
        return <LoadingAnimation />;
    }

    if (props.path.includes("detail")) {
        return <ProtectedArea area={props.areas.find((a) => a.id === props.path[2])} onPathChange={props.onPathChange} />;
    }

    const [sortOrder, setSortOrder] = React.useState<SortOrder>(undefined);
    const [sortType, setSortType] = React.useState<ProtectedAreasPanelSortType>(undefined);

    const sortedAreas = React.useMemo(() => {
        const sorted = [].concat(props.areas).sort((a, b) => {
            if (sortType === "name") {
                return stringSort(a.name, b.name, sortOrder);
            } else if (sortType === "type") {
                return stringSort(a.type, b.type, sortOrder);
            } else if (sortType === "protection") {
                return stringSort(getProtectionClass(a.properties), getProtectionClass(b.properties), sortOrder);
            }
        });
        return sorted;
    }, [props.areas, sortOrder, sortType]);

    const sort = (order: SortOrder, type: ProtectedAreasPanelSortType) => {
        setSortType(type);
        setSortOrder(order);
    };

    return (
        <div>
            <HorizontalStack style={{ marginBottom: "1rem" }}>
                <BackButton onClick={() => props.onPathChange(["stats"])} style={{ marginBottom: "0.5rem" }} />
                <SectionHeader bordered={false} level={3} text={t("ui.protected_area_plural")} />
            </HorizontalStack>

            <table className="s-table">
                <thead>
                    <tr>
                        <TableHeaderCell
                            onClick={(arg) => sort(arg, "name")}
                            sortedBy={getSortBy(sortType, "name", sortOrder)}
                            label={t("ui.name")}
                        />
                        <TableHeaderCell
                            onClick={(arg) => sort(arg, "type")}
                            sortedBy={getSortBy(sortType, "type", sortOrder)}
                            label={t("ui.type")}
                        />
                        <TableHeaderCell
                            onClick={(arg) => sort(arg, "protection")}
                            sortedBy={getSortBy(sortType, "protection", sortOrder)}
                            label={t("ui.protection_class")}
                        />
                    </tr>
                </thead>
                <tbody>
                    {sortedAreas.map((a) => (
                        <ProtectedAreaListItem key={a.id} area={a} onClick={() => props.onPathChange(["areas", "detail", a.id])} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}
