import * as React from "react";
import { colors } from "@/utils/style";

export const SUBSCRIPTION_CONTENT = {
    text: "This is not part of your subscription plan, ",
    link: "https://kuyua.pipedrive.com/scheduler/R0y0w3HA/initial-meeting",
    linkText: "contact us",
};

interface SubscriptionTooltipProps {
    children: React.ReactNode;
    show?: boolean;
    alwaysShow?: boolean;
}

const SubscriptionMessage = () => (
    <>
        <span>{SUBSCRIPTION_CONTENT.text}</span>
        <a
            href={SUBSCRIPTION_CONTENT.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: colors.brightBlue, textDecoration: "underline" }}
            onClick={(e) => e.stopPropagation()}
        >
            {SUBSCRIPTION_CONTENT.linkText}
        </a>
    </>
);

export const SubscriptionTooltip: React.FC<SubscriptionTooltipProps> = ({ children, show = true, alwaysShow = false }) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    const handleMouseEnter = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setIsVisible(true);
    };

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setIsVisible(false);
        }, 2000);
    };

    React.useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    if (alwaysShow) {
        return <SubscriptionMessage />;
    }

    return (
        <div style={{ position: "relative", display: "inline-block" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {isVisible && show && (
                <div
                    style={{
                        position: "absolute",
                        bottom: "100%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        color: "white",
                        padding: "8px",
                        borderRadius: "4px",
                        whiteSpace: "nowrap",
                        zIndex: 1000,
                        marginBottom: "8px",
                    }}
                >
                    <SubscriptionMessage />
                </div>
            )}
        </div>
    );
};
