import React from "react";

interface LoadingStateProps {
    loading: boolean;
    error: string | null;
}

export const LoadingState: React.FC<LoadingStateProps> = ({ loading, error }) => {
    if (loading) {
        return (
            <div style={{ color: "#fff", background: "#2B3A4A", padding: 16, display: "flex", alignItems: "center" }}>
                <div
                    className="spinner"
                    style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        border: "2px solid #fff",
                        borderTopColor: "transparent",
                        animation: "spin 1s linear infinite",
                        marginRight: 10,
                    }}
                />
                Loading...
            </div>
        );
    }
    if (error) {
        return <div style={{ color: "#fff", background: "#2B3A4A", padding: 16 }}>Error: {error}</div>;
    }
    return null;
};
