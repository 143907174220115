import * as React from "react";
import Bubble from "@/lib/Bubble";
import { colors, StyleSheet } from "@/utils/style";
import { Bar } from "@/project/sleap/Assess/AssessTile";
import { useDependencies, useImpacts } from "@/context";
import { getBubbleBarColor } from "@/utils/getBubbleBarColor";
import { getRatingText } from "@/utils/getRatingText";
import { Rating } from "@/types/utils";
import useFetchDependencies from "@/utils/useFetchDependencies";
import useFetchImpacts from "@/utils/useFetchImpacts";

export const MaterialityDetail: React.FC<{ site: INewSite }> = ({ site }) => {
    console.log("site from materiality =====>>>> ", site);
    // const impacts = useImpacts();
    // const dependencies = useDependencies();

    // const no_total_impacts = impacts.length;
    // const no_site_impacts = restructuredImpactsGeoServer.reduce((sum, newValue) => sum + newValue.values.length, 0);
    // const no_total_dependencies = dependencies.length;
    // const no_site_dependencies = restructuredDependenciesGeoServer.reduce((sum, newValue) => sum + newValue.values.length, 0);
    const economicActivities = JSON.parse(localStorage.getItem("newEconomicActivities"));
    console.log("economicActivitiesIds from material->", economicActivities);
    const economicActivitiesIds = economicActivities.map((ea) => ea.economic_activity_id).join(",");
    const siteEconomicActivitiesLength = economicActivitiesIds?.split(",").length;
    const { totalUniqueImpacts, totalUniqueImpactsWithRating, restructuredImpactsGeoServer, loading, error } = useFetchImpacts(
        economicActivitiesIds,
        site.site_id,
    );
    const { totalUniqueDependencies, totalUniqueDependenciesWithRating, restructuredDependenciesGeoServer } = useFetchDependencies(
        economicActivitiesIds,
        site.site_id,
    );
    console.log("restructuredDependenciesGeoServer", totalUniqueDependenciesWithRating);
    return (
        <div style={styles.materialBox}>
            {restructuredImpactsGeoServer && restructuredDependenciesGeoServer && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontSize: 17,
                            textTransform: "capitalize",
                            marginBottom: 20,
                        }}
                    >
                        economic activities
                    </span>
                    <Bubble
                        count={siteEconomicActivitiesLength}
                        size="md"
                        style={{
                            color: colors.white,
                            backgroundColor: getBubbleBarColor([...restructuredImpactsGeoServer, ...restructuredDependenciesGeoServer]),
                            marginBottom: 20,
                        }}
                    />
                    <div style={styles.multipleContainer}>
                        <ul style={{ margin: "unset", padding: "0 10px" }}>
                            {economicActivities.map((activity, i) => (
                                <li key={i}>{activity.economic_activity_name}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            {restructuredImpactsGeoServer && restructuredDependenciesGeoServer && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontSize: 17,
                            textTransform: "capitalize",
                            marginBottom: 20,
                        }}
                    >
                        impacts
                    </span>
                    <Bubble
                        count={totalUniqueImpactsWithRating}
                        countTotal={12}
                        size="md"
                        style={{
                            color: colors.white,
                            backgroundColor: getBubbleBarColor([...restructuredImpactsGeoServer, ...restructuredDependenciesGeoServer]),
                            marginBottom: 20,
                        }}
                    />
                    <div>
                        {restructuredImpactsGeoServer &&
                            restructuredImpactsGeoServer.map((impact) => (
                                // <Bar
                                //   key={'impact_bar_' + impact.rating}
                                //   style={{ paddingBottom: 5 }}
                                //   backgroundColor={getBubbleBarColor([...restructuredImpactsGeoServer, ...restructuredDependenciesGeoServer])}
                                //   color={colors.opaqueBlue}
                                //   barColor={colors.mediumBlue}
                                //   rating={impact.rating}
                                //   values={impact.values}
                                //   totalAvailable={no_total_impacts}
                                // />
                                <div style={{ fontSize: "12px" }}>
                                    {getRatingText(impact.rating as Rating)}: {impact.values.length}
                                </div>
                            ))}
                    </div>
                </div>
            )}
            {restructuredDependenciesGeoServer && restructuredImpactsGeoServer && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontSize: 17,
                            textTransform: "capitalize",
                            marginBottom: 20,
                        }}
                    >
                        dependencies
                    </span>
                    <Bubble
                        count={totalUniqueDependenciesWithRating}
                        countTotal={21}
                        size="md"
                        style={{
                            color: colors.white,
                            backgroundColor: getBubbleBarColor([...restructuredImpactsGeoServer, ...restructuredDependenciesGeoServer]),
                            marginBottom: 20,
                        }}
                    />
                    <div>
                        {restructuredDependenciesGeoServer &&
                            restructuredDependenciesGeoServer.map((dependency) => (
                                // <Bar
                                //   key={'impact_bar_' + dependency.rating}
                                //   style={{ paddingBottom: 5 }}
                                //   backgroundColor={getBubbleBarColor([...restructuredImpactsGeoServer, ...restructuredDependenciesGeoServer])}
                                //   color={colors.opaqueBlue}
                                //   barColor={colors.mediumBlue}
                                //   rating={dependency.rating}
                                //   values={dependency.values}
                                //   totalAvailable={no_total_dependencies}
                                // />
                                <div style={{ fontSize: "12px" }}>
                                    {getRatingText(dependency.rating as Rating)}: {dependency.values.length}
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = StyleSheet.create({
    materialBox: {
        position: "absolute",
        width: "100%",
        height: 265,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        padding: "20px 15px",
        backgroundColor: colors.opaqueBlueWithoutDependency,
        zIndex: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    multipleContainer: {
        color: "white",
        maxHeight: 100,
        overflowY: "scroll",
        width: 130,
        fontSize: 12,
    },
});
