import * as React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "@/context";
import { BreadcrumbPath, MasterHeader, SidebarLink } from "@/lib";
import { getHomeUrl, t } from "@/utils";
import SidebarPreferencesPanel from "@/project/SidebarPreferencesPanel";

export default function AccountPage(): JSX.Element {
    const { state } = React.useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isSmallLayout = ["xs", "sm", "md"].includes(state.layoutSize);
    const breadcrumb = [
        { label: t("ui.home"), route: getHomeUrl(state.config.team) },
        { label: t("ui.account"), route: "/account" },
    ];
    let title = {
        "/account": t("ui.account_overview"),
        "/account/members": t("ui.team"),
        "/account/password": t("ui.change_password"),
    }[location.pathname];

    React.useEffect(() => {
        document.title = `${title} — kuyua`;
    }, [title]);

    if (location.pathname.indexOf("/account/members/") === 0) {
        title = t("ui.team_member");
    }

    return (
        <div>
            <div className="s-app-sidebar">
                <div className="s-app-sidebar-header" />
                <div className="s-app-sidebar-body" style={{ paddingTop: "100px" }}>
                    <SidebarLink label={t("ui.overview")} onClick={() => navigate("/account")} />
                    <SidebarLink label={t("ui.team")} onClick={() => navigate("/account/members")} />
                    <SidebarLink label={t("ui.change_password")} onClick={() => navigate("/account/password")} />
                    <SidebarLink label={t("ui.switch_team")} onClick={() => navigate("/teams")} />
                </div>
                <div className="s-app-sidebar-footer">
                    <SidebarPreferencesPanel />
                </div>
            </div>
            <div className="s-app-content" style={{ padding: "0 2rem 2rem 2rem" }}>
                <BreadcrumbPath items={breadcrumb} onItemClick={(item) => navigate(item.route)} style={{ marginTop: "1rem" }} />

                <MasterHeader style={{ marginTop: isSmallLayout ? "60px" : "40px" }} text={title} />

                <Outlet />
            </div>
        </div>
    );
}
