import * as React from "react";
import "./tooltip.scss";

interface ITooltipProps {
    children: React.ReactNode;
    alignment?: "right" | "left";
}

export default function Tooltip(props: ITooltipProps): JSX.Element {
    return (
        <div
            className={`s-tooltip
            ${props.alignment === "right" ? "s-tooltip--prefer-right" : ""}`}
        >
            {props.children}
        </div>
    );
}
