export function setInteractive(map: mapboxgl.Map, enabled: boolean) {
    if (!map) {
        return;
    }

    if (enabled) {
        map.boxZoom.enable();
        map.doubleClickZoom.enable();
        map.dragPan.enable();
        map.dragRotate.enable();
        map.keyboard.enable();
        map.scrollZoom.enable();
        map.touchZoomRotate.enable();
    } else {
        map.boxZoom.disable();
        map.doubleClickZoom.disable();
        map.dragPan.disable();
        map.dragRotate.disable();
        map.keyboard.disable();
        map.scrollZoom.disable();
        map.touchZoomRotate.disable();
    }
}

export function getEmptyAddress(): IPostAddress {
    return {
        city: "",
        country_code: "",
        country: "",
        name: "",
        province: "",
        street_address: "",
        zip_code: "",
    };
}

export function parseAddress(result: IMapboxQueryResult): IPostAddress {
    const addr = getEmptyAddress();

    for (const feat of result?.features || []) {
        switch (feat.id.split(".")[0]) {
            case "address":
                addr.street_address = feat.text;

                if (feat.address) {
                    addr.street_address += " " + feat.address;
                }
                break;

            case "city":
                addr.city = feat.text;
                break;

            case "country":
                addr.country = feat.text;
                addr.country_code = feat.properties.short_code.toUpperCase();
                break;

            case "place":
                addr.city = feat.text;
                break;

            case "postcode":
                addr.zip_code = feat.text;
                break;

            case "region":
                addr.province = feat.text;
                break;
        }
    }

    return addr;
}
