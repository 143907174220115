import * as React from "react";
import Dropdown from "@/utils/Dropdown";
import { AppContext, ToastAdded } from "@/context";
import { mdiFileUploadOutline } from "@mdi/js";
import { Icon } from "../../lib";
import { style, StyleSheet } from "@/utils/style";
import CryptoJS from "crypto-js";

export const UploadDocs: React.FC = ({ onUploadSuccess }) => {
    const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/webp", "application/pdf"];
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            Authorization: `Bearer ${encryptedToken}`,
        },
    };

    const { dispatch } = React.useContext(AppContext);
    const [isCommon, setIsCommon] = React.useState(false);
    const [companies, setCompanies] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState<number[]>([]);
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [selectedFileName, setSelectedFileName] = React.useState<string | null>(null);
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const validateForm = React.useCallback(() => {
        const newErrors: { [key: string]: string } = {};

        if (!selectedFile) {
            newErrors.file = "Please select a file.";
        }

        if (!isCommon && selectedCompany.length === 0) {
            newErrors.company = "Please select a company.";
        }

        setErrors(newErrors);
    }, [selectedFile, isCommon, selectedCompany]);

    React.useEffect(() => {
        validateForm();
    }, [validateForm, isCommon, selectedCompany, selectedFile]);

    const handleSetIsCommon = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsCommon(e.target.checked);
    };

    const handleCompanySelect = (selected: number[]): void => {
        const filterdCompanies = Array.from(new Set(selected));
        setSelectedCompany(filterdCompanies);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                setErrors((prev) => ({
                    ...prev,
                    file: "Unsupported file type. Please select a .jpg, .png, .jpeg, .webp, or .pdf file.",
                }));
                setSelectedFile(null);
                setSelectedFileName(null);
            } else if (file.size > 10 * 1024 * 1024) {
                setErrors((prev) => ({ ...prev, file: "File size exceeds 10 MB." }));
                setSelectedFile(null);
                setSelectedFileName(null);
            } else {
                setSelectedFile(file);
                setSelectedFileName(file.name);
                setErrors((prev) => ({ ...prev, file: "" }));
            }
        }
    };

    const handleUpload = async () => {
        if (Object.keys(errors).length > 0 || !selectedFile) {
            alert("Please resolve all errors before uploading.");
            return;
        }

        const companyIds = isCommon
            ? []
            : (selectedCompany.length > 0 ? selectedCompany : companies.map((company) => company.id)).toString();
        const url = `${process.env.KUYUA_APP_BACKEND_URL}/docs/upload?${isCommon ? "isCommon=true" : `companyIds=${companyIds}&isCommon=false`}`;

        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: header.headers,
                body: formData,
            });
            const result = await response.json();

            if (result.status === 201) {
                dispatch({
                    type: ToastAdded,
                    toast: { kind: "success", text: "File upload success." },
                });
                onUploadSuccess();
                resetForm();
            } else {
                dispatch({
                    type: ToastAdded,
                    toast: { kind: "error", text: "File upload failed." },
                });
            }
        } catch (error) {
            alert("An error occurred during the upload.");
        }
    };

    const resetForm = () => {
        setErrors({});
        setSelectedCompany([]);
        setSelectedFile(null);
        setIsCommon(false);
        setSelectedFileName(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const getCompanies = async () => {
        const CompaniesUrl = `${process.env.KUYUA_APP_BACKEND_URL}/companies/all`;
        const response = await fetch(CompaniesUrl, header);
        const data = await response.json();
        setCompanies(
            Array.isArray(data.data)
                ? data.data.map((company) => ({
                      id: company.company_id,
                      label: company.slug,
                  }))
                : [data.data],
        );
    };

    React.useEffect(() => {
        getCompanies();
    }, []);

    const isFormValid = Object.keys(errors).length === 0 && selectedFile !== null;

    return (
        <div style={styles.uploadDocs}>
            <h2>Upload Documents</h2>
            <div style={styles.checkboxContainer}>
                <input type="checkbox" id="isCommon" checked={isCommon} onChange={handleSetIsCommon} />
                <label htmlFor="isCommon" style={styles.checkboxLabel}>
                    For all accounts?
                </label>
            </div>
            {!isCommon && (
                <div style={styles.dropdownContainer}>
                    <label htmlFor="companyDropdown" style={styles.dropdownLabel}>
                        Teams:
                    </label>
                    <Dropdown
                        placeholder={"Team"}
                        handler={handleCompanySelect}
                        options={companies}
                        selected={selectedCompany}
                        flat
                        selectedRight
                        multiple
                    />
                    {/* {errors.company && <p style={styles.error}>{errors.company}</p>} */}
                </div>
            )}
            <div style={styles.fileUploadContainer}>
                <label htmlFor="fileUpload" style={styles.fileUploadLabel}>
                    <Icon path={mdiFileUploadOutline} size={36} color="white" />
                </label>
                <input required type="file" id="fileUpload" onChange={handleFileChange} style={styles.fileUploadInput} ref={fileInputRef} />
            </div>
            <div>{selectedFileName && <p style={{ marginBottom: "10px", padding: "0px" }}>{selectedFileName}</p>}</div>
            {/* {errors.file && <p style={styles.error}>{errors.file}</p>} */}
            <button
                onClick={handleUpload}
                style={{
                    ...style.confirmButton,
                    cursor: isFormValid ? "pointer" : "not-allowed",
                }}
                disabled={!isFormValid}
            >
                Upload
            </button>
        </div>
    );
};

const styles = StyleSheet.create({
    uploadDocs: {
        // maxWidth: '400px',
        // margin: '0 auto',
        padding: "20px",
        // border: '1px solid #ccc',
        borderRadius: "8px",
    },

    checkboxContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
    },

    checkboxLabel: {
        marginLeft: "8px",
    },

    dropdownContainer: {
        marginBottom: "20px",
    },

    dropdownLabel: {
        display: "block",
        marginBottom: "8px",
    },

    dropdown: {
        width: "100%",
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "4px",
    },

    fileUploadContainer: {
        // display: 'flex',
        justifyContent: "center",
        marginTop: "25px",
    },

    fileUploadLabel: {
        cursor: "pointer",
    },

    fileUploadInput: {
        display: "none",
    },
    uploadButton: {
        marginTop: "20px",
        padding: "10px 10px",
        backgroundColor: "rgb(32, 51, 77)",
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
    },

    error: {
        color: "red",
        marginTop: "10px",
    },
    // Responsive Styles
    "@media (max-width: 768px)": {
        uploadDocs: {
            width: "90%",
            padding: "15px",
        },
        dropdown: {
            padding: "6px",
        },
        fileUploadLabel: {
            transform: "scale(0.9)",
        },
    },
});
