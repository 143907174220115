import * as React from "react";
import { colors, StyleSheet } from "@/utils/style";
import StaticMapWrapper from "@/lib/map/StaticMapWrapper";
import { AllSitesTable } from "@/project/measure/AllSitesTable";

export const MeasurePage: React.FC = () => {
    return (
        <div className="content">
            <StaticMapWrapper right={true} />
            <div style={styles.wrapper}>
                <div style={styles.tableWrapper}>
                    <AllSitesTable />
                </div>
            </div>
        </div>
    );
};

const styles = StyleSheet.create({
    wrapper: {
        width: "100%",
        height: "100%",
        marginTop: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 5,
        zIndex: 10,
    },
    tableWrapper: {
        width: "100%",
        backgroundColor: colors.darkBlue,
        border: `1px solid ${colors.brightBlue}`,
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
    },
});
