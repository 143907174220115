/* eslint-disable @typescript-eslint/no-explicit-any */
import CryptoJS from "crypto-js";

import {
  fetchDepAndImp
} from "./helpers";
import { impactCategories, variables } from "./variables";

const encryptedToken = CryptoJS.AES.encrypt(
  process.env.API_KEY,
  process.env.SECRET_KEY
).toString();

const header = {
  headers: {
    Authorization: `Bearer ${encryptedToken}`,
  },
};

if (
  !process.env.API_KEY ||
  !process.env.SECRET_KEY ||
  !process.env.KUYUA_APP_BACKEND_URL ||
  !process.env.GEO_SERVER_URL
) {
  throw new Error("Missing necessary environment variables.");
}

const formatRating = (rating: string) =>
  ({
    H: "High",
    M: "Medium",
    L: "Low",
    VH: "Very High",
  }[rating] ||
  rating ||
  "Unknown");

const formatImpactsAndDependencies = (data: any[], type: string) => {
  const result: { [key: string]: string } = {};
  ["VH", "H", "M", "L"].forEach((rating) => {
    result[`${type} (${formatRating(rating)})`] = "";
  });

  if (!Array.isArray(data)) {
    console.error(`Invalid ${type} data: expected array, got ${typeof data}`);
    return result;
  }

  data.forEach(({ rating, values }) => {
    if (Array.isArray(values) && values.length > 0) {
      const key = `${type} (${formatRating(rating)})`;
      result[key] = values
        .filter(
          (value: string) =>
            value && typeof value === "string" && !value.includes("undefined")
        )
        .map((value: string) => `• ${value.trim()}`)
        .join("\n");
    }
  });

  return result;
};

const filterAndFormatImpactsAndDependencies = (impacts: Record<string, any>, categories: string[]) => {
  return Object.keys(impacts || {})
    .filter((impact) => {
      if (["Very High", "High", "Medium"].includes(impacts[impact].value)) {
        const originalImpactName = impact
          .toLowerCase()
          .trim()
          .replaceAll(" ", "_");
        return categories.includes(originalImpactName);
      }
      return false;
    })
    .map((impact) => ` • ${impact.toLowerCase().trim().replaceAll(" ", "_")}`)
    .join("\n");
};

export const sbm3 = async (site: INewSite) => {
  if (!site) {
    throw new Error("Missing site information.");
  }
  async function fetchSiteKPIS(site){
    try {
      const KPIsURL = 
      `${process.env.KUYUA_APP_BACKEND_URL}/site/kpis?site_id=${site.site_id}&kpis=msa,msa-delta,bsa,bsa-distance,habitats,npp,species,change-y-average,deforestation,water-risk,materiality`;
      const response = await fetch(KPIsURL,header);
      const data = response.json();
      return data;
    } catch (error) {
      console.error("Error at fetch kpis.");
    }
  }
  const [kpisResponse] = await Promise.all([
    fetchSiteKPIS(site)
  ]);
  const{speciesData, msa, msaDelta, BSACount,siteName, impacts, dependencies,
     siteAddress, sitePriorityScore, siteType, economic_activities, materiality}= kpisResponse;

  const fullSiteName = siteName;
  const activities = economic_activities
    .map(
      (e: { economic_activity_name: any }) =>
        `• ${e.economic_activity_name || "Unknown activity"}`
    )
    .join("\n");

  const formattedImpacts = formatImpactsAndDependencies(impacts, "Impact");
  const formattedDependencies = formatImpactsAndDependencies(
    dependencies,
    "Dependency"
  );

  const impactsArray = impacts.flatMap((impact: any) => impact.values);

  const formatImpactCategory = (category: string[]) =>
    impactsArray
      .filter((impact: string) => impact && category.includes(impact))
      .map((impact: any) => ` • ${impact}`)
      .join("\n") || "No impacts identified";

  const ecologicalStatus =
    msa !== undefined ? Number(msa).toFixed(2) : "N/A";
  const projectedChangeInEcologicalStatus =
    msaDelta !== undefined
      ? Number(msaDelta).toFixed(0) + "%"
      : "N/A";

  let eA = await Promise.all(
    economic_activities.map(async (eco) => {
      const response = await fetchDepAndImp(
        "impacts",
        eco.economic_activity_id
      );
      return {
        economic_activity_id: eco.economic_activity_id,
        economic_activity_name: eco.economic_activity_name,
        impacts: response,
      };
    })
  );
  const activitiesImpactingBSAsAndSpecies = eA
    .filter((activity) =>
      activity.impacts.some(
        (impact: { rating: string; values: string[] }) =>
          ["M", "H", "VH"].includes(impact.rating) &&
          !(
            impact.values.length === 1 &&
            (impact.values.includes(variables.impacts.ghg_emissions) ||
              impact.values.includes(variables.impacts.non_ghg_emissions))
          )
      )
    )
    .map(
      (activity) => `• ${activity.economic_activity_name || "Unknown activity"}`
    )
    .join("\n");

  const materialityValues = materiality?.result?.answers;
  materialityValues.impacts = materialityValues.impacts || {};
  materialityValues.dependencies = materialityValues.dependencies || {};

  // Function to add missing values based on the rating
  async function addMissingValues(categoryList, answerCategory) {
    categoryList.forEach(({ rating, values }) => {
      values.forEach(value => {
        const impValue = value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        // Check if the value already exists in answers; if not, add it
        if (!Object.keys(materialityValues[answerCategory]).includes(impValue)) {
          materialityValues[answerCategory][impValue] = { value: convertRating(rating) , comment: "" };
        }
      });
    });
  }
  function convertRating(rating){
    switch (rating) {
      case "VH":
        return "Very High";
      case "H":
        return "High";
      case "M":
        return "Medium"
      case "L":
        return "Low"
      default:
        break;
    }
  }
  // Adding missing impact and dependency values
  await addMissingValues(impacts, "impacts");
  await addMissingValues(dependencies, "dependencies");
  console.log("mat: ",materialityValues);
  const impactsCausingLandDegradation = formatImpactCategory(
    impactCategories.landDegradation
  );

  const actualImpactsCausingLandDegradation =
    filterAndFormatImpactsAndDependencies(
      materialityValues?.impacts,
      impactCategories.landDegradation
    );

  const impactsCausingSoilSealing = formatImpactCategory(
    impactCategories.sealingSoil
  );

  const actualImpactsCausingSoilSealing = filterAndFormatImpactsAndDependencies(
    materialityValues?.impacts,
    impactCategories.sealingSoil
  );

  const impactsCausingDesertification = formatImpactCategory(
    impactCategories.desertification
  );

  const actualImpactsCausingDesertification =
    filterAndFormatImpactsAndDependencies(
      materialityValues?.impacts,
      impactCategories.desertification
    );

  const reshapeActualImpactsAndDependencies = (
    category: Record<string, { value: string }>
  ) => {
    const reshaped: { rating: string; values: string[] }[] = [];

    Object.entries(category).forEach(([key, { value }]) => {
      const existing = reshaped.find((item) => item.rating === value);
      if (existing) {
        existing.values.push(key.toLowerCase().trim().replaceAll(" ", "_"));
      } else {
        reshaped.push({
          rating: value,
          values: [key.toLowerCase().trim().replaceAll(" ", "_")],
        });
      }
    });

    return reshaped;
  };

  const reshapedImpacts = reshapeActualImpactsAndDependencies(
    materialityValues?.impacts || {}
  );
  const formattedActualImpacts = formatImpactsAndDependencies(
    reshapedImpacts,
    "Impact"
  );

  const reshapedDependencies = reshapeActualImpactsAndDependencies(
    materialityValues?.dependencies || {}
  );
  const formattedActualDependencies = formatImpactsAndDependencies(
    reshapedDependencies,
    "Dependency"
  );

  const finalData = [
    ["Site Name", ` ${fullSiteName || "Unnamed Site"}`],
    ["Address", ` ${siteAddress || "Address not available"}`],
    ["Country", ` ${site.country || "Unknown"}`],
    ["Latitude", ` ${site.latlng?.lat ?? "N/A"}`],
    ["Longitude", ` ${site.latlng?.lng ?? "N/A"}`],
    ["Site Type", ` ${siteType}`],
    ["Activities on site", ` ${activities || "No activities recorded"}`],
    ["Impact Radius (m)", ` ${site.radius || "Not specified"}`],
    ["Materiality Threshold", " medium, high, very high"],
    ["Priority Score (1-100)", ` ${sitePriorityScore}`],
    [""],
    ["Ecological Status (0-100)", ` ${ecologicalStatus}`],
    [
      "Projected Change in Ecological Status",
      ` ${projectedChangeInEcologicalStatus}`,
    ],
    [""],
    [
      "Activities impacting BSAs and Species",
      activitiesImpactingBSAsAndSpecies,
    ],
    [
      "Biodiversity Sensitive Areas impacted",
      ` ${BSACount === undefined ? "N/A" : BSACount}`,
    ],
    ["Threatened Species", ` ${speciesData?.threatened_species_count || "N/A"}`],
    [""],
    ["", "Potential", "Actual"],
    [
      "Impacts causing Land Degradation",
      impactsCausingLandDegradation,
      actualImpactsCausingLandDegradation,
    ],
    [
      "Impacts causing Soil Sealing",
      impactsCausingSoilSealing,
      actualImpactsCausingSoilSealing,
    ],
    [
      "Impacts causing Desertification",
      impactsCausingDesertification,
      actualImpactsCausingDesertification,
    ],
    [""],
    ["Rated Impacts", "Potential", "Actual"],

    ...Object.entries(formattedImpacts).map(([key, value]) => [
      key,
      ` ${value}`,
      ` ${formattedActualImpacts[key]}`,
    ]),
    [""],
    ["Rated Dependencies", "Potential", "Actual"],
    ...Object.entries(formattedDependencies).map(([key, value]) => [
      key,
      ` ${value}`,
      ` ${formattedActualDependencies[key]}`,
    ]),
  ];

  return finalData;
};

function handleSiteType(siteTypeId: string) {
  switch (siteTypeId) {
    case "1":
      return "own";
    case "2":
      return "upstream";
    case "3":
      return "downstream";
    default:
      break;
  }
}
