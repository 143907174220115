import React from "react";
import { style, StyleSheet } from "@/utils/style";
import { Icon } from "@/lib";
import { mdiMagnify } from "@mdi/js";
import { LocationSearchProps } from "@/types/sleap";

export const LocationSearch: React.FC<LocationSearchProps> = ({ inputHandler }) => {
    return (
        <div style={styles.wrapper}>
            <input
                id={"searchBar"}
                style={{ ...style.inputField, ...styles.searchBar }}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => inputHandler(e.target.value)}
                placeholder={"SEARCH"}
            />
            <Icon style={styles.icon} color="#fff" path={mdiMagnify} size={20} />
        </div>
    );
};

const styles = StyleSheet.create({
    wrapper: {
        position: "relative",
        flex: 1,
    },
    searchBar: {
        width: "100%",
        margin: "unset",
    },
    icon: {
        position: "absolute",
        right: 5,
        top: "50%",
        transform: "translateY(-50%)",
    },
});
