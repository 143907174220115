import * as React from "react";
import classnames from "classnames";

interface ISectionHeaderProps {
    bordered?: boolean;
    level: number | string;
    linkText?: string;
    middleAccessory?: React.ReactElement;
    onLinkClick?: () => void;
    rightAccessory?: React.ReactElement;
    style?: React.CSSProperties;
    tagLevel?: 2 | 3 | 4 | 5;
    text: string;
}

export default function SectionHeader(props: ISectionHeaderProps): JSX.Element {
    const classes = classnames("s-section-header", {
        [`s-section-header-${props.level}`]: typeof props.level === "number" ? props.level : undefined,
        "s-section-header-bordered": props.bordered !== false,
        "s-section-header-no-border": props.bordered === false,
    });

    const HeadingTag = `h${props.tagLevel ? props.tagLevel : 2}` as keyof JSX.IntrinsicElements;

    return (
        <div className={classes} style={props.style}>
            <HeadingTag
                className="s-section-header-text"
                style={{
                    fontSize: typeof props.level === "string" ? props.level : undefined,
                }}
            >
                {props.text}
            </HeadingTag>
            {props.linkText && (
                <a className="s-section-header-link" onClick={props.onLinkClick}>
                    {props.linkText}
                </a>
            )}
            {props.middleAccessory}
            {props.rightAccessory}
        </div>
    );
}
