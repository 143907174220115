import * as React from "react";
import classnames from "classnames";

interface ICardProps {
    children: React.ReactNode;
    inset?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
}

export default function Card(props: ICardProps): JSX.Element {
    const classes = classnames("ui-card", {
        "ui-card-clickable": !!props.onClick,
    });

    return (
        <div className={classes} style={{ ...props.style, padding: props.inset || "1rem" }} onClick={props.onClick}>
            {props.children}
        </div>
    );
}
