import * as React from "react";
import { AppContext, ToastAdded } from "@/context";
import { Button, LoadingAnimation } from "@/lib";
import { postFile, t } from "@/utils";
import "./index.scss";

const twoMbSize = 2097152;
const xlsxType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const csvType = "text/csv";

interface IUploadStepProps {
    onNext: () => void;
    setFile: (file: File) => void;
    setFileData: (fileData: Record<string, string>) => void;
}

export default function SiteImportUploadStep(props: IUploadStepProps): JSX.Element {
    const { dispatch } = React.useContext(AppContext);
    const fileInputRef = React.useRef<HTMLInputElement>();
    const [fileError, setFileError] = React.useState<string>(undefined);
    const [loading, setLoading] = React.useState<boolean>(false);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        if (file.type !== xlsxType && file.type !== csvType) {
            setFileError(t("ui.sites_import.filetype_error"));
            return;
        } else if (file.size > twoMbSize) {
            setFileError(t("ui.sites_import.file_too_big"));
            return;
        }

        setFileError(undefined);
        setLoading(true);
        props.setFile(file);

        const data = new FormData();
        data.append("file", file);
        postFile<Record<string, string>>("/api/v1/file_to_json", data)
            .then((reply) => {
                if (reply.ok) {
                    props.setFileData(reply.data);
                    props.onNext();
                } else {
                    dispatch({
                        type: ToastAdded,
                        toast: {
                            kind: "error",
                            text: t("ui.sites_import.file_error_server"),
                        },
                    });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="s-site-import-file">
            {fileError && <div className="s-error-text s-site-import-file-error">{fileError}</div>}
            <div style={{ textAlign: "center" }}>
                <Button onClick={() => fileInputRef.current.click()} disabled={loading} variant="primary">
                    {t("ui.sites_import.button")}
                </Button>
                <div className="s-site-import-file-size" style={{ marginTop: ".5rem" }}>
                    {t("ui.sites_import.filesize_info")}
                </div>
                <input
                    ref={fileInputRef}
                    className="s-site-import-input"
                    disabled={loading}
                    type="file"
                    accept=".csv,.xlsx"
                    onChange={onFileChange}
                />
            </div>

            {loading && <LoadingAnimation />}
        </div>
    );
}
