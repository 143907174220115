import * as React from "react";
import classnames from "classnames";
import { apiGet, httpGet } from "@/utils";
import { CloseButton } from "@/lib";
import { AppContext, useProject, useTeam } from "@/context";
import AddToSitesPanel from "./AddToSitesPanel";
import ProtectedAreasPanel from "./ProtectedAreasPanel";
import HeadlineKpisPanel from "./HeadlineKpisPanel";

interface IMapBottomPanelProps {
    onClose: () => void;
    onPathChange: (path: (number | string)[]) => void;
    path: (number | string)[];
    pointOfInterest: ILatLng;
    poiSiteId: number;
    raster: IProjectRasterConfig;
    style?: React.CSSProperties;
}

export default function MapBottomPanel(props: IMapBottomPanelProps): JSX.Element {
    const { state } = React.useContext(AppContext);
    const team = useTeam();
    const project = useProject();
    const [areas, setAreas] = React.useState<IMapFeature[]>(undefined);
    const [kpis, setKpis] = React.useState<Record<string, number>>({});

    React.useEffect(() => {
        if (!props.pointOfInterest) {
            setAreas(undefined);
            props.onPathChange([]);

            return;
        }

        const token = state.config.tileserver_api_token;
        const { lat, lng } = props.pointOfInterest;

        setAreas(undefined);
        setKpis({});

        props.onPathChange(["stats", "loading"]);

        httpGet<Record<string, number>>(`/api/v1/rasters/pixel/msa2015/${lng}/${lat}?token=${token}`)
            .then((reply) => {
                if (reply?.ok) {
                    setKpis((kpis) => ({ ...kpis, ...reply.data }));
                }

                return httpGet<Record<string, number>>(`/api/v1/rasters/pixel/aq3bwscat20190715/${lng}/${lat}?token=${token}`);
            })
            .then((reply) => {
                if (reply?.ok) {
                    // NOTE! The raster map has only positive values and thus the water risk range has been shifted from
                    //       [-1..4] to [0..5]. So reduce the value received from raster map by one to get the actual
                    //       value.

                    setKpis((kpis) => ({
                        ...kpis,
                        aq3bwscat20190715: reply.data.aq3bwscat20190715 - 1,
                    }));
                }

                if (props.raster && !["aq3bwscat20190715", "msa2015"].includes(props.raster.id)) {
                    // A raster has been selected by user, and it is not one of our two standard rasters

                    return httpGet<Record<string, number>>(`/api/v1/rasters/pixel/${props.raster.id}/${lng}/${lat}?token=${token}`);
                }
            })
            .then((reply) => {
                if (reply?.ok) {
                    setKpis((kpis) => ({ ...kpis, ...reply.data }));
                }

                return apiGet<{ features: IMapFeature[] }>(team.slug, `projects/${project.slug}/maps@${lat},${lng}?range=1000`);
            })
            .then((reply) => {
                if (reply?.ok) {
                    setAreas(reply.data.features.sort((a, b) => a.name.localeCompare(b.name)));
                }
            });
    }, [props.pointOfInterest, props.raster]);

    const classes = classnames("s-map-bottom-panel", {
        "s-map-bottom-panel-visible": !!props.pointOfInterest,
        [`s-map-bottom-panel-${state.layoutSize}`]: true,
    });

    return (
        <div className={classes} style={props.style}>
            <CloseButton onClick={props.onClose} size="lg" style={{ position: "absolute", right: "1rem", top: "1rem" }} />

            <div className="s-map-bottom-panel-content">
                {props.path.includes("stats") && (
                    <HeadlineKpisPanel
                        areas={areas}
                        kpis={kpis}
                        onPathChange={(path) => props.onPathChange(path)}
                        path={props.path}
                        poiSiteId={props.poiSiteId}
                        raster={props.raster}
                    />
                )}

                {props.path.includes("add") && (
                    <AddToSitesPanel
                        center={props.pointOfInterest}
                        onClose={() => props.onPathChange([])}
                        onPathChange={(path) => props.onPathChange(path)}
                        path={props.path}
                    />
                )}

                {props.path.includes("areas") && (
                    <ProtectedAreasPanel
                        areas={areas}
                        onClose={() => props.onPathChange([])}
                        onPathChange={(path) => props.onPathChange(path)}
                        path={props.path}
                    />
                )}
            </div>
        </div>
    );
}
