import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, ToastAdded, useProject, useTeam, useTheme } from "@/context";
import { BreadcrumbPath, Button, CopyrightNotice, HorizontalStack, MasterHeader, SitePicker, TextInput } from "@/lib";
import { apiPost, getHomeUrl, t } from "@/utils";

interface INewSFDRReportForm {
    name: string;
    site_ids: number[];
}

export default function SFDRNewReportPage(): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const team = useTeam();
    const project = useProject();
    const theme = useTheme();
    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const nameRef = React.useRef<HTMLInputElement>(undefined);
    const isSmallLayout = ["xs", "sm", "md"].includes(state.layoutSize);
    const navigate = useNavigate();
    const [form, setForm] = React.useState<INewSFDRReportForm>({
        name: "",
        site_ids: [],
    });
    const onCreate = () => {
        if (form.name.trim().length === 0) {
            nameRef.current?.focus();
            return;
        }

        if (form.site_ids.length === 0) {
            return;
        }

        setIsSaving(true);
        apiPost<unknown>(team.slug, `projects/${project.slug}/sfdr/create`, form)
            .then((reply) => {
                if (reply.ok) {
                    dispatch({
                        type: ToastAdded,
                        toast: {
                            kind: "success",
                            text: t("report.sfdr.report_created") + "!",
                        },
                    });
                    navigate(`/${team.slug}/${project.slug}/reports`);
                } else {
                    dispatch({
                        type: ToastAdded,
                        toast: {
                            kind: "error",
                            text: "There was an error creating report, please try again later!",
                        },
                    });
                }
            })
            .finally(() => setIsSaving(false));
    };
    const breadcrumb = [
        { label: t("ui.home"), route: getHomeUrl(team) },
        { label: t("ui.report_plural"), route: getHomeUrl(team) + "/reports" },
        {
            label: t("report.sfdr.create_report"),
            route: getHomeUrl(team) + "/reports/create",
        },
    ];

    return (
        <div style={{ padding: "0 2rem 2rem 2rem" }}>
            <BreadcrumbPath items={breadcrumb} style={{ marginTop: "1rem" }} onItemClick={(item) => navigate(item.route)} />
            <MasterHeader
                style={{
                    marginTop: isSmallLayout ? "60px" : "40px",
                    marginBottom: ".5rem",
                }}
                text={t("report.sfdr.create_report")}
            />

            <div style={{ marginBottom: "2.5rem", maxWidth: "60rem" }}>
                {t("report.sfdr.create_report_description")
                    .split("\n")
                    .map((t, ix) => (
                        <p key={ix} style={{ marginBottom: ".5rem" }}>
                            {t}
                        </p>
                    ))}
            </div>

            <label htmlFor="sfdr_report_name" style={{ display: "block", marginBottom: ".5rem" }}>
                {t("ui.report_name")}
            </label>

            <TextInput
                disabled={isSaving}
                ref={nameRef}
                id="sfdr_report_name"
                onInput={(name) => setForm({ ...form, name })}
                value={form.name}
            />

            <label style={{ display: "block", margin: "2rem 0 .5rem 0" }}>{t("ui.report_format")}</label>

            <div style={{ overflowX: "scroll" }}>
                <HorizontalStack horizontalAlign="space-between" verticalAlign="stretch">
                    <div
                        style={{
                            background: "#f2ebfb",
                            padding: "1rem",
                            borderRadius: "1rem",
                            border: `2px solid ${theme.tintColor}`,
                            flex: "0 0 auto",
                            width: "25rem",
                        }}
                    >
                        <div style={{ fontSize: "1.15rem", marginBottom: ".5rem" }}>SFDR</div>
                        <div style={{ fontSize: theme.smallFontSize }}>{t("report.sfdr.description")}</div>
                    </div>
                    <div
                        style={{
                            background: "rgba(0 0 0 / .025)",
                            borderRadius: "1rem",
                            border: `1px solid ${theme.hairlineColor}`,
                            flex: "0 0 auto",
                            width: "25rem",
                        }}
                    />
                    <div
                        style={{
                            background: "rgba(0 0 0 / .025)",
                            borderRadius: "1rem",
                            border: `1px solid ${theme.hairlineColor}`,
                            flex: "0 0 auto",
                            width: "25rem",
                        }}
                    />
                </HorizontalStack>
            </div>

            <SitePicker
                disabled={isSaving}
                inputColumnLabel={t("ui.available_site_plural")}
                onSelectedSiteIdsChange={(site_ids) => setForm({ ...form, site_ids })}
                orientation={["lg", "xl"].includes(state.layoutSize) ? "horizontal" : "vertical"}
                outputColumnLabel={t("ui.site_included_in_report_plural")}
                report={project.reports[project.latest_report_id] as IAssessmentReport}
                selectedSiteIds={form.site_ids}
                style={{ marginTop: "2rem" }}
            />

            <HorizontalStack horizontalAlign="flex-end" style={{ marginTop: "2rem" }}>
                <Button disabled={isSaving} onClick={() => navigate(`/${team.slug}/${project.slug}/reports`)}>
                    {t("actions.cancel")}
                </Button>
                <Button disabled={isSaving} onClick={onCreate} variant="primary">
                    {t("actions.create")}
                </Button>
            </HorizontalStack>

            <CopyrightNotice />
        </div>
    );
}
