interface IHttpGetResult<T> {
    ok: boolean;
    status: number;
    data: T;
}

export interface IHttpPostResult<T> {
    ok: boolean;
    reason: string | undefined;
    status: number;
    data: T;
    response?: T;
}

interface IHttpDeleteResult<T> {
    ok: boolean;
    data?: T;
}

export interface IHttpPatchResult<T> {
    ok: boolean;
    data: T;
}

export async function apiGet<T>(
    team: string,
    url: string,
    params: Record<string, string> = {},
    cancelToken?: string,
): Promise<IHttpGetResult<T>> {
    if (!team) {
        return httpGet<T>(`/api/v1/${url}`, params);
    }

    return await httpGet<T>(`/api/v1/teams/${team}/${url}`, params);
}

export async function httpGet<T>(url: string, params: Record<string, string> = {}, cancelToken?: string): Promise<IHttpGetResult<T>> {
    try {
        const qs = new URLSearchParams(params).toString();
        const full = url + (qs ? "?" + qs : "");
        const response = await fetch(full, { cache: "no-cache" });

        if (response.status === 401) {
            document.location = "/login?401";
            return;
        } else if ([500, 502, 503, 504].includes(response.status)) {
            return { ok: false, status: response.status, data: undefined };
        }

        const data = await response.json();

        return { ok: data.ok, status: response.status, data: data.data };
    } catch (err) {
        console.error(err);

        return { ok: false, status: 0, data: undefined };
    }
}

export async function externalHttpGet<T>(url: string, params: Record<string, string> = {}): Promise<T> {
    try {
        const qs = new URLSearchParams(params).toString();
        const full = url + (qs ? "?" + qs : "");
        const response = await fetch(full, { cache: "no-cache" });

        return await response.json();
    } catch (err) {
        console.error(err);

        return null;
    }
}

export async function rawHttpGet(url: string, params: Record<string, string> = {}): Promise<string> {
    try {
        const qs = new URLSearchParams(params).toString();
        const full = url + (qs ? "?" + qs : "");
        const response = await fetch(full, { cache: "no-cache" });

        return await response.text();
    } catch (err) {
        console.error(err);

        return null;
    }
}

export async function apiPatch<T>(team: string, url: string, payload: unknown): Promise<IHttpPatchResult<T>> {
    if (!team) {
        return postResultFetch<T>(`/api/v1/${url}`, payload, "PATCH");
    }

    return postResultFetch<T>(`/api/v1/teams/${team}/${url}`, payload, "PATCH");
}

export async function apiPost<T>(team: string, url: string, payload: unknown): Promise<IHttpPostResult<T>> {
    if (!team) {
        return postResultFetch<T>(`/api/v1/${url}`, payload, "POST");
    }

    return postResultFetch<T>(`/api/v1/teams/${team}/${url}`, payload, "POST");
}

export async function httpPost<T>(url: string, payload: unknown): Promise<IHttpPostResult<T>> {
    return postResultFetch(url, payload, "POST");
}

export async function apiDelete<T>(url: string, team?: string): Promise<IHttpDeleteResult<T>> {
    if (!team) {
        return httpDelete<T>(`/api/v1/${url}`);
    }

    return httpDelete<T>(`/api/v1/teams/${team}/${url}`);
}

export async function httpDelete<T>(url: string): Promise<IHttpDeleteResult<T>> {
    try {
        await fetch(url, { method: "DELETE" });

        return { ok: true };
    } catch (err) {
        console.error(err);

        return { ok: false };
    }
}

export async function apiPut<T>(team: string, url: string, payload: unknown): Promise<IHttpPostResult<T>> {
    if (!team) {
        return postResultFetch<T>(`/api/v1/${url}`, payload, "PUT");
    }

    return postResultFetch<T>(`/api/v1/teams/${team}/${url}`, payload, "PUT");
}

export function getHomeUrl(team: ITeam): string {
    let url = "/";

    if (team) {
        url += team.slug;

        if (Object.keys(team.projects).length === 1) {
            url += "/" + Object.keys(team.projects)[0];
        }
    } else {
        url += "teams";
    }

    return url;
}

async function postResultFetch<T>(url: string, payload: unknown, method: string): Promise<IHttpPostResult<T>> {
    try {
        const response = await fetch(url, {
            method: method,
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
        });
        const data = await response.json();

        return {
            ok: data.ok,
            status: response.status,
            reason: data.reason,
            data: data.data,
        };
    } catch (err) {
        console.error(err);

        return { ok: false, status: 0, reason: null, data: undefined };
    }
}

export async function postFile<T>(url: string, payload: FormData): Promise<IHttpPostResult<T>> {
    try {
        const response = await fetch(url, {
            method: "POST",
            body: payload,
        });
        const data = await response.json();

        return {
            ok: data.ok,
            status: response.status,
            reason: data.reason,
            data: data.data,
            response: data,
        };
    } catch (err) {
        console.error(err);

        return { ok: false, status: 0, reason: null, data: undefined };
    }
}
export async function getCountry(lat: number, lng: number, state) {
    const accessToken =
        state.config?.mapbox_public_api_token ||
        "pk.eyJ1IjoiYW5kcmUtaGlja21hbm4iLCJhIjoiY2xoNjR4enBkMDE3cjNqcGc0aG93ZzlueSJ9.JH3ClP3oIf2uvc4ZpFvjJQ";
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`;

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.features.length > 0) {
            const countryFeature = data.features.find((feature) => feature.place_type.includes("country"));

            if (countryFeature) {
                const country = countryFeature.text;
                const countryCode = countryFeature.properties.short_code;
                return { country, countryCode };
            } else {
                throw new Error("Country not found");
            }
        } else {
            throw new Error("No results found");
        }
    } catch (error) {
        console.error("Error fetching country data:", error);
        return null;
    }
}
