import * as React from 'react';
import { colors, style, StyleSheet } from "@/utils/style";
import BSAMap from "./BSAMap";
import { HeaderKpi } from '../species/SpeciesPage';
import { CSVLink } from 'react-csv'; // Import react-csv

const BSA = ({ site, setTabId }) => {
  const [bsaCount, setBsaCount] = React.useState(0);
  const [bsa, setBsa] = React.useState([]);
  const [proximity, setProximity] = React.useState<number>(0);
  const [coverageArea, setCoverageArea] = React.useState<number>(0);
  const [selected, setSelected] = React.useState({});
  React.useEffect(() => {
    setTabId("");
  }, [bsa]);
  function handleBSAChange(data: any) {
    try {
      setBsaCount(data?.radiusFiltered?.features?.length || 0);
      setBsa(data?.radiusFiltered?.features || []);
      setProximity(data?.closestDistanceMeters?.distance_meters ? Number(data.closestDistanceMeters.distance_meters).toFixed(0) : 0);
      setCoverageArea(data?.coverageAreaM2 || 0);
    } catch (error) {
      console.error('Error handling BSA data:', error);
      setBsaCount(0);
      setBsa([]);
      setProximity(0);
      setCoverageArea(0);
    }
  }
  const handleSiteType = (site_type_id) => {
    switch (site_type_id) {
      case "1":
        return "own";
      case "2":
        return "upstream";
      case "3":
        return "downstream";
      default:
        break;
    }
  };
  // Prepare data for CSV export with fallback for missing properties
  const csvData = bsa.map((item) => ({
    Name: item?.properties?.name || "N/A",
    Distance: item?.properties?.distance_meters ? Number(item.properties.distance_meters).toFixed(0) : "N/A",
    Type: item?.properties?.desig_eng || "N/A",
    "Reported Area (km²)": item?.properties?.rep_area ? Number(item.properties.rep_area).toFixed(3) : "N/A",
    'Biodiversity Sensitive Areas (BSA)': bsaCount || "N/A",
    'Distance to closest (m)': proximity || "N/A"
  }));

  return (
    <div>
        {/* CSV Export Button */}
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <CSVLink data={csvData} filename={`${site?.name || "site"}_BSA.csv`}>
          <button style={{ width: "120px", height: "40px", cursor: "pointer" }} style={styles.export}>Export CSV</button>
        </CSVLink>
      </div>
      <br />
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1, gap: 30 }}>
        <div style={styles.wrapper}>
          <div className="table-container">
            <table className="s-table s-table-fixed species-table" style={{ textAlign: 'left' }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Distance (m)</th>
                  <th>Type</th>
                  <th>Reported Area (km²)</th>
                </tr>
              </thead>
              <tbody>
                {bsa.length > 0 ? (
                  bsa.map((item, index) => (
                    <tr key={index}>
                      <td onClick={() => setSelected(item)}>{item?.properties?.name || "N/A"}</td>
                      <td>{item?.properties?.distance_meters ? Number(item.properties.distance_meters).toFixed(0) : "N/A"}</td>
                      <td>{item?.properties?.desig_eng || "N/A"}</td>
                      <td>{item?.properties?.rep_area ? Number(item.properties.rep_area).toFixed(3) : "N/A"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>No Records.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 20 }}>
          <div style={styles.summaryHeader}>
            <HeaderKpi label='Biodiversity Sensitive Areas (BSA)' value={bsaCount || 0} />
            <HeaderKpi label='Distance to closest (m)' value={proximity || 0} />
          </div>
          <div style={{ position: 'relative', minHeight: 500 }}>
            <BSAMap site={site} onBSAChange={handleBSAChange} selected={selected} />
          </div>
        </div>
      </div>
    </div>
  );
};


const styles = StyleSheet.create({
  wrapper: {
    width: '50%',
    borderRadius: 10,
    border: `1px solid ${colors.brightBlue}`,
    padding: 10,
    backgroundColor: colors.darkBlue,
  },
  summaryHeader: {
    height: 90,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.darkBlue,
    borderRadius: 10,
    padding: '5px 20px',
  },
  export: {
    height: '100%',
    borderRadius: 20,
    backgroundColor: 'transparent',
    opacity: 1,
    padding: '5px 10px',
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    border: `1px solid ${colors.white}`,
    fontSize: 13,
  },
});

export default BSA;
