import { RISK_COLORS, RISK_ORDER } from './constants';
import { RiskData } from './types';

/**
 * Convert risk classification to color code
 */
export function riskToColor(riskClass: string | null): string {
    if (!riskClass) return RISK_COLORS["no data"];
    const lower = riskClass.toLowerCase();
    return RISK_COLORS[lower] || RISK_COLORS["no data"];
}

/**
 * Get hazard classification based on data and time period
 */
export function getHazardClass(hazardObj: any | null | undefined, time: string): string | null {
    if (!hazardObj) return null;

    if (time === "today") {
        if (!hazardObj.current_climate_values_risks) return null;
        const currKeys = Object.keys(hazardObj.current_climate_values_risks);
        if (currKeys.length === 0) return null;
        const firstMetricKey = currKeys[0];
        return hazardObj.current_climate_values_risks[firstMetricKey]?.class || null;
    }

    // For future times (2035, 2050, 2100)
    if (!hazardObj.future_climate_values_risks) return null;
    const topClass = hazardObj.future_climate_values_risks.class;
    if (topClass) return topClass;

    const futKeys = Object.keys(hazardObj.future_climate_values_risks);
    if (futKeys.length === 0) return null;
    const firstFutKey = futKeys[0];
    return hazardObj.future_climate_values_risks[firstFutKey]?.class || null;
}

/**
 * Get numeric rank for risk comparison
 */
export function getRiskRank(risk: string | null): number {
    if (!risk) return 0;
    const lower = risk.toLowerCase();
    const idx = RISK_ORDER.indexOf(lower);
    return idx === -1 ? 0 : idx;
}

/**
 * For a single hazard, find the max risk among all scenarios' "today" classification
 */
export function pickHighestRiskTodayAcrossScenarios(data: RiskData, hazardKey: string, scenarios: string[]): string {
    let bestRisk = "no data";
    scenarios.forEach((scenario) => {
        const hazardObj = data[scenario]?.today?.[hazardKey];
        const risk = getHazardClass(hazardObj, "today") || "no data";
        if (getRiskRank(risk) > getRiskRank(bestRisk)) {
            bestRisk = risk;
        }
    });
    return bestRisk;
}

/**
 * Format dimension text for display
 */
export function formatDimension(dimension: string): string {
    return dimension
        .split("_")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join("-");
}

/**
 * Build CSV headers dynamically including future scenario/time combinations
 */
export function buildCsvHeaders(scenarios: string[], scenarioLabels: { [key: string]: string }) {
    const headers = [
        { label: "Risk", key: "Risk" },
        { label: "Cluster", key: "Cluster" },
        { label: "Category", key: "Category" },
        { label: "Today", key: "Today" },
    ];

    scenarios.forEach((scenario) => {
        const scenLabel = scenarioLabels[scenario] || scenario;
        ["2035", "2050", "2100"].forEach((time) => {
            const colName = `${scenLabel} ${time}`;
            headers.push({ label: colName, key: colName });
        });
    });

    return headers;
}
