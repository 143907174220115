import * as React from "react";
import { capitalize, t } from "@/utils";
import { HorizontalStack, Markdown, Rating, Title } from "@/lib";
import BaseModalDialog from "./BaseModalDialog";

export default function ConservationMeasureModal(props: { measure: IConservationMeasure; onClose: () => void }): JSX.Element {
    const measure = props.measure;
    const ratings = [] as { label: string; value: number }[];

    if (measure) {
        ratings.push({ label: "effort", value: measure.effort_score });
        ratings.push({ label: "impact", value: measure.impact_score });
    }

    return (
        <BaseModalDialog onClose={props.onClose} title={measure.name}>
            <HorizontalStack horizontalAlign="space-between" verticalAlign="flex-start">
                <div style={{ width: "calc(50% - 1rem)" }}>
                    <Title text={"Strategy: " + capitalize(measure.mitigation)} />
                    <div style={{ color: "#333", fontSize: ".85rem", marginTop: "1rem" }}>
                        {t("mitigation." + measure.mitigation + ".description")}
                    </div>
                </div>

                <div style={{ width: "calc(50% - 1rem)" }}>
                    <Title text="Feasibility" style={{ marginBottom: "1rem" }} />

                    {ratings.map((r) => (
                        <HorizontalStack key={r.label} horizontalAlign="space-between" style={{ marginBottom: "1rem" }}>
                            <span>{capitalize(r.label)}</span>
                            <Rating range={5} value={r.value} />
                        </HorizontalStack>
                    ))}
                </div>
            </HorizontalStack>

            <Title text="Description" style={{ marginTop: "2rem", marginBottom: "1rem" }} />
            <Markdown md={measure.description} />
        </BaseModalDialog>
    );
}
