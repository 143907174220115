import React from "react";
import { StyleSheet } from "@/utils/style";
import { IPriorityTriangleProps } from "@/types/sleap";

const PriorityTriangle: React.FC<IPriorityTriangleProps> = ({ value }) => {
    console.log("value from PriorityTriangle =====>>>> ", value);
    const [marginLeftValue, setMarginLeftValue] = React.useState(0);
    const [bColor, setBColor] = React.useState("");

    const calculateColor = () => {
        let bColor = "";

        if (value < 4) {
            bColor = "green";
        } else if (value >= 4 && value < 20) {
            bColor = "yellow";
        } else if (value >= 20 && value < 40) {
            bColor = "orange";
        } else if (value >= 40 && value < 60) {
            bColor = "red";
        } else {
            bColor = "darkred";
        }
        return bColor;
    };

    React.useEffect(() => {
        // let marginLeftValue = 0;
        // if (value > 9 && value < 100) {
        //     marginLeftValue = 2;
        // }
        // else if (value < 10) {
        //     marginLeftValue = 5;
        // }

        // setMarginLeftValue(marginLeftValue);
        //we do it manually because we make it empty for now (maybe we can add text in the future)
        setMarginLeftValue(5);
        setBColor(calculateColor());
    }, [value]);

    return (
        <div>
            <div className="priority-triangle" style={{ backgroundColor: bColor }} />
            <div style={{ ...styles.triangleText, marginLeft: marginLeftValue }}></div>
        </div>
    );
};

const styles = StyleSheet.create({
    triangleText: {
        position: "relative",
        marginTop: -12,
    },
});

export default PriorityTriangle;
