import * as React from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import { isThreatenedSpecies, isProtectedSpecies, isKeyStoneSpecies, t } from "@/utils";
import { StaticMap } from "@/lib/map";
import Dropdown from "@/utils/Dropdown";
import { booleanSort, getSortBy, SortOrder, speciesRoleSort, stringSort } from "@/utils/sort";
import { TableHeaderCell } from "@/lib";
import { AppContext } from "@/context";
import { SiteSpeciesItem } from "@/project/site/species/index";
import "./index.scss";
import Checkbox from "@/utils/Checkbox";
import CryptoJS from "crypto-js";
import { calculateZoomLevel } from "@/utils/calculateZoomLevel";
import { DownloadButtonDefault } from "@/components/DownloadButton";
const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
const header = {
    headers: {
        Authorization: `Bearer ${encryptedToken}`,
    },
};
// const siteRadius = parseInt(localStorage.getItem("siteRadius"));
interface SiteOverviewProps {
    site: INewSiteDetailed;
    bbox: number[];
    layers: string[];
    speciesData: {};
    onLayerLoad: (id: string, layer: IKeyAreaLayer) => void;
    onClick: (species: ISpecies, what: "info" | "map") => void;
    mapZoom: number;
    setTabId;
}

type SortType = "species" | "umbrella" | "keystone" | "protected" | "threatened";

const SpeciesRoleDropdownItems = [
    { id: -1, label: t("ui.all"), apiLabel: "_all" },
    {
        id: 3,
        group: t("ui.habitat_role"),
        label: t("ui.apex_predator"),
        apiLabel: "apex predator",
    },
    {
        id: 4,
        group: t("ui.habitat_role"),
        label: t("ui.habitat_creator"),
        apiLabel: "habitat creators",
    },
    {
        id: 5,
        group: t("ui.habitat_role"),
        label: t("ui.seed_disperser"),
        apiLabel: "seed dispersers",
    },
    {
        id: 6,
        group: t("ui.habitat_role"),
        label: t("ui.other"),
        apiLabel: "other",
    },
];

export const SpeciesPage: React.FC<SiteOverviewProps> = ({ site, bbox, layers, speciesData, onLayerLoad, onClick, mapZoom, setTabId }) => {
    const { state } = React.useContext(AppContext);
    //const keystoneSpecies = site.species.filter(s => isKeyStoneSpecies(s)).length;
    const [keystoneSpecies, setKeystoneSpeciesLength] = React.useState(0);
    const [threatenedSpecies, setThreatenedSpecies] = React.useState(0);
    const [totalSpecies, setSpeciesTotal] = React.useState();
    const [staticMapPins, setStaticMapPins] = React.useState([
        {
            center: site.latlng,
            id: "site-" + site.site_id,
            text: site.site_name ?? site.site_id,
        },
    ]);
    console.log("Outer site range -->", site.radius);
    const [speciesAlldata, setSpecies] = React.useState([]);
    React.useEffect(() => {
        if (speciesData.total_unique_keystone_species > 0) {
            //site.address.country_code = res.countryCode.toUpperCase();
            setSpecies(speciesData.species);
            setSpeciesTotal(speciesData.total_unique_keystone_species);
            setKeystoneSpeciesLength(speciesData.key_stone_species_count);
            setThreatenedSpecies(speciesData.threatened_species_count);
        } else {
            setSpeciesTotal(0);
        }
        setTabId("");
    }, [totalSpecies]);

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <DownloadButtonDefault disabled={true}>Export CSV</DownloadButtonDefault>
            </div>

            <div style={{ display: "flex", flexDirection: "row", flex: 1, gap: 30 }}>
                <div style={styles.wrapper}>
                    {speciesAlldata?.species ? (
                        <span className="spinner"></span>
                    ) : (
                        <SpeciesTable
                            site={site}
                            onClick={onClick}
                            AllSpecies={speciesAlldata}
                            sharedState={staticMapPins}
                            setSharedState={setStaticMapPins}
                        />
                    )}
                </div>
                <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: 20 }}>
                    <div style={styles.summaryHeader}>
                        <HeaderKpi label={t("ui.total_species")} value={totalSpecies} />
                        <HeaderKpi label={t("ui.threatened_species")} value={threatenedSpecies} />
                        {/* <HeaderKpi label={t('ui.protected_species')} value={protectedSpecies} /> */}
                        <HeaderKpi label={t("ui.keystone_species")} value={keystoneSpecies} />
                    </div>
                    <div style={{ position: "relative", minHeight: 500 }}>
                        <StaticMap
                            bbox={bbox}
                            center={site.latlng}
                            circles={[
                                //{ center: site.latlng, id: `site-${site.id}-inner`, radius: siteRadius/4 },
                                {
                                    center: site.latlng,
                                    id: `site-${site.site_id}-outer`,
                                    radius: site.radius,
                                },
                            ]}
                            layers={layers}
                            onLayerLoad={onLayerLoad}
                            pins={staticMapPins}
                            rasters={{}}
                            zoom={mapZoom} // Use the calculated zoom level here
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const SpeciesTable: React.FC<{
    site: INewSiteDetailed;
    onClick: (species: ISpecies, what: "info" | "map") => void;
    sharedState;
    AllSpecies;
    setSharedState;
}> = ({ site, onClick, sharedState, AllSpecies, setSharedState }) => {
    const { state } = React.useContext(AppContext);
    const [sortType, setSortType] = React.useState<SortType>("species");
    const [sortOrder, setSortOrder] = React.useState<SortOrder>("desc");

    const [speciesTypeIds, setSpeciesTypeIds] = React.useState<number[]>([]);
    const [roleIds, setRoleIds] = React.useState<number[]>([]);
    const speciesTypes = [
        //{ id: 1, label: t("ui.protected_species") },
        { id: 2, label: t("ui.threatened_species") },
    ];
    const [speciesAlldata, setSpecies] = React.useState([]);
    const [isSpeciesLoading, setIsSpeciesLoading] = React.useState(true);

    React.useEffect(() => {
        if (speciesAlldata.length === 0) {
            setSpecies(AllSpecies);
        }
    }, [speciesAlldata]);

    const onSort = (order: SortOrder, type: SortType) => {
        setSortType(type);
        setSortOrder(order);
    };
    const conservation_statuses = ["CR", "EN", "VU"];

    const filteredSpeciesList = React.useMemo(() => {
        if (speciesAlldata.length > 0) {
            //let species = [].concat(speciesAlldata?.species).filter(s => s.is_keystone_species === true);
            let species = [];
            const seenTaxonKeys = new Set();

            speciesAlldata
                //.filter(s => s.is_keystone_species === true)
                .forEach((s) => {
                    if (!seenTaxonKeys.has(s.name)) {
                        species.push(s);
                        seenTaxonKeys.add(s.name);
                    }
                });
            console.log("species: ", speciesAlldata.length);
            if (speciesTypeIds.length !== 0) {
                const selectedSpeciesLabels = speciesTypeIds.map((id) => speciesTypes.find((c) => c.id === id)?.label);

                species = species.filter((s) => {
                    const isThreatened = conservation_statuses.includes(s.conservation_status);
                    return selectedSpeciesLabels.includes(t("ui.threatened_species")) && isThreatened;
                });
            }

            const selectedRoleLabels = SpeciesRoleDropdownItems.filter((selectedRole) => roleIds.includes(selectedRole.id)).map(
                (filteredRole) => filteredRole.apiLabel,
            );

            let filteredSpecies = selectedRoleLabels.length === 0 ? species : [];

            selectedRoleLabels.forEach((selectedRoleLabel) => {
                if (["apex predator", "habitat creators", "seed dispersers"].includes(selectedRoleLabel)) {
                    filteredSpecies = filteredSpecies.concat(species.filter((s) => s.key_stone_species_category === selectedRoleLabel));
                } else if (selectedRoleLabel === "other") {
                    filteredSpecies = filteredSpecies.concat(species.filter((s) => s.is_keystone_species === false));
                }
            });

            species = filteredSpecies;
            setIsSpeciesLoading(false);
            return species.sort((a, b) => {
                if (sortType === "species") {
                    return stringSort(a.name, b.name, sortOrder);
                } else if (sortType === "protected") {
                    return booleanSort(
                        !conservation_statuses.includes(a.conservation_status),
                        !conservation_statuses.includes(b.conservation_status),
                        sortOrder,
                    );
                } else if (sortType === "threatened") {
                    return booleanSort(
                        conservation_statuses.includes(a.conservation_status),
                        conservation_statuses.includes(b.conservation_status),
                        sortOrder,
                    );
                } else {
                    return speciesRoleSort(a, b, sortOrder);
                }
            });
        } else {
            setTimeout(() => {
                setIsSpeciesLoading(false);
                return [];
            }, 1500);
        }
    }, [speciesTypeIds, speciesAlldata.length, roleIds, sortOrder, sortType]);
    const handleSetRole = (ids: number[]): void => {
        const oldSelect = roleIds;
        const newSelect = ids.filter((newId) => !oldSelect.includes(newId));

        if (ids.length === 0 || newSelect.includes(-1)) {
            setRoleIds([]);
        } else {
            setRoleIds(ids);
        }
    };

    const handleSetSpeciesType = (selectedId: number, check: boolean): void => {
        if (check) {
            setSpeciesTypeIds((prev) => [...prev, selectedId]);
        } else {
            setSpeciesTypeIds(speciesTypeIds.filter((id) => id !== selectedId));
        }
    };

    async function OverrideStaticMap(specie) {
        const radiusByKm = Number(site.radius) / 1000 || 20;
        const url = `${process.env.GEO_SERVER_URL}/species/occurrences?decimalLatitude=${site.latlng.lat}&decimalLongitude=${site.latlng.lng}&rangeByKM=${radiusByKm}&taxonKey=${specie.taxonKey}&countryCode=${site.country_code}`;
        //https://geoserver-staging.kuyua.net
        console.log("URL: ", url);
        console.log("taxonKey: ", specie.taxonKey);
        console.log("CountryCode: ", site.country_code);
        try {
            const response = await fetch(url, header);
            const data = await response.json();
            console.log("occurrence data: ", data);
            const relatedSpecies = data?.species.map((s) => ({
                center: { lat: s.decimalLatitude, lng: s.decimalLongitude },
                id: `related-specie-${s.id}`,
                color: "#800080", // Purple color for the dots
                text: "",
                isPriorityLocation: true,
            }));
            const points = [
                {
                    center: site.latlng,
                    id: "site-" + site.site_id,
                    text: site.site_name ?? site.site_id,
                },
                ...relatedSpecies,
            ];
            setSharedState(points);
        } catch (error) {
            console.error("Error fetching species:", error);
        }
    }
    const HandleSpecieRank = (specie): String => {
        if (specie.key_stone_species_category === "apex predator") {
            return t("ui.apex_predator");
        } else if (specie.key_stone_species_category === "habitat creators") {
            return t("ui.habitat_creator");
        } else if (specie.key_stone_species_category === "seed dispersers") {
            return t("ui.seed_disperser");
        } else {
            return t("ui.other");
        }
    };
    return (
        <>
            <div style={{ ...style.flexRow, ...styles.filterBar }}>
                <Dropdown
                    placeholder={"key-stone species"}
                    dropdownStyle={{
                        padding: "5px 10px",
                        marginBottom: "unset",
                        fontSize: 13,
                        height: "unset",
                        minWidth: "140px",
                    }}
                    listDropdownStyle={{ top: "unset" }}
                    handler={handleSetRole}
                    options={SpeciesRoleDropdownItems}
                    selected={roleIds}
                    flat
                    multiple
                    selectedRight
                />
                <div style={{ ...style.flexRow, justifyContent: "right", flex: 2 }}>
                    {speciesTypes.map((species, i) => (
                        <div id={"species_" + species.id} style={{ marginRight: 30 }}>
                            <Checkbox
                                checked={speciesTypeIds.includes(species.id)}
                                label={species.label}
                                checkHandler={(check: boolean) => handleSetSpeciesType(species.id, check)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className={"table-container"}>
                <table className="s-table s-table-fixed species-table">
                    <thead>
                        <tr>
                            <TableHeaderCell
                                onClick={(arg: SortOrder) => onSort(arg, "species")}
                                sortedBy={getSortBy(sortType, "species", sortOrder)}
                                label={t("ui.species")}
                            />
                            <TableHeaderCell
                                label={t("ui.keystone_species")}
                                onClick={(arg: SortOrder) => onSort(arg, "keystone")}
                                sortedBy={getSortBy(sortType, "keystone", sortOrder)}
                                style={{ ...styles.sTableHeading }}
                            />
                            {/* Removed based on requirements */}
                            {/* <TableHeaderCell
                                label={t("ui.protected_species")}
                                onClick={(arg: SortOrder) => onSort(arg, "protected")}
                                sortedBy={getSortBy(sortType, "protected", sortOrder)}
                            /> */}
                            <TableHeaderCell
                                label={t("ui.threatened_species")}
                                onClick={(arg: SortOrder) => onSort(arg, "threatened")}
                                sortedBy={getSortBy(sortType, "threatened", sortOrder)}
                                textAlign={"end"}
                                style={{ ...styles.sTableHeading }}
                            />
                            {/* <TableHeaderCell
              label={t('Distance (KM)')}
              textAlign={'end'}
            /> */}
                        </tr>
                    </thead>
                    <tbody>
                        {isSpeciesLoading ? (
                            // Show the spinner while the data is being fetched
                            <tr>
                                <td colSpan={3} style={{ textAlign: "center" }}>
                                    <span className="spinner"></span>
                                </td>
                            </tr>
                        ) : speciesAlldata.length === 0 ? (
                            // Display an empty table when the list is empty
                            <tr>
                                <td colSpan={3} style={{ textAlign: "center", padding: "20px" }}>
                                    No species for site: {site.site_name}
                                </td>
                            </tr>
                        ) : (
                            // Render the rows when data is available
                            filteredSpeciesList.map((s) => (
                                <tr key={s.name}>
                                    {" "}
                                    {/* Ensure unique key for each row */}
                                    <td>
                                        <div>
                                            <a className="s-link" onClick={() => OverrideStaticMap(s)}>
                                                {s.name}
                                            </a>
                                        </div>
                                        <div className="s-table-sublabel">{s.common_name || "—"}</div>
                                    </td>
                                    <td>
                                        <div style={{ textAlign: "left", paddingLeft: "50px" }}>{HandleSpecieRank(s)}</div>
                                        <div className="s-table-sublabel">&nbsp;</div>
                                    </td>
                                    <td style={{ textAlign: "left", paddingLeft: "50px" }}>
                                        <Checkbox
                                            checked={conservation_statuses.includes(s.conservation_status)}
                                            readonly={true}
                                            checkHandler={() => {}}
                                        />
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export const HeaderKpi: React.FC<{ label: string; value: number | string }> = ({ label, value }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                alignItems: "center",
            }}
        >
            <span style={{ fontSize: 12, textTransform: "uppercase" }}>{label}</span>
            <span style={{ fontSize: 30, color: colors.brightBlue }}>{value}</span>
        </div>
    );
};

const styles = StyleSheet.create({
    wrapper: {
        width: "50%",
        borderRadius: 10,
        border: `1px solid ${colors.brightBlue}`,
        padding: 10,
        backgroundColor: colors.darkBlue,
    },
    summaryHeader: {
        height: 90,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: colors.darkBlue,
        borderRadius: 10,
        padding: "5px 20px",
    },
    filterBar: {
        borderRadius: 10,
        backgroundColor: colors.opaqueWhite,
        padding: 10,
        alignItems: "center",
        gap: 10,
    },
    export: {
        height: "100%",
        borderRadius: 20,
        backgroundColor: "transparent",
        opacity: 1,
        padding: "5px 10px",
        color: colors.white,
        display: "flex",
        alignItems: "center",
        gap: 5,
        border: `1px solid ${colors.white}`,
        fontSize: 13,
    },
    sTableHeading: {
        padding: "10px 5px 10px 30px",
    },
});
