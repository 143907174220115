import * as React from "react";

export default function CopyrightNotice(): JSX.Element {
    return (
        <div className="s-copyright-notice">
            Copyright &copy; 2023 kuyua GmbH, All Rights Reserved. Citations:&nbsp;
            <a href="https://doi.org/10.15468/39omei" rel="nofollow noopener noreferrer">
                GBIF Secretariat: GBIF Backbone Taxonomy
            </a>
            <div>
                World regions source: United Nations and{" "}
                <a href="https://ourworldindata.org/world-region-map-definitions" rel="nofollow noopener noreferrer">
                    OurWorldInData.org/world-region-map-definitions • CC BY
                </a>
            </div>
        </div>
    );
}
