import { Rating } from "@/types/utils.d";

export function getRatingText(rating: Rating): string {
    switch (rating) {
        case Rating.VERY_HIGH:
            return 'Very High';
        case Rating.HIGH:
            return 'High';
        case Rating.MEDIUM:
            return 'Medium';
        case Rating.LOW:
            return 'Low';
        case Rating.VERY_LOW:
            return 'Very Low';
        default:
            return 'Not Relevant';
    }
}

export function getRatingTextFromNumber(rating: number): string {
    switch (rating) {
        case 5:
            return 'Very High';
        case 4:
            return 'High';
        case 3:
            return 'Medium';
        case 2:
            return 'Low';
        case 1:
            return 'Very Low';
        default:
            return 'Not Relevant';
    }
}

