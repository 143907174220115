import React, { createContext, useContext } from "react";

export type LocationDetails = {
    zipcode: string;
    country: string;
    city: string;
    street: string;
};

export type IPolygonData = {
    polygonData: string;
    polygonCenter: [number, number];
    locationDetails: LocationDetails;
    area?: number;
};
// Define the context type
interface IPolygonContext {
    polygonData: IPolygonData | null;
    isPolygon: boolean;
    polygonHandler: (polygonData: string, polygonCenter: [number, number], locationDetails: LocationDetails | null, area: number) => void;
    polygonSwitch: (value: boolean) => boolean;
    selectPolygon: (polygon: IPolygonData | null) => void;
}

// Create the context with a default undefined value
const PolygonContext = createContext<IPolygonContext | undefined>(undefined);

// Add this hook before the PolygonProvider
const usePolygon = () => {
    const [polygonData, setPolygonData] = React.useState<IPolygonData | null>(null);
    const [isPolygon, setIsPolygon] = React.useState(false);

    const polygonHandler = (
        polygonData: string,
        polygonCenter: [number, number],
        locationDetails: LocationDetails | null,
        area: number,
    ) => {
        setPolygonData({
            polygonData,
            polygonCenter,
            locationDetails: locationDetails || {
                zipcode: "",
                country: "",
                city: "",
                street: "",
            },
            area,
        });
    };

    const polygonSwitch = (value: boolean) => {
        setIsPolygon(value);
        return value;
    };

    const selectPolygon = (polygon: IPolygonData | null) => {
        setPolygonData(polygon);
    };

    return {
        polygonData,
        isPolygon,
        polygonHandler,
        polygonSwitch,
        selectPolygon,
    };
};

// Define the provider component
export const PolygonProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { polygonData, isPolygon, polygonHandler, polygonSwitch, selectPolygon } = usePolygon();

    return (
        <PolygonContext.Provider
            value={{
                polygonData,
                isPolygon,
                polygonHandler,
                polygonSwitch,
                selectPolygon,
            }}
        >
            {children}
        </PolygonContext.Provider>
    );
};

// Custom hook to use the context
export const usePolygonContext = () => {
    const context = useContext(PolygonContext);
    if (context === undefined) {
        throw new Error("usePolygonContext must be used within a PolygonProvider");
    }
    return context;
};
