import * as React from "react";
import { createPortal } from "react-dom";
import { apiGet, t } from "@/utils";
import { useProject, useTeam } from "@/context";
import LoadingAnimation from "./LoadingAnimation";

interface IDownloadHolderProps {
    onClose: () => void;
    token: string;
}

export default function DownloadHolder(props: IDownloadHolderProps): JSX.Element {
    const team = useTeam();
    const project = useProject();

    React.useEffect(() => {
        const intervalId = window.setInterval(() => {
            const url = `projects/${project.slug}/download`;
            const params = { status: "true", token: props.token };

            apiGet<{ status: "ready" | "wait" }>(team.slug, url, params).then((reply) => {
                if (reply?.ok && reply.data.status === "ready") {
                    document.location = `/api/v1/teams/${team.slug}/${url}?token=${props.token}`;
                    props.onClose();
                }
            });
        }, 2500);

        return () => {
            window.clearInterval(intervalId);
        };
    }, []);

    return createPortal(
        <div className="s-download-holder">
            <div className="s-download-holder-content">
                <span>{t("ui.preparing_report_for_download")}…</span>
                <LoadingAnimation />
            </div>
        </div>,
        document.getElementById("modals"),
    );
}
