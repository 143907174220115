import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import { LoadingState } from "./components/LoadingState";
import { ClimateRiskHeader } from "./components/ClimateRiskHeader";
import { ClimateRiskLegend } from "./components/ClimateRiskLegend";
import { ClimateRiskTable } from "./components/ClimateRiskTable";
import { SCENARIOS, SCENARIO_LABELS } from "./constants";
import { NestedClimateTableProps } from "./types";
import { useTeam } from "@/context";

// Type definitions
interface ScenarioLabels {
    [key: string]: string;
}

interface ClimateRiskClass {
    class?: string;
    [key: string]: any;
}

interface HazardData {
    current_climate_values_risks?: {
        [key: string]: ClimateRiskClass;
    };
    future_climate_values_risks?: {
        class?: string;
        [key: string]: ClimateRiskClass | string | undefined;
    };
}

// Component
export default function NestedClimateTable({ lat, lng }: NestedClimateTableProps) {
    // State
    const [data, setData] = useState(null);
    const [dimension, setDimension] = useState("water_related");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { id: companyId } = useTeam();
    // API configuration
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            Authorization: `Bearer ${encryptedToken}`,
            "Content-Type": "application/json",
        },
    };

    // Fetch data
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setError(null);

            try {
                const url = `${process.env.KUYUA_APP_BACKEND_URL}/climate-risk?company_id=${companyId}&lat=${lat}&lng=${lng}&dimension=${dimension}`;
                const resp = await fetch(url, header);
                if (!resp.ok) {
                    throw new Error(`HTTP error! status: ${resp.status}`);
                }
                const json = await resp.json();
                setData(json);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [lat, lng, dimension]);

    return (
        <div style={{ fontFamily: "sans-serif", background: "#2B3A4A", color: "white", padding: 16 }}>
            <ClimateRiskHeader
                dimension={dimension}
                setDimension={setDimension}
                data={data}
                SCENARIOS={SCENARIOS}
                SCENARIO_LABELS={SCENARIO_LABELS}
                lat={lat}
                lng={lng}
            />

            <ClimateRiskTable
                loading={loading}
                error={error}
                data={data}
                SCENARIOS={SCENARIOS}
                SCENARIO_LABELS={SCENARIO_LABELS}
                dimension={dimension}
                setDimension={setDimension}
            />
            <ClimateRiskLegend />
        </div>
    );
}
