import React, { useContext } from "react";
import { AppContext, NavigationChanged, ShowSideStepDetail, SitesChanged, useProject, useTeam } from "@/context";
import { fetchDivisions, fetchEconomicActivities, fetchSections } from "@/rest/apiHelper";
import { apiGet } from "@/utils/http";
import { IOrderParam } from "@/types/site";
import { iActivities, INewDBSite } from "@/types/sleap";
import CryptoJS from "crypto-js";
const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
const header = {
    headers: {
        Authorization: `Bearer ${encryptedToken}`,
    },
};

type SleapStepHook = [string, (newStep: string) => void, () => void];

type ActivitiesHook = [iActivities, (selected: number[], value: string) => void];

export function useSleapStep(): SleapStepHook {
    const { state, dispatch } = React.useContext(AppContext);

    const [step, setStep] = React.useState("");

    React.useEffect(() => {
        step !== state.sideStepActive && setStep(state.sideStepActive);
    }, [state.sideStepActive]);

    const setStepHandler = React.useCallback((newStep: string) => {
        dispatch({ type: NavigationChanged, sideStepActive: newStep });
    }, []);

    const toggleStepDetail = React.useCallback(() => {
        dispatch({ type: ShowSideStepDetail, value: !state.showSideStepDetail });
    }, []);

    return [step, setStepHandler, toggleStepDetail];
}

export function useActivities(project?: IProject, site?: INewDBSite): ActivitiesHook {
    const [activities, setActivities] = React.useState({
        section: {
            multiple: true,
            value: "section",
            label: "Section",
            options: [],
            selected: [],
            show: true,
        },
        division: {
            multiple: true,
            value: "division",
            label: "Division",
            options: [],
            selected: [],
            show: false,
        },
        classes: {
            multiple: true,
            value: "classes",
            label: "Economic Activities",
            options: [],
            selected: [],
            show: false,
        },
    });
    async function loadSectionOptions() {
        let EndPointURL = `${process.env.KUYUA_APP_BACKEND_URL}/industries`;
        await fetch(EndPointURL, header)
            .then(async (sectionResponse) => {
                const sectionOptions = await sectionResponse.json();
                setActivities((prev) => ({
                    ...prev,
                    section: {
                        ...prev.section,
                        options: sectionOptions?.industries,
                    },
                }));
                console.log("sections -> ", sectionOptions);
            })
            .catch((e) => {
                console.error("Error fetching sections:", e);
            });
    }

    async function loadDivisions(sectionIds: number[]) {
        let EndPointURL = `${process.env.KUYUA_APP_BACKEND_URL}/sub-industries`;
        await fetch(EndPointURL, header)
            .then(async (Response) => {
                const divisionOptionsRes = await Response.json();
                const divisionOptions = divisionOptionsRes.sub_industries.filter((subIndustry) =>
                    sectionIds.includes(subIndustry.section_id),
                );
                setActivities((prev) => ({
                    ...prev,
                    division: {
                        ...prev.division,
                        options: divisionOptions,
                    },
                }));
                console.log("divisions -> ", divisionOptions);
            })
            .catch((e) => {
                console.error("Error fetching sections:", e);
            });
    }

    async function loadEconomicActivities(divisionIds: number[]) {
        let EndPointURL = `${process.env.KUYUA_APP_BACKEND_URL}/economic-activities/activities`;
        await fetch(EndPointURL, header).then(async (Response) => {
            const classOptionsRes = await Response.json();
            const classOptions = classOptionsRes.economic_activities.filter((subIndustry) => divisionIds.includes(subIndustry.division_id));
            setActivities((prev) => ({
                ...prev,
                classes: {
                    ...prev.classes,
                    options: classOptions,
                },
            }));
            console.log("activities -> ", classOptions);
        });
    }

    const filterBySelectedSections = (sectionIds: number[], divisionIds: number[]) => {
        if (!sectionIds.length) return [];

        const filteredSelectedDivisions = activities.division.options.filter(
            (division) => divisionIds.includes(division.id) && sectionIds.includes(division.section_id),
        );
        return filteredSelectedDivisions.map((d) => d.id);
    };

    const filterBySelectedDivisions = (divisionIds: number[], classIds: number[]) => {
        if (!divisionIds.length) return [];

        const filteredSelectedClasses = activities.classes.options.filter(
            (activity) => classIds.includes(activity.id) && divisionIds.includes(activity.division_id),
        );
        return filteredSelectedClasses.map((a) => a.id);
    };

    const handleSelect = (selected: number[], value: string) => {
        if (value === "section") {
            loadDivisions(selected);
        }

        if (value === "division") {
            loadEconomicActivities(selected);
        }

        setActivities((prev) => {
            const updatedActivities = {
                ...prev,
                [value]: {
                    ...prev[value as keyof typeof prev],
                    selected: selected,
                },
            };

            Object.keys(updatedActivities).forEach((activity: keyof iActivities) => {
                let show: boolean;
                let selected: number[];
                switch (activity) {
                    case "section":
                        show = true;
                        selected = updatedActivities.section.selected;
                        break;
                    case "division":
                        show = updatedActivities.section.selected.length > 0;
                        selected = filterBySelectedSections(updatedActivities.section.selected, updatedActivities.division.selected);
                        break;
                    case "classes":
                        show = updatedActivities.section.selected.length > 0 && updatedActivities.division.selected.length > 0;
                        selected = filterBySelectedDivisions(updatedActivities.division.selected, updatedActivities.classes.selected);
                        break;
                }
                updatedActivities[activity].show = show;
                updatedActivities[activity].selected = selected;
            });

            return updatedActivities;
        });
    };

    React.useEffect(() => {
        if (!project) return;
        setActivities((prev) => {
            const sectionIds = project.economic_activities.length
                ? [...new Set(project.economic_activities.map((ac) => ac.section_id))]
                : [];
            const divisionIds = project.economic_activities.length
                ? [...new Set(project.economic_activities.map((ac) => ac.division_id))]
                : [];

            if (sectionIds.length) loadDivisions(sectionIds);
            if (divisionIds.length) loadEconomicActivities(divisionIds);

            return {
                section: {
                    ...prev.section,
                    selected: sectionIds,
                },
                division: {
                    ...prev.division,
                    selected: divisionIds,
                    show: sectionIds.length > 0,
                },
                classes: {
                    ...prev.classes,
                    selected: project.economic_activities.map((activity) => activity.id),
                    show: divisionIds.length > 0,
                },
            };
        });
    }, [project?.economic_activities]);

    React.useEffect(() => {
        if (!site || !site.economic_activities) {
            return;
        } else {
            console.log("site from hook", site);
        }
        setActivities((prev) => {
            const sectionIds = site.economic_activities.length ? [...new Set(site.economic_activities.map((ac) => ac.industry_id))] : [];
            const divisionIds = site.economic_activities.length
                ? [...new Set(site.economic_activities.map((ac) => ac.subindustry_id))]
                : [];

            if (sectionIds.length) loadDivisions(sectionIds);
            if (divisionIds.length) loadEconomicActivities(divisionIds);

            return {
                section: {
                    ...prev.section,
                    selected: sectionIds,
                },
                division: {
                    ...prev.division,
                    selected: divisionIds,
                    show: sectionIds.length > 0,
                },
                classes: {
                    ...prev.classes,
                    selected: site.economic_activities.map((activity) => activity.economic_activity_id),
                    show: divisionIds.length > 0,
                },
            };
        });
    }, [site?.economic_activities]);

    React.useEffect(() => {
        loadSectionOptions();
    }, []);

    return [activities, handleSelect];
}

export function useSites() {
    const { state, dispatch } = useContext(AppContext);
    const team = useTeam();
    const project = useProject();
    const [page, setPage] = React.useState<number>(1);
    const [totalPages, setTotalPages] = React.useState<number>(1);
    const [order, setOrder] = React.useState<IOrderParam>({
        by: "name",
        direction: "asc",
    });

    const sites = state.sites;

    React.useEffect(() => {
        if (project && page < totalPages) {
            fetchSites(page + 1, order);
        }
    }, [page, totalPages]);

    const fetchSites = React.useCallback(
        async (pageToFetch: number, order: IOrderParam = { by: "site_name", direction: "asc" }) => {
            // const sitesUrl = `projects/${project.slug}/reports/${project.latest_report_id}/site/page/${pageToFetch}`;
            // const params = {
            //     'order_by': order.by,
            //     'order_direction': order.direction,
            // };
            let NewDbUrl = `${process.env.KUYUA_APP_BACKEND_URL}/sites?page=1&priority_location=true&sortBy=priority_score&sortOrder=desc&company_id=${team.id}`;
            let NewDbUrlQueries = [];
            NewDbUrlQueries.push("sortBy=" + order.by);
            NewDbUrlQueries.push("sortOrder=" + order.direction);
            try {
                NewDbUrl = NewDbUrl + "&" + NewDbUrlQueries.join("&");
                console.log(NewDbUrl);
                const response = await fetch(NewDbUrl, header);
                const data = await response.json();
                setPage(pageToFetch);
                setTotalPages(data.totalPages);

                // will be the case for first fetch or after reset
                let newSites: INewDBSite[];

                if (pageToFetch === 1) {
                    newSites = data.sites;
                } else {
                    newSites = [...sites, ...data.sites];
                }

                dispatch({ type: SitesChanged, sites: newSites });
                console.log("mapped sites from hook ===>>> ", data.sites);
            } catch (error) {
                console.error("Error fetching sites from kuyuaappdb:", error);
            }
            // apiGet<{pages: number, current_page: number, items: INewSite[]}>(team.slug, sitesUrl, params).then(reply => {
            //     if (reply.ok) {
            //         setPage(reply.data.current_page);
            //         setTotalPages(reply.data.pages);

            //         // will be the case for first fetch or after reset
            //         let newSites: INewSite[];

            //         if (pageToFetch === 1) {
            //             newSites = reply.data.items;
            //         } else {
            //             newSites = [...sites, ...reply.data.items];
            //         }

            //         dispatch({ type: SitesChanged, sites: newSites });
            //     }
            // });
        },
        [sites, order],
    );

    React.useEffect(() => {
        if (sites.length === 0 && project) {
            fetchSites(page);
        }
    }, [project]);

    const updateSites = React.useCallback(
        (orderParam?: IOrderParam) => {
            dispatch({ type: SitesChanged, sites: [] });
            setPage(1);
            setTotalPages(1);
            setOrder(orderParam);
            project && fetchSites(1, orderParam);
        },
        [project],
    );

    return { sites, fetchSites: updateSites };
}
