export function hasProperty(obj: Record<string, unknown>, name: string): boolean {
    return obj !== null && obj !== undefined ? Object.prototype.hasOwnProperty.call(obj, name) : false;
}

export function getKeyPath<T>(path: string, obj: Record<string, unknown>): T | undefined {
    const parts = path.split(".");
    let ctx = obj;

    for (let i = 0; i < parts.length; i += 1) {
        ctx = ctx[parts[i]] as Record<string, unknown>;

        if (ctx === undefined) {
            return undefined;
        }
    }

    return ctx as T;
}

export function take<T>(items: T[], maxCount: number): T[] {
    if (!items) {
        return [];
    }

    return items.slice(0, maxCount);
}

export function times<T>(count: number, fn: (index: number) => T): T[] {
    const items = [];

    for (let i = 0; i < count; i++) {
        items.push(fn(i));
    }

    return items;
}

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
    const ret: any = {}; // eslint-disable-line

    keys.forEach((key) => (ret[key] = obj[key]));

    return ret;
}

export function unique<T>(items: T[]): T[] {
    if (!items) {
        return [];
    }

    const set = new Set();
    const out = [] as T[];

    for (const item of items) {
        if (!set.has(item)) {
            set.add(item);
            out.push(item);
        }
    }

    return out;
}

export function groupBy<T, K>(items: T[], fn: (item: T) => K): Map<K, T[]> {
    const groups = new Map<K, T[]>();

    items.map((item) => {
        const key = fn(item);
        const node = groups.get(key);

        if (!node) {
            groups.set(key, [item]);
        } else {
            node.push(item);
        }
    });

    return groups;
}

export function getScopedTagValue(tags: string[], prefix: string, defaultValue = 0): number {
    for (const tag of tags) {
        if (tag.startsWith(prefix)) {
            const value = parseInt(tag.substring(prefix.length));

            if (!isNaN(value)) {
                return value;
            }
        }
    }

    return defaultValue;
}

export function getSiteName(site: INewSiteDetailed): string {
    if (site.name) {
        return site.name;
    }

    if (!site.address) {
        return "Unnamed Site";
    }

    const { city, country } = site.address;
    const { lat, lng } = site.latlng;

    return [city, country].filter((n) => !!n).join(", ") || `${Math.round(lat * 1000) / 1000}, ${Math.round(lng * 1000) / 1000}`;
}

export function mdSnippet(s: string, maxLength = 250): string {
    const trim = (s: string) => s.trim().replace(/[:]$/, "");

    for (const ln of s.split("\n")) {
        if (ln.trim() === "" || /^\S*[#1-9-]/.test(ln)) {
            continue;
        }

        return ln.length < maxLength ? trim(ln) : trim(ln.substring(0, maxLength)) + "…";
    }

    return "";
}

export function isObject(obj: unknown): boolean {
    return typeof obj === "object" && obj !== null && !Array.isArray(obj);
}

export function isEqual(a: unknown, b: unknown): boolean {
    if (a === b) {
        return true;
    }

    if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length !== b.length) {
            return false;
        }

        for (let i = 0; i < a.length; i++) {
            if (!isEqual(a[i], b[i])) {
                return false;
            }
        }

        return true;
    }

    if (isObject(a) && isObject(b)) {
        const ak = Object.keys(a).sort();
        const bk = Object.keys(b).sort();

        if (!isEqual(ak, bk)) {
            return false;
        }

        for (const k of ak) {
            if (!isEqual((a as Record<string, unknown>)[k], (b as Record<string, unknown>)[k])) {
                return false;
            }
        }

        return true;
    }

    return false;
}

export function isEmpty(obj: unknown): boolean {
    if (Array.isArray(obj)) {
        return obj.length === 0;
    }

    if (isObject(obj)) {
        return Object.keys(obj).length === 0;
    }

    return undefined;
}

export function generateId(): string {
    return `${new Date().getTime()}${Math.ceil(Math.random() * 10000)}`;
}

export function deepCopy(original: object): object {
    return JSON.parse(JSON.stringify(original));
}
