import { Rating } from "@/types/utils.d";

export const calculatePriorityScore = (rating: Rating, length: number) => {
    return rating === Rating.VERY_HIGH ? length * 2 : rating === Rating.MEDIUM ? length * 0.5 : length;
};

export const accumulatePriorityScore = (sites: ImpactDependencyValues[]) => {
    return sites.reduce((acc: any, item: any) => {
        return acc + calculatePriorityScore(item.rating, item.values.length);
    }, 0);
};
