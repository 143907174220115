import * as React from "react";
import { AppContext, ToastAdded } from "@/context";
import { Button, HorizontalStack, LabeledTextInput, VerticalStack } from "@/lib";
import { BaseModalDialog } from "@/lib/modals";
import { apiPost, t } from "@/utils";
import TeamRolePicker from "./TeamRolePicker";
import { mandatoryStringError, emailError } from "@/utils/validation";

interface IInviteTeamMemberProps {
    onClose: () => void;
}

export default function InviteTeamMember(props: IInviteTeamMemberProps): JSX.Element {
    const { state, dispatch } = React.useContext(AppContext);
    const [userData, setUserData] = React.useState({
        first_name: "",
        last_name: "",
        email: "",
        role: "member",
    });
    const [isSaving, setIsSaving] = React.useState(false);
    const firstNameRef = React.useRef<HTMLInputElement>(null);
    const lastNameRef = React.useRef<HTMLInputElement>(null);
    const emailRef = React.useRef<HTMLInputElement>(null);
    const [errors, setErrors] = React.useState<Record<string, string>>({});

    const validateFields = () => {
        const firstNameError = mandatoryStringError(userData.first_name);
        const lastNameError = mandatoryStringError(userData.last_name);
        const emailErrorString = emailError(userData.email);

        setErrors({
            firstName: firstNameError,
            lastName: lastNameError,
            email: emailErrorString,
        });

        if (firstNameError) {
            firstNameRef.current.focus();
        } else if (lastNameError) {
            lastNameRef.current.focus();
        } else if (emailErrorString) {
            emailRef.current.focus();
        }
    };

    const onInviteClick = () => {
        const formInvalid =
            mandatoryStringError(userData.first_name) || mandatoryStringError(userData.last_name) || emailError(userData.email);
        if (!formInvalid) {
            setIsSaving(true);
            apiPost<{ ok: boolean }>(state.config.team.slug, "members/invite", userData)
                .then((reply) => {
                    dispatch({
                        type: ToastAdded,
                        toast: reply?.ok
                            ? { kind: "success", text: t("ui.team_member_invite_succes") }
                            : {
                                  kind: "error",
                                  text: JSON.stringify(reply.data) || t("ui.unknown_error"),
                              },
                    });

                    if (reply.ok) {
                        props.onClose();
                    }
                })
                .finally(() => setIsSaving(false));
        } else {
            validateFields();
        }
    };

    return (
        <BaseModalDialog
            footer={
                <HorizontalStack horizontalAlign="flex-end" style={{ margin: "1rem 1.5rem" }}>
                    <Button onClick={props.onClose}>{t("actions.cancel")}</Button>
                    <Button disabled={isSaving} variant="primary" onClick={onInviteClick}>
                        {t("actions.invite")}
                    </Button>
                </HorizontalStack>
            }
            maxBodyHeight="calc(100vh - 15rem)"
            onClose={props.onClose}
            scrollableBody={true}
            title={t("ui.invite_new_member")}
        >
            <form>
                <VerticalStack>
                    <LabeledTextInput
                        ref={firstNameRef}
                        id="invite_first_name"
                        disabled={isSaving}
                        label={t("ui.first_name")}
                        onInput={(first_name) => setUserData({ ...userData, first_name })}
                        onBlur={() =>
                            setErrors({
                                ...errors,
                                firstName: mandatoryStringError(userData.first_name),
                            })
                        }
                        value={userData.first_name}
                        error={errors.firstName}
                    />

                    <LabeledTextInput
                        ref={lastNameRef}
                        id="invite_last_name"
                        disabled={isSaving}
                        label={t("ui.last_name")}
                        onInput={(last_name) => setUserData({ ...userData, last_name })}
                        onBlur={() =>
                            setErrors({
                                ...errors,
                                lastName: mandatoryStringError(userData.last_name),
                            })
                        }
                        value={userData.last_name}
                        error={errors.lastName}
                    />

                    <LabeledTextInput
                        ref={emailRef}
                        id="invite_email"
                        disabled={isSaving}
                        label={t("ui.email")}
                        onInput={(email) => setUserData({ ...userData, email })}
                        onBlur={() => setErrors({ ...errors, email: emailError(userData.email) })}
                        value={userData.email}
                        error={errors.email}
                    />

                    <TeamRolePicker disabled={isSaving} role={userData.role} onChange={(role) => setUserData({ ...userData, role })} />
                </VerticalStack>
            </form>
        </BaseModalDialog>
    );
}
