import * as React from "react";
import { AppContext } from "@/context";
import { take } from "@/utils";

interface IStaticMapProps {
    bbox?: number[];
    center?: ILatLng;
    pins?: IMapPin[];
    size: ISize;
    mapStyle?: string;
    zoom?: number;
}

export default function PinMap(props: IStaticMapProps): JSX.Element {
    const { state } = React.useContext(AppContext);
    const { width, height } = props.size;
    const size = `${width}x${height}@2x`;
    const token = state.config.mapbox_public_api_token;
    const mapStyle = props.mapStyle || "light-v10";
    const center = props.center ? `${props.center.lng},${props.center.lat},${props.zoom}` : `[${props.bbox.join(",")}]`;
    const pins =
        take(props.pins, 25)
            .map((p) => `pin-s+${p.color}(${p.center.lng},${p.center.lat})`)
            .join() || "";
    const url = `https://api.mapbox.com/styles/v1/mapbox/${mapStyle}/static/${pins}${pins ? "/" : ""}${center}/${size}?access_token=${token}`;

    return <img src={url} width={width} height={height} />;
}
