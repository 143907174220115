import React from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import { CSVLink } from "react-csv";
import { AppContext } from "@/context";
import IndigenousMap from "./IndigenousMap";
import CryptoJS from "crypto-js";
import HeaderKpi from "./HeaderKpi";
import LoadingAnimation from "@/lib/LoadingAnimation";
import { DownloadButtonDefault } from "@/components/DownloadButton";

const IndigenousPeople = ({ site, setTabId }) => {
    const [territoriesCount, setTerritoriesCount] = React.useState(0);
    const [territories, setTerritories] = React.useState([]);
    const [closestDistance, setClosestDistance] = React.useState(0);
    const [selected, setSelected] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (site?.latlng) {
            fetchIndigenousData();
        }
    }, [site]);
    React.useEffect(() => {
        setTabId("");
    }, [loading]);

    const fetchIndigenousData = async () => {
        try {
            setLoading(true);
            const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
            const header = {
                headers: {
                    Authorization: `Bearer ${encryptedToken}`,
                },
            };
            const geoServerURL = process.env.GEO_SERVER_URL;
            const siteRadius = parseInt(localStorage.getItem("siteRadius")) || 5000;
            const response = await fetch(
                `${geoServerURL}/indigenous/territories?lat=${site.latlng.lat}&lng=${site.latlng.lng}&radius=${siteRadius}`,
                header,
            );

            if (!response.ok) {
                throw new Error("Failed to fetch indigenous territories data");
            }

            const data = await response.json();
            handleTerritoriesChange(data);
        } catch (error) {
            console.error("Error fetching indigenous territories:", error);
            setTerritoriesCount(0);
            setTerritories([]);
            setClosestDistance(0);
        } finally {
            setLoading(false);
        }
    };

    function handleTerritoriesChange(data: any) {
        try {
            if (typeof data === "object" && data !== null) {
                setTerritoriesCount(data?.stats?.total_territories || 0);
                setTerritories(data?.features || []);
                setClosestDistance(data?.stats?.min_distance || 0);
            } else {
                throw new Error("Invalid data format");
            }
        } catch (error) {
            console.error("Error handling Indigenous Territories data:", error);
            setTerritoriesCount(0);
            setTerritories([]);
            setClosestDistance(0);
        }
    }

    // Prepare data for CSV export with caching
    const csvData = React.useMemo(() => {
        // Summary rows
        const summaryRows = [
            ["Number of Indigenous Territories", territoriesCount.toLocaleString()],
            ["Distance to closest Territory (m)", closestDistance ? Number(closestDistance).toLocaleString() : "N/A"],
        ];

        // Territory rows
        const territoryHeader = ["Name", "Identity", "Distance (m)"];
        const territoryRows = (Array.isArray(territories) ? territories : []).map((item) => [
            item?.properties?.name || "N/A",
            item?.properties?.identity || "N/A",
            item?.properties?.distance_meters ? Number(item.properties.distance_meters).toLocaleString() : "N/A",
        ]);

        return [...summaryRows, [""], territoryHeader, ...territoryRows];
    }, [territories, territoriesCount, closestDistance]);

    return (
        <div>
            {loading ? (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <LoadingAnimation text="Loading indigenous territories data... This process may take a few moments" />
                </div>
            ) : (
                <>
                    {/* CSV Export Button */}
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            position: "relative",
                            gap: "10px",
                        }}
                    >
                        <CSVLink data={csvData} filename={`${site?.name || "site"}_indigenous_territories.csv`}>
                            <DownloadButtonDefault>Export CSV</DownloadButtonDefault>
                        </CSVLink>
                    </div>
                    <br />
                    <div style={{ display: "flex", flexDirection: "row", flex: 1, gap: 30 }}>
                        <div style={styles.wrapper}>
                            <div className="table-container">
                                <table className="s-table s-table-fixed species-table" style={{ textAlign: "left" }}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Identity</th>
                                            <th>Distance (m)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(territories) && territories.length > 0 ? (
                                            territories.map((territory, index) => (
                                                <tr key={index}>
                                                    <td onClick={() => setSelected(territory)}>{territory?.properties?.name || "N/A"}</td>
                                                    <td>{territory?.properties?.identity || "N/A"}</td>
                                                    <td>
                                                        {territory?.properties?.distance_meters
                                                            ? Number(territory.properties.distance_meters).toFixed(0)
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={4}>No Records.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: 20 }}>
                            <div style={styles.summaryHeader}>
                                <HeaderKpi label="Indigenous Territories" value={territoriesCount || 0} />
                                <HeaderKpi label="Distance to closest (m)" value={Number(closestDistance).toFixed(0) || 0} />
                            </div>
                            <div style={{ position: "relative", minHeight: 500 }}>
                                <IndigenousMap site={site} territories={territories} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const styles = StyleSheet.create({
    wrapper: {
        width: "50%",
        borderRadius: 10,
        border: `1px solid ${colors.brightBlue}`,
        padding: 10,
        backgroundColor: colors.darkBlue,
    },
    summaryHeader: {
        height: 90,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: colors.darkBlue,
        borderRadius: 10,
        padding: "5px 20px",
    },
    export: {
        height: "100%",
        borderRadius: 20,
        backgroundColor: "transparent",
        opacity: 1,
        padding: "5px 10px",
        border: `1px solid ${colors.brightBlue}`,
        color: colors.brightBlue,
        "&:hover": {
            backgroundColor: colors.brightBlue,
            color: colors.white,
        },
    },
});

export default IndigenousPeople;
