import React from "react";

export const ClimateRiskLegend: React.FC = () => {
    const legendItems = [
        { color: "#3DA46D", label: "Low" },
        { color: "#F6BB43", label: "Medium" },
        { color: "#FF8C42", label: "High" },
        { color: "#F1654C", label: "Severe" },
        { color: "#6C7A89", label: "No Data" },
    ];

    return (
        <div style={{ marginTop: 16, display: "flex", gap: 20 }}>
            {legendItems.map(({ color, label }) => (
                <div key={label}>
                    <span
                        style={{
                            width: 16,
                            height: 16,
                            backgroundColor: color,
                            display: "inline-block",
                            marginRight: 5,
                        }}
                    />
                    {label}
                </div>
            ))}
        </div>
    );
};
