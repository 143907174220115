export const SpeciesChanged = "species/speciesChanged";

export function makeSpeciesState(): ISpeciesState {
    return {
        species: {},
    };
}

export const speciesReducer = (state: ISpeciesState, action: IGenericAction): ISpeciesState => {
    switch (action.type) {
        case SpeciesChanged:
            state.species = { ...state.species, [action.species.id]: action.species };
            return state;
    }

    return state;
};
