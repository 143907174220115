import * as React from "react";
import ColorLegend from "../ColorLegend";
import Tooltip from "../Tooltip/Tooltip";
import "./VerticalBarGraph.scss";

export interface IBarYValue {
    value: number;
    name: string;
    color: string;
    //TODO add background patterns to bars for better accessibility?
}

export interface IVerticalBar {
    yValues: IBarYValue[];
    xValue: string;
}

interface IVerticalBarGraphProps {
    bars: IVerticalBar[];
    caption?: string;
    yMax?: number;
    showLegend?: boolean;
    yMin?: number;
}

export default function VerticalBarGraph(props: IVerticalBarGraphProps): JSX.Element {
    const [yMax, setYMax] = React.useState<number>(0);
    const [tooltip, setTooltip] = React.useState<{
        title: string;
        text: string;
        id: string;
    }>(undefined);
    const [bars, setBars] = React.useState<{ fullHeight: number; heightPercentage: number; values: IVerticalBar }[]>([]);
    const [colorLegend, setColorLegend] = React.useState<{ color: string; text: string }[]>(undefined);

    const getPreferredStyle = (columnIndex: number): "right" | "left" => {
        const minWindowWidthForTooltip = 1200;
        return window.innerWidth < minWindowWidthForTooltip && columnIndex > bars.length / 2 ? "right" : "left";
    };

    const getPercentage = (value: number, max: number) => {
        return Math.round((value / max) * 100);
    };

    const getYSegmentsSum = (bar: IVerticalBar) => {
        return bar.yValues
            .map((y) => y.value)
            .reduce((accumulator, y) => {
                return accumulator + y;
            }, 0);
    };

    const getTallestBar = (bars: IVerticalBar[]) => {
        const fullBarValues = bars.map((bar) => getYSegmentsSum(bar));

        return Math.max(...fullBarValues);
    };

    const createColorLegend = (bars: IVerticalBar[]) => {
        const yValues = bars.flatMap((bar) => bar.yValues);
        const legend: { color: string; text: string }[] = [];
        yValues.forEach((y) => {
            if (!legend.find((color) => color.color === y.color)) {
                legend.push({ color: y.color, text: y.name });
            }
        });
        return legend;
    };

    React.useEffect(() => {
        const bars = props.bars;
        const max = getTallestBar(bars);
        if (yMax !== max) {
            setYMax(max);
        }

        setBars(
            bars.map((bar) => {
                const height = getYSegmentsSum(bar);
                return {
                    fullHeight: height,
                    heightPercentage: getPercentage(height, max),
                    values: bar,
                };
            }),
        );

        setColorLegend(props.showLegend ? createColorLegend(bars) : undefined);
    }, [props.bars, props.showLegend]);

    return (
        <figure className={`s-bar-graph ${bars?.length === 1 ? "s-bar-graph--one-bar" : ""}`}>
            {props.caption && <figcaption className="s-bar-graph__caption">{props.caption}</figcaption>}
            <div className="s-bar-graph__outer_container">
                <div className="s-bar-graph__graph-container">
                    <div className="s-bar-graph__y-axis">
                        <div>{yMax}</div>
                        <div>{Math.round(yMax / 2)}</div>
                        <div>{props.yMin || 0}</div>
                    </div>
                    <ul className="s-bar-graph-list">
                        {bars.map((bar, barIndex) => (
                            <li key={barIndex + "-" + bar.heightPercentage} className="s-bar-graph__bar-container">
                                <div className={`s-bar-graph__percentage s-bar-graph__percentage-${bar.heightPercentage}`}>
                                    {bar.values.yValues.map((yValue, segmentIndex) => (
                                        <div
                                            key={barIndex + "- " + segmentIndex}
                                            className={`s-bar-graph__percentage s-bar-graph__percentage-${getPercentage(yValue.value, bar.fullHeight)}`}
                                            style={{ backgroundColor: yValue.color }}
                                            onMouseOver={() =>
                                                setTooltip({
                                                    title: bar.values.xValue,
                                                    text: yValue.name + ": " + yValue.value,
                                                    id: barIndex + "- " + segmentIndex,
                                                })
                                            }
                                            onMouseOut={() => setTooltip(undefined)}
                                        >
                                            {tooltip && tooltip.id === barIndex + "- " + segmentIndex && (
                                                <Tooltip alignment={getPreferredStyle(barIndex)}>
                                                    {tooltip.title}
                                                    <br />
                                                    {tooltip.text}
                                                </Tooltip>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className={`s-bar-graph__x-text ${bars.length === 1 && "s-bar-graph__x-text--single"}`}>
                                    {bar.values.xValue}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                {colorLegend && <ColorLegend colors={colorLegend} />}
            </div>
        </figure>
    );
}
