import * as React from "react";
import StaticMapWrapper from "@/lib/map/StaticMapWrapper";
import { AllSitesTable } from "@/project/measure/AllSitesTable";
import { colors, StyleSheet } from "@/utils/style";

export default function ActAndTrackPage(): JSX.Element {
    return (
        <div className="content">
            <StaticMapWrapper right={true} />
            <div style={styles.wrapper}>
                <div style={styles.tableWrapper}>
                    <AllSitesTable activeDetailTab="actions" />
                </div>
            </div>
        </div>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        width: "80%",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        top: 125,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        gap: 5,
        zIndex: 10,
    },
    tableWrapper: {
        width: "100%",
        backgroundColor: colors.darkBlue,
        border: `1px solid ${colors.brightBlue}`,
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
});
