import * as React from "react";
import { Radio, VerticalStack } from "@/lib";
import { t } from "@/utils";

interface ITeamRolePickerProps {
    disabled: boolean;
    onChange: (role: string) => void;
    role: string;
    style?: React.CSSProperties;
}

export default function TeamRolePicker(props: ITeamRolePickerProps): JSX.Element {
    const { role, onChange } = props;

    return (
        <VerticalStack spacing="tight" style={props.style}>
            <div
                style={{
                    fontFamily: "Roobert Medium",
                    fontSize: "1.1rem",
                    marginBottom: "1rem",
                }}
            >
                {t("ui.role")}
            </div>

            <Radio
                checked={role === "admin"}
                disabled={props.disabled}
                help="Can manage billing, team members, sites and view site assessments and action plans"
                label="Administrator"
                onToggle={() => onChange("admin")}
            />
            <Radio
                checked={role === "manager"}
                disabled={props.disabled}
                help="Can manage sites and view site assessments and action plans"
                label="Manager"
                onToggle={() => onChange("manager")}
            />
            <Radio
                checked={role === "member"}
                disabled={props.disabled}
                label="Member"
                help="Can view site assessments and action plans"
                onToggle={() => onChange("member")}
            />
        </VerticalStack>
    );
}
